import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import FragmaticBuilder from '../redux/fragmatic-builder/reducer';
import GlobalConfigurations from '../redux/global-configurations/reducer';
import DesignComponents from '../redux/design-components/reducer';
import Export from '../redux/export/reducer';
import Token from '../redux/login/reducer';
import User from '../redux/user/reducer';
import ProjectRole from '../redux/project-role/reducer';
import GlobalTraits from '../redux/global-traits/reducer';

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  whitelist: [
    'Token',
    'User',
  ],
  stateReconciler: autoMergeLevel2,
  debug: true,
};

const rootReducer = combineReducers({
  FragmaticBuilder,
  GlobalConfigurations,
  DesignComponents,
  GlobalTraits,
  Export,
  Token,
  User,
  ProjectRole,
});

export default persistReducer(rootPersistConfig, rootReducer);