const getHtml = () => {
  return `
    <div class="alert alert-dark" role="alert">
  A simple dark alert—check it out!
</div>`;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const DarkAlert = { getHtml, getCss, getJs };

export default DarkAlert;
