import { Collapse, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  setAtoms,
  setMolecules,
  setOrganisms,
  setLayouts,
  setPages,
} from '../../../redux/design-components/actions';

import AtomsDesigner from './atoms';
import MoleculesDesigner from './molecules';
import OrganismsEditor from './organisms';
import LayoutsEditor from './layouts';
import PagesEditor from './pages';
import { useParams } from 'react-router-dom';
import axios from '../../../utils/axios';
import ErrorPage from '../../common/ErrorPage';

const { Panel } = Collapse;

const DesignComponents = ({
  atoms,
  molecules,
  organisms,
  initialState,
  setAtoms,
  setMolecules,
  setOrganisms,
  setLayouts,
  setPages,
}) => {
  const [loading, setLoading] = useState(true);
  const [netError, setNetError] = useState(false);
  const { projectId, step } = useParams();

  useEffect(() => {
    // hit the component get api here
    axios
      .get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}`
      )
      .then(data => {
        if (data) {
          setAtoms([...data.data?.atoms]);
          setMolecules([...data.data?.molecules]);
          setOrganisms([...data.data?.organisms]);
          setLayouts([...data.data?.layouts]);
          setPages([...data.data?.pages]);
        }
        setLoading(false);
      })
      .catch(err => {
        if (!err.status) {
          // network error
          setNetError(true);
        }
        setLoading(false);
        console.log(err, 'error');
      });
  }, []);
  if (loading) {
    return (
      <center>
        <Spin
          tip="Loading..."
          size="large"
          style={{ display: 'flex', justifyContent: 'center' }}
        />
      </center>
    );
  }
  return netError ? (
    <ErrorPage errorMessage="Please check your Internet connection" />
  ) : (
    <>
      <Collapse
        style={{ marginTop: 30 }}
        accordion
        defaultActiveKey={['panel-atoms']}
      >
        <Panel header="Atoms" key="panel-atoms">
          <AtomsDesigner />
        </Panel>
        <Panel header="Molecules" key="panel-molecules">
          <MoleculesDesigner />
        </Panel>
        <Panel header="Organisms" key="panel-organisms">
          <OrganismsEditor />
        </Panel>
        <Panel header="Drupal Components" key="panel-layouts">
          <LayoutsEditor />
        </Panel>
        <Panel header="Pages" key="panel-pages">
          <PagesEditor />
        </Panel>
      </Collapse>
    </>
  );
};

const mapStateToProps = state => {
  return {
    initialState: state.DesignComponents,
    atoms: state.DesignComponents.atoms,
    molecules: state.DesignComponents.molecules,
    organisms: state.DesignComponents.organisms,
    // currentConfigs: state.GlobalConfigurations.finalConfigs,
  };
};

const mapDispatchToProps = dispatch => ({
  setAtoms: atoms => {
    dispatch(setAtoms(atoms));
  },
  setMolecules: molecules => {
    dispatch(setMolecules(molecules));
  },
  setOrganisms: organisms => {
    dispatch(setOrganisms(organisms));
  },
  setLayouts: layouts => {
    dispatch(setLayouts(layouts));
  },
  setPages: pages => {
    dispatch(setPages(pages));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DesignComponents);
