import Editor from '@monaco-editor/react';
import { Form, Button } from 'antd';
import { useEffect, useState } from 'react';

const InputCode = ({
  language = 'javascript',
  defaultValue = '',
  setValue,
  editorHeight = '60vh',
  setIsNextDisabled = false,
}) => {
  const [editor, setEditor] = useState();
  const [error, setError] = useState();

  function handleEditorChange(value) {
    setValue(value);
  }

  function handleEditorDidMount(editor, monaco) {
    setEditor(editor);
  }
  function handleEditorValidation(markers) {
    // model markers
    let errorMsg = '';
    markers.forEach(marker => {
      errorMsg = `${marker.startLineNumber}:${marker.startColumn}  ${marker.message}`;
    });
    setError(errorMsg);
    if (setIsNextDisabled) {
      errorMsg ? setIsNextDisabled(true) : setIsNextDisabled(false);
    }
  }
  const editorOptions = {
    minimap: {
      enabled: false,
    },
    theme: 'vs-dark',
  };
  useEffect(() => {
    editor &&
      setTimeout(() => {
        editor.getAction('editor.action.formatDocument').run();
      }, 100);
  }, [editor]);
  return (
    <>
      <Form.Item>
        <Editor
          height={editorHeight}
          defaultLanguage={language}
          value={defaultValue}
          onChange={handleEditorChange}
          onValidate={handleEditorValidation}
          onMount={handleEditorDidMount}
          options={editorOptions}
        />
      </Form.Item>
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <Button
          type="link"
          onClick={() => {
            editor.getAction('editor.action.formatDocument').run();
          }}
        >
          Format Code
        </Button>
        <p style={{ color: 'red', marginLeft: '20px' }}>{error ? error : ''}</p>
      </div>
    </>
  );
};
export default InputCode;
