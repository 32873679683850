import { Form, Input, Select, Button } from 'antd';
import { useEffect, useState } from 'react';
const PictureTraits = ({
  form,
  globalImageStyleOptions,
  pictureSourceCollection,
  handleAddPictureCollection,
  globalMediaOptions,
  handleAddAnotherSource,
  child_level_1_index,
  child_level_2_index,
  child_level_3_index,
}) => {
  const [selectedImageStyleOptions, setSelectedImageStyleOptions] = useState(
    []
  );

  const handleImageTraits = label => {
    setSelectedImageStyleOptions(label);
  };

  const naming_condition = field => {
    if (child_level_3_index !== undefined) {
      return `${field}_${child_level_1_index}_${child_level_2_index}_${child_level_3_index}`;
    } else if (child_level_2_index !== undefined) {
      return `${field}_${child_level_1_index}_${child_level_2_index}`;
    } else if (child_level_1_index !== undefined) {
      return `${field}_${child_level_1_index}`;
    } else {
      return `${field}`;
    }
  };

  const naming_condition_2 = field => {
    if (child_level_3_index !== undefined) {
      return `child_para_${child_level_1_index}_${child_level_2_index}_${child_level_3_index}_${field}`;
    } else if (child_level_2_index !== undefined) {
      return `child_para_${child_level_1_index}_${child_level_2_index}_${field}`;
    } else if (child_level_1_index !== undefined) {
      return `child_para_${child_level_1_index}_${field}`;
    } else {
      return `${field}`;
    }
  };

  let name_for_source = naming_condition('fall_back_image_src_link');
  let name_for_alt_text = naming_condition('fall_back_image_alt_text');
  let name_for_style = naming_condition('fall_back_image_style');

  return (
    <>
      <Form.Item
        label="Fall Back Image source"
        name={name_for_source}
        rules={[
          {
            required: true,
            message: 'Please input image source link',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Fall Back Image Alt Text"
        name={name_for_alt_text}
        rules={[
          {
            required: true,
            message: 'Please input image alt text',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Fall Back Image styles"
        name={name_for_style}
        rules={[
          {
            required: true,
            message: 'Please select any of image style from the list',
          },
        ]}
      >
        <Select
          style={{ width: 120 }}
          onChange={(value, label) => {
            handleImageTraits(label);
          }}
          options={[...globalImageStyleOptions]}
        />
      </Form.Item>
      {pictureSourceCollection?.map((eachCollection, index) => {
        return (
          <div key={index}>
            <Form.Item
              label="Source"
              name={naming_condition_2(`source${index}`)}
              rules={[
                {
                  required: true,
                  message: 'Please input image source link',
                },
              ]}
            >
              <Input
                label="Source"
                name="source"
                value={eachCollection[`source${index}`]}
                onChange={event => handleAddPictureCollection(event, index)}
              />
            </Form.Item>
            <Form.Item
              label="Media"
              name={naming_condition_2(`media${index}`)}
              rules={[
                {
                  required: true,
                  message: 'Please input image source link',
                },
              ]}
            >
              <Select
                style={{ width: 120 }}
                value={eachCollection.media}
                onChange={event =>
                  handleAddPictureCollection(event, index, 'select')
                }
                options={globalMediaOptions}
              />
            </Form.Item>
          </div>
        );
      })}
      <div>
        <Button onClick={handleAddAnotherSource}>Add Another Source</Button>
      </div>
    </>
  );
};

export default PictureTraits;
