import * as TYPES from './action-types';

export const setAtoms = data => ({
  type: TYPES.SET_ATOMS,
  data,
});

export const setMolecules = data => ({
  type: TYPES.SET_MOLEULES,
  data,
});

export const setOrganisms = data => ({
  type: TYPES.SET_ORGANISMS,
  data,
});

export const setLayouts = data => ({
  type: TYPES.SET_LAYOUTS,
  data,
});

export const setPages = data =>({
  type: TYPES.SET_PAGES,
  data,
})
