const getHtml = () => {
    return `
    <div class="cta">
  <h2>This is a reason to act</h2>
<a form="" target="_blank" href="#" data-text="Click here" aria-label="button" class="btn">
      <span class="button__inner-rollover-text">Click here</span>
  </a>
</div>
    `;
  };
  
  const getCss = () => {
    return `
    document.getElementById('slider').addEventListener(
      'click', () =>{
        console.log('from slider click')
      }
    )
    `;
  };
  
  const getJs = () => {
    return ``;
  };
  
  const CTA = { getHtml, getCss, getJs };
  
  export default CTA;
