const getHtml = () => {
  return `
    <div class="progress">
    <div class="progress-bar progress-bar-striped bg-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
  </div>
          `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const Striped = { getHtml, getCss, getJs };

export default Striped;
