import * as TYPES from './action-types';

import CONSTANTS from '../../utils/constants';

const initialState = {
  atoms: CONSTANTS.COMPONENTS.ATOMS,
  molecules: CONSTANTS.COMPONENTS.MOLECULES,
  organisms: CONSTANTS.COMPONENTS.ORGANISMS,
  layouts: CONSTANTS.COMPONENTS.LAYOUTS,
  pages: CONSTANTS.COMPONENTS.PAGES,
};

const DesignComponents = (state = initialState, { type, data }) => {
  switch (type) {
    case TYPES.SET_ATOMS:
      return {
        ...state,
        atoms: data,
      };
    case TYPES.SET_MOLEULES:
      return {
        ...state,
        molecules: data,
      };
    case TYPES.SET_ORGANISMS:
      return {
        ...state,
        organisms: data,
      };
    case TYPES.SET_LAYOUTS:
      return {
        ...state,
        layouts: data,
      };
    case TYPES.SET_PAGES:
      return {
        ...state,
        pages: data,
      };
    default:
      return state;
  }
};

export default DesignComponents;
