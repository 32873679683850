/* eslint-disable jsx-a11y/anchor-is-valid */
import { Modal, Table, Typography, Alert, Button, Row, Col } from 'antd';
import axios from '../../../utils/axios/index';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import moment from 'moment';

const formatDate = timestamp => moment(timestamp).format('YYYY/MM/DD hh:mm A');

const PrevExports = ({ forceUpdate }) => {
  const { Text } = Typography;
  const { projectId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState(null);
  const [message, setMessage] = useState('');
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      align: 'center',
      key: 'name',
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
      align: 'center',
      render: text => {
        return text ? <>{(text / 1024).toFixed(2)} KB</> : '-';
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      align: 'center',
      key: 'creates_at',
    },
    {
      title: 'Created By',
      dataIndex: 'user',
      align: 'center',
      key: 'user',
    },
    {
      title: 'Download Link',
      dataIndex: 'file',
      align: 'center',
      key: 'download_link',
      render: (text, record) => {
        return text ? (
          <a type="primary" href={text}>
            Download
          </a>
        ) : (
          '-'
        );
      },
    },
    {
      title: 'Export Status',
      dataIndex: 'exportStatus',
      key: 'exportStatus',
      align: 'center',
      render: text => (
        <span
          style={{
            color:
              text === 'error'
                ? 'red'
                : text === 'success'
                ? 'green'
                : text === 'pending'
                ? 'orange'
                : '',
            fontWeight: 'bold',
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: 'Pushed To Repo',
      dataIndex: 'status',
      align: 'center',
      key: 'status',
      render: (text, record) => {
        return (
          <span
            style={{
              color:
                text === 'error'
                  ? 'red'
                  : text === 'success'
                  ? 'green'
                  : text === 'pending'
                  ? 'orange'
                  : '',
              fontWeight: 'bold',
            }}
          >
            {text === 'null' ? '-' : text}
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    const prevExports = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/exports`
        );

        if (res.data?.docs?.length > 0) {
          const tableData = res.data?.docs?.map((ele, index) => {
            return {
              name: ele.name,
              ...(ele.size && { size: ele.size }),
              createdAt: formatDate(ele.createdAt),
              ...(ele.file && { file: ele.file }),
              user: ele.user
                ? `${ele.user?.firstName} ${ele.user?.lastName}`
                : `-`,
              status: ele.status,
              exportStatus: ele.exportStatus,
            };
          });

          const message = res.data?.docs[0]?.user
            ? `Last exported by ${res.data.docs[0].user?.firstName} ${
                res.data.docs[0].user?.lastName
              } at ${formatDate(res.data.docs[0].createdAt)}`
            : `Last exported at ${formatDate(res.data.docs[0].createdAt)}`;

          setData(tableData);
          setMessage(message);
        } else {
          const message = `No Export History found`;
          setMessage(message);
        }
      } catch (error) {
        console.log('error', error);
      }
    };

    prevExports();
  }, [forceUpdate]);

  return (
    <>
      <Modal
        closable={false}
        open={showModal}
        onOk={() => setShowModal(false)}
        title="Exports History"
        cancelButtonProps={{ style: { display: 'none' } }}
        width={1200}
      >
        <Alert
          message="Note: Exports are automatically deleted after 7 days."
          type="info"
          style={{ marginBottom: 20 }}
        />
        {data ? (
          <Table
            dataSource={data}
            columns={columns}
            pagination={{ pageSize: 7, showSizeChanger: false }}
            size="small"
          />
        ) : (
          <Text>No Export history found</Text>
        )}
      </Modal>

      <Row justify="end">
        <Col>
          <Button type="link" onClick={() => setShowModal(true)}>
            {message}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default PrevExports;
