const getHtml = () => {
  return `
    <div class="hero__banner">
  
        <img class="image" src="https://placeimg.com/1680/684/nature" alt="This is the banner image's alt text">
            <div class="hero__banner__content">
                <h1 class="hero__banner__heading">
                        This is the banner heading
                </h1>
                <h3 class="hero__banner__subheading">
                    Curabitur non nulla sit amet nisl tempus convallis quis ac lectus
                </h3>
                <p class="hero__banner__body">
                    ellentesque in ipsum id orci porta dapibus. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.
                </p>
            </div>
    </div>
    `;
};

const getJs = () =>{
    return `
    document.getElementById('slider').addEventListener(
        'click', () =>{
          console.log('from slider click')
        }
      )
      
    `
}

const getCss = () =>{
    return `
    `
}

const Banner = { getHtml, getCss, getJs };
  
export default Banner;