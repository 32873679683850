const getHtml = () => {
  return `
    <button type="button" class="btn btn-secondary">Secondary</button>
  `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const SecondaryButton = { getHtml, getCss, getJs };

export default SecondaryButton;
