/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Table,
  Typography,
} from 'antd';
import React, { useEffect } from 'react';
import uuid from 'react-uuid';
import { useState } from 'react';

const { Title } = Typography;

const FONTFILEFORMAT = [
  {
    value: 'opentype',
    label: 'OTF (OpenType font)',
  },
  {
    value: 'woff',
    label: 'woff (Web Open Font Format)',
  },
  {
    value: 'woff2',
    label: 'woff2 (Web Open Font Format 2.0)',
  },
  {
    value: 'truetype',
    label: 'ttf (TrueType font)',
  },
];

const TypographyForm = ({
  allFonts,
  typographyFields,
  setTypographyFields,
}) => {
  const [typographyForm] = Form.useForm();
  const [editRecord, setEditRecord] = useState(null);
  //------------------ columns for typography -------------------------//

  useEffect(() => {
    if (editRecord) {
      typographyForm.setFieldsValue({
        font_family: editRecord.font_family,
        font_file_url: editRecord.font_file_url,
        font_file_format: editRecord.font_file_format,
        font_weight: editRecord.font_weight,
      });
    }
  }, [editRecord]);

  const typographyColumns = [
    {
      title: 'Name',
      dataIndex: 'font_family',
      key: 'font_family',
    },
    {
      title: 'Asset Url',
      dataIndex: 'font_file_url',
      key: 'font_file_url',
    },
    {
      title: 'Format',
      dataIndex: 'font_file_format',
      key: 'font_file_format',
    },
    {
      title: 'Font weight',
      dataIndex: 'font_weight',
      key: 'font_weight',
    },

    {
      title: 'Delete',
      dataIndex: '',
      key: '',
      render: (_, record) => {
        return (
          <a
            onClick={() => {
              Modal.confirm({
                title: 'Delete Alert.',
                content: 'Do you want to delete it?',
                okText: 'Yes',
                cancelText: 'No',
                onOk: () => {
                  const allValues = typographyFields.filter(
                    ele => ele.id !== record.id
                  );
                  setTypographyFields(allValues);
                },
              });
            }}
          >
            Delete
          </a>
        );
      },
    },
    {
      title: 'Edit',
      dataIndex: '',
      key: '',
      render: (_, record) => {
        return (
          <a
            onClick={() => {
              setEditRecord(record);
            }}
          >
            Edit
          </a>
        );
      },
    },
  ];
  //------------------------------------------------------------------//
  const onFinish = values => {
    values.font_family = values.font_family.trim();
    if (editRecord) {
      const newFields = typographyFields.map(field => {
        if (field.id === editRecord.id) {
          field = {
            id: field.id,
            ...values,
          };
        }
        return field;
      });
      setTypographyFields(newFields);
      setEditRecord(null);
      typographyForm.resetFields();
    } else {
      const valueToPush = { id: uuid(), ...values };
      setTypographyFields([...typographyFields, valueToPush]);
      typographyForm.resetFields();
    }
  };

  return (
    <>
      <h3>Typography:</h3>
      <Row gutter={20}>
        <Col span={6}>
          <Title level={5} style={{ textAlign: 'center' }}>
            {editRecord ? 'Edit Typography' : 'Add Typography'}
          </Title>
          <Form
            name="typographyForm"
            form={typographyForm}
            onFinish={onFinish}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            labelAlign="left"
          >
            <Form.Item
              label="Font Family"
              rules={[
                { required: true, message: 'Please input Font Family' },
                { whitespace: true },
                {
                  pattern: /^[A-Za-z0-9_-]+$/,
                  message: "Invalid Input Special Character's not allowed",
                },
              ]}
              name="font_family"
            >
              <Input placeholder="Font Family" />
            </Form.Item>
            <Form.Item
              label="Font File"
              rules={[{ required: true, message: 'Please select Font Url' }]}
              name="font_file_url"
            >
              <Select
                placeholder="Font Url"
                style={{
                  width: '100%',
                }}
                options={allFonts}
              />
            </Form.Item>
            <Form.Item
              label="File Format"
              rules={[
                { required: true, message: 'Please select Font file format' },
              ]}
              name="font_file_format"
            >
              <Select
                placeholder="Please select Font file format"
                style={{
                  width: '100%',
                }}
                options={FONTFILEFORMAT}
              />
            </Form.Item>
            <Form.Item
              label="Font Weight"
              rules={[
                { required: true, message: 'Please enter font weight' },
              ]}
              name="font_weight"
            >
              <InputNumber
                min={1}
                style={{
                  width: '50%',
                }}
                placeholder="Font weight"
              />
            </Form.Item>
            <Button type="primary" onClick={() => typographyForm.submit()}>
              {editRecord ? 'Edit Typography' : 'Add Typography'}
            </Button>
          </Form>
        </Col>
        <Col span={18}>
          {typographyFields && typographyFields.length > 0 && (
            <Table
              dataSource={typographyFields}
              columns={typographyColumns}
              pagination={false}
              bordered
              size="small"
              scroll={{
                y: 240,
              }}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default TypographyForm;
