import { useState } from 'react';
import { Form } from 'antd';
import InputColor from 'react-input-color';

export default function ColorPicker({ name ,editRecord }) {
  const [color, setColor] = useState(null);

  return (
    <>
      <Form.Item label="Choose Color" name={name}>
        <InputColor
          initialValue= {editRecord ? editRecord.hexcode : "#FFFFFF"}
          onChange={setColor}
          placement="right"
        />
      </Form.Item>
    </>
  );
}
