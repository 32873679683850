const getHtml = () => {
  return `
  <div class="alert alert-info" role="alert">
  A simple info alert—check it out!
</div>
          `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const InfoAlert = { getHtml, getCss, getJs };

export default InfoAlert;
