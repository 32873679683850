import { CKEditor as Editor } from 'ckeditor4-react';
import { useEffect, useRef, useState } from 'react';
import { replaceVariables } from '../../../utils/helpers/replaceTraits';

const CKEditor = ({
  entity,
  editorRef,
  bootstrap_css,
  saveRef,
  childCss = [],
  component
}) => {
  if (!entity.html?.trim()) {
    entity.html = entity.html;
  }

  const data = `${entity.html}`;

  const TraitsToTokens = () => {
    const tokens = [];

    entity.traits?.forEach(trait => {
      let arrayToPush = [];

      if (trait.type === 'paragraph') {
        arrayToPush = [
          `<!--START-LOOP-{{${trait.name}}}-->`,
          `<!--START-LOOP-{{${trait.name}}}-->`,
        ];

        tokens.push(arrayToPush);

        trait.children?.forEach(child1 => {
          if (child1.type === 'paragraph') {
            arrayToPush = [
              `<!--START-LOOP-{{${trait.name.slice(0, -1)}.${child1.name}}}-->`,
              `<!--START-LOOP-{{${trait.name.slice(0, -1)}.${child1.name}}}-->`,
            ];

            tokens.push(arrayToPush);
            child1.children?.forEach(child2 => {
              if (child2.type === 'paragraph') {
                arrayToPush = [
                  `<!--START-LOOP-{{${trait.name.slice(
                    0,
                    -1
                  )}.${child1.name.slice(0, -1)}.${child2.name}}}-->`,
                  `<!--START-LOOP-{{${trait.name.slice(
                    0,
                    -1
                  )}.${child1.name.slice(0, -1)}.${child2.name}}}-->`,
                ];

                tokens.push(arrayToPush);
                child2.children?.forEach(child3 => {
                  if (child3.type === 'image') {
                    child3.children?.forEach(child4 => {
                      arrayToPush = [
                        `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                          0,
                          -1
                        )}.${child2.name.slice(0, -1)}.${child3.name}.${
                          child4.name
                        } }}`,
                        `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                          0,
                          -1
                        )}.${child2.name.slice(0, -1)}.${child3.name}.${
                          child4.name
                        } }}`,
                      ];
                      tokens.push(arrayToPush);

                      // for classes---
                      arrayToPush = [
                        `start_curly_${trait.name.slice(
                          0,
                          -1
                        )}_dot_${child1.name.slice(
                          0,
                          -1
                        )}_dot_${child2.name.slice(0, -1)}_dot_${
                          child3.name
                        }_dot_${child4.name}_end_curly`,
                        `start_curly_${trait.name.slice(
                          0,
                          -1
                        )}_dot_${child1.name.slice(
                          0,
                          -1
                        )}_dot_${child2.name.slice(0, -1)}_dot_${
                          child3.name
                        }_dot_${child4.name}_end_curly`,
                      ];
                      tokens.push(arrayToPush);
                    });
                  } else if (child3.type === 'picture') {
                    child3.children?.forEach(child4 => {
                      if (child4.type === 'image') {
                        // Disabled to not make tokens for images inside pictures.
                        // child4?.children.forEach((child5, i) => {
                        //   arrayToPush = [
                        //     `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                        //       0,
                        //       -1
                        //     )}.${child2.name.slice(0, -1)}.${child3.name}.${
                        //       child4.name
                        //     }.${child5.name} }}`,
                        //     `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                        //       0,
                        //       -1
                        //     )}.${child2.name.slice(0, -1)}.${child3.name}.${
                        //       child4.name
                        //     }.${child5.name} }}`,
                        //   ];
                        //   tokens.push(arrayToPush);
                        //   // for classes---
                        //   arrayToPush = [
                        //     `start_curly_${trait.name.slice(
                        //       0,
                        //       -1
                        //     )}_dot_${child1.name.slice(
                        //       0,
                        //       -1
                        //     )}_dot_${child2.name.slice(0, -1)}_dot_${
                        //       child3.name
                        //     }_dot_${child4.name}_dot_${child5.name}_end_curly`,
                        //     `start_curly_${trait.name.slice(
                        //       0,
                        //       -1
                        //     )}_dot_${child1.name.slice(
                        //       0,
                        //       -1
                        //     )}_dot_${child2.name.slice(0, -1)}_dot_${
                        //       child3.name
                        //     }_dot_${child4.name}_dot_${child5.name}_end_curly`,
                        //   ];
                        //   tokens.push(arrayToPush);
                        // });
                      } else {
                        arrayToPush = [
                          `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                            0,
                            -1
                          )}.${child2.name.slice(0, -1)}.${child3.name} }}`,
                          `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                            0,
                            -1
                          )}.${child2.name.slice(0, -1)}.${child3.name} }}`,
                        ];
                        tokens.push(arrayToPush);

                        // for classes---
                        arrayToPush = [
                          `start_curly_${trait.name.slice(
                            0,
                            -1
                          )}_dot_${child1.name.slice(
                            0,
                            -1
                          )}_dot_${child2.name.slice(0, -1)}_dot_${
                            child3.name
                          }_end_curly`,
                          `start_curly_${trait.name.slice(
                            0,
                            -1
                          )}_dot_${child1.name.slice(
                            0,
                            -1
                          )}_dot_${child2.name.slice(0, -1)}_dot_${
                            child3.name
                          }_end_curly`,
                        ];
                        tokens.push(arrayToPush);
                      }
                    });
                  } else if (child3.type === 'text_link'){
                    arrayToPush = [
                      `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                        0,
                        -1
                      )}.${child2.name.slice(0, -1)}.${child3.name}.title }}`,
                      `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                        0,
                        -1
                      )}.${child2.name.slice(0, -1)}.${child3.name}.title }}`,
                    ];
                    tokens.push(arrayToPush);

                    arrayToPush = [
                      `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                        0,
                        -1
                      )}.${child2.name.slice(0, -1)}.${child3.name}.url }}`,
                      `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                        0,
                        -1
                      )}.${child2.name.slice(0, -1)}.${child3.name}.url }}`,
                    ];
                    tokens.push(arrayToPush);

                    arrayToPush = [
                      `start_curly_${trait.name.slice(
                        0,
                        -1
                      )}_dot_${child1.name.slice(
                        0,
                        -1
                      )}_dot_${child2.name.slice(0, -1)}_dot_${
                        child3.name
                      }_dot_title_end_curly`,
                      `start_curly_${trait.name.slice(
                        0,
                        -1
                      )}_dot_${child1.name.slice(
                        0,
                        -1
                      )}_dot_${child2.name.slice(0, -1)}_dot_${
                        child3.name
                      }_dot_title_end_curly`,
                    ];
                    tokens.push(arrayToPush);

                    arrayToPush = [
                      `start_curly_${trait.name.slice(
                        0,
                        -1
                      )}_dot_${child1.name.slice(
                        0,
                        -1
                      )}_dot_${child2.name.slice(0, -1)}_dot_${
                        child3.name
                      }_dot_url_end_curly`,
                      `start_curly_${trait.name.slice(
                        0,
                        -1
                      )}_dot_${child1.name.slice(
                        0,
                        -1
                      )}_dot_${child2.name.slice(0, -1)}_dot_${
                        child3.name
                      }_dot_url_end_curly`,
                    ];
                    tokens.push(arrayToPush);
                  }
                   else {
                    arrayToPush = [
                      `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                        0,
                        -1
                      )}.${child2.name.slice(0, -1)}.${child3.name} }}`,
                      `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                        0,
                        -1
                      )}.${child2.name.slice(0, -1)}.${child3.name} }}`,
                    ];
                    tokens.push(arrayToPush);

                    // for classes---
                    arrayToPush = [
                      `start_curly_${trait.name.slice(
                        0,
                        -1
                      )}_dot_${child1.name.slice(
                        0,
                        -1
                      )}_dot_${child2.name.slice(0, -1)}_dot_${
                        child3.name
                      }_end_curly`,
                      `start_curly_${trait.name.slice(
                        0,
                        -1
                      )}_dot_${child1.name.slice(
                        0,
                        -1
                      )}_dot_${child2.name.slice(0, -1)}_dot_${
                        child3.name
                      }_end_curly`,
                    ];
                    tokens.push(arrayToPush);
                  }
                });
                arrayToPush = [
                  `<!--END-LOOP-{{${trait.name.slice(
                    0,
                    -1
                  )}.${child1.name.slice(0, -1)}.${child2.name}}}-->`,
                  `<!--END-LOOP-{{${trait.name.slice(
                    0,
                    -1
                  )}.${child1.name.slice(0, -1)}.${child2.name}}}-->`,
                ];

                tokens.push(arrayToPush);
              } else {
                if (child2.type === 'image') {
                  child2.children?.forEach(child3 => {
                    arrayToPush = [
                      `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                        0,
                        -1
                      )}.${child2.name}.${child3.name} }}`,
                      `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                        0,
                        -1
                      )}.${child2.name}.${child3.name} }}`,
                    ];
                    tokens.push(arrayToPush);

                    // for classes---
                    arrayToPush = [
                      `start_curly_${trait.name.slice(
                        0,
                        -1
                      )}_dot_${child1.name.slice(0, -1)}_dot_${
                        child2.name
                      }_dot_${child3.name}_end_curly`,
                      `start_curly_${trait.name.slice(
                        0,
                        -1
                      )}_dot_${child1.name.slice(0, -1)}_dot_${
                        child2.name
                      }_dot_${child3.name}_end_curly`,
                    ];
                    tokens.push(arrayToPush);
                  });
                } else if (child2.type === 'picture') {
                  child2.children?.forEach(child3 => {
                    if (child3.type === 'image') {
                      // child3?.children.forEach((child4, i) => {
                      //   arrayToPush = [
                      //     `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                      //       0,
                      //       -1
                      //     )}.${child2.name}.${child3.name}.${child4.name} }}`,
                      //     `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                      //       0,
                      //       -1
                      //     )}.${child2.name}.${child3.name}.${child4.name} }}`,
                      //   ];
                      //   tokens.push(arrayToPush);
                      //   // for classes---
                      //   arrayToPush = [
                      //     `start_curly_${trait.name.slice(
                      //       0,
                      //       -1
                      //     )}_dot_${child1.name.slice(0, -1)}_dot_${
                      //       child2.name
                      //     }_dot_${child3.name}_dot_${child4.name}_end_curly`,
                      //     `start_curly_${trait.name.slice(
                      //       0,
                      //       -1
                      //     )}_dot_${child1.name.slice(0, -1)}_dot_${
                      //       child2.name
                      //     }_dot_${child3.name}_dot_${child4.name}_end_curly`,
                      //   ];
                      //   tokens.push(arrayToPush);
                      // });
                    } else {
                      arrayToPush = [
                        `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                          0,
                          -1
                        )}.${child2.name} }}`,
                        `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                          0,
                          -1
                        )}.${child2.name} }}`,
                      ];
                      tokens.push(arrayToPush);

                      // for classes---
                      arrayToPush = [
                        `start_curly_${trait.name.slice(
                          0,
                          -1
                        )}_dot_${child1.name.slice(0, -1)}_dot_${
                          child2.name
                        }_end_curly`,
                        `start_curly_${trait.name.slice(
                          0,
                          -1
                        )}_dot_${child1.name.slice(0, -1)}_dot_${
                          child2.name
                        }_end_curly`,
                      ];
                      tokens.push(arrayToPush);
                    }
                  });
                } else if (child2.type === 'text_link')
                {
                  arrayToPush = [
                    `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                      0,
                      -1
                    )}.${child2.name}.title }}`,
                    `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                      0,
                      -1
                    )}.${child2.name}.title }}`,
                  ];
                  tokens.push(arrayToPush);  

                  arrayToPush = [
                    `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                      0,
                      -1
                    )}.${child2.name}.url }}`,
                    `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                      0,
                      -1
                    )}.${child2.name}.url }}`,
                  ];
                  tokens.push(arrayToPush); 

                  arrayToPush = [
                    `start_curly_${trait.name.slice(
                      0,
                      -1
                    )}_dot_${child1.name.slice(0, -1)}_dot_${
                      child2.name
                    }_dot_title_end_curly`,
                    `start_curly_${trait.name.slice(
                      0,
                      -1
                    )}_dot_${child1.name.slice(0, -1)}_dot_${
                      child2.name
                    }_dot_title_end_curly`,
                  ];
                  tokens.push(arrayToPush);

                  arrayToPush = [
                    `start_curly_${trait.name.slice(
                      0,
                      -1
                    )}_dot_${child1.name.slice(0, -1)}_dot_${
                      child2.name
                    }_dot_url_end_curly`,
                    `start_curly_${trait.name.slice(
                      0,
                      -1
                    )}_dot_${child1.name.slice(0, -1)}_dot_${
                      child2.name
                    }_dot_url_end_curly`,
                  ];
                  tokens.push(arrayToPush);
                }
                 else {
                  arrayToPush = [
                    `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                      0,
                      -1
                    )}.${child2.name} }}`,
                    `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                      0,
                      -1
                    )}.${child2.name} }}`,
                  ];
                  tokens.push(arrayToPush);

                  // for classes---
                  arrayToPush = [
                    `start_curly_${trait.name.slice(
                      0,
                      -1
                    )}_dot_${child1.name.slice(0, -1)}_dot_${
                      child2.name
                    }_end_curly`,
                    `start_curly_${trait.name.slice(
                      0,
                      -1
                    )}_dot_${child1.name.slice(0, -1)}_dot_${
                      child2.name
                    }_end_curly`,
                  ];
                  tokens.push(arrayToPush);
                }
              }
            });
            arrayToPush = [
              `<!--END-LOOP-{{${trait.name.slice(0, -1)}.${child1.name}}}-->`,
              `<!--END-LOOP-{{${trait.name.slice(0, -1)}.${child1.name}}}-->`,
            ];

            tokens.push(arrayToPush);
          } else {
            if (child1.type === 'image') {
              child1.children?.forEach(child2 => {
                arrayToPush = [
                  `{{ ${trait.name.slice(0, -1)}.${child1.name}.${
                    child2.name
                  } }}`,
                  `{{ ${trait.name.slice(0, -1)}.${child1.name}.${
                    child2.name
                  } }}`,
                ];
                tokens.push(arrayToPush);

                // for classes---
                arrayToPush = [
                  `start_curly_${trait.name.slice(0, -1)}_dot_${
                    child1.name
                  }_dot_${child2.name}_end_curly`,
                  `start_curly_${trait.name.slice(0, -1)}_dot_${
                    child1.name
                  }_dot_${child2.name}_end_curly`,
                ];
                tokens.push(arrayToPush);
              });
            } else if (child1.type === 'picture') {
              child1.children?.forEach(child2 => {
                if (child2.type === 'image') {
                  // child2?.children.forEach((child3, i) => {
                  //   arrayToPush = [
                  //     `{{ ${trait.name.slice(0, -1)}.${child1.name}.${
                  //       child2.name
                  //     }.${child3.name} }}`,
                  //     `{{ ${trait.name.slice(0, -1)}.${child1.name}.${
                  //       child2.name
                  //     }.${child3.name} }}`,
                  //   ];
                  //   tokens.push(arrayToPush);
                  //   // for classes---
                  //   arrayToPush = [
                  //     `start_curly_${trait.name.slice(0, -1)}_dot_${
                  //       child1.name
                  //     }_dot_${child2.name}_dot_${child3.name}_end_curly`,
                  //     `start_curly_${trait.name.slice(0, -1)}_dot_${
                  //       child1.name
                  //     }_dot_${child2.name}_dot_${child3.name}_end_curly`,
                  //   ];
                  //   tokens.push(arrayToPush);
                  // });
                } else {
                  arrayToPush = [
                    `{{ ${trait.name.slice(0, -1)}.${child1.name} }}`,
                    `{{ ${trait.name.slice(0, -1)}.${child1.name} }}`,
                  ];
                  tokens.push(arrayToPush);
                  // for classes---
                  arrayToPush = [
                    `start_curly_${trait.name.slice(0, -1)}_dot_${
                      child1.name
                    }_end_curly`,
                    `start_curly_${trait.name.slice(0, -1)}_dot_${
                      child1.name
                    }_end_curly`,
                  ];
                  tokens.push(arrayToPush);
                }
              });
            } else if (child1.type === 'text_link') {
              arrayToPush = [
                `{{ ${trait.name.slice(0, -1)}.${child1.name}.title }}`,
                `{{ ${trait.name.slice(0, -1)}.${child1.name}.title }}`,
              ];
              tokens.push(arrayToPush);

              arrayToPush = [
                `{{ ${trait.name.slice(0, -1)}.${child1.name}.url }}`,
                `{{ ${trait.name.slice(0, -1)}.${child1.name}.url }}`,
              ];
              tokens.push(arrayToPush);

              arrayToPush = [
                `start_curly_${trait.name.slice(0, -1)}_dot_${
                  child1.name
                }_dot_title_end_curly`,
                `start_curly_${trait.name.slice(0, -1)}_dot_${
                  child1.name
                }_dot_title_end_curly`,
              ];
              tokens.push(arrayToPush);

              arrayToPush = [
                `start_curly_${trait.name.slice(0, -1)}_dot_${
                  child1.name
                }_dot_url_end_curly`,
                `start_curly_${trait.name.slice(0, -1)}_dot_${
                  child1.name
                }_dot_url_end_curly`,
              ];
              tokens.push(arrayToPush);
            }
            else {
              arrayToPush = [
                `{{ ${trait.name.slice(0, -1)}.${child1.name} }}`,
                `{{ ${trait.name.slice(0, -1)}.${child1.name} }}`,
              ];
              tokens.push(arrayToPush);
              // for classes---
              arrayToPush = [
                `start_curly_${trait.name.slice(0, -1)}_dot_${
                  child1.name
                }_end_curly`,
                `start_curly_${trait.name.slice(0, -1)}_dot_${
                  child1.name
                }_end_curly`,
              ];
              tokens.push(arrayToPush);
            }
          }
        });
        arrayToPush = [
          `<!--END-LOOP-{{${trait.name}}}-->`,
          `<!--END-LOOP-{{${trait.name}}}-->`,
        ];

        tokens.push(arrayToPush);
      } else if (trait.type === 'image') {
        trait.children?.forEach(child => {
          arrayToPush = [
            `{{ ${trait.name}.${child.name} }}`,
            `{{ ${trait.name}.${child.name} }}`,
          ];
          tokens.push(arrayToPush);
          // for classes---
          arrayToPush = [
            `start_curly_${trait.name}_dot_${child.name}_end_curly`,
            `start_curly_${trait.name}_dot_${child.name}_end_curly`,
          ];
          tokens.push(arrayToPush);
        });
      } else if (trait.type === 'picture') {
        trait.children?.forEach(child => {
          if (child.type === 'image') {
            // child?.children.forEach((child2, i) => {
            //   arrayToPush = [
            //     `{{ ${trait.name}.${child.name}.${child2.name} }}`,
            //     `{{ ${trait.name}.${child.name}.${child2.name} }}`,
            //   ];
            //   tokens.push(arrayToPush);
            //   // for classes---
            //   arrayToPush = [
            //     `start_curly_${trait.name}_dot_${child.name}_dot_${child2.name}_end_curly`,
            //     `start_curly_${trait.name}_dot_${child.name}_dot_${child2.name}_end_curly`,
            //   ];
            //   tokens.push(arrayToPush);
            // });
          } else {
            // changing to have full name ............
            arrayToPush = [`{{ ${trait.name} }}`, `{{ ${trait.name} }}`];
            tokens.push(arrayToPush);

            // for classes ---
            arrayToPush = [
              `start_curly_${trait.name}_end_curly`,
              `start_curly_${trait.name}_end_curly`,
            ];
            tokens.push(arrayToPush);
          }
        });
      } else if (trait.type === 'text_link') {
        // console.log("daman",trait);
        arrayToPush = [`{{ ${trait.name}.title }}`, `{{ ${trait.name}.title }}`];
        tokens.push(arrayToPush);

        arrayToPush = [`{{ ${trait.name}.url }}`, `{{ ${trait.name}.url }}`];
        tokens.push(arrayToPush);

        // for classes ---
        arrayToPush = [
          `start_curly_${trait.name}_dot_title_end_curly`,
          `start_curly_${trait.name}__dot_title_end_curly`,
        ];
        tokens.push(arrayToPush);

        arrayToPush = [
          `start_curly_${trait.name}_dot_url_end_curly`,
          `start_curly_${trait.name}_dot_url_end_curly`,
        ];
        tokens.push(arrayToPush);
      } else {
        arrayToPush = [`{{ ${trait.name} }}`, `{{ ${trait.name} }}`];
        tokens.push(arrayToPush);

        // for classes ---
        arrayToPush = [
          `start_curly_${trait.name}_end_curly`,
          `start_curly_${trait.name}_end_curly`,
        ];
        tokens.push(arrayToPush);
      }
    });

    const sortedObject = {
      '{{': [],
      start_curly: [],
      '<!--START-LOOP': [],
      '<!--END-LOOP': [],
    };

    tokens.forEach(token => {
      if (token[0].startsWith('{{')) {
        sortedObject['{{'].push(token);
      } else if (token[0].includes('start_curly')) {
        sortedObject['start_curly'].push(token);
      } else if (token[0].includes('<!--START-LOOP')) {
        sortedObject['<!--START-LOOP'].push(token);
      } else if (token[0].includes('<!--END-LOOP')) {
        sortedObject['<!--END-LOOP'].push(token);
      } else {
        return null;
      }
    });

    const sortedArray = Object.values(sortedObject).flat();
    return sortedArray;
  };

  return (
    <Editor
      initData={data}
      onInstanceReady={event => {
        editorRef.current = event.editor;
      }}
      config={{
        allowedContent: true,
        enterMode: 2,
        bodyClass: 'bootstrap-styles',
        extraPlugins: ['customsourcearea', 'customToken', 'customsource'],
        availableTokens: TraitsToTokens(),
        contentsCss: [bootstrap_css, entity.css, ...childCss],
        // tokenStart: ['{{ '],
        // tokenEnd: [' }}'],
        replaceVariables: replaceVariables,
        traits: entity.traits,
        component: component,
        saveRef: saveRef,
        toolbar: null, // Hide the toolbar
        height: '460px',

      }}
      editorUrl={`${process.env.REACT_APP_SERVER_BASE_URL}/public/ckeditor/ckeditor.js`}
    />
  );
};

export default CKEditor;
