const getHtml = () => {
  return `
    <button type="button" class="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Tooltip on bottom">
  Tooltip on bottom
</button>`;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return `var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })`;
};

const Bottom = { getHtml, getCss, getJs };

export default Bottom;
