const getHtml = () => {
    return `
        <div class="container" data-gjs-type="default">
          <div class="row">
            <div class="col" data-gjs-type="default" style="min-height : 50px;"></div>
            <div class="col" data-gjs-type="default" style="min-height : 50px;"></div>
          </div>
        </div>
      `;
  };
  
  const getCss = () => {
    return ``;
  };
  
  const getJs = () => {
    return ``;
  };
  
  const TwoColumn = { getHtml, getCss, getJs };
  
  export default TwoColumn;
  