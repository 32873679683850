import LargeSpacing from './large';
import MediumSpacing from './medium';
import SmallSpacing from './small';


const Spacing = {
    SmallSpacing,
    MediumSpacing,
    LargeSpacing
};

export default Spacing;
