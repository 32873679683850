import {
  Divider,
  Input,
  Typography,
  Spin,
  Row,
  Col,
  Alert,
  Button,
  message,
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { setGlobalConfigs } from '../../../redux/global-configurations/actions';
import BreakPointSection from './config-sections/breakPoint-section';
import ColorSection from './config-sections/color-section';
import ImageStyleSection from './config-sections/image-styles-section';
import TypographySection from './config-sections/typography-section';
import { useParams } from 'react-router-dom';
import axios from '../../../utils/axios';
import InputCode from '../../common/InputCode';
import ErrorPage from '../../common/ErrorPage';
import LastModified from '../../common/LastModified.js';
import ExternalFontsSection from './config-sections/external-fonts-section';

const { TextArea } = Input;

const GlobalConfigurations = ({
  currentConfigs,
  setCurrentConfigs,
  currentAssets,
  setIsNextDisabled,
  projectRole,
}) => {
  const [globalCss, setGlobalCss] = useState('');
  const [globalImageStyles, setGlobalImageStyles] = useState([]);
  const { projectId, step } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [netError, setNetError] = useState(false);
  const [globalCssWithoutWrapper, setGlobalCssWithoutWrapper] = useState('');
  const [uploadJSON, setUploadJson] = useState('');
  const [colorsInputFeild, setColorsInputFeild] = useState([]);

  const [typographyFields, setTypographyFields] = useState([]);

  const [breakPointsFields, setBreakPointsFields] = useState([]);

  const [externalFontsFields, setExternalFontsFields] = useState([]);

  const [allFonts, setAllFonts] = useState([]);

  const didMountref = useRef(false);

  const uploadClickHandler = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/updateConfig`,
        JSON.parse(uploadJSON)
      );
      if (res.data) {
        setCurrentConfigs(res.data);
        setColorsInputFeild([...(res.data?.global_configuration?.colors || [])]);
        setTypographyFields([...(res.data?.global_configuration?.typography || [])]);
        setGlobalCss(res.data?.global_configuration?.globalStyles);
        setExternalFontsFields([...(res.data?.global_configuration?.externalFonts || [])]);
        message.success('JSON uploaded successfully.');
      } else {
        message.error('something went wrong!!');
      }
    } catch (e) {        
      message.error('Your JSON is invalid!!');
      console.log('error', e);
    }
  };

  const defaultDataSet = () => {
    setColorsInputFeild([...(currentConfigs?.global_configuration?.colors || [])]);
    setTypographyFields([...(currentConfigs?.global_configuration?.typography || [])]);
    setBreakPointsFields([
      ...(currentConfigs?.global_configuration?.breakpoints || []),
    ]);
    setExternalFontsFields([
      ...(currentConfigs?.global_configuration?.externalFonts || []),
    ]);
    setIsNextDisabled(false);
  };

  useEffect(() => {
    let fontList = [];
    axios
      .get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/assets?limit=10&offset=0`
      )
      .then(data => {
        // console.log({data})
        if (data?.data) {
          axios
            .get(
              `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/assets?limit=${data.data.totalDocs}&offset=0`
            )
            .then(data => {
              setIsNextDisabled(false);
              data.data.docs?.forEach(asset => {
                if (
                  asset.mimeType === 'application/octet-stream' ||
                  asset.mimeType === 'font/ttf' ||
                  asset.mimeType === 'font/otf' ||
                  asset.mimeType === 'application/font-woff' ||
                  asset.mimeType === 'font/woff' ||
                  asset.mimeType === 'font/woff2'
                ) {
                  fontList.push({
                    value: asset.file,
                    label: asset.name,
                    key: asset._id,
                  });
                }
              });
              setAllFonts([...fontList]);
              setIsLoading(false);
            });
        }
      })
      .catch(err => {
        if (!err.status) {
          setNetError(true);
          setIsLoading(false);
        }
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (didMountref.current) {
      let finalObject = {
        global_configuration: {
          colors: [...colorsInputFeild],
          typography: [...typographyFields],
          breakpoints: [...breakPointsFields],
          externalFonts: [...externalFontsFields],
          globalStyles: globalCss,
          globalImageStyles,
          globalStylesWithoutWrapper: globalCssWithoutWrapper,
        },
      };
      setIsNextDisabled(false);
      setCurrentConfigs(finalObject);
    } else {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}`
        )
        .then(data => {
          console.log({ data });
          if (data?.data) {
            setCurrentConfigs(data.data);
            setColorsInputFeild([...(data.data?.global_configuration?.colors || [])]);
            setTypographyFields([
              ...(data.data?.global_configuration?.typography || []),
            ]);
            setExternalFontsFields([
              ...(data.data?.global_configuration?.externalFonts || []),
            ]);
            setBreakPointsFields([
              ...(data.data?.global_configuration?.breakpoints || []),
            ]);
            setGlobalCss(data.data?.global_configuration?.globalStyles);
            setGlobalImageStyles([
              ...(data.data?.global_configuration?.globalImageStyles || []),
            ]);
            setGlobalCssWithoutWrapper(
              data.data?.global_configuration?.globalStylesWithoutWrapper
            );
            setUploadJson(JSON.parse(data.data?.figmaTokenExport));
            setIsNextDisabled(false);
            setIsLoading(false);
          } else defaultDataSet();
        })
        .catch(err => {
          if (!err.status) {
            setNetError(true);
            setIsLoading(false);
          }
          console.log(err);
        });
    }

    didMountref.current = true;
  }, [
    colorsInputFeild,
    typographyFields,
    breakPointsFields,
    globalCss,
    globalImageStyles,
    globalCssWithoutWrapper,
    externalFontsFields,
  ]);
  if (projectRole === 'developer') {
    setIsNextDisabled(true);
  }
  return isLoading ? (
    <Spin
      tip="Loading..."
      size="large"
      style={{ display: 'flex', justifyContent: 'center' }}
    />
  ) : netError ? (
    <ErrorPage errorMessage="Please check your Internet connection" />
  ) : (
    <>
      <Row justify="space-between" style={{ marginTop: 10 }}>
        {projectRole === 'developer' && (
          <Col md={19}>
            <Alert
              message="Request team lead for updating these changes."
              type="info"
              showIcon
            />
          </Col>
        )}
        <Col>
          <LastModified />
        </Col>
      </Row>
      <ColorSection
        colorsInputFeild={colorsInputFeild}
        setColorsInputFeild={setColorsInputFeild}
      />
      <Divider />

      <TypographySection
        allFonts={allFonts}
        typographyFields={typographyFields}
        setTypographyFields={setTypographyFields}
      />
      <Divider />

      <ExternalFontsSection
        externalFontsFields={externalFontsFields}
        setExternalFontsFields={setExternalFontsFields}
      />
      <Divider />

      <BreakPointSection
        breakPointsFields={breakPointsFields}
        setBreakPointsFields={setBreakPointsFields}
      />

      <Divider />

      <h3>Global Styles:</h3>
      <Alert
        message="Note: The styles added in this section are applied globally and wrapped within a custom bootstrap class."
        type="info"
        style={{ marginBottom: '10px' }}
      />

      <InputCode
        language="css"
        defaultValue={globalCss}
        setValue={setGlobalCss}
        setIsNextDisabled={setIsNextDisabled}
      />
      <Divider />

      <h3>Global Styles Without Wrapper:</h3>
      <Alert
        message="Warning: Please note that the styles added in this section will not be wrapped in a custom bootstrap class (.bootstrap-styles) and will be applied globally on all the pages."
        type="warning"
        style={{ marginBottom: '10px' }}
      />
      <InputCode
        language="css"
        defaultValue={globalCssWithoutWrapper}
        setValue={setGlobalCssWithoutWrapper}
        setIsNextDisabled={setIsNextDisabled}
      />

      <br />
      <Divider />
      <ImageStyleSection
        globalImageStyles={globalImageStyles}
        setGlobalImageStyles={setGlobalImageStyles}
      />

      <br />
      <Divider />

      <h3>Figma Token Export:</h3>

      <Alert
        message="Note: You can import Figma Token Export JSON file."
        type="info"

        style={{ marginBottom: '10px' }}
      />

      <TextArea
        style={{
          height: 400,
          marginBottom: 24,
        }}
        onChange={e => {
          setUploadJson(e.target.value);
        }}
        defaultValue={JSON.stringify(uploadJSON , null , 2)}
        placeholder="Input here your JSON"
      />

      <Button
        type="primary"
        disabled={uploadJSON === ''}
        onClick={uploadClickHandler}
      >
        Import
      </Button>

      <br />
    </>
  );
};

const mapStateToProps = state => {
  return {
    currentConfigs: state.GlobalConfigurations.finalConfigs,
    currentAssets: state.GlobalConfigurations.initialAssets,
    projectRole: state.ProjectRole.projectRole,
  };
};

const mapDispatchToProps = dispatch => ({
  setCurrentConfigs: configs => {
    dispatch(setGlobalConfigs(configs));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalConfigurations);
