const getHtml = () => {
  return `
        <h3>Example heading <span class="badge bg-secondary">New</span></h3>
        `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const H3Badge = { getHtml, getCss, getJs };

export default H3Badge;
