import CONSTANTS from '../../utils/constants';
import * as TYPES from './action-types';

const initialState = {
  finalConfigs: {
    global_configuration: {
      colors: [
        {
            name: "AES Blue",
            variableName: "primary",
            hexcode: "#214ADE"
        },
        {
            name: "AES Purple",
            variableName: "secondary",
            hexcode: "#8C5CF2"
        },
        {
            name: "AES Green",
            variableName: "success",
            hexcode: "#16A837"
        },
        {
            name: "AES Aqua",
            variableName: "primary-aqua",
            hexcode: "#00A2C7"
        },
        {
            name: "AES Blue 75%",
            variableName: "secondary-blue",
            hexcode: "#5977E6"
        },
        {
            name: "AES Blue 50%",
            variableName: "tertiary-blue",
            hexcode: "#90A5EF"
        },
        {
            name: "AES Blue 25%",
            variableName: "quaternary-blue",
            hexcode: "#C8D2F7"
        },
        {
            name: "AES Purple 75%",
            variableName: "secondary-purple",
            hexcode: "#A985F5"
        },
        {
            name: "AES Purple 50%",
            variableName: "tertiary-purple",
            hexcode: "#C6AEF9"
        },
        {
            name: "AES Purple 25%",
            variableName: "quaternary-purple",
            hexcode: "#E2D6FC"
        },
        {
            name: "AES Green 75%",
            variableName: "secondary-green",
            hexcode: "#50BE69"
        },
        {
            name: "AES Green 50%",
            variableName: "tertiary-green",
            hexcode: "#8BD49B"
        },
        {
            name: "AES Green 25%",
            variableName: "quaternary-green",
            hexcode: "#C5E9CD"
        },
        {
            name: "AES Aqua 75%",
            variableName: "secondary-aqua",
            hexcode: "#40B9D5"
        },
        {
            name: "AES Aqua 50%",
            variableName: "tertiary-aqua",
            hexcode: "#80D1E3"
        },
        {
            name: "AES Aqua 25%",
            variableName: "quaternary-blue",
            hexcode: "#BFE8F1"
        },
        {
            name: "AES Icon Grey",
            variableName: "primary-grey",
            hexcode: "#BCBEC0"
        },
        {
            name: "AES Grey 2",
            variableName: "secondary-grey",
            hexcode: "#BFBFBF"
        },
        {
            name: "AES Grey 3",
            variableName: "tertiary-grey",
            hexcode: "#E5E5E5"
        },
        {
            name: "AES Grey 4",
            variableName: "quaternary-grey",
            hexcode: "#F5F5F5"
        }
    ],
      typography: [
        {
          font_family: '',
          font_file_url: '',
          font_file_format: '',
          font_weight: null,
        },
      ],
      breakpoints: [
        {
          name: 'Desktop XL',
          width: '1200px',
          height: '',
          widthMedia: '',
          priority: 0,
          id: 'Desktop_XL',
          "font-size": "20px",
          max_width: '1400px'
        },
        {
          name: 'Desktop R',
          width: '992px',
          height: '',
          widthMedia: '',
          priority: 0,
          id: 'Desktop_R',
          "font-size": "16px",
          max_width: '1200px'
        },
        {
          name: 'Mobile landscape',
          width: '576px',
          height: '',
          widthMedia: '768px',
          priority: 768,
          id: 'mobileLandscape',
          "font-size": "14px",
          max_width: '768px'
        },
        {
          name: 'Tablet',
          width: '768px',
          height: '',
          widthMedia: '992px',
          priority: 992,
          id: 'tablet',
          "font-size": "16px",
          max_width: '991px',
        },
        {
          name: 'Mobile portrait',
          width: '320px',
          height: '',
          widthMedia: '480px',
          priority: 480,
          id: 'mobilePortrait',
          "font-size": "14px",
          max_width: '575px'
        },
      ],
      globalJavascript:[
        {
          name: '',
          cdn: ''
        },
      ],
      globalStyles:'',
      globalImageStyles: [
        {
          name: '',
          height: '',
          width: '',
          boarder_radius: '',
        }
      ]
    },
  },
  initialCDN: CONSTANTS.BOOTSTRAP_CSS_CDN,
  initialAssets: []
};

const GlobalConfigurations = (state = initialState, { type, data }) => {
  switch (type) {
    case TYPES.SET_GLOBAL_CONFIGS:
      return {
        ...state,
        finalConfigs: data,
      };
      case TYPES.SET_GLOBAL_CSS_CDN:
      return {
        ...state,
        initialCDN: data,
      };
      case TYPES.SET_GLOBAL_ASSETS:
      return {
        ...state,
        initialAssets: data,
      };
    default:
      return state;
  }
};

export default GlobalConfigurations;
