const getHtml = () => {
  return `
    <div class="spinner-border" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>`;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const Border = { getHtml, getCss, getJs };

export default Border;
