import { useState } from 'react';
import { Form, Input, Modal, Radio, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import axios from '../../utils/axios';

const AddProject = props => {
  const [AddProjectForm] = Form.useForm();

  // handle value of status radio button
  const [statusValue, setStatusValue] = useState(true);
  const [isCloneOperationRunning , setIsCloneOperationRunning] = useState(false);

  const onChange = e => {
    setStatusValue(e.target.value);
  };
  const handleOK = () => {
    setIsCloneOperationRunning(true);
    props.setIsLoading(true);
    AddProjectForm.submit();
  };

  const onFinish = async values => {
    values.url = values.url == '' ? undefined : values.url;

    await axios
      .post(`${process.env.REACT_APP_SERVER_BASE_URL}/projects`, { ...values })
      .then(res => {
        //updating project state
        props.addProjectHandler(res.data);

        // closing modal
        setIsCloneOperationRunning(false);
        props.handleAddProjectModal(false);
        AddProjectForm.resetFields();
        props.message('success', 'Project Created successfully');
      })
      .catch(error => {
        props.message('error', 'Error: Project not created successfully');
      });
  };

  return (
    <>
      <Modal
        title="Create New Project"
        closable={true}
        open={props.isOpen}
        onOk={handleOK}
        onCancel={() => {
          props.handleAddProjectModal(false);
          AddProjectForm.resetFields();
        }}
        confirmLoading={isCloneOperationRunning}
      >
        <Form
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 16,
          }}
          form={AddProjectForm}
          name="control-hooks"
          onFinish={onFinish}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Project Name required' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: false }]}
          >
            <TextArea />
          </Form.Item>

          <Form.Item label="Status" name="status" rules={[{ required: false }]}>
            <Radio.Group
              onChange={onChange}
              value={statusValue}
              defaultValue={true}
            >
              <Radio value={true}>Active</Radio>
              <Radio value={false}>Disable</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="URL"
            name="url"
            rules={[{ required: false, type: 'url', message: 'Invalid URL' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Assets Path"
            name="assetsPath"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Includes Prefix"
            name="includesPrefix"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Includes Path"
            name="includesPath"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Theme Name"
            name="themeName"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Repository"
            name="repository"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddProject;
