import { createStore, compose, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import logger from 'redux-logger';
import { persistStore } from 'redux-persist';
import persistedReducers from '../reducers';

const middlewares = [ReduxThunk];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

const store = createStore(
  persistedReducers,
  {},
  composeEnhancers(applyMiddleware(...middlewares))
);

const persistor = persistStore(store, { timeout: false }, err => {
  if (err) {
    console.warn('Persistor Error:', err);
  }
});

export { store, persistor };
