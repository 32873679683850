const getHtml = () => {
    return `
    <div class="container ms-1" data-gjs-type="default" >
    <div class="row">
      <div class="col " data-gjs-type="default" style="min-height : 50px;"></div>
    </div>
  </div>

    `;
  };
  
  const getCss = () => {
    return ``;
  };
  
  const getJs = () => {
    return ``;
  };
  
  const SmallSpacing = { getHtml, getCss, getJs };
  
  export default SmallSpacing;
  