/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Col, Form, Input, Modal, Row, Table, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useState, useEffect } from 'react';
import uuid from 'react-uuid';

const { Title } = Typography;

const ExternalFontsForm = ({ externalFontsFields, setExternalFontsFields }) => {
  const [externalFontsForm] = Form.useForm();

  const [editRecord, setEditRecord] = useState(null);

  useEffect(() => {
    if (editRecord) {
        externalFontsForm.setFieldsValue({
        name: editRecord.name,
        url: editRecord.url
      });
    }
  }, [editRecord]);

  //------------------- columns for externalFonts -----------------------------//
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Url',
      dataIndex: 'url',
      key: 'url',
    },
    {
      title: 'Delete',
      dataIndex: '',
      key: '',
      render: (_, record) => {
        return (
          <a
            onClick={() => {
              Modal.confirm({
                title: 'Delete Alert',
                content: 'Do you want to delete it?',
                okText: 'Yes',
                cancelText: 'No',
                onOk: () => {
                  const updatedValues = externalFontsFields.filter(
                    ele => ele.id !== record.id
                  );
                  setExternalFontsFields(updatedValues);
                },
              });
            }}
          >
            Delete
          </a>
        );
      },
    },
    {
      title: 'Edit',
      dataIndex: '',
      key: '',
      render: (_, record) => {
        return (
          <a
            onClick={() => {
              setEditRecord(record);
            }}
          >
            Edit
          </a>
        );
      },
    },
  ];
  //-------------------------------------------------------------------//
  const onFinish = values => {
    values.name = values.name.trim();
    const keys = Object.keys(values);

    if (editRecord) {
      const newExternalFonts = externalFontsFields.map((font, index) => {
        if (font.id === editRecord.id) {
          font = {
            ...font,
            name: values.name,
            url: values.url
          };
        }
        return font;
      });
      setExternalFontsFields([...newExternalFonts]);
      setEditRecord(null);
      externalFontsForm.resetFields();
    } else {
      keys.forEach(key => {
          const valueToPush = {
            id: uuid(),
            name: values.name,
            url: values.url,
          };
          setExternalFontsFields([...externalFontsFields, valueToPush]);
          externalFontsForm.resetFields();
      });
    }
  };

  return (
    <>
      <h3>External Fonts:</h3>
      <Row gutter={20}>
        <Col span={6}>
          <Title level={5} style={{ textAlign: 'center' }}>
            {editRecord ? 'Edit Font-Url' : 'Add Font'}
          </Title>
          <Form
            name="externalfonts"
            form={externalFontsForm}
            onFinish={onFinish}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            labelAlign="left"
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please input Name',
                },
                {
                  pattern: /^[A-Za-z0-9_-]+$/,
                  message: "Invalid Input Special Character's not allowed",
                },
                { whitespace: true },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
            <Form.Item
              label="Url"
              name="url"
              rules={[
                {
                  required: true,
                  message: 'Please input Url of External Font',
                },
              ]}
            >
              <TextArea placeholder="External Font Url" 
                style={{ height: '100px' }}
              />
            </Form.Item>

            <Button type="primary" onClick={() => externalFontsForm.submit()}>
              {editRecord ? 'Edit Font' : 'Add Font'}
            </Button>
          </Form>
        </Col>
        <Col span={18}>
          {externalFontsFields && externalFontsFields.length > 0 && (
            <Table
              dataSource={externalFontsFields}
              columns={columns}
              pagination={false}
              bordered
              size="small"
              scroll={{
                y: 240,
              }}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default ExternalFontsForm;
