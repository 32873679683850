import * as TYPES from './action-type';

const initialState = {
    token: '',
}

const Token = (state=initialState,{type,data}) =>{
    switch (type) {
        case TYPES.TOKEN:
          return {
            ...state,
            token: data,
          };
        default:
          return state;
      }
}

export default Token;