const getHtml = () => {
  return `
    <div class="btn-group dropend">
    <button type="button" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
      Dropright
    </button>
    <ul class="dropdown-menu">
    <li><a class="dropdown-item" href="#">Action</a></li>
    <li><a class="dropdown-item" href="#">Another action</a></li>
    <li><a class="dropdown-item" href="#">Something else here</a></li>
    </ul>
  </div>
  
  `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const Right = { getHtml, getCss, getJs };

export default Right;
