import Default from './default';
import Primary from './primary';
import Secondary from './secondary';

const Icon = {
  Default,
  Primary,
  Secondary,
};

export default Icon;
