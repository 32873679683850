const getHtml = () => {
  return `
    <a href="#" class="link-dark">Dark link</a>
  `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const DefaultLink = { getHtml, getCss, getJs };

export default DefaultLink;
