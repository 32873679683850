const getHtml = () => {
  return `
    <div class="alert alert-warning" role="alert">
    A simple warning alert—check it out!
  </div>
        `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const WarningAlert = { getHtml, getCss, getJs };

export default WarningAlert;
