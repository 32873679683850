import Default from './default';
import Primary from './primary';
import Secondary from './secondary';
import Success from './success';
import Danger from './danger';
import Warning from './warning';
import Info from './info';
import Light from './light';
import Dark from './dark';

const Alert = {
  Default,
  Primary,
  Secondary,
  Success,
  Danger,
  Warning,
  Info,
  Light,
  Dark,
};

export default Alert;
