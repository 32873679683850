const getHtml = () => {
  return `
      <div class="w-50">
      <video controls>
      <source src="movie.mp4" type="video/mp4">
      <source src="movie.ogg" type="video/ogg">
      Your browser does not support the video tag.
    </video>
      </div>
    `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const PrimaryVideo = { getHtml, getCss, getJs };

export default PrimaryVideo;
