import { Form, Input, Modal, Radio, Select } from 'antd';
import Password from 'antd/lib/input/Password';
import { useState } from 'react';
import { connect } from 'react-redux';

const AddUser = props => {
  const [AddUserForm] = Form.useForm();

  // handle value of status radio button
  const [statusValue, setStatusValue] = useState(true);

  return (
    <>
      <Modal
        title="Add User"
        open={props.open}
        onCancel={() => {
          props.setAddUserModal(false);
          AddUserForm.resetFields();
        }}
        onOk={() => {
          AddUserForm.submit();
        }}
      >
        <Form
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 16,
          }}
          form={AddUserForm}
          name="Add User Form"
          onFinish={value => {
            props.addModalOnOk(value);
            props.setAddUserModal(false);
            AddUserForm.resetFields();
          }}
        >
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              { required: true, message: 'First Name is required' },
              { whitespace: true },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[
              { required: true, message: 'Last Name is required' },
              { whitespace: true },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Email is Required',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              { required: true, message: 'Password is required' },
              { whitespace: true },
            ]}
          >
            <Password />
          </Form.Item>

          <Form.Item
            label="Role"
            name="role"
            rules={[
              { required: true, message: 'Role is required' },
              { whitespace: true },
            ]}
          >
            <Select
              options={
                props.userRole === 'administrator'
                  ? [
                      { label: 'Developer', value: 'developer' },
                      { label: 'Tech Lead', value: 'team_lead' },
                    ]
                  : [{ label: 'Developer', value: 'developer' }]
              }
            />
          </Form.Item>

          <Form.Item
            label="Status"
            name="status"
            rules={[{ required: false }]}
            value={statusValue}
            initialValue={true}
          >
            <Radio.Group
              onChange={e => setStatusValue(e.target.value)}
              initialValue={true}
            >
              <Radio value={true}>Active</Radio>
              <Radio value={false}>Inactive</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = state => {
  return {
    userRole: state.User.role,
  };
};

export default connect(mapStateToProps, null)(AddUser);
