/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import uuid from 'react-uuid';
import {
  PlusOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Row,
  Select,
  Table,
  Typography,
  Spin,
} from 'antd';

import {
  setAtoms,
  setOrganisms,
  setMolecules,
  setLayouts,
  setPages,
} from '../../../../redux/design-components/actions';

import CONSTANTS from '../../../../utils/constants';
import { useParams } from 'react-router-dom';
// import TraitForm from '../../../common/traits/trait-form/index';
import axios from '../../../../utils/axios';
import CKEditor from '../../../common/CKEditor/CKEditor';
import LastModified from '../../../common/LastModified.js';
import EditForm from '../../../common/traits/editTraitComponent';
import TraitForm from '../../../common/traits/CreateTraitComponent';
import { replaceVariables } from '../../../../utils/helpers/replaceTraits';
import pages from '../../design-components/pages';

const { Item: MenuItem, SubMenu } = Menu;
const { Text } = Typography;
const { TextArea } = Input;

const PageTraits = ({
  designComponents,
  pages,
  setPages,
  globalConfigs,
  configuration,
  projectRole,
}) => {
  const bootstrap_css = configuration.initialCDN;
  const [collapsed, setCollapsed] = useState(false);
  const [selectedPage, setselectedPage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  //   const [isTraitForm, setIsTraitForm] = useState(false);
  const [traitform] = Form.useForm();
  const [edittraitform] = Form.useForm();
  const [traitTable, setTraitTable] = useState([]);
  const [editModal, showEditModal] = useState(false);
  const [editTraitData, setEditTraitData] = useState(null);
  const [globalMediaOptions, setGlobalMediaOptions] = useState([]);
  const [globalImageStyleOptions, setGlobalImageStyleOptions] = useState([]);
  const [isSelectedTraitParagraph, setIsSelectedTraitParagraph] =
    useState(false);
  const [isSelectedTraitType, setIsSelectedTraitType] = useState(undefined);

  const [didTraitsUpdate, setDidTraitsUpdate] = useState(false);
  const [usedChildren, setUsedChildren] = useState();
  const isUsedChildrenLoaded = useRef(false);
  const [isLoading, setIsLoading] = useState(true);

  const saveRef = useRef(null);

  useEffect(() => {
    setDidTraitsUpdate(false);
  }, [pages]);

  const { projectId, step } = useParams();
  const editorRef = useRef(null);
  const [key, setKey] = useState(0); // initialize key state variable for ckeditor rerender
  const handleAddTraits = layout => {
    setIsModalOpen(true);
    setselectedPage(layout);
    // setIsTraitForm(true);
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleAddChildren = () => {
    setChildren([
      ...children,
      { type: '', label: '', name: '', defaultValue: '' },
    ]);
  };

  useEffect(() => {
    if (selectedPage?.traits?.length) {
      setTraitTable([...selectedPage?.traits]);
    }
  }, [selectedPage]);

  const handleRemoveNested = (parentIndex, childIndex) => {
    const newChildren = [...children];
    const parent = newChildren[parentIndex];

    if (childIndex === undefined) {
      if (parent.children && parent.children.length > 0) {
        parent.children.pop();
      }
    } else {
      const child = parent.children[childIndex];
      if (child.children && child.children.length > 0) {
        child.children.pop();
      }
    }

    setChildren(newChildren);
  };

  const handleAddAnotherSource = () => {
    let newFields = { type: 'text_link', source: '', media: '' };
    setPictureSourceCollection([...pictureSourceCollection, newFields]);
  };

  const handleAddPictureCollection = (event, index, key) => {
    let newPicCollection = [...pictureSourceCollection];
    if (key === 'select') newPicCollection[index]['media'] = event;
    else newPicCollection[index][event.target.name] = event.target.value;
    setPictureSourceCollection(newPicCollection);
  };

  const handleAddNested = (parentIndex, childIndex) => {
    const newChildren = [...children];
    const parent = newChildren[parentIndex];

    if (childIndex === undefined) {
      if (!parent.children) {
        parent.children = [];
      }
      parent.children.push({
        type: '',
        label: '',
        defaultValue: '',
        children: [],
      });
    } else {
      const child = parent.children[childIndex];
      if (!child.children) {
        child.children = [];
      }
      child.children.push({
        type: '',
        label: '',
        defaultValue: '',
      });
    }

    setChildren(newChildren);
  };

  const [children, setChildren] = useState([
    { type: '', label: '', name: '', defaultValue: '' },
  ]);
  const [pictureSourceCollection, setPictureSourceCollection] = useState([
    { type: 'text_link', source: '', media: '' },
  ]);

  const handleOk = () => {
    if (traitform && !editTraitData) {
      traitform.submit();
    }
    if (editTraitData) {
      edittraitform.submit();
    }
  };

  const handleReset = () => {
    Modal.confirm({
      title: 'Reset Alert!',
      content: (
        <div>
          <Alert
            message="You will have to re-insert the tokens used in this component."
            type="warning"
          />
          <br></br>
          Do you want to Reset Component?
        </div>
      ),
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        let updatedLayout = [...pages];
        updatedLayout.forEach(layout => {
          if (layout.key === selectedPage.key) {
            let html = layout.html;

            let modifiedContent = html.replace(
              /{{([^}]+)}}|start_curly([^]+?)_end_curly|<!--(.*?)-->/g,
              function (subMatch, p1, p2, p3) {
                if (p1) {
                  return ``;
                } else if (p2) {
                  return ``;
                } else if (p3) {
                  console.log(p3);
                  return `<!--${p3}-->`;
                }
              }
            );

            layout.html = modifiedContent;

            axios
              .patch(
                `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/components/pages/${layout._id}`,
                layout
              )
              .then(data => {
                setDidTraitsUpdate(true);
                setKey(key + 1); // to rerender ckeditor...
                message.success('Component Resetted.');
              })
              .catch(error => {
                console.error(error);
                message.error('Something went wrong ! Please try again.');
              });
          }
        });
        setPages(updatedLayout);
      },
    });
  };

  const onFinish = values => {
    const newpages = [...pages];

    let traitIndex;
    if (editTraitData) {
      traitIndex = selectedPage.traits.findIndex(trait => {
        return trait.key === editTraitData.key;
      });
    }
    const Traits = selectedPage.traits;

    const componentTypeKeys = Object.keys(designComponents);

    // for (const key of componentTypeKeys) {
    //   for (const component of designComponents[key]) {
    //     if ((key === 'atoms' || key === 'molecules') && component.variations) {
    //       for (const variation of component.variations) {
    //         if (variation.traits) {
    //           for (const trait of variation.traits) {
    //             if (trait.drupal_field === values.drupal_field_name) {
    //               message.error('drupal_field must be unique...');
    //               return; // Stop the loop and exit the function
    //             }
    //           }
    //         }
    //       }
    //     } else if (component.traits && !component.variations) {
    //       for (const trait of component.traits) {
    //         if (trait.drupal_field === values.drupal_field_name) {
    //           message.error('drupal_field must be unique...');
    //           return;
    //         }
    //       }
    //     }
    //   }
    // }

    
    const newLabel = `${selectedPage.label} ${values.label}`;
    const newName = `${_.snakeCase(selectedPage.label)}_${_.snakeCase(
      values.label
    )}`;

    let newChildrenArr = undefined;

    if (values.type === 'paragraph') {
      newChildrenArr = [];
      children.forEach((child, i) => {
        if (values[`child_type_${i}`]) {
          if (values[`child_type_${i}`] === 'paragraph') {
            newChildrenArr.push({
              type: values[`child_type_${i}`],
              label: values[`child_label_${i}`],
              cardinality: values[`cardinality_${i}`],
              drupal_field_name: values[`child_drupal_field_name_${i}`],
              name: _.snakeCase(values[`child_label_${i}`]),
              children: child.children?.map((child_1, j) => {
                if (values[`nested_child_type_${i}_${j}`] === 'paragraph') {
                  return {
                    type: values[`nested_child_type_${i}_${j}`],
                    label: values[`nested_child_label_${i}_${j}`],
                    drupal_field_name: values[`child_drupal_field_name_${i}_${j}`],
                    cardinality: values[`cardinality_${i}_${j}`],
                    name: _.snakeCase(values[`nested_child_label_${i}_${j}`]),
                    children: child_1.children?.map((child_2, k) => {
                      if (
                        values[`nested_child_type_${i}_${j}_${k}`] === 'image'
                      ) {
                        return {
                          type: values[`nested_child_type_${i}_${j}_${k}`],
                          label: values[`nested_child_label_${i}_${j}_${k}`],
                          drupal_field_name: values[`child_drupal_field_name_${i}_${j}_${k}`],
                          name: _.snakeCase(
                            values[`nested_child_label_${i}_${j}_${k}`]
                          ),
                          children: [
                            {
                              type: 'text_link',
                              name: _.snakeCase(
                                `${_.snakeCase(
                                  values[`nested_child_type_${i}_${j}_${k}`]
                                )}_img_source_${i}_${j}_${k}`
                              ),
                              label: ` ${
                                values[`nested_child_label_${i}_${j}_${k}`]
                              } image source`,
                              defaultValue:
                                values[`image_src_link_${i}_${j}_${k}`],
                            },
                            {
                              type: 'text_plain',
                              name: _.snakeCase(
                                `${_.snakeCase(
                                  values[`nested_child_type_${i}_${j}_${k}`]
                                )}_img_alt_text_${i}_${j}_${k}`
                              ),
                              label: `${
                                values[`nested_child_label_${i}_${j}_${k}`]
                              } image alt text`,
                              defaultValue:
                                values[`image_alt_text_${i}_${j}_${k}`],
                            },
                            {
                              type: 'text_plain',
                              name: _.snakeCase(
                                `${_.snakeCase(
                                  values[`nested_child_type_${i}_${j}_${k}`]
                                )}_img_styles_${i}_${j}_${k}`
                              ),
                              label: `${
                                values[`nested_child_label_${i}_${j}_${k}`]
                              } image styles`,
                              options: [...globalImageStyleOptions],
                              // defaultValue:
                              //   values[`image_style_${i}_${j}_${k}`],
                              defaultValue: (() => {
                                const modifiedValue = values[
                                  `image_style_${i}_${j}_${k}`
                                ]
                                  .replace(/(\.bootstrap-styles|\.|\s)/g, '')
                                  .trim();
                                return modifiedValue;
                              })(),
                            },
                          ],
                        };
                      } else if (
                        values[`nested_child_type_${i}_${j}_${k}`] === 'picture'
                      ) {
                        //////////////////////////

                        return {
                          type: values[`nested_child_type_${i}_${j}_${k}`],
                          label: values[`nested_child_label_${i}_${j}_${k}`],
                          drupal_field_name: values[`child_drupal_field_name_${i}_${j}_${k}`],
                          name: _.snakeCase(
                            values[`nested_child_label_${i}_${j}_${k}`]
                          ),
                          children: [
                            {
                              type: 'image',
                              name: _.snakeCase(
                                `${
                                  values[`nested_child_label_${i}_${j}_${k}`]
                                }_fall_back_image_src_link`
                              ),
                              label: `${
                                values[`nested_child_label_${i}_${j}_${k}`]
                              } fall back image source`,
                              children: [
                                {
                                  type: 'text_link',
                                  name: 'image_src_link',
                                  label: 'Image src link',
                                  defaultValue:
                                    values[
                                      `fall_back_image_src_link_${i}_${j}_${k}`
                                    ],
                                },
                                {
                                  type: 'text_plain',
                                  name: 'image_alt_text',
                                  label: 'Image alt text',
                                  defaultValue:
                                    values[
                                      `fall_back_image_alt_text_${i}_${j}_${k}`
                                    ],
                                },
                                {
                                  type: 'text_link',
                                  name: 'image_styles',
                                  label: 'Image styles',
                                  options: [...globalImageStyleOptions],
                                  // defaultValue:
                                  //   values[
                                  //     `fall_back_image_style_${i}_${j}_${k}`
                                  //   ],
                                  defaultValue: (() => {
                                    const modifiedValue = values[
                                      `fall_back_image_style_${i}_${j}_${k}`
                                    ]
                                      .replace(
                                        /(\.bootstrap-styles|\.|\s)/g,
                                        ''
                                      )
                                      .trim();
                                    return modifiedValue;
                                  })(),
                                },
                              ],
                            },
                            {
                              type: 'sources',
                              name: _.snakeCase(
                                `${
                                  values[`nested_child_label_${i}_${j}_${k}`]
                                }_alternate_sources`
                              ),
                              label: `${
                                values[`nested_child_label_${i}_${j}_${k}`]
                              } alternate source`,
                              children: [...pictureSourceCollection],
                            },
                          ],
                        };

                        ///////////////////////////
                      } else {
                        return {
                          type: values[`nested_child_type_${i}_${j}_${k}`],
                          label: values[`nested_child_label_${i}_${j}_${k}`],
                          drupal_field_name: values[`child_drupal_field_name_${i}_${j}_${k}`],
                          name: _.snakeCase(
                            values[`nested_child_label_${i}_${j}_${k}`]
                          ),
                          defaultValue:
                            values[`nested_child_defaultValue_${i}_${j}_${k}`],
                            ...(values[`title_${i}_${j}_${k}`] && { title: values[`title_${i}_${j}_${k}`] }),
                        };
                      }
                    }),
                    // ],
                  };
                }

                if (values[`nested_child_type_${i}_${j}`] === 'image') {
                  return {
                    type: values[`nested_child_type_${i}_${j}`],
                    label: values[`nested_child_label_${i}_${j}`],
                    drupal_field_name: values[`child_drupal_field_name_${i}_${j}`],
                    name: _.snakeCase(values[`nested_child_label_${i}_${j}`]),
                    // defaultValue: values[`nested_child_defaultValue_${i}_${j}`],
                    children: [
                      {
                        type: 'text_link',
                        name: _.snakeCase(
                          `${_.snakeCase(
                            values[`nested_child_type_${i}_${j}`]
                          )}_img_source_${i}_${j}`
                        ),
                        label: ` ${
                          values[`nested_child_label_${i}_${j}`]
                        } image source`,
                        defaultValue: values[`image_src_link_${i}_${j}`],
                      },
                      {
                        type: 'text_plain',
                        name: _.snakeCase(
                          `${_.snakeCase(
                            values[`nested_child_type_${i}_${j}`]
                          )}_img_alt_text_${i}_${j}`
                        ),
                        label: `${
                          values[`nested_child_label_${i}_${j}`]
                        } image alt text`,
                        defaultValue: values[`image_alt_text_${i}_${j}`],
                      },
                      {
                        type: 'text_plain',
                        name: _.snakeCase(
                          `${_.snakeCase(
                            values[`nested_child_type_${i}_${j}`]
                          )}_img_styles_${i}_${j}`
                        ),
                        label: `${
                          values[`nested_child_label_${i}_${j}`]
                        } image styles`,
                        options: [...globalImageStyleOptions],
                        defaultValue: values[`image_style_${i}_${j}`],
                      },
                    ],
                  };
                } else if (
                  values[`nested_child_type_${i}_${j}`] === 'picture'
                ) {
                  return {
                    type: values[`nested_child_type_${i}_${j}`],
                    label: values[`nested_child_label_${i}_${j}`],
                    drupal_field_name: values[`child_drupal_field_name_${i}_${j}`],
                    name: _.snakeCase(values[`nested_child_label_${i}_${j}`]),
                    children: [
                      {
                        type: 'image',
                        name: _.snakeCase(
                          `${
                            values[`nested_child_label_${i}_${j}`]
                          }_fall_back_image_src_link`
                        ),
                        label: `${
                          values[`nested_child_label_${i}_${j}`]
                        } fall back image source`,
                        children: [
                          {
                            type: 'text_link',
                            name: 'image_src_link',
                            label: 'Image src link',
                            defaultValue:
                              values[`fall_back_image_src_link_${i}_${j}`],
                          },
                          {
                            type: 'text_plain',
                            name: 'image_alt_text',
                            label: 'Image alt text',
                            defaultValue:
                              values[`fall_back_image_alt_text_${i}_${j}`],
                          },
                          {
                            type: 'text_link',
                            name: 'image_styles',
                            label: 'Image styles',
                            options: [...globalImageStyleOptions],
                            defaultValue:
                              values[`fall_back_image_style_${i}_${j}`],
                          },
                        ],
                      },
                      {
                        type: 'sources',
                        name: _.snakeCase(
                          `${
                            values[`nested_child_label_${i}_${j}`]
                          }_alternate_sources`
                        ),
                        label: `${
                          values[`nested_child_label_${i}_${j}`]
                        } alternate source`,
                        children: [...pictureSourceCollection],
                      },
                    ],
                  };
                } else {
                  return {
                    type: values[`nested_child_type_${i}_${j}`],
                    label: values[`nested_child_label_${i}_${j}`],
                    drupal_field_name: values[`child_drupal_field_name_${i}_${j}`],
                    name: _.snakeCase(values[`nested_child_label_${i}_${j}`]),
                    defaultValue: values[`nested_child_defaultValue_${i}_${j}`],
                    ...(values[`title_${i}_${j}`] && { title: values[`title_${i}_${j}`] }),
                  };
                }
              }),
              // }],
            });
          } else if (values[`child_type_${i}`] === 'image') {
            newChildrenArr.push({
              type: values[`child_type_${i}`],
              label: values[`child_label_${i}`],
              drupal_field_name: values[`child_drupal_field_name_${i}`],
              name: _.snakeCase(values[`child_label_${i}`]),
              //////
              children: [
                {
                  type: 'text_link',
                  name: _.snakeCase(
                    `${_.snakeCase(values[`child_type_${i}`])}_img_source_${i}`
                  ),
                  label: ` ${values[`child_label_${i}`]} image source`,
                  defaultValue: values[`image_src_link_${i}`],
                },
                {
                  type: 'text_plain',
                  name: _.snakeCase(
                    `${_.snakeCase(
                      values[`child_type_${i}`]
                    )}_img_alt_text_${i}`
                  ),
                  label: `${values[`child_label_${i}`]} image alt text`,
                  defaultValue: values[`image_alt_text_${i}`],
                },
                {
                  type: 'text_plain',
                  name: _.snakeCase(
                    `${_.snakeCase(values[`child_type_${i}`])}_img_styles_${i}`
                  ),
                  label: `${values[`child_label_${i}`]} image styles`,
                  options: [...globalImageStyleOptions],
                  // defaultValue: values[`image_style_${i}`],
                  defaultValue: (() => {
                    const modifiedValue = values[`image_style_${i}`]
                      .replace(/(\.bootstrap-styles|\.|\s)/g, '')
                      .trim();
                    return modifiedValue;
                  })(),
                },
              ],
            });
          } else if (values[`child_type_${i}`] === 'picture') {
            /////////////////////////////////

            newChildrenArr.push({
              type: values[`child_type_${i}`],
              label: values[`child_label_${i}`],
              drupal_field_name: values[`child_drupal_field_name_${i}`],
              name: _.snakeCase(values[`child_label_${i}`]),
              children: [
                {
                  type: 'image',
                  name: _.snakeCase(
                    `${values[`child_label_${i}`]}_fall_back_image_src_link`
                  ),
                  label: `${values[`child_label_${i}`]} fall back image source`,
                  children: [
                    {
                      type: 'text_link',
                      name: 'image_src_link',
                      label: 'Image src link',
                      defaultValue: values[`fall_back_image_src_link_${i}`],
                    },
                    {
                      type: 'text_plain',
                      name: 'image_alt_text',
                      label: 'Image alt text',
                      defaultValue: values[`fall_back_image_alt_text_${i}`],
                    },
                    {
                      type: 'text_link',
                      name: 'image_styles',
                      label: 'Image styles',
                      options: [...globalImageStyleOptions],
                      // defaultValue: values[`fall_back_image_style_${i}`],
                      defaultValue: (() => {
                        const modifiedValue = values[
                          `fall_back_image_style_${i}`
                        ]
                          .replace(/(\.bootstrap-styles|\.|\s)/g, '')
                          .trim();
                        return modifiedValue;
                      })(),
                    },
                  ],
                },
                {
                  type: 'sources',
                  name: _.snakeCase(
                    `${values[`child_label_${i}`]}_alternate_sources`
                  ),
                  label: `${values[`child_label_${i}`]} alternate source`,
                  children: [...pictureSourceCollection],
                },
              ],
            });
          } else {
            newChildrenArr.push({
              type: values[`child_type_${i}`],
              label: values[`child_label_${i}`],
              drupal_field_name: values[`child_drupal_field_name_${i}`],
              name: _.snakeCase(values[`child_label_${i}`]),
              defaultValue: values[`child_defaultValue_${i}`],
              // children: [],
              ...(values[`title_${i}`] && { title: values[`title_${i}`] }),
            });
          }
        } else {
          newChildrenArr.push({
            type: values[`child_type_${i}`],
            label: values[`child_label_${i}`],
            drupal_field_name: values[`child_drupal_field_name_${i}`],
            name: _.snakeCase(values[`child_label_${i}`]),
            defaultValue: values[`child_defaultValue_${i}`],
            // children: [],
          });
        }
      });
    } else if (values.type === 'picture') {
      newChildrenArr = [
        {
          type: 'image',
          name: _.snakeCase(
            `${selectedPage.label}_${values.label}_fall_back_image_src_link`
          ),
          label: `${selectedPage.label} ${values.label} fall back image source`,
          children: [
            {
              type: 'text_link',
              name: 'image_src_link',
              label: 'Image src link',
              defaultValue: values.fall_back_image_src_link,
            },
            {
              type: 'text_plain',
              name: 'image_alt_text',
              label: 'Image alt text',
              defaultValue: values.fall_back_image_alt_text,
            },
            {
              type: 'text_link',
              name: 'image_styles',
              label: 'Image styles',
              options: [...globalImageStyleOptions],
              // defaultValue: values.fall_back_image_style,
              defaultValue: (() => {
                const modifiedValue = values.fall_back_image_style
                  .replace(/(\.bootstrap-styles|\.|\s)/g, '')
                  .trim();
                return modifiedValue;
              })(),
            },
          ],
        },
        {
          type: 'sources',
          name: _.snakeCase(
            `${selectedPage.label}_${values.label}_alternate_sources`
          ),
          label: `${selectedPage.label} ${values.label} alternate source`,
          children: [...pictureSourceCollection],
        },
      ];
    } else if (values.type === 'image') {
      newChildrenArr = [
        {
          type: 'text_link',
          name: _.snakeCase(`img_source`),
          label: `image source`,
          defaultValue: values.image_src_link,
        },
        {
          type: 'text_plain',
          name: _.snakeCase(`img_alt_text`),
          label: `image alt text`,
          defaultValue: values.image_alt_text,
        },
        {
          type: 'text_plain',
          name: _.snakeCase(`img_styles`),
          label: `image styles`,
          options: [...globalImageStyleOptions],
          // defaultValue: values.image_style,
          defaultValue: (() => {
            const modifiedValue = values.image_style
              .replace(/(\.bootstrap-styles|\.|\s)/g, '')
              .trim();
            return modifiedValue;
          })(),
        },
      ];
    }

    if (editTraitData) {
      Traits[traitIndex] = {
        ...editTraitData,
        key: editTraitData.key,
        type: values.type,
        // name: newName,
        // label: newLabel,
        drupal_field : values.drupal_field_name,
        ...(values.cardinality && { cardinality: values.cardinality }),
        children: newChildrenArr,
        ...(values.defaultValue && { defaultValue: values.defaultValue }),
        ...(values.title && { title: values.title }),
        ...(values.options && {options : values.options.split(',')}),
      };
    } else {
      newpages.forEach(ele => {
        if (ele.key === selectedPage.key) {
          const newLabel = `${ele.label} ${values.label}`;
          const newName = `${_.snakeCase(ele.label)}_${_.snakeCase(
            values.label
          )}`;
          if (ele.traits && ele.traits.length) {
            ele.traits.push({
              key: uuid(),
              type: values.type,
              name: newName,
              label: newLabel,
              drupal_field : values.drupal_field_name,
              ...(values.cardinality && { cardinality: values.cardinality }),
              ...(values.title && { title: values.title }),
              ...(newChildrenArr?.length > 0 && { children: newChildrenArr }),
              defaultValue: values.defaultValue,
              ...(values.options && {options : values.options.split(',')}),
            });
            setTraitTable([...ele.traits]);
          } else {
            ele.traits = [
              {
                key: uuid(),
                type: values.type,
                name: newName,
                label: newLabel,
                drupal_field : values.drupal_field_name,
                ...(values.cardinality && { cardinality: values.cardinality }),
                ...(values.title && { title: values.title }),
                ...(newChildrenArr?.length > 0 && { children: newChildrenArr }),
                defaultValue: values.defaultValue,
              },
            ];
            setTraitTable([...ele.traits]);
          }
        }
      });
    }
    setPages(newpages);
    setIsModalOpen(false);
    traitform.resetFields();
    setIsSelectedTraitParagraph(false);
    setIsSelectedTraitType(undefined);
    if (editTraitData) {
      setTraitTable([...Traits]);
    }
    showEditModal(false);
    setEditTraitData(null);
    setPictureSourceCollection([{ type: 'text_link', source: '', media: '' }]);
    setChildren([{ type: '', label: '', name: '', defaultValue: '' }]);
  };

  const handleCancel = () => {
    setEditTraitData(null);
    setIsModalOpen(false);
    setChildren([{ type: '', label: '', name: '', defaultValue: '' }]);
    setPictureSourceCollection([{ type: 'text_link', source: '', media: '' }]);
    edittraitform.resetFields();
    traitform.resetFields();
    showEditModal(false);
  };

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: 'Delete',
      dataIndex: '',
      key: '',
      render: (_, record) => {
        const hasChildren = record.children && record.children.length > 0;
        if ((hasChildren && !record.key) || record.key === undefined) {
          return null;
        }
        return (
          <a
            onClick={() => {
              Modal.confirm({
                title: 'Delete Alert.',
                content: 'Do you want to delete it?',
                okText: 'Yes',
                cancelText: 'No',
                onOk: () => {
                  const newpages = [...pages];
                  newpages.forEach(ele => {
                    if (ele.key === selectedPage.key) {
                      if (ele.traits && ele.traits.length) {
                        const updatedTraits = traitTable.filter(
                          e => e.name != record.name
                        );
                        ele.traits = [...updatedTraits];
                        setTraitTable([...updatedTraits]);
                      }
                    }
                  });
                  setPages(newpages);
                },
              });
            }}
          >
            Delete
          </a>
        );
      },
    },
    {
      title: 'Edit',
      dataIndex: '',
      key: '',
      render: (_, record) => {
        const hasChildren = record.children && record.children.length > 0;
        if ((hasChildren && !record.key) || record.key === undefined) {
          return null;
        }
        return (
          <a
            onClick={() => {
              edittraitform.resetFields();

              showEditModal(true);
              setEditTraitData({ ...record });
              setChildren(record.children);
              if (record.type === 'picture') {
                setPictureSourceCollection(record.children?.[1]?.children);
              }
            }}
          >
            Edit
          </a>
        );
      },
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/configurations`
        );
        if (selectedPage) {
          axios
            .get(
              `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/components/pages/${selectedPage._id}/children`
            )
            .then(res => {
              setUsedChildren(res.data);
              if (res.status === 200) {
                isUsedChildrenLoaded.current = true;
                setIsLoading(false);
              }
            })
            .catch(error => {
              console.log(error);
              setIsLoading(false);
            });
        }
        const fetchedOptions =
          response.data.global_configuration.globalImageStyles;
        const options =
          fetchedOptions?.map(opt => ({
            label: opt.name,
            value: `.${CONSTANTS.BOOTSTRAP_WRAPPER_CLASS} .${_.kebabCase(
              opt.name
            )}`,
          })) || [];
        const mediaOptions =
          response.data.global_configuration.breakpoints?.map(opt => {
            return {
              label: opt.name,
              value: `(min-width: ${opt.width}) and (max-width: ${opt.max_width})`,
            };
          }) || [];

        setGlobalImageStyleOptions([...options]);
        setGlobalMediaOptions([...mediaOptions]);
      } catch (error) {
        console.log('error', error);
      }
    };

    fetchData();
  }, [globalConfigs?.globalImageStyles, projectId, selectedPage]);

  const handleSaveVariation = selectedPage => {
    setKey(key + 1); // to rerender ckeditor...
    selectedPage.html = editorRef.current.getData();
    let updatedLayout = [...pages];
    updatedLayout.forEach(layout => {
      if (layout.key === selectedPage.key) {
        delete layout.__v;
        axios
          .patch(
            `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/components/pages/${layout._id}`,
            layout
          )
          // .then(response => response.json())
          .then(data => {
            setDidTraitsUpdate(true);
            setIsLoading(false);
            message.success('Layout Saved successfully');
          })
          .catch(error => {
            setIsLoading(false);
            console.error(error);
            message.error('Something went wrong ! Please try again.');
          });
      }
    });
    setPages(updatedLayout);
  };

  return (
    <>
      <EditForm
        form={edittraitform}
        globalImageStyleOptions={globalImageStyleOptions}
        globalMediaOptions={globalMediaOptions}
        setEditData={editTraitData}
        onFinish={onFinish}
        handleAddChildren={handleAddChildren}
        children={children}
        handleAddAnotherSource={handleAddAnotherSource}
        handleAddPictureCollection={handleAddPictureCollection}
        pictureSourceCollection={pictureSourceCollection}
        setPictureSourceCollection={setPictureSourceCollection}
        handleAddNested={handleAddNested}
        editModal={editModal}
        handleOk={handleOk}
        setEditTraitData={setEditTraitData}
        setIsModalOpen={setIsModalOpen}
        setChildren={setChildren}
        showEditModal={showEditModal}
        handleRemoveNested={handleRemoveNested}
      />

      <Modal
        title={'Create New Trait'}
        okText={'Create'}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={() => {
          handleCancel();
        }}
        width={650}
      >
        <TraitForm
          form={traitform}
          globalImageStyleOptions={globalImageStyleOptions}
          globalMediaOptions={globalMediaOptions}
          handleAddChildren={handleAddChildren}
          onFinish={onFinish}
          children={children}
          handleAddNested={handleAddNested}
          handleAddAnotherSource={handleAddAnotherSource}
          handleAddPictureCollection={handleAddPictureCollection}
          pictureSourceCollection={pictureSourceCollection}
          setPictureSourceCollection={setPictureSourceCollection}
          handleRemoveNested={handleRemoveNested}
        />
      </Modal>

      <Row>
        <Col md={collapsed ? 1 : 4} style={{ transition: '1s' }}>
          <Button
            type="primary"
            onClick={toggleCollapsed}
            style={{
              marginBottom: 16,
              //   marginLeft: 16,
            }}
          >
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </Button>
          <Menu
            mode="inline"
            // onSelect={handleMenuItemSelect}
            inlineCollapsed={collapsed}
          >
            {pages &&
              pages?.map(layout => (
                <MenuItem
                  key={layout?.key}
                  onClick={() => {
                    setselectedPage(layout);
                    isUsedChildrenLoaded.current = false;
                  }}
                >
                  {layout?.label}
                </MenuItem>
              ))}
          </Menu>
        </Col>
        <Col md={collapsed ? 23 : 20} style={{ transition: '1s' }}>
          {selectedPage && isUsedChildrenLoaded.current ? (
            pages?.map(layout => {
              return selectedPage.key === layout.key ? (
                <Spin spinning={isLoading}>
                  <CKEditor
                    key={key}
                    entity={selectedPage}
                    editorRef={editorRef}
                    bootstrap_css={bootstrap_css}
                    saveRef={saveRef}
                    childCss={usedChildren?.childCss}
                    component={layout}
                  />

                  <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
                    <LastModified
                      entityType="pages"
                      entityId={layout._id}
                      forceUpdate={didTraitsUpdate}
                      projectRole={projectRole}
                    />
                  </Row>

                  <Row justify="end" style={{ marginTop: '2em' }}>
                    <Col style={{ margin: '1em' }}>
                      <Button
                        type="primary"
                        onClick={() => {
                          handleAddTraits(layout);
                          traitform.resetFields();
                        }}
                      >
                        Create Traits
                      </Button>
                    </Col>

                    <Col style={{ margin: '1em' }}>
                      <Button
                        type="primary"
                        onClick={() => {
                          window.open(
                            window.location.href +
                              `/preview?key=${selectedPage.key}&type=pages&id=${selectedPage._id}`
                          );
                        }}
                      >
                        Preview
                      </Button>
                    </Col>

                    <Col style={{ margin: '1em' }}>
                      <Button type="primary" onClick={handleReset}>
                        Reset
                      </Button>
                    </Col>

                    <Col style={{ margin: '1em' }}>
                      <Button
                        ref={saveRef}
                        type="primary"
                        onClick={() => {
                          handleSaveVariation(layout);
                          setIsLoading(true);
                        }}
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    {layout.traits?.length ? (
                      <Col justify="start" span={12}>
                        <h3>Available Traits</h3>
                        <Table
                          dataSource={traitTable}
                          columns={columns}
                          pagination={false}
                          size="small"
                        />
                      </Col>
                    ) : null}
                  </Row>
                </Spin>
              ) : null;
            })
          ) : (
            <>
              <Row justify="center" align="middle" style={{ height: '100%' }}>
                <Col>
                  <Text type="warning">
                    Please select a component to proceed
                  </Text>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = state => {
  return {
    designComponents: state.DesignComponents,
    atoms: state.DesignComponents.atoms,
    molecules: state.DesignComponents.molecules,
    organisms: state.DesignComponents.organisms,
    layouts: state.DesignComponents.layouts,
    pages: state.DesignComponents.pages,
    globalConfigs:
      state.GlobalConfigurations.finalConfigs?.global_configuration,
    configuration: state.GlobalConfigurations,
    projectRole: state.ProjectRole.projectRole,
  };
};

const mapDispatchToProps = dispatch => ({
  setPages: pages => {
    dispatch(setPages(pages));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PageTraits);
