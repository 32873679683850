const getHtml = () => {
  return `
    <i class="bi bi-alarm"></i>
  `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const DefaultIcon = { getHtml, getCss, getJs };

export default DefaultIcon;
