const getHtml = () => {
  return `
    <div class="alert alert-primary" role="alert">
      This is a primary alert.
    </div>
  `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const PrimaryAlert = { getHtml, getCss, getJs };

export default PrimaryAlert;
