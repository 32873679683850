import { Form, Input, Select } from 'antd';
const { Option } = Select;
const CSSPropertyWithUnit = ({
  fieldName,
  required = true,
  message = 'Required field',
  placeholder = 'Enter value',
  unitOnchange,
  amountOnchange,
  fieldUnit,
  fieldValue,
}) => {
  return !unitOnchange ? (
    <>
      <Form.Item label={placeholder}>
        <Input.Group compact style={{ display: 'flex' }}>
          <Form.Item
            name={[fieldName, 'value']}
            noStyle
            rules={[
              {
                required: required,
                message: message,
              },
            ]}
          >
            <Input
              type="number"
              style={{
                width: '86%',
              }}
              placeholder={placeholder}
            />
          </Form.Item>
          <Form.Item
            label="Unit"
            name={[fieldName, 'unit']}
            noStyle
            rules={[
              {
                required: required,
                message: 'unit is required',
              },
            ]}
          >
            <Select placeholder="unit">
              <Option value="px">px</Option>
              <Option value="%">%</Option>
              <Option value="em">em</Option>
              <Option value="rem">rem</Option>
              <Option value="vh">vh</Option>
              <Option value="vw">vw</Option>
            </Select>
          </Form.Item>
        </Input.Group>
      </Form.Item>
    </>
  ) : (
    <>
      <Input.Group compact style={{ display: 'flex' }}>
        <Input
          type="number"
          style={{
            width: '86%',
          }}
          value={fieldValue}
          placeholder={placeholder}
          onChange={e => amountOnchange(e.target.value)}
        />

        <Select
          placeholder="unit"
          onChange={value => {
            unitOnchange(value);
          }}
          value={fieldUnit}
        >
          <Option value="em">em</Option>
          <Option value="rem">rem</Option>
        </Select>
      </Input.Group>
    </>
  );
};

export default CSSPropertyWithUnit;
