import GrapesJS from 'grapesjs';
import React, { useEffect, useRef, useState } from 'react';
import {
  Collapse,
  Form,
  Input,
  InputNumber,
  Select,
  message,
  Typography,
} from 'antd';
// import createRoot
import ReactDOM from 'react-dom';

import { connect } from 'react-redux';
// import GlobalConfigurations from '../../../redux/global-configurations/reducer';

import GrapesJSPostCSSParser from 'grapesjs-parser-postcss';

import 'grapesjs/dist/css/grapes.min.css';

import CONSTANTS from '../../../utils/constants';
import InputCode from '../InputCode';
import Modal from 'antd/lib/modal/Modal';
import { Col, Row } from 'antd';
import styles from '../../../utils/constants/styles';
import CSSPropertyWithUnit from '../CSSPropertyWithUnit';
import { useParams } from 'react-router-dom';
import axios from '../../../utils/axios';
import CustomBlockManager from './CustomBlockManager';
import './editor.css';

//cdn : CONSTANTS.BOOTSTRAP_CSS_CDN

const { Panel } = Collapse;
// const { createRoot } = ReactDOM;

const Editor = ({
  id,
  onInit,
  onDestroy,
  children,
  finalConfigs,
  initialCDN,
  childCss = [],
  // customBlocks = [],
  ...options
}) => {
  const ss = ', sans-serif';
  let FONTFAMILIES = [
    'Arial, Helvetica' + ss,
    'Arial Black, Gadget' + ss,
    'Brush Script MT' + ss,
    'Comic Sans MS, cursive' + ss,
    'Courier New, Courier, monospace',
    'Georgia, serif',
    'Helvetica' + ss,
    'Impact, Charcoal' + ss,
    'Lucida Sans Unicode, Lucida Grande' + ss,
    'Tahoma, Geneva' + ss,
    'Times New Roman, Times, serif',
    'Trebuchet MS, Helvetica' + ss,
    'Verdana, Geneva' + ss,
  ].map(font => {
    return { id: font, label: font.split(',')[0], value: font.split(',')[0] };
  });
  let CUSTOMFONTFAMILIES = finalConfigs?.global_configuration?.typography.map(
    fontFamily => {
      return {
        id: fontFamily.font_family,
        label: fontFamily.font_family,
        value: fontFamily.font_family,
      };
    }
  );

  const globalStylesWithoutWrapper =
    finalConfigs?.global_configuration?.globalStylesWithoutWrapper;
  const globalStyles = finalConfigs?.global_configuration?.globalStyles;
  const allGlobalStyles = `${globalStyles}\n${globalStylesWithoutWrapper}`;

  // Add all custom classes by extracting global styling and globalStylingWithoutWrapper.
  const regex = /(?<!:|\w|-|\/|\*|.)\.([\w-\.\s]+)(?![^\{]*\})/g;
  const globalStylesClasses = [];
  let match;

  if (allGlobalStyles) {
    let i = 0;
    while ((match = regex.exec(allGlobalStyles)) !== null) {
      const fetchClasses = match[1].trim().split(/[.\s]+/);
      fetchClasses.map(item => {
        i++;

        if (globalStylesClasses?.length == 0) {
          globalStylesClasses.push({
            value: item.trim(),
            label: item.trim(),
            key: `${item.trim()}${i}`,
          });
        } else {
          const found = globalStylesClasses.some(
            el => el.label === item.trim()
          );
          if (!found) {
            globalStylesClasses.push({
              value: item.trim(),
              label: item.trim(),
              key: `${item.trim()}${i}`,
            });
          }
        }
      });
    }
  }

  // color with 'text-color-*' class
  let colors = finalConfigs?.global_configuration?.colors.map(color => {
    return {
      label: color.variableName + ` (${color.hexcode})`,
      value: `text-color-${color.variableName}`,
    };
  });

  // Backgroudcolor with 'bg-color-*' class
  const backgroundColor = finalConfigs?.global_configuration?.colors.map(
    color => {
      return {
        label: color.variableName + ` (${color.hexcode})`,
        value: `bg-color-${color.variableName}`,
      };
    }
  );
  // Border color with 'border-color-*' class
  const borderColor = finalConfigs?.global_configuration?.colors.map(color => {
    return {
      label: color.variableName + ` (${color.hexcode})`,
      value: `border-color-${color.variableName}`,
    };
  });

  const screenSize = window.screen.width;
  const [selectedClasses, setSelectedClasses] = useState([]);

  FONTFAMILIES = [...CUSTOMFONTFAMILIES, ...FONTFAMILIES];
  const [editor, setEditor] = useState();
  const [isShow, setIsShow] = useState(false);
  const [editorCode, setEditorCode] = useState('');
  const [editorCss, setEditorCss] = useState('');
  const CSS_CDN = initialCDN;
  const [messageApi, contextHolder] = message.useMessage();
  const { projectId, step } = useParams();
  const CanvasConfig = {
    styles: [CSS_CDN, CONSTANTS.BOOTSTRAP_ICON_CSS_CDN, ...childCss],
    scripts: [CONSTANTS.BOOTSTRAP_JS_CDN],
  };
  const { Text } = Typography;

  useEffect(() => {
    const selector = `#${id}`;
    let defaultDevice = {
      name: 'Default',
      width: '', // default size
    };

    if (finalConfigs.global_configuration.breakpoints[0]?.width !== '') {
      finalConfigs.global_configuration.breakpoints.unshift(defaultDevice);
    }
    let imageList = [];
    axios
      .get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/assets?limit=10&offset=0`
      )
      .then(data => {
        if (data) {
          axios
            .get(
              `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/assets?limit=${data.data.totalDocs}&offset=0`
            )
            .then(data => {
              data.data.docs?.forEach(asset => {
                if (
                  asset.mimeType === 'image/jpeg' ||
                  asset.mimeType === 'image/svg+xml' ||
                  asset.mimeType === 'image/png' ||
                  asset.mimeType === 'image/jpg'
                ) {
                  imageList.push({
                    value: asset.file,
                    label: asset.name,
                    key: asset._id,
                  });
                }
              });
            });
        }
      })
      .catch(err => {
        console.log(err);
      });

    if (!editor) {
      const editor = GrapesJS.init({
        container: selector,
        fromElement: !!children,
        plugins: [GrapesJSPostCSSParser],
        deviceManager: {
          devices: [...finalConfigs.global_configuration.breakpoints],
        },
        styleManager: {
          custom: true,
        },
        // blockManager: {
        //   // custom: true,
        //   // blocks:[...customBlocks]
        // },
        canvas: CanvasConfig,
        keepUnusedStyles: true,
        allowScripts: 1,
        ...options,
      });
      window.editor = editor;
      setSelectedClasses(editor.getSelected()?.getClasses());
      let active = '';
      const CollapesActiveFunction = value => {
        active = value;
      };

      let styleSearchText;
      const setStyleSearchText = value => {
        styleSearchText = value;
      };

      editor.on('component:selected', component => {
        // disable Editing because it's a lower level component
        if (editor.getSelected()?.getClasses().includes('drag-n-drop')) {
          editor.getSelected().set('editable', false);
          editor.getSelected().set('stylable', false);
          editor.getSelected().set('unstylable', false);
          editor.getSelected().set('toolbar', []);
          editor.getSelected().set('draggable', false);
          editor.getSelected().set('removable', false);
        }
      });

      editor.on('block:custom', props => {
        ReactDOM.render(
          <CustomBlockManager
            blocks={props.blocks}
            dragStart={props.dragStart}
            dragStop={props.dragStop}
            editor={editor}
          />,
          props.container
        );
      });

      editor.on('style:custom', props => {
        if (editor.getSelected()) {
          const StylePanel = ({ CollapesActiveFunction, styleSearchText }) => {
            const [fontsize, setFontSize] = useState();
            const [fontunit, setFontUnit] = useState();
            const [letterSpacingValue, setLetterSpacingValue] = useState();
            const [letterSpacingUnit, setLetterSpacingUnit] = useState();
            const [lineHeightValue, setLineHeightValue] = useState();
            const [lineHeightUnit, setLineHeightUnit] = useState();
            const [selectedFontFamily, setSelectedFontFamily] = useState();
            const [selecctedFontWeight, setSelectedFontWeight] = useState();
            const [selectedOpacity, setSelectedOpacity] = useState();
            const [selectedBackgroudImage, setSelectedBackgroudImage] =
              useState();
            const [selectedTopBorderWidth, setSelectedTopBorderWidth] =
              useState();
            const [selectedLeftBorderWidth, setSelectedLeftBorderWidth] =
              useState();
            const [selectedBottomBorderWidth, setSelectedBottomBorderWidth] =
              useState();
            const [selectedRightBorderWidth, setSelectedRightBorderWidth] =
              useState();
            const [searchStyle, setSearchStyle] = useState(styleSearchText);
            const [resultOfSearchStyle, setResultOfSearchStyle] = useState([
              ...styles,
            ]);

            const [activeKey, setActiveKey] = useState(active);
            const findItem = (array, findValue) => {
              const index = array.findIndex(item =>
                findValue.includes(item.value)
              );
              return array[index];
            };

            useEffect(() => {
              if (searchStyle) {
                let panelKeys = [];
                let dataForSearch = JSON.parse(
                  JSON.stringify({ styles: styles })
                )?.styles;
                searchStyle &&
                  dataForSearch.map((item, itemIndex) => {
                    panelKeys.push(item.category);
                    const data = dataForSearch[itemIndex].items.filter(style =>
                      style.label
                        .toLowerCase()
                        .includes(searchStyle.toLowerCase())
                    );
                    dataForSearch[itemIndex].items = data;
                  });
                setResultOfSearchStyle([...dataForSearch]);
                panelKeys.push('Typography');
                panelKeys.push('Decorations');
                if (searchStyle) {
                  setActiveKey([...panelKeys]);
                  CollapesActiveFunction([...panelKeys]);
                } else {
                  setActiveKey([]);
                  CollapesActiveFunction([]);
                }
              }
            }, [searchStyle]);

            // +++++++++++++++++++++++++++    Getting  and setting all styles +++++++++++++++++++++++++++++

            useEffect(() => {
              let getStyles = editor?.StyleManager?.getSelected()?.getStyle();
              let keys = getStyles && Object.keys(getStyles);
              if (keys && keys.length > 0) {
                keys.map(style => {
                  switch (style) {
                    case 'font-size':
                      var value = getStyles[style].split(
                        /(\d+(?:\.\d+)?)(em|rem)/g
                      );
                      setFontSize(value[1]);
                      setFontUnit(value[2]);
                      break;
                    case 'letter-spacing':
                      var value = getStyles[style].split(
                        /(\d+(?:\.\d+)?)(em|rem)/g
                      );
                      setLetterSpacingValue(value[1]);
                      setLetterSpacingUnit(value[2]);
                      break;
                    case 'line-height':
                      var value = getStyles[style].split(
                        /(\d+(?:\.\d+)?)(em|rem)/g
                      );
                      setLineHeightValue(value[1]);
                      setLineHeightUnit(value[2]);
                      break;
                    case 'font-family':
                      setSelectedFontFamily(getStyles[style]);
                      break;
                    case 'font-weight':
                      setSelectedFontWeight(getStyles[style]);
                      break;
                    case 'border-top-width':
                      var value =
                        getStyles[style].split(/(\d+(?:\.\d+)?)(px)/g);
                      setSelectedTopBorderWidth(value[1]);
                      break;
                    case 'border-left-width':
                      var value =
                        getStyles[style].split(/(\d+(?:\.\d+)?)(px)/g);
                      setSelectedLeftBorderWidth(value[1]);
                      break;
                    case 'border-bottom-width':
                      var value =
                        getStyles[style].split(/(\d+(?:\.\d+)?)(px)/g);
                      setSelectedBottomBorderWidth(value[1]);
                      break;
                    case 'border-right-width':
                      var value =
                        getStyles[style].split(/(\d+(?:\.\d+)?)(px)/g);
                      setSelectedRightBorderWidth(value[1]);
                      break;
                    case 'background-image':
                      let ImageLabel = findItem(imageList, getStyles[style]);
                      setSelectedBackgroudImage(ImageLabel);
                      break;

                    case 'opacity':
                      setSelectedOpacity(getStyles[style]);
                      break;
                  }
                });
              }
            }, []);

            const fontUnitonchange = value => {
              setFontUnit(value);
              if (fontsize && value) {
                let isClass = editor?.getSelected()?.getClasses();
                if (isClass && isClass.length > 0) {
                  editor
                    .getSelected()
                    ?.em?.get('StyleManager')
                    .addStyleTargets({ 'font-size': `${fontsize}${value}` });
                } else {
                  messageApi.open({
                    type: 'warning',
                    content: 'Please add a class before applying any styles',
                  });
                }
              }
            };
            const fontOnchange = value => {
              setFontSize(value);
              if (fontunit) {
                let isClass = editor.getSelected()?.getClasses();
                if (isClass && isClass.length > 0) {
                  editor
                    .getSelected()
                    ?.em?.get('StyleManager')
                    .addStyleTargets({ 'font-size': `${value}${fontunit}` });
                } else {
                  messageApi.open({
                    type: 'warning',
                    content: 'Please add a class before applying any styles',
                  });
                }
              }
            };

            const letterSpacingUnitOnchange = value => {
              setLetterSpacingUnit(value);
              if (letterSpacingValue && value) {
                let isClass = editor.getSelected()?.getClasses();
                if (isClass && isClass.length > 0) {
                  editor
                    .getSelected()
                    ?.em?.get('StyleManager')
                    .addStyleTargets({
                      'letter-spacing': `${letterSpacingValue}${value}`,
                    });
                } else {
                  messageApi.open({
                    type: 'warning',
                    content: 'Please add a class before applying any styles',
                  });
                }
              }
            };
            const letterSpacingValueOnchange = value => {
              setLetterSpacingValue(value);
              if (letterSpacingUnit) {
                let isClass = editor.getSelected()?.getClasses();
                if (isClass && isClass.length > 0) {
                  editor
                    .getSelected()
                    ?.em?.get('StyleManager')
                    ?.addStyleTargets({
                      'letter-spacing': `${value}${letterSpacingUnit}`,
                    });
                } else {
                  messageApi.open({
                    type: 'warning',
                    content: 'Please add a class before applying any styles',
                  });
                }
              }
            };
            const lineHeightUnitOnchange = value => {
              setLineHeightUnit(value);
              if (lineHeightValue && value) {
                let isClass = editor.getSelected()?.getClasses();
                if (isClass && isClass.length > 0) {
                  editor
                    .getSelected()
                    ?.em?.get('StyleManager')
                    .addStyleTargets({
                      'line-height': `${lineHeightValue}${value}`,
                    });
                } else {
                  messageApi.open({
                    type: 'warning',
                    content: 'Please add a class before applying any styles',
                  });
                }
              }
            };
            const lineHeightValueOnchange = value => {
              setLineHeightValue(value);
              if (lineHeightUnit) {
                let isClass = editor.getSelected()?.getClasses();
                if (isClass && isClass.length > 0) {
                  editor
                    .getSelected()
                    ?.em?.get('StyleManager')
                    .addStyleTargets({
                      'line-height': `${value}${lineHeightUnit}`,
                    });
                } else {
                  messageApi.open({
                    type: 'warning',
                    content: 'Please add a class before applying any styles',
                  });
                }
              }
            };
            const typographyTagsForSearch =
              'font-size  font size  font-family  font family  font-weight  font weight  letter-spacing  letter specing';
            const decorationTagsForSearch =
              'text color text-color  bordercolor border color  border-top-width top border width  border-left-width left border width  border-bottom-width bottom border width  border-right-width right border width  background color background-color  background image background-image  opacity';
            return (
              <div className="customStyleManager">
                <Input
                  placeholder="Search styles ..."
                  onChange={e => {
                    setSearchStyle(e.target.value);
                    setStyleSearchText(e.target.value);
                  }}
                  style={{ margin: '10px 0 10px 0' }}
                  value={searchStyle}
                />
                <Collapse
                  activeKey={activeKey}
                  onChange={value => {
                    setActiveKey(value);
                    CollapesActiveFunction(value);
                  }}
                  style={{ backgroundColor: ' rgb(0 0 0 / 15%)' }}
                >
                  <Panel header="Global Styles" key="globalStyles">
                    <Select
                      showSearch
                      placeholder="Select Global Styles"
                      options={globalStylesClasses}
                      filterOption={(input, option) =>
                        (option?.label ?? '').includes(input)
                      }
                      dropdownStyle={
                        !globalStylesClasses.length > 0 && { display: 'none' }
                      }
                      onChange={value => {
                        editor.getSelected()?.addClass(value);
                        messageApi.open({
                          type: 'success',
                          content: 'Class applied',
                        });
                      }}
                      style={{ minWidth: '100%', maxWidth: '100%' }}
                    />
                  </Panel>

                  {(typographyTagsForSearch.includes(
                    searchStyle?.toLowerCase().trim()
                  ) ||
                    searchStyle == undefined) && (
                    <Panel header="Typography" key="Typography">
                      <Row
                        justify="space-between"
                        style={
                          searchStyle &&
                          !'font-size font size'.includes(
                            searchStyle?.toLowerCase()
                          )
                            ? { display: 'none' }
                            : {}
                        }
                      >
                        <Col md={12} style={{ textAlign: 'left' }}>
                          <p style={{ margin: '5px 0 0 0', fontSize: '.8rem' }}>
                            Font Size
                          </p>
                        </Col>
                        <Col md={12}>
                          {fontsize && fontunit && (
                            <p
                              style={
                                searchStyle &&
                                !'font-size font size'.includes(
                                  searchStyle?.toLowerCase()
                                )
                                  ? { display: 'none' }
                                  : {
                                      color: '#d278c9',
                                      margin: '5px 0 0 0',
                                      cursor: 'pointer',
                                      fontSize: '.8rem',
                                    }
                              }
                              onClick={() => {
                                editor?.StyleManager?.getSelected()?.removeStyle(
                                  'font-size'
                                );
                                setFontSize(null);
                                setFontUnit(null);
                              }}
                            >
                              Font size x
                            </p>
                          )}
                        </Col>
                        <Col md={24}>
                          <CSSPropertyWithUnit
                            fieldName="font-size"
                            placeholder="Font size"
                            unitOnchange={fontUnitonchange}
                            amountOnchange={fontOnchange}
                            fieldValue={fontsize}
                            fieldUnit={fontunit}
                          />
                        </Col>
                      </Row>

                      <Row
                        justify="space-between"
                        style={
                          searchStyle &&
                          !'font-family font family'.includes(
                            searchStyle?.toLowerCase()
                          )
                            ? { display: 'none' }
                            : {}
                        }
                      >
                        <Col md={12} style={{ textAlign: 'left' }}>
                          <p style={{ margin: '5px 0 0 0', fontSize: '.8rem' }}>
                            Font Family
                          </p>
                        </Col>
                        <Col md={12}>
                          {selectedFontFamily && (
                            <p
                              style={
                                searchStyle &&
                                !'font-family font family'.includes(
                                  searchStyle?.toLowerCase()
                                )
                                  ? { display: 'none' }
                                  : {
                                      color: '#d278c9',
                                      margin: '5px 0 0 0',
                                      cursor: 'pointer',
                                      fontSize: '.8rem',
                                    }
                              }
                              onClick={() => {
                                editor?.StyleManager?.getSelected()?.removeStyle(
                                  'font-family'
                                );
                                setSelectedFontFamily(null);
                              }}
                            >
                              Font Family x
                            </p>
                          )}
                        </Col>
                        <Col md={24}>
                          <Select
                            showSearch
                            placeholder="select font family"
                            options={[...FONTFAMILIES]}
                            filterOption={(input, option) =>
                              (option?.label ?? '').includes(input)
                            }
                            value={selectedFontFamily}
                            style={{ minWidth: '100%', maxWidth: '100%' }}
                            onChange={value => {
                              let isClass = editor.getSelected()?.getClasses();
                              if (isClass && isClass.length > 0) {
                                setSelectedFontFamily(value);

                                editor
                                  .getSelected()
                                  ?.em?.get('StyleManager')
                                  .addStyleTargets({ 'font-family': value });
                              } else {
                                messageApi.open({
                                  type: 'warning',
                                  content:
                                    'Please add a class before applying any styles',
                                });
                              }
                            }}
                          />
                        </Col>
                      </Row>

                      <Row
                        justify="space-between"
                        style={
                          searchStyle &&
                          !'font-weight font weight'.includes(
                            searchStyle?.toLowerCase()
                          )
                            ? { display: 'none' }
                            : {}
                        }
                      >
                        <Col md={12} style={{ textAlign: 'left' }}>
                          <p style={{ margin: '5px 0 0 0', fontSize: '.8rem' }}>
                            Font Weight
                          </p>
                        </Col>
                        <Col md={12}>
                          {selecctedFontWeight && (
                            <p
                              style={
                                searchStyle &&
                                !'font-weight font weight'.includes(
                                  searchStyle?.toLowerCase()
                                )
                                  ? { display: 'none' }
                                  : {
                                      color: '#d278c9',
                                      margin: '5px 0 0 0',
                                      cursor: 'pointer',
                                      fontSize: '.8rem',
                                    }
                              }
                              onClick={() => {
                                editor?.StyleManager?.getSelected()?.removeStyle(
                                  'font-weight'
                                );
                                setSelectedFontWeight(null);
                              }}
                            >
                              Font Weight x
                            </p>
                          )}
                        </Col>
                        <Col md={24}>
                          <Input
                            type="number"
                            placeholder="Enter Font Weight"
                            value={selecctedFontWeight}
                            onChange={e => {
                              let isClass = editor.getSelected()?.getClasses();
                              if (isClass && isClass.length > 0) {
                                editor
                                  .getSelected()
                                  ?.em?.get('StyleManager')
                                  .addStyleTargets({
                                    'font-weight': e.target.value,
                                  });
                              } else {
                                messageApi.open({
                                  type: 'warning',
                                  content:
                                    'Please add a class before applying any styles',
                                });
                              }
                            }}
                          />
                        </Col>
                      </Row>

                      <Row
                        justify="space-between"
                        style={
                          searchStyle &&
                          !'letter-spacing letter specing'.includes(
                            searchStyle?.toLowerCase()
                          )
                            ? { display: 'none' }
                            : {}
                        }
                      >
                        <Col md={12} style={{ textAlign: 'left' }}>
                          <p style={{ margin: '5px 0 0 0', fontSize: '.8rem' }}>
                            Letter Spacing
                          </p>
                        </Col>
                        <Col md={12}>
                          {letterSpacingValue && letterSpacingUnit && (
                            <p
                              style={
                                searchStyle &&
                                !'letter-spacing letter spacing'.includes(
                                  searchStyle?.toLowerCase()
                                )
                                  ? { display: 'none' }
                                  : {
                                      color: '#d278c9',
                                      margin: '5px 0 0 0',
                                      cursor: 'pointer',
                                      fontSize: '.8rem',
                                    }
                              }
                              onClick={() => {
                                editor?.StyleManager?.getSelected()?.removeStyle(
                                  'letter-spacing'
                                );
                                setLetterSpacingValue(null);
                                setLetterSpacingUnit(null);
                              }}
                            >
                              Letter Spacing x
                            </p>
                          )}
                        </Col>
                        <Col md={24}>
                          <CSSPropertyWithUnit
                            fieldName="letter-spacing"
                            placeholder="Letter Spacing"
                            unitOnchange={letterSpacingUnitOnchange}
                            amountOnchange={letterSpacingValueOnchange}
                            fieldValue={letterSpacingValue}
                            fieldUnit={letterSpacingUnit}
                          />
                        </Col>
                      </Row>

                      <Row
                        justify="space-between"
                        style={
                          searchStyle &&
                          !'line-height line height'.includes(
                            searchStyle?.toLowerCase()
                          )
                            ? { display: 'none' }
                            : {}
                        }
                      >
                        <Col md={12} style={{ textAlign: 'left' }}>
                          <p style={{ margin: '5px 0 0 0', fontSize: '.8rem' }}>
                            Line Height
                          </p>
                        </Col>
                        <Col md={12}>
                          {lineHeightValue && lineHeightUnit && (
                            <p
                              style={
                                searchStyle &&
                                !'line-height line height'.includes(
                                  searchStyle?.toLowerCase()
                                )
                                  ? { display: 'none' }
                                  : {
                                      color: '#d278c9',
                                      margin: '5px 0 0 0',
                                      cursor: 'pointer',
                                      fontSize: '.8rem',
                                    }
                              }
                              onClick={() => {
                                editor?.StyleManager?.getSelected()?.removeStyle(
                                  'line-height'
                                );
                                setLineHeightValue(null);
                                setLineHeightUnit(null);
                              }}
                            >
                              Line Height x
                            </p>
                          )}
                        </Col>
                        <Col md={24}>
                          <CSSPropertyWithUnit
                            fieldName="line-height"
                            placeholder="Line Height"
                            unitOnchange={lineHeightUnitOnchange}
                            amountOnchange={lineHeightValueOnchange}
                            fieldValue={lineHeightValue}
                            fieldUnit={lineHeightUnit}
                          />
                        </Col>
                      </Row>
                    </Panel>
                  )}
                  {(decorationTagsForSearch.includes(
                    searchStyle?.toLowerCase().trim()
                  ) ||
                    searchStyle == undefined) && (
                    <Panel header="Decorations" key="Decorations">
                      <Row
                        justify="space-between"
                        style={
                          searchStyle &&
                          !'text color text-color'.includes(
                            searchStyle?.toLowerCase()
                          )
                            ? { display: 'none' }
                            : {}
                        }
                      >
                        <Col md={24} style={{ textAlign: 'left' }}>
                          <p style={{ margin: '5px 0 0 0', fontSize: '.8rem' }}>
                            Color
                          </p>
                        </Col>
                        <Col md={24}>
                          <Select
                            showSearch
                            options={[...colors]}
                            placeholder="text color"
                            filterOption={(input, option) =>
                              (option?.label ?? '').includes(input)
                            }
                            onChange={value => {
                              editor.getSelected().addClass(value);
                              messageApi.open({
                                type: 'success',
                                content: 'Class added',
                              });
                            }}
                            style={{
                              maxWidth: '100%',
                              minWidth: '100%',
                            }}
                          />
                        </Col>
                      </Row>

                      <Row
                        justify="space-between"
                        style={
                          searchStyle &&
                          !'bordercolor border color'.includes(
                            searchStyle?.toLowerCase()
                          )
                            ? { display: 'none' }
                            : {}
                        }
                      >
                        <Col md={24} style={{ textAlign: 'left' }}>
                          <p style={{ margin: '5px 0 0 0', fontSize: '.8rem' }}>
                            Border Color
                          </p>
                        </Col>
                        <Col md={24}>
                          <Select
                            showSearch
                            options={[...borderColor]}
                            placeholder="Border color"
                            filterOption={(input, option) =>
                              (option?.label ?? '').includes(input)
                            }
                            onChange={value => {
                              editor.getSelected().addClass(value);
                              messageApi.open({
                                type: 'success',
                                content: 'Class added.',
                              });
                            }}
                            style={{
                              maxWidth: '100%',
                              minWidth: '100%',
                            }}
                          />
                        </Col>
                      </Row>

                      <Row
                        justify="space-between"
                        style={
                          searchStyle &&
                          !'border-top-width top border width'.includes(
                            searchStyle?.toLowerCase()
                          )
                            ? { display: 'none' }
                            : {}
                        }
                      >
                        <Col md={12} style={{ textAlign: 'left' }}>
                          <p style={{ margin: '5px 0 0 0', fontSize: '.8rem' }}>
                            Top Border Width
                          </p>
                        </Col>
                        <Col md={12}>
                          {selectedTopBorderWidth && (
                            <p
                              style={
                                searchStyle &&
                                !'border-top-width top border width'.includes(
                                  searchStyle?.toLowerCase()
                                )
                                  ? { display: 'none' }
                                  : {
                                      color: '#d278c9',
                                      margin: '5px 0 0 0',
                                      cursor: 'pointer',
                                      fontSize: '.8rem',
                                    }
                              }
                              onClick={() => {
                                editor?.StyleManager?.getSelected()?.removeStyle(
                                  'border-top-width'
                                );
                                setSelectedTopBorderWidth(null);
                              }}
                            >
                              Top Border Width x
                            </p>
                          )}
                        </Col>
                        <Col md={24}>
                          <Input
                            value={selectedTopBorderWidth}
                            placeholder="Top Border width"
                            onChange={e => {
                              let isClass = editor.getSelected()?.getClasses();
                              if (isClass && isClass.length > 0) {
                                setSelectedTopBorderWidth(e.target.value);

                                editor
                                  .getSelected()
                                  ?.em?.get('StyleManager')
                                  .addStyleTargets({
                                    'border-top-width': `${e.target.value}px`,
                                  });
                              } else {
                                messageApi.open({
                                  type: 'warning',
                                  content:
                                    'Please add a class before applying any styles',
                                });
                              }
                            }}
                            style={{
                              maxWidth: '100%',
                              minWidth: '100%',
                            }}
                          />
                        </Col>
                      </Row>

                      <Row
                        justify="space-between"
                        style={
                          searchStyle &&
                          !'border-left-width left border width'.includes(
                            searchStyle?.toLowerCase()
                          )
                            ? { display: 'none' }
                            : {}
                        }
                      >
                        <Col md={12} style={{ textAlign: 'left' }}>
                          <p style={{ margin: '5px 0 0 0', fontSize: '.8rem' }}>
                            Left Border Width
                          </p>
                        </Col>
                        <Col md={12}>
                          {selectedLeftBorderWidth && (
                            <p
                              style={
                                searchStyle &&
                                !'border-left-width left border width'.includes(
                                  searchStyle?.toLowerCase()
                                )
                                  ? { display: 'none' }
                                  : {
                                      color: '#d278c9',
                                      margin: '5px 0 0 0',
                                      cursor: 'pointer',
                                      fontSize: '.8rem',
                                    }
                              }
                              onClick={() => {
                                editor?.StyleManager?.getSelected()?.removeStyle(
                                  'border-left-width'
                                );
                                setSelectedLeftBorderWidth(null);
                              }}
                            >
                              Left Border Width x
                            </p>
                          )}
                        </Col>
                        <Col md={24}>
                          <Input
                            value={selectedLeftBorderWidth}
                            placeholder="Left Border width"
                            onChange={e => {
                              let isClass = editor.getSelected()?.getClasses();
                              if (isClass && isClass.length > 0) {
                                setSelectedLeftBorderWidth(e.target.value);

                                editor
                                  .getSelected()
                                  ?.em?.get('StyleManager')
                                  .addStyleTargets({
                                    'border-left-width': `${e.target.value}px`,
                                  });
                              } else {
                                messageApi.open({
                                  type: 'warning',
                                  content:
                                    'Please add a class before applying any styles',
                                });
                              }
                            }}
                            style={{
                              maxWidth: '100%',
                              minWidth: '100%',
                            }}
                          />
                        </Col>
                      </Row>

                      <Row
                        justify="space-between"
                        style={
                          searchStyle &&
                          !'border-bottom-width bottom border width'.includes(
                            searchStyle?.toLowerCase()
                          )
                            ? { display: 'none' }
                            : {}
                        }
                      >
                        <Col md={12} style={{ textAlign: 'left' }}>
                          <p style={{ margin: '5px 0 0 0', fontSize: '.8rem' }}>
                            Bottom Border Width
                          </p>
                        </Col>
                        <Col md={12}>
                          {selectedBottomBorderWidth && (
                            <p
                              style={
                                searchStyle &&
                                !'border-bottom-width bottom border width'.includes(
                                  searchStyle?.toLowerCase()
                                )
                                  ? { display: 'none' }
                                  : {
                                      color: '#d278c9',
                                      margin: '5px 0 0 0',
                                      cursor: 'pointer',
                                      fontSize: '.8rem',
                                    }
                              }
                              onClick={() => {
                                editor?.StyleManager?.getSelected()?.removeStyle(
                                  'border-bottom-width'
                                );
                                setSelectedBottomBorderWidth(null);
                              }}
                            >
                              Bottom Border Width x
                            </p>
                          )}
                        </Col>
                        <Col md={24}>
                          <Input
                            value={selectedBottomBorderWidth}
                            placeholder="Bottom Border width"
                            onChange={e => {
                              let isClass = editor.getSelected()?.getClasses();
                              if (isClass && isClass.length > 0) {
                                setSelectedBottomBorderWidth(e.target.value);

                                editor
                                  .getSelected()
                                  ?.em?.get('StyleManager')
                                  .addStyleTargets({
                                    'border-bottom-width': `${e.target.value}px`,
                                  });
                              } else {
                                messageApi.open({
                                  type: 'warning',
                                  content:
                                    'Please add a class before applying any styles',
                                });
                              }
                            }}
                            style={{
                              maxWidth: '100%',
                              minWidth: '100%',
                            }}
                          />
                        </Col>
                      </Row>

                      <Row
                        justify="space-between"
                        style={
                          searchStyle &&
                          !'border-right-width right border width'.includes(
                            searchStyle?.toLowerCase()
                          )
                            ? { display: 'none' }
                            : {}
                        }
                      >
                        <Col md={12} style={{ textAlign: 'left' }}>
                          <p style={{ margin: '5px 0 0 0', fontSize: '.8rem' }}>
                            Right Border Width
                          </p>
                        </Col>
                        <Col md={12}>
                          {selectedRightBorderWidth && (
                            <p
                              style={
                                searchStyle &&
                                !'border-bottom-width bottom border width'.includes(
                                  searchStyle?.toLowerCase()
                                )
                                  ? { display: 'none' }
                                  : {
                                      color: '#d278c9',
                                      margin: '5px 0 0 0',
                                      cursor: 'pointer',
                                      fontSize: '.8rem',
                                    }
                              }
                              onClick={() => {
                                editor?.StyleManager?.getSelected()?.removeStyle(
                                  'border-right-width'
                                );
                                setSelectedRightBorderWidth(null);
                              }}
                            >
                              Right Border Width x
                            </p>
                          )}
                        </Col>
                        <Col md={24}>
                          <Input
                            value={selectedRightBorderWidth}
                            placeholder="Right Border width"
                            onChange={e => {
                              let isClass = editor.getSelected()?.getClasses();
                              if (isClass && isClass.length > 0) {
                                setSelectedRightBorderWidth(e.target.value);

                                editor
                                  .getSelected()
                                  ?.em?.get('StyleManager')
                                  .addStyleTargets({
                                    'border-right-width': `${e.target.value}px`,
                                  });
                              } else {
                                messageApi.open({
                                  type: 'warning',
                                  content:
                                    'Please add a class before applying any styles',
                                });
                              }
                            }}
                            style={{
                              maxWidth: '100%',
                              minWidth: '100%',
                            }}
                          />
                        </Col>
                      </Row>

                      <Row
                        justify="space-between"
                        style={
                          searchStyle &&
                          !'background color background-color'.includes(
                            searchStyle?.toLowerCase()
                          )
                            ? { display: 'none' }
                            : {}
                        }
                      >
                        <Col md={24} style={{ textAlign: 'left' }}>
                          <p style={{ margin: '5px 0 0 0', fontSize: '.8rem' }}>
                            Back ground Color{' '}
                          </p>
                        </Col>
                        <Col md={24}>
                          <Select
                            showSearch
                            options={[...backgroundColor]}
                            placeholder="background color"
                            filterOption={(input, option) =>
                              (option?.label ?? '').includes(input)
                            }
                            onChange={value => {
                              editor.getSelected().addClass(value);
                              messageApi.open({
                                type: 'success',
                                content: 'Class added.',
                              });
                            }}
                            style={{
                              maxWidth: '100%',
                              minWidth: '100%',
                            }}
                          />
                        </Col>
                      </Row>

                      <Row
                        justify="space-between"
                        style={
                          searchStyle &&
                          !'background image background-image'.includes(
                            searchStyle?.toLowerCase()
                          )
                            ? { display: 'none' }
                            : {}
                        }
                      >
                        <Col md={12} style={{ textAlign: 'left' }}>
                          <p style={{ margin: '5px 0 0 0', fontSize: '.8rem' }}>
                            Back ground Image{' '}
                          </p>
                        </Col>
                        <Col md={12}>
                          {selectedBackgroudImage && (
                            <p
                              style={
                                searchStyle &&
                                !'background image background-image'.includes(
                                  searchStyle?.toLowerCase()
                                )
                                  ? { display: 'none' }
                                  : {
                                      color: '#d278c9',
                                      margin: '5px 0 0 0',
                                      cursor: 'pointer',
                                      fontSize: '.8rem',
                                    }
                              }
                              onClick={() => {
                                editor?.StyleManager?.getSelected()?.removeStyle(
                                  'background-image'
                                );
                                setSelectedBackgroudImage(null);
                              }}
                            >
                              Background Image x
                            </p>
                          )}
                        </Col>
                        <Col md={24}>
                          <Select
                            showSearch
                            options={[...imageList]}
                            defaultValue={imageList[0]}
                            value={selectedBackgroudImage}
                            filterOption={(input, option) =>
                              (option?.label ?? '').includes(input)
                            }
                            onChange={value => {
                              let isClass = editor.getSelected()?.getClasses();
                              if (isClass && isClass.length > 0) {
                                setSelectedBackgroudImage(value);

                                editor
                                  .getSelected()
                                  ?.em?.get('StyleManager')
                                  .addStyleTargets({
                                    'background-image': `url('${value}')`,
                                  });
                              } else {
                                messageApi.open({
                                  type: 'warning',
                                  content:
                                    'Please add a class before applying any styles',
                                });
                              }
                            }}
                            style={{
                              maxWidth: '100%',
                              minWidth: '100%',
                            }}
                          />
                        </Col>
                      </Row>

                      <Row
                        justify="space-between"
                        style={
                          searchStyle &&
                          !'opacity'.includes(searchStyle?.toLowerCase())
                            ? { display: 'none' }
                            : {}
                        }
                      >
                        <Col md={12} style={{ textAlign: 'left' }}>
                          <p style={{ margin: '5px 0 0 0', fontSize: '.8rem' }}>
                            Opacity{' '}
                          </p>
                        </Col>
                        <Col md={12}>
                          {selectedOpacity && (
                            <p
                              style={
                                searchStyle &&
                                !'opacity'.includes(searchStyle?.toLowerCase())
                                  ? { display: 'none' }
                                  : {
                                      color: '#d278c9',
                                      margin: '5px 0 0 0',
                                      cursor: 'pointer',
                                      fontSize: '.8rem',
                                    }
                              }
                              onClick={() => {
                                editor?.StyleManager?.getSelected()?.removeStyle(
                                  'opacity'
                                );
                                setSelectedOpacity(null);
                              }}
                            >
                              Opacity x
                            </p>
                          )}
                        </Col>
                        <Col md={24}>
                          <InputNumber
                            min={0}
                            max={1}
                            step={0.1}
                            value={selectedOpacity}
                            onChange={value => {
                              let isClass = editor.getSelected()?.getClasses();
                              if (isClass && isClass.length > 0) {
                                setSelectedOpacity(value);
                                editor
                                  .getSelected()
                                  ?.em?.get('StyleManager')
                                  .addStyleTargets({ opacity: value });
                              } else {
                                messageApi.open({
                                  type: 'warning',
                                  content:
                                    'Please add a class before applying any styles',
                                });
                              }
                            }}
                            style={{ minWidth: '100%', maxWidth: '100%' }}
                          />
                        </Col>
                      </Row>
                    </Panel>
                  )}
                  {resultOfSearchStyle.map(
                    (styleCategory, index) =>
                      styleCategory.items &&
                      styleCategory.items.length && (
                        <Panel
                          header={styleCategory.category}
                          key={styleCategory.category}
                        >
                          <>
                            {styleCategory.items?.map((items, index) => {
                              return (
                                items.options &&
                                items.options.length && (
                                  <Row>
                                    <Col md={24} style={{ textAlign: 'left' }}>
                                      <p style={{ margin: '5px 0 0 0' }}>
                                        {items.label}
                                      </p>
                                    </Col>
                                    <Col md={24}>
                                      <Select
                                        value={[...selectedClasses]}
                                        key={index}
                                        options={items.options}
                                        onChange={value => {
                                          editor.getSelected()?.addClass(value);
                                          messageApi.open({
                                            type: 'success',
                                            content: 'Class applied',
                                          });
                                        }}
                                        style={{
                                          maxWidth: '100%',
                                          minWidth: '100%',
                                        }}
                                        placeholder="Select Style..."
                                      />
                                    </Col>
                                  </Row>
                                )
                              );
                            })}
                          </>
                        </Panel>
                      )
                  )}
                </Collapse>
              </div>
            );
          };
          if (props.container.children.length > 1) {
            props.container.removeChild(props.container.lastChild);
          }
          const componentContainer = document.createElement('div');

          const preventStyle = editor
            .getSelected()
            .getClasses()
            ?.includes('drag-n-drop');
          ReactDOM.render(
            preventStyle ? (
              <Text type="warning">
                You cannot apply styling to lower-level components.{' '}
              </Text>
            ) : (
              <StylePanel
                CollapesActiveFunction={CollapesActiveFunction}
                styleSearchText={styleSearchText}
              />
            ),
            componentContainer
          );

          props.container.appendChild(componentContainer);
        }
      });

      editor.on(`block:drag:stop`, (droppedComponent, block) => {
        const addingClassintoChildComponent = component => {
          component?.components()?.forEach(inner => {
            inner.addClass('drag-n-drop');
            addingClassintoChildComponent(inner);
          });
        };

        droppedComponent && addingClassintoChildComponent(droppedComponent[0]);
      });

      // Add bootstrap namespace/wrapper class.
      editor.Canvas.getBody().classList.add(CONSTANTS.BOOTSTRAP_WRAPPER_CLASS);

      var cmdm = editor.Commands;
      var pnm = editor.Panels;

      cmdm.add('html-edit', {
        run: function (editor, sender) {
          sender && sender.set('active', 0);

          var InnerHtml = editor.getHtml();
          var Css = editor.getCss();
          setEditorCode(`${InnerHtml}`);
          setEditorCss(Css);
          setIsShow(true);
        },
      });

      pnm.addButton('options', [
        {
          id: 'edit',
          className: 'fa fa-edit',
          command: 'html-edit',
          attributes: {
            title: 'Edit',
          },
        },
      ]);
      setEditor(editor);

      const editorDomComponents = editor.DomComponents;
      editor.on('load', () => {
        let component = editorDomComponents.getComponents().at(0);
        component && editor.select(component.getChildAt(0));
      });

      if (typeof onInit === 'function') {
        onInit(editor);
      }
    }
    // if(editor){
    //   editor.setConfig({
    //     canvas: {
    //       styles: CanvasConfig.styles,
    //       scripts: CanvasConfig.scripts,
    //     },
    //   });
    // }
  }, [children, editor, id, onInit, options]);

  useEffect(() => {
    return function cleanup() {
      if (editor) {
        if (typeof onDestroy === 'function') {
          onDestroy();
        }
        const index = GrapesJS.editors.findIndex(
          e => e.Config.container === editor.Config.container
        );
        if (typeof index === 'number' && index >= 0) {
          GrapesJS.editors.splice(index, 1);
        }
        editor.destroy();
      }
    };
  }, [editor, onDestroy]);

  return (
    <React.Fragment key={id}>
      {contextHolder}
      <div id={id}>{children}</div>
      <Modal
        centered
        closable={false}
        open={isShow}
        onOk={() => {
          editor.setComponents(editorCode.trim());
          editor.setStyle(editorCss);
          setIsShow(false);
        }}
        onCancel={() => setIsShow(false)}
        width={screenSize}
        destroyOnClose={true}
      >
        <Row gutter={10}>
          <Col md={12}>
            <h3>HTML</h3>
            <InputCode
              language="html"
              defaultValue={editorCode}
              setValue={setEditorCode}
            />
          </Col>
          <Col md={12}>
            <h3>CSS</h3>
            <InputCode
              language="css"
              defaultValue={editorCss}
              setValue={setEditorCss}
            />
          </Col>
        </Row>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    finalConfigs: state.GlobalConfigurations.finalConfigs,
    initialCDN: state.GlobalConfigurations.initialCDN,
  };
};

export default connect(mapStateToProps, null)(Editor);
