const getHtml = () => {
    return `
    <div>
    <h1>title</h1>
    <ul class="list-group list-group-horizontal">
        <li class="list-group-item">Cras justo odio</li>
        <li class="list-group-item">Dapibus ac facilisis in</li>
        <li class="list-group-item">Morbi leo risus</li>
    </ul>
  </div>
  `;
  };
  
  const getCss = () => {
    return ``;
  };
  
  const getJs = () => {
    return ``;
  };
  
  const HorizontalList = { getHtml, getCss, getJs };
  
  export default HorizontalList;
  