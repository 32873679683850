import * as TYPES from './action-types';

const initialState = {
    projectRole:'',
}

const ProjectRole = (state=initialState,{type,data}) =>{
    switch (type) {
        case TYPES.SET_PROJECT_ROLE:
          return {
            ...state,
             projectRole:data,
          };
        default:
          return state;
      }
}

export default ProjectRole;