import { Button, Modal, Spin, Table, Tooltip, Typography, message } from 'antd';
import axios from '../../../utils/axios/index';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Link from 'antd/lib/typography/Link';
import 'jsondiffpatch/dist/formatters-styles/html.css';
import moment from 'moment';
import WarningModal from '../modals/WarningModal';
import { connect } from 'react-redux';
import {
  setAtoms,
  setLayouts,
  setMolecules,
  setOrganisms,
  setPages,
} from '../../../redux/design-components/actions';
import { setGlobalConfigs } from '../../../redux/global-configurations/actions';
import CONSTANTS from '../../../utils/constants';

const formatDate = timestamp => moment(timestamp).format('YYYY/MM/DD hh:mm A');
const LastModified = ({
  entityType,
  entityId,
  forceUpdate,
  updateRef,
  setAtoms,
  setMolecules,
  setOrganisms,
  setLayouts,
  setPages,
  setCurrentConfig,
  projectRole,
}) => {
  const jsondiffpatch = require('jsondiffpatch');
  const { Text } = Typography;
  const { projectId, step } = useParams();
  const [messageContent, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState(null);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [usingComponent, setUsingComponent] = useState();
  const [version, setVersion] = useState();
  const [updatedComponent, setUpdatedComponent] = useState({
    molecules: [],
    organisms: [],
    layouts: [],
    pages: [],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [isUpdating, setIsUpdating] = useState(false);
  // const [updated,setupdated] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const columns = [
    {
      title: 'Version',
      dataIndex: 'version',
      key: 'version',
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
    },
    {
      title: 'Diff',
      dataIndex: 'diff',
      key: 'diff',
      width: 600,
      render: html => (
        <div
          style={{
            maxWidth: '500px',
            maxHeight: '300px',
            overflowY: 'auto',
          }}
          dangerouslySetInnerHTML={{ __html: html }}
        ></div>
      ),
    },
    {
      title: 'Timestamp',
      dataIndex: 'timestamp',
      key: 'timestamp',
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: '',
      render: (text, record, index) => {
        return index == 0 && currentPage == 1 ? (
          <Text type="success">Current version</Text>
        ) : (
          <Tooltip
            title={
              projectRole === CONSTANTS.USER_ROLES.DEVELOPER
                ? 'Request team lead for updating these changes'
                : ''
            }
          >
            <Button
              type="link"
              onClick={() => {
                setVersion(record.version);
              }}
              disabled={projectRole === CONSTANTS.USER_ROLES.DEVELOPER}
            >
              Restore
            </Button>
          </Tooltip>
        );
      },
    },
  ];

  const getAllComponents = () => {
    let err = false;
    if (step !== 'configurations') {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/components`
        )
        .then(res => {
          updateRef();
          setAtoms(res.data?.atoms);
          setMolecules(res.data.molecules);
          setOrganisms(res.data?.organisms);
          setLayouts(res.data?.layouts);
          setPages(res.data.pages);
          setIsUpdating(false);
          setVersion(null);
          setShowModal(false);
          messageApi.open({
            type: 'success',
            content: 'Component restored successfully.',
          });
        })
        .catch(error => {
          console.log('error', error);
          messageApi.open({
            type: 'error',
            content: 'Something went wrong!. please refresh.',
          });
        });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/configurations`
        )
        .then(res => {
          setCurrentConfig(res.data);
          setIsUpdating(false);
          setVersion(null);
          setShowModal(false);
          messageApi.open({
            type: 'success',
            content: 'Component restored successfully.',
          });
        })
        .catch(error => {
          messageApi.open({
            type: 'error',
            content: 'Something went wrong!. please refresh.',
          });
        });
    }
  };

  const restoreComponents = (version, components) => {
    if (step !== 'configurations') {
      axios
        .post(
          `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/components/${entityType}/${entityId}/restore`,
          {
            version: version,
            components: { ...updatedComponent },
          }
        )
        .then(res => {
          setIsWarningModalOpen(false);
          getAllComponents();
        })
        .catch(error => {
          messageApi.open({
            type: 'error',
            content: 'Component not updated.',
          });
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/configurations/restore`,
          { version: version }
        )
        .then(res => {
          getAllComponents();
        })
        .catch(error => {
          messageApi.open({
            type: 'error',
            content: 'Component not updated.',
          });
        });
    }
  };

  useEffect(() => {
    if (version) {
      usingComponent
        ? setIsWarningModalOpen(true)
        : Modal.confirm({
            title: 'confirm',
            content: 'Are you want to restore?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => {
              restoreComponents(version, updatedComponent);
              setIsUpdating(true);
            },
          });
    }
  }, [version]);

  useEffect(() => {
    const fetchVersions = async () => {
      try {
        const res =
          step === 'configurations'
            ? await axios.get(
                `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}/versions`
              )
            : await axios.get(
                `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/components/${entityType}/${entityId}/versions`
              );
        if (res.data?.length > 0) {
          const tableData = res.data?.map((ele, index) => {
            return {
              version: ele.version,
              user: ele.user
                ? `${ele.user?.firstName} ${ele.user?.lastName}`
                : `-`,
              diff: jsondiffpatch.formatters.html.format(ele.diff, {}),
              timestamp: formatDate(ele.timestamp),
            };
          });

          const messageContent = res.data[0]?.user
            ? `Last modified by ${res.data[0].user?.firstName} ${
                res.data[0].user?.lastName
              } on ${formatDate(res.data[0].timestamp)}`
            : `Last modified on ${formatDate(res.data[0].timestamp)}`;

          setData(tableData);
          setMessage(messageContent);
        } else {
          const messageContent = `No revision history found`;
          setMessage(messageContent);
        }
      } catch (error) {
        console.log('error', error);
      }
      if (step !== 'configurations') {
        if (entityType !== 'pages') {
          axios
            .get(
              `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/components/${entityType}/${entityId}/usages`
            )
            .then(res => {
              if (
                res.data?.molecules?.length > 0 ||
                res.data?.organisms?.length > 0 ||
                res.data?.layouts?.length > 0 ||
                res.data?.pages?.length > 0
              ) {
                setUsingComponent(res.data);
              } else {
                setUsingComponent(null);
              }
            })
            .catch(error => {
              console.log('error', error);
            });
        }
      }
    };

    fetchVersions();
  }, [entityType, entityId, projectId, step, forceUpdate]);

  const selectedUpdatedComponent = collection => {
    setUpdatedComponent({ ...updatedComponent, ...collection });
  };

  return (
    <>
      {contextHolder}
      <Modal
        centered
        open={showModal}
        onOk={() => setShowModal(false)}
        onCancel={() => setShowModal(false)}
        title="Revision History"
        cancelButtonProps={{ style: { display: 'none' } }}
        width={1200}
      >
        {data ? (
          <Table
            dataSource={data}
            columns={columns}
            scroll={{ y: 550 }}
            loading={isUpdating}
            size="small"
            pagination={{
              pageSize: 5,
              onChange: page => {
                setCurrentPage(page);
              },
              showSizeChanger: false,
            }}
          />
        ) : (
          <Text>No revision history found</Text>
        )}

        <Modal
          open={isWarningModalOpen}
          onOk={() => {
            Modal.confirm({
              title: 'confirm',
              content: 'Are you want to restore?',
              okText: 'Yes',
              cancelText: 'No',
              onOk: () => {
                restoreComponents(version, updatedComponent);
                setIsUpdating(true);
                setIsWarningModalOpen(false);
              },
            });
          }}
          onCancel={() => {
            setIsWarningModalOpen(false);
            setVersion(null);
          }}
          width={800}
        >
          <WarningModal
            usingComponent={usingComponent}
            selectedUpdatedComponent={selectedUpdatedComponent}
            usedInfo={false}
          />
        </Modal>
      </Modal>
      <Button
        type="link"
        onClick={() => setShowModal(true)}
        style={{ textDecoration: 'underline' }}
      >
        {messageContent}
      </Button>
    </>
  );
};
const mapDispatchToProps = dispatch => ({
  setAtoms: atoms => {
    dispatch(setAtoms(atoms));
  },
  setMolecules: molecules => {
    dispatch(setMolecules(molecules));
  },
  setOrganisms: organisms => {
    dispatch(setOrganisms(organisms));
  },
  setLayouts: layouts => {
    dispatch(setLayouts(layouts));
  },
  setPages: pages => {
    dispatch(setPages(pages));
  },
  setCurrentConfig: config => {
    dispatch(setGlobalConfigs(config));
  },
});

export default connect(null, mapDispatchToProps)(LastModified);
