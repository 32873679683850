const getHtml = () => {
  return `
  <div class="btn-group dropup">
  <button type="button" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
    Dropup
  </button>
  <ul class="dropdown-menu">
  <li><a class="dropdown-item active" href="#">Action</a></li>
  <li><a class="dropdown-item" href="#">Another action</a></li>
  <li><a class="dropdown-item" href="#">Something else here</a></li>
  <li><hr class="dropdown-divider"></li>
  <li><a class="dropdown-item" href="#">Separated link</a></li>  </ul>
</div> `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const Up = { getHtml, getCss, getJs };

export default Up;
