const getHtml = () => {
    return `
      <a href="#" class="text-warning stretched-link">Warning link</a>
    `;
  };
  
  const getCss = () => {
    return ``;
  };
  
  const getJs = () => {
    return ``;
  };
  
  const WarningLink = { getHtml, getCss, getJs };
  
  export default WarningLink;
  