const getHtml = () => {
    return `
      <div class="form-group">
        <label for="inputPassword">Password</label>
        <input type="password" class="form-control" id="inputPassword" aria-describedby="nameHelp" placeholder="Password">
      </div>
    `;
  };
  
  const getCss = () => {
    return ``;
  };
  
  const getJs = () => {
    return ``;
  };
  
  const PasswordInput = { getHtml, getCss, getJs };
  
  export default PasswordInput;
  