import { useEffect, useState } from 'react';
import { Form, Input, Modal, Radio } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import axios from '../../utils/axios';

const UpdateProject = props => {
  const [EditProjectForm] = Form.useForm();

  // set form fields value
  EditProjectForm.setFieldsValue({
    name: props.values.name,
    description: props.values.description,
    status: props.values.status,
    url: props.values.url,
    assetsPath: props.values.assetsPath,
    repository: props.values.repository,
    includesPrefix: props.values.includesPrefix,
    includesPath: props.values.includesPath,
    themeName: props.values.themeName,
  });

  // handle value of status radio button
  const [statusValue, setStatusValue] = useState(true);
  const onChange = e => {
    setStatusValue(e.target.value);
  };

  // submit the form on modal onOk
  const handleOK = () => {
    EditProjectForm.submit();
  };

  const onFinish = async values => {
    values.url = values.url == '' ? undefined : values.url;

    await axios
      .patch(
        `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${props.values._id}`,
        { ...values }
      )
      .then(res => {
        //updating project state
        props.editProjectHandler(res.data);

        // closing modal
        props.handleEditProjectModal(false);
        EditProjectForm.resetFields();
        props.message('success', 'Project updated successfully');
      })
      .catch(error => {
        props.message('error', 'Error: Project not updated successfully');
        console.log('Project not created', error);
      });
  };

  return (
    <>
      <Modal
        title="Edit Project"
        closable={false}
        open={props.isOpen}
        onOk={handleOK}
        onCancel={() => {
          props.handleEditProjectModal(false);
          EditProjectForm.resetFields();
        }}
      >
        <Form
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 16,
          }}
          form={EditProjectForm}
          name="control-hooks"
          onFinish={onFinish}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Project Name required' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: false }]}
          >
            <TextArea />
          </Form.Item>

          <Form.Item label="Status" name="status" rules={[{ required: false }]}>
            <Radio.Group onChange={onChange} value={statusValue}>
              <Radio value={true}>Active</Radio>
              <Radio value={false}>Disable</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="URL"
            name="url"
            rules={[{ required: false, message: 'invalid Url', type: 'url' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Assets Path"
            name="assetsPath"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Includes Prefix"
            name="includesPrefix"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Includes Path"
            name="includesPath"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Theme Name"
            name="themeName"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Repository"
            name="repository"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateProject;
