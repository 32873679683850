import {
  PlusOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Row,
  Select,
  Table,
  Tooltip,
  Typography,
  Spin,
} from 'antd';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import uuid from 'react-uuid';
import DependencyTable from '../../../common/DependencyTable/index';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  setAtoms,
  setLayouts,
  setMolecules,
  setOrganisms,
} from '../../../../redux/design-components/actions';
import Editor from '../../../common/editor';
import EditorConfig from './editor-config';
import axios from '../../../../utils/axios';
import InputCode from '../../../common/InputCode';
import LastModified from '../../../common/LastModified.js';
import WarningModal from '../../../common/modals/WarningModal';
import CONSTANTS from '../../../../utils/constants';
import { replaceVariables } from '../../../../utils/helpers/replaceTraits';
import { Switch, InputNumber } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';

const { Item: MenuItem, SubMenu } = Menu;
const { Text } = Typography;

const LayoutsEditor = ({
  atoms,
  molecules,
  organisms,
  layouts,
  setOrganisms,
  setLayouts,
  globalConfigs,
  projectRole,
}) => {
  const editorRef = useRef(null);

  const { projectId, step } = useParams();

  const didLayoutsUpdate = useRef(false);

  const [nameOfLayoutToRename, setNameOfLayoutToRename] = useState(undefined);
  const [selectedLayout, setSelectedLayout] = useState(null);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addNewLayoutForm] = Form.useForm();
  const [showJSBlock, setShowJSBlock] = useState(false);
  const [currentCompJs, setCurrentCompJs] = useState('');
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [usingComponent, setUsingComponent] = useState();
  const [variationData, setVariationData] = useState();
  const [updatedComponent, setUpdatedComponent] = useState({
    pages: [],
  });
  const [isPreviewMode, setIsPreviewMode] = useState(true);

  const [usedInfo, setUsedInfo] = useState(false);
  const [usagesDetail, setUsagesDetail] = useState({});
  const [childrenInfoModalOpen, setChildrenInfoModalOpen] = useState(false);
  const [usedChildren, setUsedChildren] = useState();

  const [globalMediaOptions, setGlobalMediaOptions] = useState([]);

  const [globalImageStyleOptions, setGlobalImageStyleOptions] = useState([]);
  const [pictureSourceCollection, setPictureSourceCollection] = useState([
    [
      { type: 'text_link', name: 'srcset', label: '', defaultValue: '' },
      {
        type: 'text_plain',
        name: 'media',
        label: 'media',
        defaultValue: '',
        options: globalMediaOptions,
      },
    ],
  ]);
  const [selectedDependencyType, setSelectedDependencyType] =
    useState(undefined);
  const [enteredCdnLink, setEnteredCdnLink] = useState(undefined);

  const [externalLibraryModal, setExternalLibraryModal] = useState(false);

  const [collapsed, setCollapsed] = useState(false);

  const [externalLibrary] = Form.useForm();
  const [draggedcomphtml, setdraggedcomphtml] = useState([]);
  const isUsedChildrenLoaded = useRef(false);
  const [isLoading, setIsLoading] = useState(true);

  const [checked, setChecked] = useState(false);
  const [draggedModal, setDraggedModal] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [insertData, setInsertData] = useState(null);
  const [draggedForm] = Form.useForm();
  const [droppedComp, setDroppedComp] = useState(null);


  console.log(layouts);


  const columns = [
    {
      title: 'Trait Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Parent Name',
      dataIndex: 'parentname',
      key: 'parentname',
    },
    {
      title: 'Trait Type',
      dataIndex: 'type',
      key: 'traittype',
    },
    {
      title: 'Default value',
      dataIndex: 'defaultValue',
      key: 'defaultvalue',
      render: (text, record) =>
        record.defaultValue ? (
          <Input
            defaultValue={text}
            onChange={e => {
              record.defaultValue = e.target.value; // Update the value in traitsToBeCopied array
            }}
          />
        ) : null,
    },
    {
      title: 'Options',
      dataIndex: 'options',
      key:'options',
      render: (text, record) => 
        record.options ? (
          <Input
            defaultValue={text}
            onChange={e => {
              record.options = e.target.value.split(','); // Update the value in traitsToBeCopied array
            }}
          /> 
      ) : null,
    },
  ];

  useEffect(() => {
    if(droppedComp || draggedModal)
    {
      importTheRequiredProperties(droppedComp);
    }
  },[checked , draggedModal])

  useEffect(() => {
    if (layouts && selectedLayout && didLayoutsUpdate.current) {
      const layout = layouts.find(
        layoutToFind => layoutToFind.key === selectedLayout.key
      );
      if (layout) {
        let editorComponents = layout.html;
        if (layout.css) {
          editorComponents = `${editorComponents}<style>${layout.css}</style>`;
        }
        if (editorRef.current) {
          updateEditorComponents(editorRef.current, editorComponents);
        }
        didLayoutsUpdate.current = false;
        isUsedChildrenLoaded.current = false;
      }
    }
  }, [layouts]);

  useEffect(() => {
    if (selectedLayout) {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/components/layouts/${selectedLayout._id}/usages`
        )
        .then(res => {
          setUsagesDetail(res.data);
          setUsingComponent(res.data);
        })
        .catch(error => {
          console.log(error);
        });

      // Layout children api call
      axios
        .get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/components/layouts/${selectedLayout._id}/children`
        )
        .then(res => {
          setUsedChildren(res.data);
          if (res.status === 200) {
            isUsedChildrenLoaded.current = true;
            setIsLoading(false);
          }
        })
        .catch(error => {
          console.log(error);
          setIsLoading(false);
        });
    }
  }, [selectedLayout, isWarningModalOpen, layouts]);

  const handleInlineCss = e => {
    const newLayouts = [...layouts];
    newLayouts.forEach(layout => {
      if (layout.key === selectedLayout.key) {
        layout.inLineCss = e.target.checked;
      }
    });
    setLayouts(newLayouts);
  };

  let configArray = [];

  const updatedBlocks = (entities, type) => {
    const configs = { ...EditorConfig() };
    entities.forEach(entity => {
      if (entity.variations) {
        entity.variations?.forEach(variation => {
          variation._id = entity._id;
          variation.type = type;
          variation.category = entity.label;
          variation.content = `<div>${variation.html}</div> <style>${variation.css}</style>`;
          configArray = [...configArray, variation];
        });
      } else {
        // entity._id = entity._id;
        entity.type = type;
        entity.category = entity.label;
        entity.content = `<div>${entity.html}</div> <style>${entity.css}</style>`;
        configArray = [...configArray, entity];
      }
    });

    configArray.forEach((ele, i) => {
      configs.blockManager.blocks.push(ele);
    });

    return configs;
  };

  const handleSaveVariation = selectedLayout => {
    if (editorRef.current)
      if (editorRef.current) {
        const html = editorRef.current.getHtml();
        const css = editorRef.current.getCss();
        // const js = editorRef.current.getJs();
        let updatedLayout = [...layouts];
        updatedLayout.forEach(layout => {
          if (layout.key === selectedLayout.key) {
            layout.html = html;
            layout.css = css;

            let modifiedHTML = html;

            console.log({ draggedcomphtml });

            if (draggedcomphtml?.length) {
              draggedcomphtml.forEach(comp => {
                var pattern = new RegExp(
                  `<!--START-${comp.type.toUpperCase()}-{{${
                    comp.key
                  }}}-->([\\s\\S]*?)<!--END-${comp.type.toUpperCase()}-{{${
                    comp.key
                  }}}-->`
                );

                let modifiedContent = comp?.html?.replace(
                  /{{([^}]+)}}|start_curly([^]+?)_end_curly|<!--(.*?)-->/g,
                  function (subMatch, p1, p2, p3) {
                    if (p1) {
                      return `{{ ${comp.prefix}_${p1.trim()} }}`;
                    } else if (p2) {
                      return `start_curly_${comp.prefix}${p2}_end_curly`;
                    } else if (p3) {
                      return `<!--${p3}-->`;
                    }
                  }
                );

                // update if loops are used -----
                modifiedContent = modifiedContent.replace(
                  /<!--(START-LOOP|END-LOOP).*?{{([^}]+)}}.*?-->/g,
                  function (subMatch, loopType, doubleBracesContent) {
                    console.log(loopType);
                    console.log(doubleBracesContent);
                    return `<!--${loopType}-{{${
                      comp.prefix
                    }_${doubleBracesContent.trim()}}}-->`;
                  }
                );

                if (comp.type === 'atom') {
                  console.log('atom type before', modifiedHTML);

                  let matchedPatterns = [];

                  let organismPattern =
                    /<!--START-ORGANISM-(.*?)-->(.*?)<!--END-ORGANISM-\1-->/gs;

                  modifiedHTML = modifiedHTML.replace(
                    organismPattern,
                    function (match, p1, p2) {
                      // matchedPatterns.push(match); // Store the matched pattern
                      // return 'temp'; // Replace with an empty string
                      let uniqueIdentifier = `TEMP_${matchedPatterns.length}`;
                      matchedPatterns.push({
                        identifier: uniqueIdentifier,
                        pattern: match,
                      });
                      return uniqueIdentifier;
                    }
                  );

                  let moleculePattern =
                    /<!--START-MOLECULE-(.*?)-->(.*?)<!--END-MOLECULE-\1-->/gs;

                  modifiedHTML = modifiedHTML.replace(
                    moleculePattern,
                    function (match, p1, p2) {
                      // matchedPatterns.push(match); // Store the matched pattern
                      // return 'temp'; // Replace with an empty string
                      let uniqueIdentifier = `TEMP_${matchedPatterns.length}`;
                      matchedPatterns.push({
                        identifier: uniqueIdentifier,
                        pattern: match,
                      });
                      return uniqueIdentifier;
                    }
                  );

                  console.log('tambola', modifiedHTML);

                  let occurrences = 0;

                  modifiedHTML = modifiedHTML.replace(
                    new RegExp(pattern, 'g'),
                    function (match) {
                      console.log('ok matched', comp.count);
                      return ++occurrences === comp.count
                        ? modifiedContent
                        : match;
                    }
                  );

                  console.log('panchala', modifiedHTML);
                  console.log('matchedPatterns', matchedPatterns);

                  // Restore the matched patterns
                  matchedPatterns.forEach(function (pattern) {
                    // modifiedHTML = modifiedHTML.replace('temp', pattern);
                    modifiedHTML = modifiedHTML.replace(
                      pattern.identifier,
                      pattern.pattern
                    );
                  });

                  console.log('atom type after', modifiedHTML);
                } else if (comp.type === 'molecule') {
                  console.log('molecule type before', modifiedHTML);

                  let matchedPatterns = [];

                  let organismPattern =
                    /<!--START-ORGANISM-(.*?)-->(.*?)<!--END-ORGANISM-\1-->/gs;

                  modifiedHTML = modifiedHTML.replace(
                    organismPattern,
                    function (match, p1, p2) {
                      // matchedPatterns.push(match); // Store the matched pattern
                      // return 'temp'; // Replace with an empty string
                      let uniqueIdentifier = `TEMP_${matchedPatterns.length}`;
                      matchedPatterns.push({
                        identifier: uniqueIdentifier,
                        pattern: match,
                      });
                      return uniqueIdentifier;
                    }
                  );

                  let occurrences = 0;

                  modifiedHTML = modifiedHTML.replace(
                    new RegExp(pattern, 'g'),
                    function (match) {
                      console.log('ok matched', comp.count);
                      return ++occurrences === comp.count
                        ? modifiedContent
                        : match;
                    }
                  );

                  // Restore the matched patterns
                  matchedPatterns.forEach(function (pattern) {
                    // modifiedHTML = modifiedHTML.replace('temp', pattern);
                    modifiedHTML = modifiedHTML.replace(
                      pattern.identifier,
                      pattern.pattern
                    );
                  });

                  console.log('molecule type after', modifiedHTML);
                } else {
                  console.log('organism type before', modifiedHTML);

                  let occurrences = 0;
                  modifiedHTML = modifiedHTML.replace(
                    new RegExp(pattern, 'g'),
                    function (match) {
                      console.log('after', occurrences);
                      // console.log("before",count);
                      return ++occurrences === comp.count
                        ? modifiedContent
                        : match;
                    }
                  );

                  console.log('organism type after', modifiedHTML);
                }
              });

              layout.html = modifiedHTML;
            }

            delete layout.__v;

            axios
              .patch(
                `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}/layouts/${layout._id}`,
                { ...layout, components: updatedComponent }
              )
              .then(data => {
                message.success('Layout Saved successfully');
                axios
                  .get(
                    `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}`
                  )
                  .then(data => {
                    if (data) {
                      didLayoutsUpdate.current = true;
                      setAtoms([...data.data?.atoms]);
                      setMolecules([...data.data?.molecules]);
                      setOrganisms([...data.data?.organisms]);
                      setLayouts([...data.data?.layouts]);
                      setIsLoading(false);
                    }
                  })
                  .catch(err => {
                    console.log(err, 'error');
                    setIsLoading(false);
                  });
              })
              .catch(error => {
                console.error(error);
                message.error('Something went wrong ! Please try again.');
                setIsLoading(false);
              });
          }
        });
        setLayouts(updatedLayout);
      }
    setdraggedcomphtml([]);
  };

  const onFinish = values => {
    let { layoutName } = values;
    const newLayouts = [...layouts];
    const existingLayoutIndex = newLayouts.findIndex(
      layout => layout.label === layoutName
    );

    if (existingLayoutIndex !== -1) {
      message.error('Organism with Same Name Already Exist.');
    } else {
      const newLayoutKey = uuid();
      newLayouts.push({
        label: layoutName,
        key: newLayoutKey,
        html: '',
        css: '',
      });
      addNewLayoutForm.resetFields();
      axios
        .post(
          `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}/layouts`,
          {
            label: layoutName,
            key: newLayoutKey,
            html: '',
            css: '',
          }
        )
        .then(result => {
          message.success('Layout Created successfully');
          axios(
            `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}`
          )
            .then(data => {
              if (data) {
                didLayoutsUpdate.current = true;
                setAtoms([...data.data?.atoms]);
                setMolecules([...data.data?.molecules]);
                setOrganisms([...data.data?.organisms]);
                setLayouts([...data.data?.layouts]);
              }
            })
            .catch(err => console.log(err, 'error'));
        })
        .catch(error => console.log('error', error));
      setLayouts(newLayouts);
      setIsModalOpen(false);
    }
  };

  const handleAddNewLayout = () => {
    setIsModalOpen(true);
  };

  const handleAddJS = () => {
    let updatedlayouts = [...layouts];
    updatedlayouts.forEach(layout => {
      if (layout.key === selectedLayout.key) {
        layout.js = currentCompJs;
        setSelectedLayout(layout);
      }
    });

    setLayouts(updatedlayouts);
    setShowJSBlock(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    addNewLayoutForm.resetFields();
  };

  const handleOk = () => {
    if (addNewLayoutForm) {
      addNewLayoutForm.submit();
    }
  };
  const handleDeleteOrganism = () => {
    let updatedLayout = layouts.filter(
      layout => layout.key !== selectedLayout.key
    );

    axios
      .delete(
        `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}/layouts/${selectedLayout._id}`
      )
      .then(data => {
        message.success('Layout deleted successfully');
      })
      .catch(error => {
        console.error(error);
        message.error('Something went wrong ! Please try again.');
      });
    setLayouts(updatedLayout);
    setDeleteConfirmation(false);
    isUsedChildrenLoaded.current = false;
  };

  const addDependencies = values => {
    const newLayouts = [...layouts];
    newLayouts.forEach(layout => {
      if (layout.key === selectedLayout.key) {
        if (layout.dependencies) {
          layout.dependencies[values.select_library].push(values.cdn_link);
        } else {
          layout.dependencies = { js: [], css: [] };
          layout.dependencies[values.select_library].push(values.cdn_link);
        }
      }
    });
    setLayouts(newLayouts);
    setExternalLibraryModal(false);
    externalLibrary.resetFields();
  };

  const importTheRequiredProperties = component => {
    let newLayouts = [...layouts];

    newLayouts.forEach(layout => {
      if (layout.key === selectedLayout.key) {
        if (component.dependencies) {
          const JSdependenciesToBeCopied = [...component.dependencies?.js];
          const CSSdependenciesToBeCopied = [...component.dependencies?.css];

          if (layout.dependencies) {
            layout.dependencies.js = Array.from(
              new Set(layout.dependencies.js.concat(JSdependenciesToBeCopied))
            );
            layout.dependencies.css = Array.from(
              new Set(layout.dependencies.css.concat(CSSdependenciesToBeCopied))
            );
          } else {
            layout.dependencies = {
              js: [...JSdependenciesToBeCopied],
              css: [...CSSdependenciesToBeCopied],
            };
          }
        }

        /*************************************************************** */

        let traitsToBeCopied = component.traits ? [...component.traits] : [];

        let currentHTML = editorRef.current.getHtml();
        let count;

        const pattern = new RegExp(
          component.type === 'atom'
            ? `<!--START-ATOM-{{${component.key}}}-->`
            : component.type === 'molecule'
            ? `<!--START-MOLECULE-{{${component.key}}}-->`
            : component.type === 'organism'
            ? `<!--START-ORGANISM-{{${component.key}}}-->`
            : '',
          'g'
        );

        console.log({ pattern });

        // const count = (editorRef.current.getHtml().match(pattern) || []).length;

        // to not allow atom count inside molecule & organism if it is an atom
        if (component.type === 'atom') {
          let matchedPatterns = [];

          let organismPattern =
            /<!--START-ORGANISM-(.*?)-->(.*?)<!--END-ORGANISM-\1-->/gs;

          currentHTML = currentHTML.replace(
            organismPattern,
            function (match, p1, p2) {
              matchedPatterns.push(match); // Store the matched pattern
              return 'temp'; // Replace with an empty string
            }
          );

          let moleculePattern =
            /<!--START-MOLECULE-(.*?)-->(.*?)<!--END-MOLECULE-\1-->/gs;

          currentHTML = currentHTML.replace(
            moleculePattern,
            function (match, p1, p2) {
              matchedPatterns.push(match); // Store the matched pattern
              return 'temp'; // Replace with an empty string
            }
          );

          count = (currentHTML.match(pattern) || []).length;
        } else if (component.type === 'molecule') {
          // to not allow molecule count inside molecule & organism if it is an atom
          let organismPattern =
            /<!--START-ORGANISM-(.*?)-->(.*?)<!--END-ORGANISM-\1-->/gs;
          let matchedOrganismPatterns = [];

          currentHTML = currentHTML.replace(
            organismPattern,
            function (match, p1, p2) {
              matchedOrganismPatterns.push(match); // Store the matched pattern
              return 'temp'; // Replace with an empty string
            }
          );

          count = (currentHTML.match(pattern) || []).length;
        } else {
          count = (editorRef.current.getHtml().match(pattern) || []).length;
        }

        traitsToBeCopied = traitsToBeCopied.map((trait, index) => {
          let modifiedChildren = trait?.children;

          console.log("drop",trait.dropAsPara);

          trait = {
            ...trait,
            key: uuid(),
            name: !checked ?  _.snakeCase(`${selectedLayout.label}_${count}_${trait.name}`) : trait.name,
            label: !checked ? `${selectedLayout.label} ${trait.label} ${count}` : trait.name,
            children: modifiedChildren,
            // ...(trait.dropAsPara ? { dropAsPara: false } : {}),
            // drupal_field: '', // added to not include drupal_field because it is to be unique everywhere and setted manually.
            parent: {
              type: component.type,
              id: component._id,
              name: trait.name,
              traitKey: trait.key,
              key: component.key,
              sequenceNumber: count,
              draggedComponent:
                component.type !== 'organism'
                  ? _.snakeCase(`${component.category}_${component.label}`)
                  : _.snakeCase(`${component.category}`),
            },
          };

          return trait;
        });

        // const columns = [
        //   {
        //     title: 'Trait Name',
        //     dataIndex: 'name',
        //     key: 'name',
        //   },
        //   {
        //     title: 'Parent Name',
        //     dataIndex: 'parentname',
        //     key: 'parentname',
        //   },
        //   {
        //     title: 'Trait Type',
        //     dataIndex: 'type',
        //     key: 'traittype',
        //   },
        //   {
        //     title: 'Default value',
        //     dataIndex: 'defaultValue',
        //     key: 'defaultvalue',
        //     render: (text, record) =>
        //       record.defaultValue ? (
        //         <Input
        //           defaultValue={text}
        //           onChange={e => {
        //             record.defaultValue = e.target.value; // Update the value in traitsToBeCopied array
        //           }}
        //         />
        //       ) : null,
        //   },
        // ];

        const data = traitsToBeCopied.map(trait => {
          return {
            ...trait,
            parentname: trait.parent.name,
          };
        });


        setTableData(data);
        // setDraggedModal(true);
        setInsertData(
          {
            key: component.key,
            html: component.html,
            prefix: _.snakeCase(`${selectedLayout.label}_${count}`),
            type: component.type,
            traits: component.traits,
            count: count,
          },
        )

        // traitsToBeCopied.length > 0 &&
        //   Modal.confirm({
        //     title: 'Copy Traits',
        //     onOk: () => {
        //       setdraggedcomphtml(prevState => [
        //         ...prevState,
                // {
                //   key: component.key,
                //   html: component.html,
                //   prefix: _.snakeCase(`${selectedLayout.label}_${count}`),
                //   type: component.type,
                //   count: count,
                // },
        //       ]);

        //       if (layout.traits) {
        //         data.forEach(incomingTrait => {
        //           if (
        //             !layout.traits.some(
        //               oldTrait => oldTrait.name === incomingTrait.name
        //             )
        //           ) {
        //             // layout.traits.push(incomingTrait);
        //             layout.traits = [...layout.traits, incomingTrait];
        //           }
        //         });
        //       } else {
        //         layout.traits = [...data];
        //       }
        //     },
        //     // onCancel: handleCancel,
        //     okText: 'Save',
        //     width: 1350,
        //     content: (
        //       <Table
        //         columns={columns}
        //         dataSource={data}
        //         pagination={{ pageSize: 5 }}
        //       />
        //     ),
        //   });
        /******************************************************************* */

        if (
          layout.baseComponents &&
          layout.baseComponents.includes(component.key)
        ) {
        } else {
          if (layout.baseComponents) layout.baseComponents.push(component.key);
          else layout.baseComponents = [component.key];
          layout.js = layout.js ? `${layout.js} ${component.js}` : component.js;
          setLayouts(newLayouts);
        }
      }
    });
  };

  const updateEditorComponents = (editorInstance, components) => {
    editorInstance.DomComponents.getWrapper().set('content', '');
    editorInstance.setComponents(components);
  };

  const selectedUpdatedComponent = collection => {
    setUpdatedComponent({ ...updatedComponent, ...collection });
  };

  const updateRef = () => {
    didLayoutsUpdate.current = true;
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleDragDrop = () => {
    if (!checked) {
      setdraggedcomphtml(prevState => [...prevState, insertData]);

      const newLayouts = [...layouts];
      newLayouts.forEach(layout => {
        if (layout.key === selectedLayout.key) {
          if (layout.traits) {
            tableData.forEach(incomingTrait => {
              if (
                !layout.traits.some(
                  oldTrait => oldTrait.name === incomingTrait.name
                )
              ) {
                layout.traits = [...layout.traits, incomingTrait];
              }
            });
          } else {
            layout.traits = [...tableData];
          }
        }
      });
      setLayouts(newLayouts);
    } else {
      const newLayouts = [...layouts];
      newLayouts.forEach(layout => {
        if (layout.key === selectedLayout.key) {
          const formData = draggedForm.getFieldsValue();
          console.log(formData);
          const newParagraphTrait = {
            key: uuid(),
            type: 'paragraph',
            dropAsPara: true,
            name: _.snakeCase(`${selectedLayout.label}_${formData.label}`),
            label: `${selectedLayout.label} ${formData.label}`,
            cardinality: formData.cardinality,
            children: tableData,
            drupal_field: formData.drupal_field,
          };
          layout.traits = layout.traits ? [...layout.traits , newParagraphTrait] : [newParagraphTrait];
        }
      });
      setLayouts(newLayouts);
    }
    setDraggedModal(false);
    setChecked(false);
    setTableData(null);
    setInsertData(null);
  };

  return (
    <>
      {/* Dragged Component Modal */}
      <Modal
        title="Copy Traits"
        open={draggedModal}
        onOk={handleDragDrop}
        onCancel={() => setDraggedModal(false)}
        okText="Save"
        width={1350}
      >
        <br />
        <Tooltip title="This will wrap all dragged components trait into a single Paragraph.">
          <InfoCircleTwoTone style={{ marginRight: '10px' }} />
        </Tooltip>
        Wrap in a Paragraph Trait
        <Switch
          style={{ marginLeft: '15px' }}
          checked={checked}
          onChange={() => {
            setChecked(!checked);
          }}
        />
        <br />
        <br />
        <br />
        {checked ? (
          <Form
            name="draggedForm"
            form={draggedForm}
            labelCol={{
              span: 8,
            }}
            style={{
              maxWidth: 600,
            }}
            autoComplete="off"
          >
            <Form.Item
              label="Label"
              name="label"
              rules={[
                {
                  required: true,
                  message: 'Please input Label!',
                },
                {
                  validator: (_, value) => {
                    if (!value.endsWith('s')) {
                      return Promise.reject('Label must end with "s"');
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Drupal field"
              name="drupal_field"
              rules={[
                {
                  required: true,
                  message: 'Please input drupal_field!',
                },
                {
                  validator: (_, value) => {
                    if (!value.endsWith('s')) {
                      return Promise.reject('drupal_field must end with "s"');
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Cardinality"
              name="cardinality"
              rules={[
                { required: true, message: 'Please enter a cardinality' },
                { type: 'integer', message: 'Cardinality must be an integer' },
              ]}
            >
          <InputNumber min={1} />
            </Form.Item>
          </Form>
        ) : (
          <Table
            columns={columns}
            dataSource={tableData}
            pagination={{ pageSize: 5 }}
          />
        )}
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        open={deleteConfirmation}
        onOk={handleDeleteOrganism}
        onCancel={() => setDeleteConfirmation(false)}
      >
        <p>Are you sure ? You want to delete this Layout !</p>
      </Modal>

      {/* warning modal */}
      <Modal
        centered
        key={selectedLayout?.key}
        open={isWarningModalOpen}
        okText={usedInfo ? '' : 'Ok'}
        onOk={() => {
          handleSaveVariation(variationData);
          setIsWarningModalOpen(false);
          setIsLoading(true);
        }}
        onCancel={() => {
          setIsWarningModalOpen(false);
          setUsedInfo(false);
        }}
        width={1000}
        title={
          usedInfo ? 'Component Usages' : 'Auto-Update Higher Level Components'
        }
      >
        <WarningModal
          usingComponent={usingComponent}
          selectedUpdatedComponent={selectedUpdatedComponent}
          usedInfo={usedInfo}
        />
      </Modal>

      <Modal
        open={childrenInfoModalOpen}
        onCancel={() => {
          setChildrenInfoModalOpen(false);
        }}
        onOk={() => {
          setChildrenInfoModalOpen(false);
        }}
        title="Child Components."
      >
        <WarningModal
          usingComponent={usedChildren}
          selectedUpdatedComponent={selectedUpdatedComponent}
          usedInfo="childrenDetails"
        />
      </Modal>

      {/* Add JavaScript block */}
      <Modal
        closable={false}
        open={showJSBlock}
        onOk={handleAddJS}
        onCancel={() => setShowJSBlock(false)}
        title="Add JavaScript"
        width={1200}
      >
        <InputCode
          language="javascript"
          defaultValue={currentCompJs}
          setValue={setCurrentCompJs}
        />
      </Modal>

      {/* Add new Layout */}
      <Modal
        title={'Create New Layout'}
        okText="Create"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ width: '50vw' }}
      >
        <Form
          name="addNewLayoutForm"
          form={addNewLayoutForm}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
        >
          <Form.Item
            label="Layout Name"
            name="layoutName"
            rules={[{ required: true, message: 'Please input Layout name' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      {/* Rename Modal */}
      <Modal
        open={isRenameModalOpen}
        closable={false}
        onOk={() => {
          setIsRenameModalOpen(false);
          let updatedLayouts = [...layouts];
          updatedLayouts.forEach(layout => {
            if (layout.key === selectedLayout.key) {
              layout.label = nameOfLayoutToRename;

              axios
                .patch(
                  `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}/layouts/${layout._id}`,
                  layout
                )
                .then(data => {
                  message.success('Layout Renamed successfully');
                  axios
                    .get(
                      `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}`
                    )
                    .then(data => {
                      if (data) {
                        didLayoutsUpdate.current = true;
                        setAtoms([...data.data?.atoms]);
                        setMolecules([...data.data?.molecules]);
                        setOrganisms([...data.data?.organisms]);
                        setLayouts([...data.data?.layouts]);
                      }
                    })
                    .catch(err => console.log(err, 'error'));
                })
                .catch(error => {
                  console.error(error);
                  message.error('Something went wrong ! Please try again.');
                });
            }
          });
          setLayouts([...updatedLayouts]);
        }}
        onCancel={() => setIsRenameModalOpen(false)}
      >
        <Input
          value={nameOfLayoutToRename}
          onChange={e => setNameOfLayoutToRename(e.target.value)}
        />
      </Modal>

      <Modal
        title="Add External JS or CSS Library"
        closable={false}
        open={externalLibraryModal}
        onOk={() => {
          if (externalLibrary) {
            externalLibrary.submit();
          }
        }}
        onCancel={() => setExternalLibraryModal(false)}
      >
        <Form
          name="externalLibrary"
          form={externalLibrary}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={addDependencies}
        >
          <Form.Item
            label="Select Library"
            name="select_library"
            rules={[
              { required: true, message: 'Please select type of Library ' },
            ]}
          >
            <Select
              style={{ width: 120 }}
              options={[
                {
                  value: 'css',
                  label: 'CSS',
                },
                {
                  value: 'js',
                  label: 'JS',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Enter CDN Link"
            name="cdn_link"
            rules={[
              { required: true, message: 'Please input CDN Link' },
              {
                type: 'url',
                message: 'Please enter a valid URL for the CDN link',
              },
            ]}
          >
            <Input style={{ width: 200 }} />
          </Form.Item>
        </Form>
      </Modal>

      {/* End of Modals */}

      <Row>
        <Col md={collapsed ? 1 : 4} style={{ transition: '1s' }}>
          <Button
            type="primary"
            onClick={toggleCollapsed}
            style={{
              marginBottom: 16,
            }}
          >
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </Button>
          <Menu
            mode="inline"
            // onSelect={handleMenuItemSelect}
            inlineCollapsed={collapsed}
          >
            {layouts &&
              layouts?.map(layout => (
                <MenuItem
                  key={layout?.key}
                  onClick={() => {
                    setSelectedLayout(layout);
                    if (isUsedChildrenLoaded.current) {
                      console.log(isUsedChildrenLoaded, 'isUsedChildrenLoaded');
                      isUsedChildrenLoaded.current = false;
                    }
                  }}
                >
                  {layout?.label}
                </MenuItem>
              ))}
            <MenuItem
              key="add_layout_icon"
              onClick={() => {
                handleAddNewLayout();
              }}
            >
              <PlusOutlined />
              <Text>Add New Layout</Text>
            </MenuItem>
          </Menu>
        </Col>
        <Col md={collapsed ? 23 : 20} style={{ transition: '1s' }}>
          {selectedLayout && isUsedChildrenLoaded.current === true ? (
            layouts?.map(layout => {
              return selectedLayout.key === layout.key ? (
                <Spin spinning={isLoading}>
                  <Editor
                    id={`layout-editor-${layout.key}`}
                    childCss={usedChildren?.childCss}
                    // onInit={editor => {
                    //   let editorComponents = layout.html;
                    //   if (layout.css) {
                    //     editorComponents = `${editorComponents}<style>${layout.css}</style>`;
                    //   }
                    //   updateEditorComponents(editor, editorComponents);
                    //   editor.on(`block:drag:stop`, (component, block) => {
                    //     importTheRequiredProperties(block.attributes);
                    //   });
                    //   return (editorRef.current = editor);
                    // }}
                    onInit={editor => {
                      let editorComponents = layout.html
                        ? layout.html
                        : layout.html;
                      if (layout.css) {
                        editorComponents = `${editorComponents}<style>${layout.css}</style>`;
                      }
                      if (layout.html) {
                        const previewButton = editor.Panels.getButton(
                          'options',
                          'preview'
                        );
                        previewButton.set('active', true, {
                          fromListen: true,
                        });
                        editor.on('stop:preview', () => {
                          editor.setComponents(
                            `${layout.html}<style>${layout.css}</style>`
                          );
                          setIsPreviewMode(false);
                          document.getElementsByClassName(
                            'gjs-pn-panel gjs-pn-devices-c gjs-one-bg gjs-two-color'
                          )[0].style.display = 'block';
                          document.getElementsByClassName(
                            'gjs-pn-panel gjs-pn-views-container gjs-one-bg gjs-two-color'
                          )[0].style.display = 'block';
                        });
                        editor.on('run:preview', async () => {
                          const replacedHtml = await replaceVariables({
                            component: layout,
                            traits: layout.traits,
                            html: layout.html,
                          });
                          editor.setComponents(
                            `${replacedHtml}<style>${layout.css}</style>`
                          );
                          setIsPreviewMode(true);
                          document.getElementsByClassName(
                            'gjs-pn-panel gjs-pn-devices-c gjs-one-bg gjs-two-color'
                          )[0].style.display = 'none';
                          document.getElementsByClassName(
                            'gjs-pn-panel gjs-pn-views-container gjs-one-bg gjs-two-color'
                          )[0].style.display = 'none';
                        });
                      } else {
                        const previewButton = editor.Panels.getButton(
                          'options',
                          'preview'
                        );
                        previewButton.set('disable', true, {
                          fromListen: true,
                        });
                        setIsPreviewMode(false);
                      }

                      updateEditorComponents(editor, editorComponents);
                      editor.on(`block:drag:stop`, (component, block) => {
                        // importTheRequiredProperties(block.attributes);
                        setDroppedComp(block.attributes);
                        setDraggedModal(true);
                      });
                      return (editorRef.current = editor);
                    }}
                    {...updatedBlocks(atoms, 'atom')}
                    {...updatedBlocks(molecules, 'molecule')}
                    {...updatedBlocks(organisms, 'organism')}
                  ></Editor>
                  <Row
                    style={{
                      marginTop: '10px',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <LastModified
                      entityType="layouts"
                      entityId={layout._id}
                      forceUpdate={didLayoutsUpdate.current}
                      updateRef={updateRef}
                      projectRole={projectRole}
                    />
                    <Button
                      type="link"
                      onClick={() => {
                        if (usagesDetail.pages?.length > 0) {
                          setUsedInfo(true);
                          setIsWarningModalOpen(true);
                        }
                      }}
                    >
                      Used in {usagesDetail?.pages?.length} pages{' '}
                    </Button>
                  </Row>

                  <Row justify={'end'}>
                    <Button
                      type="link"
                      onClick={() => {
                        if (
                          usedChildren?.atoms?.length ||
                          usedChildren?.molecules?.length ||
                          usedChildren?.organisms?.length
                        ) {
                          setChildrenInfoModalOpen(true);
                        }
                      }}
                      style={{ float: 'right' }}
                    >
                      Uses {usedChildren?.atoms?.length || 0} atoms,
                      {usedChildren?.molecules?.length || 0} molecule,
                      {usedChildren?.organisms?.length || 0} organisms.
                    </Button>
                  </Row>

                  <Row justify="end" style={{ marginTop: '1em' }}>
                    <Col style={{ margin: '1em' }}>
                      <Button
                        type="primary"
                        onClick={() => {
                          setShowJSBlock(true);
                          setCurrentCompJs(selectedLayout.js);
                        }}
                      >
                        Add JavaScript
                      </Button>
                    </Col>

                    <Col style={{ margin: '1em' }}>
                      <Button
                        type="primary"
                        onClick={async () => {
                          try {
                            let isUsages = false;
                            let res = await axios.get(
                              `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/components/layouts/${selectedLayout._id}/usages`
                            );
                            const componnetsKeys = Object.keys(res.data);
                            componnetsKeys?.map(component => {
                              if (res.data[component].length) {
                                isUsages = true;
                              }
                            });

                            if (isUsages) {
                              setIsWarningModalOpen(true);
                              setUsingComponent(res.data);
                              setVariationData(layout);
                            } else {
                              handleSaveVariation(layout);
                              setIsLoading(true);
                            }
                          } catch (err) {
                            console.log(err);
                          }
                        }}
                      >
                        Save
                      </Button>
                    </Col>

                    <Col style={{ margin: '1em' }}>
                      {usagesDetail?.pages?.length ||
                      projectRole === CONSTANTS.USER_ROLES.DEVELOPER ? (
                        <Tooltip
                          title={
                            projectRole === CONSTANTS.USER_ROLES.DEVELOPER
                              ? 'Request team lead for updating these changes.'
                              : 'This component cannot be deleted since it has been used in higher level components.'
                          }
                        >
                          <Button type="primary" disabled={true}>
                            Delete
                          </Button>
                        </Tooltip>
                      ) : (
                        <Button
                          type="primary"
                          onClick={() => {
                            setDeleteConfirmation(true);
                          }}
                        >
                          Delete
                        </Button>
                      )}
                    </Col>

                    <Col style={{ margin: '1em' }}>
                      <Button
                        type="primary"
                        onClick={() => {
                          setIsRenameModalOpen(true);
                          setNameOfLayoutToRename(selectedLayout.label);
                        }}
                      >
                        Rename
                      </Button>
                    </Col>

                    <Col style={{ margin: '1em' }}>
                      <Button
                        type="primary"
                        onClick={() => {
                          setExternalLibraryModal(true);
                        }}
                      >
                        Add External Library{' '}
                      </Button>
                    </Col>

                    <Col style={{ margin: '1em' }}>
                      <Button
                        type="primary"
                        onClick={() => {
                          window.open(
                            window.location.href +
                              `/preview?key=${selectedLayout.key}&type=layouts&id=${selectedLayout._id}`
                          );
                        }}
                      >
                        Preview
                      </Button>
                    </Col>

                    <Col style={{ margin: '1em' }}>
                      <Checkbox
                        checked={
                          selectedLayout.inLineCss
                            ? selectedLayout.inLineCss
                            : false
                        }
                        onChange={handleInlineCss}
                      >
                        Inline CSS
                      </Checkbox>
                    </Col>
                  </Row>
                  {(layout.dependencies?.js?.length ||
                    layout.dependencies?.css?.length) && (
                    <Row style={{ marginTop: '30px' }}>
                      <Col justify="start" span={12}>
                        <DependencyTable
                          setEntity={setLayouts}
                          Entity={layouts}
                          selectedEntity={layout}
                        />
                      </Col>
                    </Row>
                  )}
                </Spin>
              ) : null;
            })
          ) : (
            <>
              <Row justify="center" align="middle" style={{ height: '100%' }}>
                <Col>
                  <Text type="warning">
                    Please select a component to proceed
                  </Text>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = state => {
  return {
    atoms: state.DesignComponents.atoms,
    molecules: state.DesignComponents.molecules,
    organisms: state.DesignComponents.organisms,
    layouts: state.DesignComponents.layouts,
    globalConfigs:
      state.GlobalConfigurations.finalConfigs?.global_configuration,
    projectRole: state.ProjectRole.projectRole,
  };
};

const mapDispatchToProps = dispatch => ({
  setAtoms: atoms => {
    dispatch(setAtoms(atoms));
  },
  setMolecules: molecules => {
    dispatch(setMolecules(molecules));
  },
  setOrganisms: organisms => {
    dispatch(setOrganisms(organisms));
  },
  setLayouts: layouts => {
    dispatch(setLayouts(layouts));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutsEditor);
