const getHtml = () => {
    return `
      <div class="form-group">
        <label for="inputName">Email Address</label>
        <input type="email" class="form-control" id="inputName" aria-describedby="nameHelp" placeholder="Enter Email">
        <small id="nameHelp" class="form-text text-muted">Please enter your email.</small>
      </div>
    `;
  };
  
  const getCss = () => {
    return ``;
  };
  
  const getJs = () => {
    return ``;
  };
  
  const EmailInput = { getHtml, getCss, getJs };
  
  export default EmailInput;
  