import * as TYPES from './action-types';

const initialState = {};

const Export = (state = initialState, { type, data }) => {
  switch (type) {
    default:
      return state;
  }
};

export default Export;
