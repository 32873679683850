const getHtml = () => {
  return `
        <h4>Example heading <span class="badge bg-secondary">New</span></h4>
        `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const H4Badge = { getHtml, getCss, getJs };

export default H4Badge;
