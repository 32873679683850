import * as TYPES from './action-types';

const initialState = {
    id:'',
    token: '',
    firstName:'',
    lastName:'',
    email:'',
    role:'',
    status:'',
    sshKey:'',
}

const User = (state=initialState,{type,data}) =>{
    switch (type) {
        case TYPES.SET_USER:
          return {
            ...state,
             id:data.id,
             token:data.token,
             firstName:data.firstName,
             lastName:data.lastName,
             email:data.email,
             role:data.role,
             status:data.status,
             sshKey:data.sshKey,
          };
        default:
          return state;
      }
}

export default User;