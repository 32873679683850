import {
  Button,
  Col,
  Divider,
  Row,
  Table,
  Typography,
  Modal,
  message,
  Spin,
  Tooltip,
} from 'antd';
import { useState, useEffect, useRef } from 'react';
import axios from '../../utils/axios';
import { PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import AddProject from './AddProject';
import { getURL, setURL } from '../../utils/urlParam';
import UpdateProject from './UpdateProject';
import ErrorPage from '../common/ErrorPage';
import BackupList from '../common/backups/BackupList';
import ManageUsers from './ManageUsers';
import { connect } from 'react-redux';
import CONSTANTS from '../../utils/constants';
import { Checkbox } from 'antd';
import { Input } from 'antd';

const ProjectList = ({ userRole }) => {
  const [projects, setProjects] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [totalProjects, setTotalProjects] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [editProjectValue, setEditProjectValue] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const isUpdate = useRef(true);
  const [netError, setNetError] = useState(false);
  const [manageUsersModal, setManageUsersModal] = useState(false);
  const [manageUsersModalData, setManageUsersModalData] = useState([]);
  const [selectedProjectID, setSelectedProjectID] = useState();
  const [projectName, setProjectName] = useState();
  const [messageApi, contextHolder] = message.useMessage();
  const [projectCloneModal, setProjectCloneModal] = useState(false);
  const [newCloneData , setNewCloneData] = useState({
    includePermissions: false,
    projectName : ''
  });
  const [projectToBeClonedId, setProjectToBeClonedId] = useState(null);
  const [isCloneOperationRunning , setIsCloneOperationRunning] = useState(false);

  const handleProjectCloning = async () => {
      try {
        setIsCloneOperationRunning(true);
        const res = await axios.post(
          `${process.env.REACT_APP_SERVER_BASE_URL}/projectClone`,
          {
            ...newCloneData
          },
          {
            headers: {
              project: projectToBeClonedId,
            },
          }
        );
        if (res.status === 201) {
          await axios
            .get(
              `${
                process.env.REACT_APP_SERVER_BASE_URL
              }/projects?limit=10&offset=${0}`
            )
            .then(res => {
              setProjects(res.data.docs);
              setTotalProjects(res.data.totalDocs);
              setIsLoading(false);
              setIsCloneOperationRunning(false);
              setProjectCloneModal(false);
              setProjectToBeClonedId(null);
              setNewCloneData({
                includePermissions: false,
                projectName : ''
              })
              alertmessage('success', 'Project cloned successfully');
            });
        }
      } catch (e) {
        alertmessage('error', 'Error: project clone failed');
        console.log(e);
      }
  };

  const alertmessage = (type, massage) => {
    messageApi.open({
      type: type,
      content: massage,
    });
  };

  useEffect(() => {
    const currentPageFromUrl = getURL('page');
    let offset = currentPageFromUrl ? (currentPageFromUrl - 1) * 10 : 0;
    if (projects && isUpdate.current) {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/projects?limit=10&offset=${offset}`
        )
        .then(res => {
          setProjects(res.data.docs);
          setTotalProjects(res.data.totalDocs);
          setIsLoading(false);
        })
        .catch(error => {
          if (!error.status) {
            // network error
            setNetError(true);
          }

          setIsLoading(false);
          alertmessage(
            'error',
            'Error: Something wrong please refresh the page'
          );
        });
      isUpdate.current = false;
    }
  }, [projects, currentPage]);

  const deleteProjectHandler = async projectId => {
    Modal.confirm({
      title: 'Delete Alert.',
      content: 'Do you want to delete it?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        const updateProject = projects.filter(
          project => project._id !== projectId
        );

        await axios
          .delete(
            `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}`
          )
          .then(res => {
            setProjects(updateProject);
            isUpdate.current = true;
            setCurrentPage(1);
            setURL('page', 1);
            alertmessage('success', 'Project deleted successfully');
          })
          .catch(error => {
            if (!error.status) {
              // network error
              setNetError(true);
            }
            alertmessage('error', 'Error: project not deleted');
          });
      },
    });
  };

  const addProjectHandler = projectData => {
    setProjects([...projects, projectData]);
    isUpdate.current = true;
  };

  const handleAddProjectModal = value => {
    setIsAddModalOpen(value);
  };

  const handleEditProjectModal = (value, record) => {
    setEditProjectValue(record);
    setIsEditModalOpen(value);
  };
  const editProjectHandler = () => {
    setProjects([...projects]);
    isUpdate.current = true;
  };
  const fetchManageAccessData = projectId => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/access`
      )
      .then(res => {
        if (res.data && res.data.length > 0) {
          setManageUsersModalData(res.data);
        } else {
          setManageUsersModalData([{ user: '', role: '', project: '' }]);
        }
      })
      .catch(error => {
        console.log('error');
        messageApi.open({
          type: 'error',
          content: 'something wrong!! try again',
        });
      });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) =>
        record.status ? (
          <Link to={`/projects/${record._id}/assets`}>{text}</Link>
        ) : (
          <span>{text}</span>
        ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: val => (val ? val : '-'),
    },
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url',
      render: val => (val ? val : '-'),
    },
    {
      title: 'Repository',
      dataIndex: 'repository',
      key: 'repository',
      render: val => (val ? val : '-'),
    },
    {
      title: 'Assets Path',
      dataIndex: 'assetsPath',
      key: 'assetsPath',
      render: val => (val ? val : '-'),
    },
    {
      title: 'Includes Prefix',
      dataIndex: 'includesPrefix',
      key: 'includesPrefix',
      render: val => (val ? val : '-'),
    },
    {
      title: 'Includes Path',
      dataIndex: 'includesPath',
      key: 'includesPath',
      render: val => (val ? val : '-'),
    },
    {
      title: 'Theme Name',
      dataIndex: 'themeName',
      key: 'themeName',
      render: val => (val ? val : '-'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: val => (
        <div className="status">{val ? 'Active' : 'Disabled'}</div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: '',
      align: 'center',
      key: '',
      render: (_, record) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {userRole === CONSTANTS.USER_ROLES.DEVELOPER ? (
              <Tooltip title="Request team lead for updating these changes.">
                <Button
                  type="link"
                  onClick={() => {
                    deleteProjectHandler(record._id);
                  }}
                  disabled={true}
                >
                  Delete
                </Button>
              </Tooltip>
            ) : (
              <Tooltip title="Project is in active state.">
                <Button
                  type="link"
                  onClick={() => {
                    deleteProjectHandler(record._id);
                  }}
                  disabled={record.status}
                >
                  {' '}
                  Delete
                </Button>{' '}
              </Tooltip>
            )}
            <Tooltip
              title={
                userRole === CONSTANTS.USER_ROLES.DEVELOPER
                  ? 'Request team lead for updating these changes.'
                  : ''
              }
            >
              <Button
                type="link"
                onClick={() => {
                  handleEditProjectModal(true, record);
                }}
                disabled={userRole === CONSTANTS.USER_ROLES.DEVELOPER}
              >
                Edit
              </Button>
            </Tooltip>
            <Tooltip
              title={
                userRole === CONSTANTS.USER_ROLES.DEVELOPER
                  ? 'Request team lead for updating these changes.'
                  : ''
              }
            >
              <Button
                type="link"
                onClick={() => {
                  setProjectToBeClonedId(record._id);
                  setProjectCloneModal(true);
                }}
                disabled={userRole === CONSTANTS.USER_ROLES.DEVELOPER}
              >
                Clone
              </Button>
            </Tooltip>
            <Tooltip
              title={
                userRole === CONSTANTS.USER_ROLES.DEVELOPER
                  ? 'Request team lead for updating these changes.'
                  : ''
              }
            >
              <Button
                type="link"
                onClick={() => {
                  setManageUsersModal(true);
                  fetchManageAccessData(record._id);
                  setSelectedProjectID(record._id);
                  setProjectName(record.name);
                }}
                disabled={userRole === CONSTANTS.USER_ROLES.DEVELOPER}
              >
                Manage Access
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return netError ? (
    <ErrorPage errorMessage="Please check your Internet connection" />
  ) : (
    <div style={{ padding: '20px' }}>
      <Modal
        title="Do you want to clone this project?"
        open={projectCloneModal}
        onOk={handleProjectCloning}
        okText='Yes'
        onCancel={() => setProjectCloneModal(false)}
        confirmLoading={isCloneOperationRunning}
      >
        <div>
          <Checkbox
            onChange={() => {
              setNewCloneData(prevState => ({
                ...prevState,
                includePermissions : !prevState.includePermissions
            }));
            }}
            checked={newCloneData.includePermissions}
          >
            Copy project level permissions
          </Checkbox>
          <br />
          <br />
          <Input
            placeholder="Enter Project Name For Clone"
            value={newCloneData.projectName}
            onChange={(e) => setNewCloneData(prevState => ({
              ...prevState,
              projectName: e.target.value
            }))}
          />
        </div>
      </Modal>
      {contextHolder}
      <Row align="middle" justify="space-between" style={{ marginBottom: 10 }}>
        <Col>
          <h1>Projects</h1>
        </Col>
        <Col>
          {userRole === CONSTANTS.USER_ROLES.ADMINISTRATOR && (
            <Button type="primary">
              <Link to="/users">Users</Link>
            </Button>
          )}
          <BackupList />

          {userRole !== CONSTANTS.USER_ROLES.DEVELOPER && (
            <Button type="primary" onClick={() => handleAddProjectModal(true)}>
              <PlusOutlined /> Create New Project
            </Button>
          )}
          {isAddModalOpen && (
            <AddProject
              isOpen={isAddModalOpen}
              handleAddProjectModal={handleAddProjectModal}
              addProjectHandler={addProjectHandler}
              message={alertmessage}
              setIsLoading={setIsLoading}
            />
          )}
          {isEditModalOpen && (
            <UpdateProject
              isOpen={isEditModalOpen}
              handleEditProjectModal={handleEditProjectModal}
              editProjectHandler={editProjectHandler}
              values={editProjectValue}
              message={alertmessage}
            />
          )}
          {manageUsersModal && (
            <ManageUsers
              manageUsersModal={manageUsersModal}
              setManageUsersModal={setManageUsersModal}
              manageUsersModalData={manageUsersModalData}
              setManageUsersModalData={setManageUsersModalData}
              selectedProjectID={selectedProjectID}
              projectName={projectName}
              alertmessage={alertmessage}
            />
          )}
        </Col>
      </Row>
      {isLoading ? (
        <Spin
          tip="Loading..."
          size="large"
          style={{ display: 'flex', justifyContent: 'center' }}
        />
      ) : (
        <Table
          dataSource={projects}
          size="small"
          columns={columns}
          onChange={e => {
            setCurrentPage(e.current);
            setURL('page', e.current);
            setIsLoading(true);
            isUpdate.current = true;
          }}
          pagination={{
            defaultCurrent: 1,
            total: totalProjects,
            current: currentPage,
          }}
        />
      )}
    </div>
  );
};
const mapStateToProps = state => {
  return {
    userRole: state.User.role,
    userId: state.User.id,
  };
};

export default connect(mapStateToProps, null)(ProjectList);
