const getHtml = () => {
    return `
    <div class="container" data-gjs-type="div" 
        style="min-height: 526px; width: auto; background-image: url('https://cdn.ayroui.com/1.0/images/card/card-2.jpg');">
    
  </div>
      `;
  };
  
  const getJs = () =>{
      return `
        
      `
  }
  
  const getCss = () =>{
      return `
      `
  }
  
  const HeroWithBackgroundImage = { getHtml, getCss, getJs };
    
  export default HeroWithBackgroundImage;