import React, { useEffect, useRef, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Select } from 'antd';
import { connect } from 'react-redux';
import {
  setAtoms,
  setMolecules,
  setOrganisms,
} from '../../redux/design-components/actions';
import { setGlobalCssCdn } from '../../redux/global-configurations/actions';
import CONSTANTS from '../../utils/constants';
// import {LoremIpsum} from 'react-lorem-ipsum';
import { loremIpsum } from 'lorem-ipsum';
import { useParams } from 'react-router-dom';
import axios from '../../utils/axios';
import scriptsLoader from '../../utils/helpers/scriptLoader';
import { replaceVariables } from '../../utils/helpers/replaceTraits';
import { getURL } from '../../utils/urlParam';

const Preview = ({
  atoms,
  molecules,
  organisms,
  currentCssCDN,
  externalJS,
}) => {
  const [key, setKey] = useState(null);
  const [allComponents, setAllComponents] = useState([]);
  const [cssLink, setCssLink] = useState(null);
  const [organismsJSDependencies, setOrganismsJSDependencies] = useState([]);
  const [organismsCSSDependencies, setOrganismsCSSDependencies] = useState([]);
  const [usedChildren, setUsedChildren] = useState();
  const isUsedChildrenLoaded = useRef(false);

  const { projectId } = useParams();
  const componentType = getURL('type');
  const componentId = getURL('id');
  const componentKey = getURL('key');

  //dropdown things
  const [classTraits , setClassTraits] = useState([]);

const { Option } = Select;

  const handleAddDependencies = dependencies => {
    setOrganismsJSDependencies(dependencies.js);
    setOrganismsCSSDependencies(dependencies.css);
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/configurations`
        );
        if (response.data.css) {
          setCssLink(response.data.css);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    if (componentType !== 'atom') {
      if (componentType === 'molecule') {
        axios
          .get(
            `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/components/molecules/${componentId}/variations/${componentKey}/children`
          )
          .then(res => {
            setUsedChildren(res.data);
            if (res.status === 200) {
              isUsedChildrenLoaded.current = true;
            }
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        axios
          .get(
            `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/components/${componentType}/${componentId}/children`
          )
          .then(res => {
            setUsedChildren(res.data);
            if (res.status === 200) {
              isUsedChildrenLoaded.current = true;
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
  }, []);

  useEffect(() => {
    let selectedKey = componentKey;
    setKey(selectedKey);
    if (!organisms.length) {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/components`
        )
        .then(data => {
          if (data) {
            setAtoms([...data.data?.atoms]);
            setMolecules([...data.data?.molecules]);
            setOrganisms([...data.data?.organisms]);
            let consolidatedComponents = [];
            if (data.data.atoms.length) {
              data.data.atoms.forEach(atom => {
                consolidatedComponents = [
                  ...consolidatedComponents,
                  ...atom.variations,
                ];
              });
            }
            if (data.data.molecules.length) {
              data.data.molecules.forEach(molecule => {
                consolidatedComponents = [
                  ...consolidatedComponents,
                  ...molecule.variations,
                ];
              });
            }
            if (data.data.organisms.length) {
              consolidatedComponents = [
                ...consolidatedComponents,
                ...data.data.organisms,
              ];
            }
            if (data.data.layouts.length) {
              consolidatedComponents = [
                ...consolidatedComponents,
                ...data.data.layouts,
              ];
            }
            if (data.data.pages.length) {
              consolidatedComponents = [
                ...consolidatedComponents,
                ...data.data.pages,
              ];
            }
            consolidatedComponents.forEach(component => {
              if (component.key === selectedKey) {
                if (component.dependencies) {
                  handleAddDependencies(component.dependencies);
                }
              }
            });

            setAllComponents([...consolidatedComponents]);
          }
        })
        .catch(err => console.log(err, 'error'));
    }
  }, []);

  useEffect(() => {
    let consolidatedComponents = [];
    if (atoms.length) {
      atoms.forEach(atom => {
        consolidatedComponents = [
          ...consolidatedComponents,
          ...atom.variations,
        ];
      });
    }
    if (molecules.length) {
      molecules.forEach(molecule => {
        consolidatedComponents = [
          ...consolidatedComponents,
          ...molecule.variations,
        ];
      });
    }
    if (organisms.length) {
      consolidatedComponents = [...consolidatedComponents, ...organisms];
    }
    setAllComponents([...consolidatedComponents]);
  }, [currentCssCDN, organisms, molecules, atoms]);
  const editorRef = useRef(null);

  const addCustomJS = selectedOrganism => {
    if (selectedOrganism && selectedOrganism.js) {
      //removing undefine
      const newJS = selectedOrganism.js?.replace(/\bundefined\b/g, '');
      var scriptTag = document.createElement('script');
      scriptTag.type = 'text/javascript';
      var code = newJS;
      try {
        scriptTag.appendChild(document.createTextNode(code));
        document.body.appendChild(scriptTag);
      } catch (e) {
        scriptTag.text = code;
        document.body.appendChild(scriptTag);
      }
    }
  };

  useEffect(() => {
    //------------------- constant bootstarp JS ----------------------//
    const bootstrapJSScript = document.createElement('script');
    bootstrapJSScript.setAttribute('src', CONSTANTS.BOOTSTRAP_JS_CDN);
    bootstrapJSScript.setAttribute('crossorigin', 'anonymous');
    document.getElementsByTagName('head')[0].append(bootstrapJSScript);

    //------------------- constant bootstarp CSS ----------------------//
    const bootstrapCSSScript = document.createElement('link');
    bootstrapCSSScript.setAttribute('href', CONSTANTS.BOOTSTRAP_ICON_CSS_CDN);
    bootstrapCSSScript.setAttribute('crossorigin', 'anonymous');
    bootstrapCSSScript.setAttribute('rel', 'stylesheet');
    document.getElementsByTagName('head')[0].append(bootstrapCSSScript);

    //------------------- compiled custom CSS ----------------------//
    if (cssLink) {
      const compiledCSS = document.createElement('link');
      compiledCSS.setAttribute('href', cssLink);
      compiledCSS.setAttribute('crossorigin', 'anonymous');
      compiledCSS.setAttribute('rel', 'stylesheet');
      document.getElementsByTagName('head')[0].append(compiledCSS);
    }
    //  adding child component css
    if (usedChildren && usedChildren?.childCss) {
      usedChildren.childCss?.forEach(item => {
        const childCssLink = document.createElement('link');
        childCssLink.setAttribute('href', item);
        childCssLink.setAttribute('crossorigin', 'anonymous');
        childCssLink.setAttribute('rel', 'stylesheet');
        document.getElementsByTagName('head')[0].append(childCssLink);
      });
    }

    if (allComponents && key) {
      let selectedOrganism = allComponents.find(organism => {
        return organism.key === key;
      });
      setClassTraits(selectedOrganism?.traits?.filter(trait => trait.options && trait.options.length > 1));
      if (
        selectedOrganism &&
        (selectedOrganism?.dependencies?.js?.length > 0 ||
          selectedOrganism?.dependencies?.css?.length > 0)
      ) {
        //------------------- JS dependencies for selected component ----------------------//
        const dependecyArr = selectedOrganism.dependencies?.js || [];
        // if we have js dependencies
        if (dependecyArr && dependecyArr.length > 0) {
          scriptsLoader
            .reset()
            .addScript(dependecyArr)
            .afterLoad(src => console.log('loading ....', src))
            .onComplete(() => {
              addCustomJS(selectedOrganism);
            })
            .load();
        } else {
          // if we don't have any JS dependencies
          addCustomJS(selectedOrganism);
        }

        //------------------- CSS dependencies for selected component ----------------------//
        selectedOrganism.dependencies?.css.forEach(dependency => {
          const styleTag = document.createElement('link');
          styleTag.setAttribute('href', dependency);
          styleTag.setAttribute('crossorigin', 'anonymous');
          styleTag.setAttribute('rel', 'stylesheet');
          document.getElementsByTagName('head')[0].append(styleTag);
        });
      } else {
        // if we don't have any dependencies
        addCustomJS(selectedOrganism);
      }
    }
  }, [key, allComponents, usedChildren]);

  return (
    <>
    {
      classTraits?.length > 0 && (
        classTraits?.map((trait , i) => (
          <Select defaultValue={trait.defaultValue} style={{ width: 200 }} dropdownStyle={{ backgroundColor: 'grey', color: 'white'}} onChange={(value) => {
            const newComponents = [...allComponents];
            const compIndex = newComponents.findIndex((comp) => comp.key === key);
            const traitIndex = newComponents[compIndex].traits.findIndex(compTrait => compTrait.key === trait.key);
            newComponents[compIndex].traits[traitIndex] = {
              ...trait,
              defaultValue: value,
            }
            setAllComponents(newComponents);
          }}>
            {trait.options.map((option) => (
              <Option value={option}>{option}</Option>
            ))}
          </Select>
        ))
      )
    }
    <div className={CONSTANTS.BOOTSTRAP_WRAPPER_CLASS}>
      <html>
        {key &&
          allComponents &&
          allComponents.map(organism => {
            return organism.key === key ? (
              <>
                {ReactHtmlParser(
                  replaceVariables({
                    component: organism,
                    traits: organism.traits,
                    html: organism.html,
                  })
                )}
                <style>{organism.css}</style>
              </>
            ) : null;
          })}
      </html>
    </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    atoms: state.DesignComponents.atoms,
    molecules: state.DesignComponents.molecules,
    organisms: state.DesignComponents.organisms,
    currentCssCDN: state.GlobalConfigurations.initialCDN,
    externalJS:
      state.GlobalConfigurations.finalConfigs.global_configuration
        .globalJavascript,
  };
};

const mapDispatchToProps = dispatch => ({
  setAtoms: atoms => {
    dispatch(setAtoms(atoms));
  },
  setMolecules: molecules => {
    dispatch(setMolecules(molecules));
  },
  setOrganisms: organisms => {
    dispatch(setOrganisms(organisms));
  },
  setCurrentCssCDN: css => {
    dispatch(setGlobalCssCdn(css));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Preview);
