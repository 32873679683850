const getHtml = () => {
  return `
    <button type="button" class="btn btn-dark">Dark</button>
    `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const DarkButton = { getHtml, getCss, getJs };

export default DarkButton;
