import { Collapse, Spin, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  setAtoms,
  setLayouts,
  setMolecules,
  setOrganisms,
  setPages,
} from '../../../redux/design-components/actions';

import { useParams } from 'react-router-dom';
import AtomsTraits from './atoms/index';
import MoleculesTraits from './molecules/index';
import OrganismsTraits from './organisms/index';
import axios from '../../../utils/axios/index';
import ErrorPage from '../../common/ErrorPage';
import LayoutTraits from './layouts/index';
import PageTraits from './Pages/index';

const { Panel } = Collapse;

const DefineTraits = ({
  atoms,
  molecules,
  organisms,
  layouts,
  pages,
  initialState,
  setAtoms,
  setMolecules,
  setOrganisms,
  setLayouts,
  setPages
}) => {
  const [loading, setLoading] = useState(true);
  const [netError, setNetError] = useState(false);
  const { projectId, step } = useParams();
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    // hit the component get api here
    axios
      .get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/components`
      )
      .then(data => {
        if (data) {
          setAtoms([...data.data?.atoms]);
          setMolecules([...data.data?.molecules]);
          setOrganisms([...data.data?.organisms]);
          setLayouts([...data.data?.layouts]);
          setPages([...data.data?.pages]);
        }
        setLoading(false);
      })
      .catch(err => {
        if (!err.status) {
          // network error
          setNetError(true);
          console.log('network error');
        }
        setLoading(false);
        messageApi.open({
          type: 'error',
          content: err,
        });
      });
  }, []);
  if (loading) {
    return (
      <center>
        <Spin
          tip="Loading..."
          size="large"
          style={{ display: 'flex', justifyContent: 'center' }}
        />
      </center>
    );
  }
  return (
    <>
      {contextHolder}
      {netError ? (
        <ErrorPage errorMessage="Please check your Internet connection" />
      ) : (
        <Collapse
          style={{ marginTop: 30 }}
          accordion
          defaultActiveKey={['panel-atoms']}
        >
          <Panel header="Atoms" key="panel-atoms">
            <AtomsTraits />
          </Panel>
          <Panel header="Molecules" key="panel-molecules">
            <MoleculesTraits />
          </Panel>
          <Panel header="Organisms" key="panel-organisms">
            <OrganismsTraits />
          </Panel>
          <Panel header="Drupal Components" key="panel-layouts">
            <LayoutTraits />
          </Panel>
          <Panel header="Pages" key="panel-pages">
            <PageTraits />
          </Panel>
        </Collapse>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    initialState: state.DesignComponents,
    atoms: state.DesignComponents.atoms,
    molecules: state.DesignComponents.molecules,
    organisms: state.DesignComponents.organisms,
    layouts: state.DesignComponents.layouts,
    pages: state.DesignComponents.pages
  };
};

const mapDispatchToProps = dispatch => ({
  setAtoms: atoms => {
    dispatch(setAtoms(atoms));
  },
  setMolecules: molecules => {
    dispatch(setMolecules(molecules));
  },
  setOrganisms: organisms => {
    dispatch(setOrganisms(organisms));
  },
  setLayouts: layouts => {
    dispatch(setLayouts(layouts))
  },
  setPages: pages => {
    dispatch(setPages(pages))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(DefineTraits);
