import { Button, Col, Form, Input, Row, Spin, message,Breadcrumb,Divider } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Link, useParams } from 'react-router-dom';
import ChangePassword from './ChangePassword';
import { useEffect, useRef, useState } from 'react';
import axios from '../../utils/axios';
import ErrorPage from '../common/ErrorPage';
import { connect } from 'react-redux';
import {setUser} from '../../redux/user/actions'

const UserProfile = () => {
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [profileForm] = Form.useForm();
  const [userData, setUserData] = useState({});
  const isUpdate = useRef(true);
  const { userId } = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const [netError, setNetError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (userData && isUpdate.current) {
      axios
        .get(`${process.env.REACT_APP_SERVER_BASE_URL}/users/${userId}`)
        .then(res => {
          setUserData(res.data);
          setIsLoading(false);
        })
        .catch(error => {
          if (!error.status) {
            setNetError(true);
          }
          console.log(error);
          messageApi.open({
            type: 'error',
            content: 'error: Please refresh',
          });
        });
      isUpdate.current = false;
    }
  }, [userData]);

  const isUpdatedHandler = () => {
    isUpdate.current = true;
  };

  const infoMessage = (msgType, msgContent) => {
    messageApi.open({
      type: msgType,
      content: msgContent,
    });
  };
  profileForm.setFieldsValue({
    firstName: userData.firstName,
    lastName: userData.lastName,
    email: userData.email,
    sshKey: userData?.sshKey,
  });

  const onFinishHandler = value => {
    if (value.email === userData.email) {
      delete value.email;
    }
    setIsLoading(true);
    axios
      .patch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/${userId}`, value)
      .then(res => {
        profileForm.resetFields();
        setUserData([]);
        // i can dispach an action for updaing ssh key with user response
        isUpdate.current = true;
        messageApi.open({
          type: 'success',
          content: 'Profile updated',
        });
      })
      .catch(error => {
        console.log({error});
        messageApi.open({
          type: 'error',
          content: 'Profile not updated',
        });
        profileForm.resetFields();
        setIsLoading(false);
      });
  };

  return netError ? (
    <ErrorPage errorMessage="Please check your Internet connection" />
  ) : (
    <>
      {contextHolder}
      {isLoading ? (
        <Spin
          size="large"
          style={{ display: 'flex', justifyContent: 'center' }}
        />
      ) : (
        <>
          <Breadcrumb style={{ fontSize: '16px', marginLeft: '7px' }}>
            <Breadcrumb.Item style={{ textDecoration: 'underline' }}>
              <Link to="/projects">Projects</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Users</Breadcrumb.Item>
          </Breadcrumb>
          <Divider />
          <Row>
            <Col md={10} style={{ margin: 'auto' }}>
              <h1 style={{ fontSize: '2rem' }}>My Profile</h1>
              <Form
                labelCol={{
                  span: 24,
                }}
                form={profileForm}
                name="Profile form"
                onFinish={values => onFinishHandler(values)}
              >
                <Form.Item
                  label="First Name"
                  name="firstName"
                  rules={[
                    { required: true, message: 'First Name is required' },
                    { whitespace: true },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Last Name"
                  name="lastName"
                  rules={[
                    { required: true, message: 'Last Name is required' },
                    { whitespace: true },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!',
                    },
                    {
                      required: true,
                      message: 'Email is Required',
                    },
                    { whitespace: true },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Private SSH Key"
                  name="sshKey"
                  rules={[{ whitespace: true }]}
                >
                  <TextArea />
                </Form.Item>
                <Link onClick={() => setIsChangePassword(true)}>
                  Change password
                </Link>

                <Form.Item style={{ float: 'right' }}>
                  <Button type="primary" htmlType="submit">
                    Done
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            {isChangePassword && (
              <ChangePassword
                open={isChangePassword}
                setIsChangePassword={setIsChangePassword}
                id={userId}
                setListingData={setUserData}
                isUpdatedHandler={isUpdatedHandler}
                infoMessage={infoMessage}
              />
            )}
          </Row>
        </>
      )}
    </>
  );
};
const mapDispatchToProps = dispatch => ({
 setUser: (data)=>{
  dispatch(setUser(data))
 }
});

export default connect(null,mapDispatchToProps) (UserProfile);
