import { Navigate } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  message,
  Typography,
} from 'antd';
import axios from '../utils/axios';
import { setToken } from '../redux/login/action';
import { connect } from 'react-redux';
import { useState } from 'react';
import { setUser } from '../redux/user/actions';

const { Title } = Typography;

const Login = ({ authToken, setToken,setUser }) => {
  const onFinish = async values => {
    await axios
      .post(`${process.env.REACT_APP_SERVER_BASE_URL}/users/login`, {
        ...values,
      })
      .then(res => {
        message.success('User login successfully');
        setToken(res.data.token);
        setUser(res.data);
        window.localStorage.setItem('userId',res.data.id)
      })
      .catch(error => {
        message.error(`Invalid user`);
      });
  };

  return (
    <>
      {authToken ? (
        <Navigate to="/projects" replace={true} />
      ) : (
        <Row align="middle" justify="center" style={{ minHeight: '70vh' }}>
          <Col md={8}>
            <Form
              name="basic"
              labelAlign="left"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Title
                level={3}
                style={{ textAlign: 'center', marginBottom: 30 }}
              >
                Login
              </Title>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please input your email!',
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>

              <Row justify="center" style={{ marginTop: 30 }}>
                <Col>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Login
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    authToken: state.Token.token,
  };
};

const mapDispatchToProps = dispatch => ({
  setToken: token => {
    dispatch(setToken(token));
  },
  setUser: user=>{
    dispatch(setUser(user));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
