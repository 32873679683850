const getHtml = () => {
  return `
        <div>
          <h1>title</h1>
          <div>
            <p>This is the body of secondary Video</p>
          </div>
        </div>
      `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const SecondaryVideo = { getHtml, getCss, getJs };

export default SecondaryVideo;
