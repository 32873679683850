const getHtml = () => {
  return `
  <div>
  <h1>title</h1>
  <ol>
    <li>Cras justo odio</li>
    <li>Dapibus ac facilisis in</li>
    <li>Morbi leo risus</li>
    <li>Porta ac consectetur ac</li>
    <li>Vestibulum at eros</li>
  </ol>
</div>
`;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const SecondaryList = { getHtml, getCss, getJs };

export default SecondaryList;
