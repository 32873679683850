const getHtml = () => {
  return `
    <div class="progress">
    <div class="progress-bar" role="progressbar" style="width: 25%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div>
  </div>       `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const Label = { getHtml, getCss, getJs };

export default Label;
