import MoleculesLibrary from '../library/molecules';


const MOLECULES = [
    {
        label: 'Card',
        component: 'Card',
        key: 'card',
        defaultVariation: MoleculesLibrary.Cards.Default.getHtml(),
        variations: [
            {
                label: 'Primary',
                key: 'card_primary',
                html: MoleculesLibrary.Cards.Default.getHtml(),
                devices: []
            }
        ]
    },
    {
        label: 'CTA',
        component: 'CTA',
        key: 'cta',
        defaultVariation: MoleculesLibrary.CTA.Default.getHtml(),
        variations: [
            {
                label: 'Primary',
                key: 'cta_primary',
                html: MoleculesLibrary.CTA.Default.getHtml(),
                devices: []
            }
        ]
    },
    {
        label: 'Banner',
        component: 'Banner',
        key: 'banner',
        defaultVariation: MoleculesLibrary.Banner.Default.getHtml(),
        variations: [
            {
                label: 'Primary',
                key: 'banner_primary',
                html: MoleculesLibrary.Banner.Default.getHtml(),
                devices: []
            }
        ]
    },
    {
        label: 'Heros',
        component: 'Heros',
        key: 'heros',
        defaultVariation: MoleculesLibrary.Heros.HeroWithBackgroundColor.getHtml(),
        variations: [
            {
                label: 'Hero with BackGround Color',
                key: 'hero_with_background_color',
                html: MoleculesLibrary.Heros.HeroWithBackgroundColor.getHtml(),
                devices: []
            },

            // variations: [
            //     {
            //     label: 'Primary',
            //     key: 'primary_banner',
            //     html: MolculesLibarary.Banner.default.getHtml(),
            //     devices: [
            //      desktop: {
            //       html: MolculesLibarary.Banner.default.getHtml('desktop'),
            //     }
            //     ]
            //     }
            //     ]
            // variations: [
            //     {
            //       label: 'Primary',
            //       key: 'primary_banner',
            //       html: MolculesLibarary.Banner.default.getHtml(),
            //       devices: [
            //         mobile: {
            //           html: MolculesLibarary.Banner.default.getHtml('mobile'),
            //         }
            //       ]
            //      }
            //     ]
            {
                label: 'Hero with BackGround Image',
                key: 'hero_with_background_image',
                html: MoleculesLibrary.Heros.HeroWithBackgroundImage.getHtml(),
                devices: []
            }
        ]
    }
];

export default MOLECULES;
