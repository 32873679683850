const getHtml = () => {
  return `
    <div class="custom-file">
    <input type="file" class="custom-file-input" id="inputGroupFile01">
    <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
  </div>
    `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const FileInput = { getHtml, getCss, getJs };

export default FileInput;
