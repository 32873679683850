const setURL = (key, value) => {
  const url = new URLSearchParams(window.location.search);

  url.set(key, value);
  window.history.replaceState(null, null, '?' + url.toString());
};

const deleteURL = key => {
  const url = new URLSearchParams(window.location.search);

  url.delete(key);
  window.history.replaceState(null, null, '?' + url.toString());
};

const getURL = key => {
  const url = new URL(window.location.href);
  return url.searchParams.get(key);
};

export { getURL, setURL, deleteURL };
