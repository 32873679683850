const styles = [
  {
    "category": "Display",
    "items": [
      {
        "label": "Block",
        "options": [
          {
            "id": ".d-block",
            "label": ".d-block",
            "value": ".d-block"
          },
          {
            "id": ".d-sm-block",
            "label": ".d-sm-block",
            "value": ".d-sm-block"
          },
          {
            "id": ".d-md-block",
            "label": ".d-md-block",
            "value": ".d-md-block"
          },
          {
            "id": ".d-lg-block",
            "label": ".d-lg-block",
            "value": ".d-lg-block"
          },
          {
            "id": ".d-xl-block",
            "label": ".d-xl-block",
            "value": ".d-xl-block"
          },
          {
            "id": ".d-xxl-block",
            "label": ".d-xxl-block",
            "value": ".d-xxl-block"
          }
        ]
      },
      {
        "label": "None",
        "options": [
          {
            "id": ".d-none",
            "label": ".d-none",
            "value": ".d-none"
          },
          {
            "id": ".d-sm-none",
            "label": ".d-sm-none",
            "value": ".d-sm-none"
          },
          {
            "id": ".d-md-none",
            "label": ".d-md-none",
            "value": ".d-md-none"
          },
          {
            "id": ".d-lg-none",
            "label": ".d-lg-none",
            "value": ".d-lg-none"
          },
          {
            "id": ".d-xl-none",
            "label": ".d-xl-none",
            "value": ".d-xl-none"
          },
          {
            "id": ".d-xxl-none",
            "label": ".d-xxl-none",
            "value": ".d-xxl-none"
          }
        ]
      },
      {
        "label": "Inline",
        "options": [
          {
            "id": ".d-inline",
            "label": ".d-inline",
            "value": ".d-inline"
          },
          {
            "id": ".d-sm-inline",
            "label": ".d-sm-inline",
            "value": ".d-sm-inline"
          },
          {
            "id": ".d-md-inline",
            "label": ".d-md-inline",
            "value": ".d-md-inline"
          },
          {
            "id": ".d-lg-inline",
            "label": ".d-lg-inline",
            "value": ".d-lg-inline"
          },
          {
            "id": ".d-xl-inline",
            "label": ".d-xl-inline",
            "value": ".d-xl-inline"
          },
          {
            "id": ".d-xxl-inline",
            "label": ".d-xxl-inline",
            "value": ".d-xxl-inline"
          },
          {
            "id": ".d-inline-block",
            "label": ".d-inline-block",
            "value": ".d-inline-block"
          },
          {
            "id": ".d-sm-inline-block",
            "label": ".d-sm-inline-block",
            "value": ".d-sm-inline-block"
          },
          {
            "id": ".d-md-inline-block",
            "label": ".d-md-inline-block",
            "value": ".d-md-inline-block"
          },
          {
            "id": ".d-lg-inline-block",
            "label": ".d-lg-inline-block",
            "value": ".d-lg-inline-block"
          },
          {
            "id": ".d-xl-inline-block",
            "label": ".d-xl-inline-block",
            "value": ".d-xl-inline-block"
          },
          {
            "id": ".d-xxl-inline-block",
            "label": ".d-xxl-inline-block",
            "value": ".d-xxl-inline-block"
          },
          {
            "id": ".d-inline-flex",
            "label": ".d-inline-flex",
            "value": ".d-inline-flex"
          },
          {
            "id": ".d-sm-inline-flex",
            "label": ".d-sm-inline-flex",
            "value": ".d-sm-inline-flex"
          },
          {
            "id": ".d-md-inline-flex",
            "label": ".d-md-inline-flex",
            "value": ".d-md-inline-flex"
          },
          {
            "id": ".d-lg-inline-flex",
            "label": ".d-lg-inline-flex",
            "value": ".d-lg-inline-flex"
          },
          {
            "id": ".d-xl-inline-flex",
            "label": ".d-xl-inline-flex",
            "value": ".d-xl-inline-flex"
          },
          {
            "id": ".d-xxl-inline-flex",
            "label": ".d-xxl-inline-flex",
            "value": ".d-xxl-inline-flex"
          }
        ]
      },
      {
        "label": "Flex",
        "options": [
          {
            "id": ".d-flex",
            "label": ".d-flex",
            "value": ".d-flex"
          },
          {
            "id": ".d-sm-flex",
            "label": ".d-sm-flex",
            "value": ".d-sm-flex"
          },
          {
            "id": ".d-md-flex",
            "label": ".d-md-flex",
            "value": ".d-md-flex"
          },
          {
            "id": ".d-lg-flex",
            "label": ".d-lg-flex",
            "value": ".d-lg-flex"
          },
          {
            "id": ".d-xl-flex",
            "label": ".d-xl-flex",
            "value": ".d-xl-flex"
          },
          {
            "id": ".d-xxl-flex",
            "label": ".d-xxl-flex",
            "value": ".d-xxl-flex"
          }
        ]
      },
      {
        "label": "Grid",
        "options": [
          {
            "id": ".d-grid",
            "label": ".d-grid",
            "value": ".d-grid"
          },
          {
            "id": ".d-sm-grid",
            "label": ".d-sm-grid",
            "value": ".d-sm-grid"
          },
          {
            "id": ".d-md-grid",
            "label": ".d-md-grid",
            "value": ".d-md-grid"
          },
          {
            "id": ".d-lg-grid",
            "label": ".d-lg-grid",
            "value": ".d-lg-grid"
          },
          {
            "id": ".d-xl-grid",
            "label": ".d-xl-grid",
            "value": ".d-xl-grid"
          },
           {
            "id": ".d-xxl-grid",
            "label": ".d-xxl-grid",
            "value": ".d-xxl-grid"
          }
        ]
      }
    ]
  },

  {
    "category": "Alignments",
    "items": [
      {
        "label": "Align Items",
        "options": [
          {
            "id": ".align-items-baseline",
            "label": ".align-items-baseline",
            "value": ".align-items-baseline"
          },
          {
            "id": ".align-items-sm-baseline",
            "label": ".align-items-sm-baseline",
            "value": ".align-items-sm-baseline"
          },
          {
            "id": ".align-items-md-baseline",
            "label": ".align-items-md-baseline",
            "value": ".align-items-md-baseline"
          },
            {
            "id": ".align-items-lg-baseline",
            "label": ".align-items-lg-baseline",
            "value": ".align-items-lg-baseline"
          },
          {
            "id": ".align-items-xl-baseline",
            "label": ".align-items-xl-baseline",
            "value": ".align-items-xl-baseline"
          },
          {
            "id": ".align-items-xxl-baseline",
            "label": ".align-items-xxl-baseline",
            "value": ".align-items-xxl-baseline"
          },
          {
            "id": ".align-items-center",
            "label": ".align-items-center",
            "value": ".align-items-center"
          },
          {
            "id": ".align-items-sm-center",
            "label": ".align-items-sm-center",
            "value": ".align-items-sm-center"
          },
          {
            "id": ".align-items-md-center",
            "label": ".align-items-md-center",
            "value": ".align-items-md-center"
          },
            {
            "id": ".align-items-lg-center",
            "label": ".align-items-lg-center",
            "value": ".align-items-lg-center"
          },
            {
            "id": ".align-items-xl-center",
            "label": ".align-items-xl-center",
            "value": ".align-items-xl-center"
          },
           {
            "id": ".align-items-xxl-center",
            "label": ".align-items-xxl-center",
            "value": ".align-items-xxl-center"
          },
          {
            "id": ".align-items-end",
            "label": ".align-items-end",
            "value": ".align-items-end"
          },
          {
            "id": ".align-items-sm-end",
            "label": ".align-items-sm-end",
            "value": ".align-items-sm-end"
          },
          {
            "id": ".align-items-md-end",
            "label": ".align-items-md-end",
            "value": ".align-items-md-end"
          },
            {
            "id": ".align-items-lg-end",
            "label": ".align-items-lg-end",
            "value": ".align-items-lg-end"
          },
            {
            "id": ".align-items-xl-end",
            "label": ".align-items-xl-end",
            "value": ".align-items-xl-end"
          },
          {
            "id": ".align-items-xxl-end",
            "label": ".align-items-xxl-end",
            "value": ".align-items-xxl-end"
          },
          {
            "id": ".align-items-start",
            "label": ".align-items-start",
            "value": ".align-items-start"
          },
          {
            "id": ".align-items-sm-start",
            "label": ".align-items-sm-start",
            "value": ".align-items-sm-start"
          },
          {
            "id": ".align-items-md-start",
            "label": ".align-items-md-start",
            "value": ".align-items-md-start"
          },
            {
            "id": ".align-items-lg-start",
            "label": ".align-items-lg-start",
            "value": ".align-items-lg-start"
          },
            {
            "id": ".align-items-xl-start",
            "label": ".align-items-xl-start",
            "value": ".align-items-xl-start"
          },
          {
            "id": ".align-items-xxl-start",
            "label": ".align-items-xxl-start",
            "value": ".align-items-xxl-start"
          },
          {
            "id": ".align-items-stretch",
            "label": ".align-items-stretch",
            "value": ".align-items-stretch"
          },
          {
            "id": ".align-items-sm-stretch",
            "label": ".align-items-sm-stretch",
            "value": ".align-items-sm-stretch"
          },
          {
            "id": ".align-items-md-stretch",
            "label": ".align-items-md-stretch",
            "value": ".align-items-md-stretch"
          },
            {
            "id": ".align-items-lg-stretch",
            "label": ".align-items-lg-stretch",
            "value": ".align-items-lg-stretch"
          },
          {
            "id": ".align-items-xl-stretch",
            "label": ".align-items-xl-stretch",
            "value": ".align-items-xl-stretch"
          },
          {
            "id": ".align-items-xxl-stretch",
            "label": ".align-items-xxl-stretch",
            "value": ".align-items-xxl-stretch"
          },
          {
            "id": ".align-content-around",
            "label": ".align-content-around",
            "value": ".align-content-around"
          },
          {
            "id": ".align-content-sm-around",
            "label": ".align-content-sm-around",
            "value": ".align-content-sm-around"
          },
          {
            "id": ".align-content-md-around",
            "label": ".align-content-md-around",
            "value": ".align-content-md-around"
          },
            {
            "id": ".align-content-lg-around",
            "label": ".align-content-lg-around",
            "value": ".align-content-lg-around"
          },
          {
            "id": ".align-content-xl-around",
            "label": ".align-content-xl-around",
            "value": ".align-content-xl-around"
          },
          {
            "id": ".align-content-xxl-around",
            "label": ".align-content-xxl-around",
            "value": ".align-content-xxl-around"
          },
          {
            "id": ".align-content-center",
            "label": ".align-content-center",
            "value": ".align-content-center"
          },
          {
            "id": ".align-content-sm-center",
            "label": ".align-content-sm-center",
            "value": ".align-content-sm-center"
          },
          {
            "id": ".align-content-md-center",
            "label": ".align-content-md-center",
            "value": ".align-content-md-center"
          },
            {
            "id": ".align-content-lg-center",
            "label": ".align-content-lg-center",
            "value": ".align-content-lg-center"
          },
            {
            "id": ".align-content-xl-center",
            "label": ".align-content-xl-center",
            "value": ".align-content-xl-center"
          },
          {
            "id": ".align-content-xxl-center",
            "label": ".align-content-xxl-center",
            "value": ".align-content-xxl-center"
          },
          {
            "id": ".align-content-end",
            "label": ".align-content-end",
            "value": ".align-content-end"
          },
          {
            "id": ".align-content-sm-end",
            "label": ".align-content-sm-end",
            "value": ".align-content-sm-end"
          },
          {
            "id": ".align-content-md-end",
            "label": ".align-content-md-end",
            "value": ".align-content-md-end"
          },
            {
            "id": ".align-content-lg-end",
            "label": ".align-content-lg-end",
            "value": ".align-content-lg-end"
          },
            {
            "id": ".align-content-xl-end",
            "label": ".align-content-xl-end",
            "value": ".align-content-xl-end"
          },
          {
            "id": ".align-content-xxl-end",
            "label": ".align-content-xxl-end",
            "value": ".align-content-xxl-end"
          },
          {
            "id": ".align-content-start",
            "label": ".align-content-start",
            "value": ".align-content-start"
          },
          {
            "id": ".align-content-sm-start",
            "label": ".align-content-sm-start",
            "value": ".align-content-sm-start"
          },
          {
            "id": ".align-content-md-start",
            "label": ".align-content-md-start",
            "value": ".align-content-md-start"
          },
            {
            "id": ".align-content-lg-start",
            "label": ".align-content-lg-start",
            "value": ".align-content-lg-start"
          },
            {
            "id": ".align-content-xl-start",
            "label": ".align-content-xl-start",
            "value": ".align-content-xl-start"
          },
          {
            "id": ".align-content-xxl-start",
            "label": ".align-content-xxl-start",
            "value": ".align-content-xxl-start"
          },
          {
            "id": ".align-content-stretch",
            "label": ".align-content-stretch",
            "value": ".align-content-stretch"
          },
          {
            "id": ".align-content-sm-stretch",
            "label": ".align-content-sm-stretch",
            "value": ".align-content-sm-stretch"
          },
          {
            "id": ".align-content-md-stretch",
            "label": ".align-content-md-stretch",
            "value": ".align-content-md-stretch"
          },
            {
            "id": ".align-content-lg-stretch",
            "label": ".align-content-lg-stretch",
            "value": ".align-content-lg-stretch"
          },
            {
            "id": ".align-content-xl-stretch",
            "label": ".align-content-xl-stretch",
            "value": ".align-content-xl-stretch"
          },
          {
            "id": ".align-content-xxl-stretch",
            "label": ".align-content-xxl-stretch",
            "value": ".align-content-xxl-stretch"
          },
          {
            "id": ".align-self-baseline",
            "label": ".align-self-baseline",
            "value": ".align-self-baseline"
          },
          {
            "id": ".align-self-sm-baseline",
            "label": ".align-self-sm-baseline",
            "value": ".align-self-sm-baseline"
          },
          {
            "id": ".align-self-md-baseline",
            "label": ".align-self-md-baseline",
            "value": ".align-self-md-baseline"
          },
            {
            "id": ".align-self-lg-baseline",
            "label": ".align-self-lg-baseline",
            "value": ".align-self-lg-baseline"
          },
          {
            "id": ".align-self-xl-baseline",
            "label": ".align-self-xl-baseline",
            "value": ".align-self-xl-baseline"
          },
          {
            "id": ".align-self-xxl-baseline",
            "label": ".align-self-xxl-baseline",
            "value": ".align-self-xxl-baseline"
          },
          {
            "id": ".align-self-center",
            "label": ".align-self-center",
            "value": ".align-self-center"
          },
          {
            "id": ".align-self-sm-center",
            "label": ".align-self-sm-center",
            "value": ".align-self-sm-center"
          },
          {
            "id": ".align-self-md-center",
            "label": ".align-self-md-center",
            "value": ".align-self-md-center"
          },
            {
            "id": ".align-self-lg-center",
            "label": ".align-self-lg-center",
            "value": ".align-self-lg-center"
          },
            {
            "id": ".align-self-xl-center",
            "label": ".align-self-xl-center",
            "value": ".align-self-xl-center"
          },
          {
            "id": ".align-self-xxl-center",
            "label": ".align-self-xxl-center",
            "value": ".align-self-xxl-center"
          },
          {
            "id": ".align-self-end",
            "label": ".align-self-end",
            "value": ".align-self-end"
          },
          {
            "id": ".align-self-sm-end",
            "label": ".align-self-sm-end",
            "value": ".align-self-sm-end"
          },
          {
            "id": ".align-self-md-end",
            "label": ".align-self-md-end",
            "value": ".align-self-md-end"
          },
            {
            "id": ".align-self-lg-end",
            "label": ".align-self-lg-end",
            "value": ".align-self-lg-end"
          },
          {
            "id": ".align-self-xl-end",
            "label": ".align-self-xl-end",
            "value": ".align-self-xl-end"
          },
          {
            "id": ".align-self-xxl-end",
            "label": ".align-self-xxl-end",
            "value": ".align-self-xxl-end"
          },
          {
            "id": ".align-self-start",
            "label": ".align-self-start",
            "value": ".align-self-start"
          },
          {
            "id": ".align-self-sm-start",
            "label": ".align-self-sm-start",
            "value": ".align-self-sm-start"
          },
          {
            "id": ".align-self-md-start",
            "label": ".align-self-md-start",
            "value": ".align-self-md-start"
          },
            {
            "id": ".align-self-lg-start",
            "label": ".align-self-lg-start",
            "value": ".align-self-lg-start"
          },
            {
            "id": ".align-self-xl-start",
            "label": ".align-self-xl-start",
            "value": ".align-self-xl-start"
          },
          {
            "id": ".align-self-xxl-start",
            "label": ".align-self-xxl-start",
            "value": ".align-self-xxl-start"
          },
          {
            "id": ".align-self-stretch",
            "label": ".align-self-stretch",
            "value": ".align-self-stretch"
          },
          {
            "id": ".align-self-sm-stretch",
            "label": ".align-self-sm-stretch",
            "value": ".align-self-sm-stretch"
          },
          {
            "id": ".align-self-md-stretch",
            "label": ".align-self-md-stretch",
            "value": ".align-self-md-stretch"
          },
            {
            "id": ".align-self-lg-stretch",
            "label": ".align-self-lg-stretch",
            "value": ".align-self-lg-stretch"
          },
            {
            "id": ".align-self-xl-stretch",
            "label": ".align-self-xl-stretch",
            "value": ".align-self-xl-stretch"
          },
          {
            "id": ".align-self-xxl-stretch",
            "label": ".align-self-xxl-stretch",
            "value": ".align-self-xxl-stretch"
          }
        ]
      },
       {
        "label": "Justify",
        "options": [
          {
            "id": " .justify-content-around",
            "label": " .justify-content-around",
            "value": " .justify-content-around"
          },
          {
            "id": ".justify-content-sm-around",
            "label": ".justify-content-sm-around",
            "value": ".justify-content-sm-around"
          },
          {
            "id": ".justify-content-md-around",
            "label": ".justify-content-md-around",
            "value": ".justify-content-md-around"
          },
            {
            "id": ".justify-content-lg-around",
            "label": ".justify-content-lg-around",
            "value": ".justify-content-lg-around"
          },
            {
            "id": ".justify-content-xl-around",
            "label": ".justify-content-xl-around",
            "value": ".justify-content-xl-around"
          },
          {
            "id": ".justify-content-xxl-around",
            "label": ".justify-content-xxl-around",
            "value": ".justify-content-xxl-around"
          },
          {
            "id": " .justify-content-center",
            "label": " .justify-content-center",
            "value": " .justify-content-center"
          },
          {
            "id": ".justify-content-sm-center",
            "label": ".justify-content-sm-center",
            "value": ".justify-content-sm-center"
          },
          {
            "id": ".justify-content-md-center",
            "label": ".justify-content-md-center",
            "value": ".justify-content-md-center"
          },
            {
            "id": ".justify-content-lg-center",
            "label": ".justify-content-lg-center",
            "value": ".justify-content-lg-center"
          },
            {
            "id": ".justify-content-xl-center",
            "label": ".justify-content-xl-center",
            "value": ".justify-content-xl-center"
          },
          {
            "id": ".justify-content-xxl-center",
            "label": ".justify-content-xxl-center",
            "value": ".justify-content-xxl-center"
          },
          {
            "id": " .justify-content-between",
            "label": " .justify-content-between",
            "value": " .justify-content-between"
          },
          {
            "id": ".justify-content-sm-between",
            "label": ".justify-content-sm-between",
            "value": ".justify-content-sm-between"
          },
          {
            "id": ".justify-content-md-between",
            "label": ".justify-content-md-between",
            "value": ".justify-content-md-between"
          },
            {
            "id": ".justify-content-lg-between",
            "label": ".justify-content-lg-between",
            "value": ".justify-content-lg-between"
          },
            {
            "id": ".justify-content-xl-between",
            "label": ".justify-content-xl-between",
            "value": ".justify-content-xl-between"
          },
          {
            "id": ".justify-content-xxl-between",
            "label": ".justify-content-xxl-between",
            "value": ".justify-content-xxl-between"
          },
          {
            "id": " .justify-content-end",
            "label": " .justify-content-end",
            "value": " .justify-content-end"
          },
          {
            "id": ".justify-content-sm-end",
            "label": ".justify-content-sm-end",
            "value": ".justify-content-sm-end"
          },
          {
            "id": ".justify-content-md-end",
            "label": ".justify-content-md-end",
            "value": ".justify-content-md-end"
          },
            {
            "id": ".justify-content-lg-end",
            "label": ".justify-content-lg-end",
            "value": ".justify-content-lg-end"
          },
            {
            "id": ".justify-content-xl-end",
            "label": ".justify-content-xl-end",
            "value": ".justify-content-xl-end"
          },
          {
            "id": ".justify-content-xxl-end",
            "label": ".justify-content-xxl-end",
            "value": ".justify-content-xxl-end"
          },
          {
            "id": " .justify-content-start",
            "label": " .justify-content-start",
            "value": " .justify-content-start"
          },
          {
            "id": ".justify-content-sm-start",
            "label": ".justify-content-sm-start",
            "value": ".justify-content-sm-start"
          },
          {
            "id": ".justify-content-md-start",
            "label": ".justify-content-md-start",
            "value": ".justify-content-md-start"
          },
            {
            "id": ".justify-content-lg-start",
            "label": ".justify-content-lg-start",
            "value": ".justify-content-lg-start"
          },
            {
            "id": ".justify-content-xl-start",
            "label": ".justify-content-xl-start",
            "value": ".justify-content-xl-start"
          },
          {
            "id": ".justify-content-xxl-start",
            "label": ".justify-content-xxl-start",
            "value": ".justify-content-xxl-start"
          },
          {
            "id": " .justify-content-evenly",
            "label": " .justify-content-evenly",
            "value": " .justify-content-evenly"
          },
          {
            "id": ".justify-content-sm-evenly",
            "label": ".justify-content-sm-evenly",
            "value": ".justify-content-sm-evenly"
          },
          {
            "id": ".justify-content-md-evenly ",
            "label": ".justify-content-md-evenly ",
            "value": ".justify-content-md-evenly "
          },
            {
            "id": ".justify-content-lg-evenly",
            "label": ".justify-content-lg-evenly",
            "value": ".justify-content-lg-evenly"
          },
            {
            "id": ".justify-content-xl-evenly",
            "label": ".justify-content-xl-evenly",
            "value": ".justify-content-xl-evenly"
          },
          {
            "id": ".justify-content-xxl-evenly",
            "label": ".justify-content-xxl-evenly",
            "value": ".justify-content-xxl-evenly"
          }
        ]
      },
      {
        "label": "Text Alignment",
        "options":[
          {
            "id": " .text-start",
            "label": " .text-start",
            "value": " .text-start"
          },
          {
            "id": ".text-sm-start",
            "label": ".text-sm-start",
            "value": ".text-sm-start"
          },
          {
            "id": ".text-md-start",
            "label": ".text-md-start",
            "value": ".text-md-start"
          },
            {
            "id": ".text-lg-start",
            "label": ".text-lg-start",
            "value": ".text-lg-start"
          },
            {
            "id": ".text-xl-start",
            "label": ".text-xl-start",
            "value": ".text-xl-start"
          },
          {
            "id": " .text-center",
            "label": " .text-center",
            "value": " .text-center"
          },
          {
            "id": ".text-sm-center",
            "label": ".text-sm-center",
            "value": ".text-sm-center"
          },
          {
            "id": ".text-md-center",
            "label": ".text-md-center",
            "value": ".text-md-center"
          },
            {
            "id": ".text-lg-center",
            "label": ".text-lg-center",
            "value": ".text-lg-center"
          },
            {
            "id": ".text-xl-center",
            "label": ".text-xl-center",
            "value": ".text-xl-center"
          },
          {
            "id": " .text-end",
            "label": " .text-end",
            "value": " .text-end"
          },
          {
            "id": ".text-sm-end",
            "label": ".text-sm-end",
            "value": ".text-sm-end"
          },
          {
            "id": ".text-md-end",
            "label": ".text-md-end",
            "value": ".text-md-end"
          },
            {
            "id": ".text-lg-end",
            "label": ".text-lg-end",
            "value": ".text-lg-end"
          },
            {
            "id": ".text-xl-end",
            "label": ".text-xl-end",
            "value": ".text-xl-end"
          },
          {
            "id": " .text-left",
            "label": " .text-left",
            "value": " .text-left"
          },
          {
            "id": ".text-sm-left",
            "label": ".text-sm-left",
            "value": ".text-sm-left"
          },
          {
            "id": ".text-md-left",
            "label": ".text-md-left",
            "value": ".text-md-left"
          },
            {
            "id": ".text-lg-left",
            "label": ".text-lg-left",
            "value": ".text-lg-left"
          },
            {
            "id": ".text-xl-left",
            "label": ".text-xl-left",
            "value": ".text-xl-left"
          },
          {
            "id": " .text-right",
            "label": " .text-right",
            "value": " .text-right"
          },
          {
            "id": ".text-sm-right",
            "label": ".text-sm-right",
            "value": ".text-sm-right"
          },
          {
            "id": ".text-md-right",
            "label": ".text-md-right",
            "value": ".text-md-right"
          },
            {
            "id": ".text-lg-right",
            "label": ".text-lg-right",
            "value": ".text-lg-right"
          },
            {
            "id": ".text-xl-right",
            "label": ".text-xl-right",
            "value": ".text-xl-right"
          }
        ]
      },
      {
        "label": "Flex Items",
        "options":[
          {
            "id": ".flex-column",
            "label": ".flex-column",
            "value": ".flex-column"
          },
          {
            "id": ".flex-sm-column",
            "label": ".flex-sm-column",
            "value": ".flex-sm-column"
          },
          {
            "id": ".flex-md-column",
            "label": ".flex-md-column",
            "value": ".flex-md-column"
          },
          {
            "id": ".flex-lg-column",
            "label": ".flex-lg-column",
            "value": ".flex-lg-column"
          },
          {
            "id": ".flex-xl-column",
            "label": ".flex-xl-column",
            "value": ".flex-xl-column"
          },
          {
            "id": ".flex-xxl-column",
            "label": ".flex-xxl-column",
            "value": ".flex-xxl-column"
          },
          {
            "id": ".flex-column-reverse",
            "label": ".flex-column-reverse",
            "value": ".flex-column-reverse"
          },
          {
            "id": ".flex-sm-column-reverse",
            "label": ".flex-sm-column-reverse",
            "value": ".flex-sm-column-reverse"
          },
          {
            "id": ".flex-md-column-reverse",
            "label": ".flex-md-column-reverse",
            "value": ".flex-md-column-reverse"
          },
          {
            "id": ".flex-lg-column-reverse",
            "label": ".flex-lg-column-reverse",
            "value": ".flex-lg-column-reverse"
          },
          {
            "id": ".flex-xl-column-reverse",
            "label": ".flex-xl-column-reverse",
            "value": ".flex-xl-column-reverse"
          },
          {
            "id": ".flex-xxl-column-reverse",
            "label": ".flex-xxl-column-reverse",
            "value": ".flex-xxl-column-reverse"
          },
          {
            "id": ".flex-row",
            "label": ".flex-row",
            "value": ".flex-row"
          },
          {
            "id": ".flex-sm-row",
            "label": ".flex-sm-row",
            "value": ".flex-sm-row"
          },
          {
            "id": ".flex-md-row",
            "label": ".flex-md-row",
            "value": ".flex-md-row"
          },
          {
            "id": ".flex-lg-row",
            "label": ".flex-lg-row",
            "value": ".flex-lg-row"
          },
          {
            "id": ".flex-xl-row",
            "label": ".flex-xl-row",
            "value": ".flex-xl-row"
          },
          {
            "id": ".flex-xxl-row",
            "label": ".flex-xxl-row",
            "value": ".flex-xxl-row"
          },
          {
            "id": ".flex-row-reverse",
            "label": ".flex-row-reverse",
            "value": ".flex-row-reverse"
          },
          {
            "id": ".flex-sm-row-reverse",
            "label": ".flex-sm-row-reverse",
            "value": ".flex-sm-row-reverse"
          },
          {
            "id": ".flex-md-row-reverse",
            "label": ".flex-md-row-reverse",
            "value": ".flex-md-row-reverse"
          },
            {
            "id": ".flex-lg-row-reverse",
            "label": ".flex-lg-row-reverse",
            "value": ".flex-lg-row-reverse"
          },
          {
            "id": ".flex-xl-row-reverse",
            "label": ".flex-xl-row-reverse",
            "value": ".flex-xl-row-reverse"
          },
          {
            "id": ".flex-xxl-row-reverse",
            "label": ".flex-xxl-row-reverse",
            "value": ".flex-xxl-row-reverse"
          },
          {
            "id": ".flex-fill",
            "label": ".flex-fill",
            "value": ".flex-fill"
          },
          {
            "id": ".flex-sm-fill",
            "label": ".flex-sm-fill",
            "value": ".flex-sm-fill"
          },
          {
            "id": ".flex-md-fill",
            "label": ".flex-md-fill",
            "value": ".flex-md-fill"
          },
          {
            "id": ".flex-lg-fill",
            "label": ".flex-lg-fill",
            "value": ".flex-lg-fill"
          },
          {
            "id": ".flex-xl-fill",
            "label": ".flex-xl-fill",
            "value": ".flex-xl-fill"
          },
          {
            "id": ".flex-xxl-fill",
            "label": ".flex-xxl-fill",
            "value": ".flex-xxl-fill"
          },
          {
            "id": ".flex-grow-0",
            "label": ".flex-grow-0",
            "value": ".flex-grow-0"
          },
          {
            "id": ".flex-sm-grow-0",
            "label": ".flex-sm-grow-0",
            "value": ".flex-sm-grow-0"
          },
          {
            "id": ".flex-md-grow-0",
            "label": ".flex-md-grow-0",
            "value": ".flex-md-grow-0"
          },
          {
            "id": ".flex-lg-grow-0",
            "label": ".flex-lg-grow-0",
            "value": ".flex-lg-grow-0"
          },
          {
            "id": ".flex-xl-grow-0",
            "label": ".flex-xl-grow-0",
            "value": ".flex-xl-grow-0"
          },
          {
            "id": ".flex-xxl-grow-0",
            "label": ".flex-xxl-grow-0",
            "value": ".flex-xxl-grow-0"
          },
          {
            "id": ".flex-grow-1",
            "label": ".flex-grow-1",
            "value": ".flex-grow-1"
          },
          {
            "id": ".flex-sm-grow-1",
            "label": ".flex-sm-grow-1",
            "value": ".flex-sm-grow-1"
          },
          {
            "id": ".flex-md-grow-1",
            "label": ".flex-md-grow-1",
            "value": ".flex-md-grow-1"
          },
          {
            "id": ".flex-lg-grow-1",
            "label": ".flex-lg-grow-1",
            "value": ".flex-lg-grow-1"
          },
          {
            "id": ".flex-xl-grow-1",
            "label": ".flex-xl-grow-1",
            "value": ".flex-xl-grow-1"
          },
          {
            "id": ".flex-xxl-grow-1",
            "label": ".flex-xxl-grow-1",
            "value": ".flex-xxl-grow-1"
          },
          {
            "id": ".flex-nowrap",
            "label": ".flex-nowrap",
            "value": ".flex-nowrap"
          },
          {
            "id": ".flex-sm-nowrap",
            "label": ".flex-sm-nowrap",
            "value": ".flex-sm-nowrap"
          },
          {
            "id": ".flex-md-nowrap",
            "label": ".flex-md-nowrap",
            "value": ".flex-md-nowrap"
          },
          {
            "id": ".flex-lg-nowrap",
            "label": ".flex-lg-nowrap",
            "value": ".flex-lg-nowrap"
          },
          {
            "id": "flex-xl-nowrap",
            "label": "flex-xl-nowrap",
            "value": "flex-xl-nowrap"
          },
          {
            "id": "flex-xxl-nowrap",
            "label": "flex-xxl-nowrap",
            "value": "flex-xxl-nowrap"
          },
          {
            "id": ".flex-wrap",
            "label": ".flex-wrap",
            "value": ".flex-wrap"
          },
          {
            "id": ".flex-sm-wrap",
            "label": ".flex-sm-wrap",
            "value": ".flex-sm-wrap"
          },
          {
            "id": ".flex-md-wrap",
            "label": ".flex-md-wrap",
            "value": ".flex-md-wrap"
          },
          {
            "id": ".flex-lg-wrap",
            "label": ".flex-lg-wrap",
            "value": ".flex-lg-wrap"
          },
          {
            "id": "flex-xl-wrap",
            "label": "flex-xl-wrap",
            "value": "flex-xl-wrap"
          },
          {
            "id": "flex-xxl-wrap",
            "label": "flex-xxl-wrap",
            "value": "flex-xxl-wrap"
          },
          {
            "id": ".flex-wrap-reverse",
            "label": ".flex-wrap-reverse",
            "value": ".flex-wrap-reverse"
          },
          {
            "id": ".flex-sm-wrap-reverse",
            "label": ".flex-sm-wrap-reverse",
            "value": ".flex-sm-wrap-reverse"
          },
          {
            "id": ".flex-md-wrap-reverse",
            "label": ".flex-md-wrap-reverse",
            "value": ".flex-md-wrap-reverse"
          },
          {
            "id": ".flex-lg-wrap-reverse",
            "label": ".flex-lg-wrap-reverse",
            "value": ".flex-lg-wrap-reverse"
          },
          {
            "id": ".flex-xl-wrap-reverse",
            "label": ".flex-xl-wrap-reverse",
            "value": ".flex-xl-wrap-reverse"
          },
          {
            "id": ".flex-xxl-wrap-reverse",
            "label": ".flex-xxl-wrap-reverse",
            "value": ".flex-xxl-wrap-reverse"
          },
          {
            "id": ".flex-shrink-0",
            "label": ".flex-shrink-0",
            "value": ".flex-shrink-0"
          },
          {
            "id": ".flex-sm-shrink-0",
            "label": ".flex-sm-shrink-0",
            "value": ".flex-sm-shrink-0"
          },
          {
            "id": ".flex-md-shrink-0",
            "label": ".flex-md-shrink-0",
            "value": ".flex-md-shrink-0"
          },
            {
            "id": ".flex-lg-shrink-0",
            "label": ".flex-lg-shrink-0",
            "value": ".flex-lg-shrink-0"
          },
          {
            "id": ".flex-xl-shrink-0",
            "label": ".flex-xl-shrink-0",
            "value": ".flex-xl-shrink-0"
          },
          {
            "id": ".flex-xxl-shrink-0",
            "label": ".flex-xxl-shrink-0",
            "value": ".flex-xxl-shrink-0"
          },
          {
            "id": ".flex-shrink-1",
            "label": ".flex-shrink-1",
            "value": ".flex-shrink-1"
          },
          {
            "id": ".flex-sm-shrink-1",
            "label": ".flex-sm-shrink-1",
            "value": ".flex-sm-shrink-1"
          },
          {
            "id": ".flex-md-shrink-1",
            "label": ".flex-md-shrink-1",
            "value": ".flex-md-shrink-1"
          },
          {
            "id": ".flex-lg-shrink-1",
            "label": ".flex-lg-shrink-1",
            "value": ".flex-lg-shrink-1"
          },
          {
            "id": ".flex-xl-shrink-1",
            "label": ".flex-xl-shrink-1",
            "value": ".flex-xl-shrink-1"
          },
          {
            "id": ".flex-xxl-shrink-1",
            "label": ".flex-xxl-shrink-1",
            "value": ".flex-xxl-shrink-1"
          },
          {
            "id": ".order-0",
            "label": ".order-0",
            "value": ".order-0"
          },
          {
            "id": ".order-sm-0",
            "label": ".order-sm-0",
            "value": ".order-sm-0"
          },
          {
            "id": ".order-md-0",
            "label": ".order-md-0",
            "value": ".order-md-0"
          },
          {
            "id": ".order-lg-0",
            "label": ".order-lg-0",
            "value": ".order-lg-0"
          },
           {
            "id": ".order-xl-0",
            "label": ".order-xl-0",
            "value": ".order-xl-0"
          },
          {
            "id": ".order-xxl-0",
            "label": ".order-xxl-0",
            "value": ".order-xxl-0"
          },
          {
            "id": ".order-1",
            "label": ".order-1",
            "value": ".order-1"
          },
          {
            "id": ".order-sm-1",
            "label": ".order-sm-1",
            "value": ".order-sm-1"
          },
          {
            "id": ".order-md-1",
            "label": ".order-md-1",
            "value": ".order-md-1"
          },
          {
            "id": ".order-lg-1",
            "label": ".order-lg-1",
            "value": ".order-lg-1"
          },
           {
            "id": ".order-xl-1",
            "label": ".order-xl-1",
            "value": ".order-xl-1"
          },
          {
            "id": ".order-xxl-1",
            "label": ".order-xxl-1",
            "value": ".order-xxl-1"
          },
          {
            "id": ".order-2",
            "label": ".order-2",
            "value": ".order-2"
          },
          {
            "id": ".order-sm-2",
            "label": ".order-sm-2",
            "value": ".order-sm-2"
          },
          {
            "id": ".order-md-2",
            "label": ".order-md-2",
            "value": ".order-md-2"
          },
          {
            "id": ".order-lg-2",
            "label": ".order-lg-2",
            "value": ".order-lg-2"
          },
           {
            "id": ".order-xl-2",
            "label": ".order-xl-2",
            "value": ".order-xl-2"
          },
          {
            "id": ".order-xxl-2",
            "label": ".order-xxl-2",
            "value": ".order-xxl-2"
          },
          {
            "id": ".order-3",
            "label": ".order-3",
            "value": ".order-3"
          },
          {
            "id": ".order-sm-3",
            "label": ".order-sm-3",
            "value": ".order-sm-3"
          },
          {
            "id": ".order-md-3",
            "label": ".order-md-3",
            "value": ".order-md-3"
          },
          {
            "id": ".order-lg-3",
            "label": ".order-lg-3",
            "value": ".order-lg-3"
          },
           {
            "id": ".order-xl-3",
            "label": ".order-xl-3",
            "value": ".order-xl-3"
          },
          {
            "id": ".order-xxl-3",
            "label": ".order-xxl-3",
            "value": ".order-xxl-3"
          },
          {
            "id": ".order-4",
            "label": ".order-4",
            "value": ".order-4"
          },
          {
            "id": ".order-sm-4",
            "label": ".order-sm-4",
            "value": ".order-sm-4"
          },
          {
            "id": ".order-md-4",
            "label": ".order-md-4",
            "value": ".order-md-4"
          },
          {
            "id": ".order-lg-4",
            "label": ".order-lg-4",
            "value": ".order-lg-4"
          },
           {
            "id": ".order-xl-4",
            "label": ".order-xl-4",
            "value": ".order-xl-4"
          },
          {
            "id": ".order-xxl-4",
            "label": ".order-xxl-4",
            "value": ".order-xxl-4"
          },
          {
            "id": ".order-5",
            "label": ".order-5",
            "value": ".order-5"
          },
          {
            "id": ".order-sm-5",
            "label": ".order-sm-5",
            "value": ".order-sm-5"
          },
          {
            "id": ".order-md-5",
            "label": ".order-md-5",
            "value": ".order-md-5"
          },
          {
            "id": ".order-lg-5",
            "label": ".order-lg-5",
            "value": ".order-lg-5"
          },
           {
            "id": ".order-xl-5",
            "label": ".order-xl-5",
            "value": ".order-xl-5"
          },
          {
            "id": ".order-xxl-5",
            "label": ".order-xxl-5",
            "value": ".order-xxl-5"
          },
          {
            "id": ".order-first",
            "label": ".order-first",
            "value": ".order-first"
          },
          {
            "id": ".order-sm-first",
            "label": ".order-sm-first",
            "value": ".order-sm-first"
          },
          {
            "id": ".order-md-first",
            "label": ".order-md-first",
            "value": ".order-md-first"
          },
          {
            "id": ".order-lg-first",
            "label": ".order-lg-first",
            "value": ".order-lg-first"
          },
          {
            "id": ".order-xl-first",
            "label": ".order-xl-first",
            "value": ".order-xl-first"
          },
          {
            "id": ".order-xxl-first",
            "label": ".order-xxl-first",
            "value": ".order-xxl-first"
          },
          {
            "id": ".order-last",
            "label": ".order-last",
            "value": ".order-last"
          },
          {
            "id": ".order-sm-last",
            "label": ".order-sm-last",
            "value": "..order-sm-last"
          },
          {
            "id": ".order-md-last",
            "label": ".order-md-last",
            "value": ".order-md-last"
          },
          {
            "id": ".order-lg-last",
            "label": ".order-lg-last",
            "value": ".order-lg-last"
          },
           {
            "id": ".order-xl-last",
            "label": ".order-xl-last",
            "value": ".order-xl-last"
          },
          {
            "id": ".order-xxl-last",
            "label": ".order-xxl-last",
            "value": ".order-xxl-last"
          }
        ]
      }
    ]
  },
  {
    "category": "Spacing",
    "items":[
      {
        "label": "Margin",
        "options":[
          {
            "id": ".m-0",
            "label": ".m-0",
            "value": ".m-0"
          },
          {
            "id": ".m-sm-0",
            "label": ".m-sm-0",
            "value": ".m-sm-0"
          },
          {
            "id": ".m-md-0",
            "label": ".m-md-0",
            "value": ".m-md-0"
          },
          {
            "id": ".m-lg-0",
            "label": ".m-lg-0",
            "value": ".m-lg-0"
          },
          {
            "id": ".m-xl-0",
            "label": ".m-xl-0",
            "value": ".m-xl-0"
          },
          {
            "id": ".m-xxl-0",
            "label": ".m-xxl-0",
            "value": ".m-xxl-0"
          },
          {
            "id": ".m-1",
            "label": ".m-1",
            "value": ".m-1"
          },
          {
            "id": ".m-sm-1",
            "label": ".m-sm-1",
            "value": ".m-sm-1"
          },
          {
            "id": ".m-md-1",
            "label": ".m-md-1",
            "value": ".m-md-1"
          },
          {
            "id": ".m-lg-1",
            "label": ".m-lg-1",
            "value": ".m-lg-1"
          },
          {
            "id": ".m-xl-1",
            "label": ".m-xl-1",
            "value": ".m-xl-1"
          },
          {
            "id": ".m-xxl-1",
            "label": ".m-xxl-1",
            "value": ".m-xxl-1"
          },
          {
            "id": ".m-2",
            "label": ".m-2",
            "value": ".m-2"
          },
          {
            "id": ".m-sm-2",
            "label": ".m-sm-2",
            "value": ".m-sm-2"
          },
          {
            "id": ".m-md-2",
            "label": ".m-md-2",
            "value": ".m-md-2"
          },
          {
            "id": ".m-lg-2",
            "label": ".m-lg-2",
            "value": ".m-lg-2"
          },
          {
            "id": ".m-xl-2",
            "label": ".m-xl-2",
            "value": ".m-xl-2"
          },
          {
            "id": ".m-xxl-2",
            "label": ".m-xxl-2",
            "value": ".m-xxl-2"
          },
          {
            "id": ".m-3",
            "label": ".m-3",
            "value": ".m-3"
          },
          {
            "id": ".m-sm-3",
            "label": ".m-sm-3",
            "value": ".m-sm-3"
          },
          {
            "id": ".m-md-3",
            "label": ".m-md-3",
            "value": ".m-md-3"
          },
          {
            "id": ".m-lg-3",
            "label": ".m-lg-3",
            "value": ".m-lg-3"
          },
          {
            "id": ".m-xl-3",
            "label": ".m-xl-3",
            "value": ".m-xl-3"
          },
          {
            "id": ".m-xxl-3",
            "label": ".m-xxl-3",
            "value": ".m-xxl-3"
          },
          {
            "id": ".m-4",
            "label": ".m-4",
            "value": ".m-4"
          },
          {
            "id": ".m-sm-4",
            "label": ".m-sm-4",
            "value": ".m-sm-4"
          },
          {
            "id": ".m-md-4",
            "label": ".m-md-4",
            "value": ".m-md-4"
          },
          {
            "id": ".m-lg-4",
            "label": ".m-lg-4",
            "value": ".m-lg-4"
          },
          {
            "id": ".m-xl-4",
            "label": ".m-xl-4",
            "value": ".m-xl-4"
          },
          {
            "id": ".m-xxl-4",
            "label": ".m-xxl-4",
            "value": ".m-xxl-4"
          },
          {
            "id": ".m-5",
            "label": ".m-5",
            "value": ".m-5"
          },
          {
            "id": ".m-sm-5",
            "label": ".m-sm-5",
            "value": ".m-sm-5"
          },
          {
            "id": ".m-md-5",
            "label": ".m-md-5",
            "value": ".m-md-5"
          },
          {
            "id": ".m-lg-5",
            "label": ".m-lg-5",
            "value": ".m-lg-5"
          },
          {
            "id": ".m-xl-5",
            "label": ".m-xl-5",
            "value": ".m-xl-5"
          },
          {
            "id": ".m-xxl-5",
            "label": ".m-xxl-5",
            "value": ".m-xxl-5"
          },
           {
            "id": ".mb-0",
            "label": ".mb-0",
            "value": ".mb-0"
          },
          {
            "id": ".mb-sm-0",
            "label": ".mb-sm-0",
            "value": ".mb-sm-0"
          },
          {
            "id": ".mb-md-0",
            "label": ".mb-md-0",
            "value": ".mb-md-0"
          },
          {
            "id": ".mb-lg-0",
            "label": ".mb-lg-0",
            "value": ".mb-lg-0"
          },
          {
            "id": ".mb-xl-0",
            "label": ".mb-xl-0",
            "value": ".mb-xl-0"
          },
          {
            "id": ".mb-xxl-0",
            "label": ".mb-xxl-0",
            "value": ".mb-xxl-0"
          },
          {
            "id": ".mt-0",
            "label": ".mt-0",
            "value": ".mt-0"
          },
          {
            "id": ".mt-sm-0",
            "label": ".mt-sm-0",
            "value": ".mt-sm-0"
          },
          {
            "id": ".mt-md-0",
            "label": ".mt-md-0",
            "value": ".mt-md-0"
          },
          {
            "id": ".mt-lg-0",
            "label": ".mt-lg-0",
            "value": ".mt-lg-0"
          },
          {
            "id": ".mt-xl-0",
            "label": ".mt-xl-0",
            "value": ".mt-xl-0"
          },
          {
            "id": ".mt-xxl-0",
            "label": ".mt-xxl-0",
            "value": ".mt-xxl-0"
          },
          {
            "id": ".me-0",
            "label": ".me-0",
            "value": ".me-0"
          },
          {
            "id": ".me-sm-0",
            "label": ".me-sm-0",
            "value": ".me-sm-0"
          },
          {
            "id": ".me-md-0",
            "label": ".me-md-0",
            "value": ".me-md-0"
          },
          {
            "id": ".me-lg-0",
            "label": ".me-lg-0",
            "value": ".me-lg-0"
          },
          {
            "id": ".me-xl-0",
            "label": ".me-xl-0",
            "value": ".me-xl-0"
          },
          {
            "id": ".me-xxl-0",
            "label": ".me-xxl-0",
            "value": ".me-xxl-0"
          },
          {
            "id": ".ms-0",
            "label": ".ms-0",
            "value": ".ms-0"
          },
          {
            "id": ".ms-sm-0",
            "label": ".ms-sm-0",
            "value": ".ms-sm-0"
          },
          {
            "id": ".ms-md-0",
            "label": ".ms-md-0",
            "value": ".ms-md-0"
          },
          {
            "id": ".ms-lg-0",
            "label": ".ms-lg-0",
            "value": ".ms-lg-0"
          },
          {
            "id": ".ms-xl-0",
            "label": ".ms-xl-0",
            "value": ".ms-xl-0"
          },
          {
            "id": ".ms-xxl-0",
            "label": ".ms-xxl-0",
            "value": ".ms-xxl-0"
          },
          {
            "id": ".mx-0",
            "label": ".mx-0",
            "value": ".mx-0"
          },
          {
            "id": ".mx-sm-0",
            "label": ".mx-sm-0",
            "value": ".mx-sm-0"
          },
          {
            "id": ".mx-md-0",
            "label": ".mx-md-0",
            "value": ".mx-md-0"
          },
          {
            "id": ".mx-lg-0",
            "label": ".mx-lg-0",
            "value": ".mx-lg-0"
          },
          {
            "id": ".mx-xl-0",
            "label": ".mx-xl-0",
            "value": ".mx-xl-0"
          },
          {
            "id": ".mx-xxl-0",
            "label": ".mx-xxl-0",
            "value": ".mx-xxl-0"
          },
          {
            "id": ".my-0",
            "label": ".my-0",
            "value": ".my-0"
          },
          {
            "id": ".my-sm-0",
            "label": ".my-sm-0",
            "value": ".my-sm-0"
          },
          {
            "id": ".my-md-0",
            "label": ".my-md-0",
            "value": ".my-md-0"
          },
          {
            "id": ".my-lg-0",
            "label": ".my-lg-0",
            "value": ".my-lg-0"
          },
          {
            "id": ".my-xl-0",
            "label": ".my-xl-0",
            "value": ".my-xl-0"
          },
          {
            "id": ".my-xxl-0",
            "label": ".my-xxl-0",
            "value": ".my-xxl-0"
          },
          {
            "id": ".m-auto",
            "label": ".m-auto",
            "value": ".m-auto"
          },
          {
            "id": ".m-sm-auto",
            "label": ".m-sm-auto",
            "value": ".m-sm-auto"
          },
          {
            "id": ".m-md-auto",
            "label": ".m-md-auto",
            "value": ".m-md-auto"
          },
          {
            "id": ".m-lg-auto",
            "label": ".m-lg-auto",
            "value": ".m-lg-auto"
          },
          {
            "id": ".m-xl-auto",
            "label": ".m-xl-auto",
            "value": ".m-xl-auto"
          },
          {
            "id": ".m-xxl-auto",
            "label": ".m-xxl-auto",
            "value": ".m-xxl-auto"
          },
          {
            "id": ".mb-auto",
            "label": ".mb-auto",
            "value": ".mb-auto"
          },
          {
            "id": ".mb-sm-auto",
            "label": ".mb-sm-auto",
            "value": ".mb-sm-auto"
          },
          {
            "id": ".mb-md-auto",
            "label": ".mb-md-auto",
            "value": ".mb-md-auto"
          },
          {
            "id": ".mb-lg-auto",
            "label": ".mb-lg-auto",
            "value": ".mb-lg-auto"
          },
          {
            "id": ".mb-xl-auto",
            "label": ".mb-xl-auto",
            "value": ".mb-xl-auto"
          },
          {
            "id": ".mb-xxl-auto",
            "label": ".mb-xxl-auto",
            "value": ".mb-xxl-auto"
          },
          {
            "id": ".me-auto",
            "label": ".me-auto",
            "value": ".me-auto"
          },
          {
            "id": ".me-sm-auto",
            "label": ".me-sm-auto",
            "value": ".me-sm-auto"
          },
          {
            "id": ".me-md-auto",
            "label": ".me-md-auto",
            "value": ".me-md-auto"
          },
          {
            "id": ".me-lg-auto",
            "label": ".me-lg-auto",
            "value": ".me-lg-auto"
          },
          {
            "id": ".me-xl-auto",
            "label": ".me-xl-auto",
            "value": ".me-xl-auto"
          },
          {
            "id": ".me-xxl-auto",
            "label": ".me-xxl-auto",
            "value": ".me-xxl-auto"
          },
          {
            "id": ".ms-auto",
            "label": ".ms-auto",
            "value": ".ms-auto"
          },
          {
            "id": ".ms-sm-auto",
            "label": ".ms-sm-auto",
            "value": ".ms-sm-auto"
          },
          {
            "id": ".ms-md-auto",
            "label": ".ms-md-auto",
            "value": ".ms-md-auto"
          },
          {
            "id": ".ms-lg-auto",
            "label": ".ms-lg-auto",
            "value": ".ms-lg-auto"
          },
          {
            "id": ".ms-xl-auto",
            "label": ".ms-xl-auto",
            "value": ".ms-xl-auto"
          },
          {
            "id": ".ms-xxl-auto",
            "label": ".ms-xxl-auto",
            "value": ".ms-xxl-auto"
          },
          {
            "id": ".mt-auto",
            "label": ".mt-auto",
            "value": ".mt-auto"
          },
          {
            "id": ".mt-sm-auto",
            "label": ".mt-sm-auto",
            "value": ".mt-sm-auto"
          },
          {
            "id": ".mt-md-auto",
            "label": ".mt-md-auto",
            "value": ".mt-md-auto"
          },
          {
            "id": ".mt-lg-auto",
            "label": ".mt-lg-auto",
            "value": ".mt-lg-auto"
          },
          {
            "id": ".mt-xl-auto",
            "label": ".mt-xl-auto",
            "value": ".mt-xl-auto"
          },
          {
            "id": ".mt-xxl-auto",
            "label": ".mt-xxl-auto",
            "value": ".mt-xxl-auto"
          },
          {
            "id": ".mx-auto",
            "label": ".mx-auto",
            "value": ".mx-auto"
          },
          {
            "id": ".mx-sm-auto",
            "label": ".mx-sm-auto",
            "value": ".mx-sm-auto"
          },
          {
            "id": ".mx-md-auto",
            "label": ".mx-md-auto",
            "value": ".mx-md-auto"
          },
          {
            "id": ".mx-lg-auto",
            "label": ".mx-lg-auto",
            "value": ".mx-lg-auto"
          },
          {
            "id": ".mx-xl-auto",
            "label": ".mx-xl-auto",
            "value": ".mx-xl-auto"
          },
          {
            "id": ".mx-xxl-auto",
            "label": ".mx-xxl-auto",
            "value": ".mx-xxl-auto"
          },
          {
            "id": ".my-auto",
            "label": ".my-auto",
            "value": ".my-auto"
          },
          {
            "id": ".my-sm-auto",
            "label": ".my-sm-auto",
            "value": ".my-sm-auto"
          },
          {
            "id": ".my-md-auto",
            "label": ".my-md-auto",
            "value": ".my-md-auto"
          },
          {
            "id": ".my-lg-auto",
            "label": ".my-lg-auto",
            "value": ".my-lg-auto"
          },
          {
            "id": ".my-xl-auto",
            "label": ".my-xl-auto",
            "value": ".my-xl-auto"
          },
          {
            "id": ".my-xxl-auto",
            "label": ".my-xxl-auto",
            "value": ".my-xxl-auto"
          }
        ]
      },
      {
        "label":"Padding",
        "options":[
          {
            "id": ".p-0",
            "label": ".p-0",
            "value": ".p-0"
          },
          {
            "id": ".p-sm-0",
            "label": ".p-sm-0",
            "value": ".p-sm-0"
          },
          {
            "id": ".p-md-0",
            "label": ".p-md-0",
            "value": ".p-md-0"
          },
          {
            "id": ".p-lg-0",
            "label": ".p-lg-0",
            "value": ".p-lg-0"
          },
          {
            "id": ".p-xl-0",
            "label": ".p-xl-0",
            "value": ".p-xl-0"
          },
          {
            "id": ".p-xxl-0",
            "label": ".p-xxl-0",
            "value": ".p-xxl-0"
          },
          {
            "id": ".p-1",
            "label": ".p-1",
            "value": ".p-1"
          },
          {
            "id": ".p-sm-1",
            "label": ".p-sm-1",
            "value": ".p-sm-1"
          },
          {
            "id": ".p-md-1",
            "label": ".p-md-1",
            "value": ".p-md-1"
          },
          {
            "id": ".p-lg-1",
            "label": ".p-lg-1",
            "value": ".p-lg-1"
          },
          {
            "id": ".p-xl-1",
            "label": ".p-xl-1",
            "value": ".p-xl-1"
          },
          {
            "id": ".p-xxl-1",
            "label": ".p-xxl-1",
            "value": ".p-xxl-1"
          },
          {
            "id": ".p-2",
            "label": ".p-2",
            "value": ".p-2"
          },
          {
            "id": ".p-sm-2",
            "label": ".p-sm-2",
            "value": ".p-sm-2"
          },
          {
            "id": ".p-md-2",
            "label": ".p-md-2",
            "value": ".p-md-2"
          },
          {
            "id": ".p-lg-2",
            "label": ".p-lg-2",
            "value": ".p-lg-2"
          },
          {
            "id": ".p-xl-2",
            "label": ".p-xl-2",
            "value": ".p-xl-2"
          },
          {
            "id": ".p-xxl-2",
            "label": ".p-xxl-2",
            "value": ".p-xxl-2"
          },
          {
            "id": ".p-3",
            "label": ".p-3",
            "value": ".p-3"
          },
          {
            "id": ".p-sm-3",
            "label": ".p-sm-3",
            "value": ".p-sm-3"
          },
          {
            "id": ".p-md-3",
            "label": ".p-md-3",
            "value": ".p-md-3"
          },
          {
            "id": ".p-lg-3",
            "label": ".p-lg-3",
            "value": ".p-lg-3"
          },
          {
            "id": ".p-xl-3",
            "label": ".p-xl-3",
            "value": ".p-xl-3"
          },
          {
            "id": ".p-xxl-3",
            "label": ".p-xxl-3",
            "value": ".p-xxl-3"
          },
          {
            "id": ".p-4",
            "label": ".p-4",
            "value": ".p-4"
          },
          {
            "id": ".p-sm-4",
            "label": ".p-sm-4",
            "value": ".p-sm-4"
          },
          {
            "id": ".p-md-4",
            "label": ".p-md-4",
            "value": ".p-md-4"
          },
          {
            "id": ".p-lg-4",
            "label": ".p-lg-4",
            "value": ".p-lg-4"
          },
          {
            "id": ".p-xl-4",
            "label": ".p-xl-4",
            "value": ".p-xl-4"
          },
          {
            "id": ".p-xxl-4",
            "label": ".p-xxl-4",
            "value": ".p-xxl-4"
          },
          {
            "id": ".p-5",
            "label": ".p-5",
            "value": ".p-5"
          },
          {
            "id": ".p-sm-5",
            "label": ".p-sm-5",
            "value": ".p-sm-5"
          },
          {
            "id": ".p-md-5",
            "label": ".p-md-5",
            "value": ".p-md-5"
          },
          {
            "id": ".p-lg-5",
            "label": ".p-lg-5",
            "value": ".p-lg-5"
          },
          {
            "id": ".p-xl-5",
            "label": ".p-xl-5",
            "value": ".p-xl-5"
          },
          {
            "id": ".p-xxl-5",
            "label": ".p-xxl-5",
            "value": ".p-xxl-5"
          },
          {
            "id": ".pb-0",
            "label": ".pb-0",
            "value": ".pb-0"
          },
          {
            "id": ".pb-sm-0",
            "label": ".pb-sm-0",
            "value": ".pb-sm-0"
          },
          {
            "id": ".pb-md-0",
            "label": ".pb-md-0",
            "value": ".pb-md-0"
          },
          {
            "id": ".pb-lg-0",
            "label": ".pb-lg-0",
            "value": ".pb-lg-0"
          },
          {
            "id": ".pb-xl-0",
            "label": ".pb-xl-0",
            "value": ".pb-xl-0"
          },
          {
            "id": ".pb-xxl-0",
            "label": ".pb-xxl-0",
            "value": ".pb-xxl-0"
          },
          {
            "id": ".pb-1",
            "label": ".pb-1",
            "value": ".pb-1"
          },
          {
            "id": ".pb-sm-1",
            "label": ".pb-sm-1",
            "value": ".pb-sm-1"
          },
          {
            "id": ".pb-md-1",
            "label": ".pb-md-1",
            "value": ".pb-md-1"
          },
          {
            "id": ".pb-lg-1",
            "label": ".pb-lg-1",
            "value": ".pb-lg-1"
          },
          {
            "id": ".pb-xl-1",
            "label": ".pb-xl-1",
            "value": ".pb-xl-1"
          },
          {
            "id": ".pb-xxl-1",
            "label": ".pb-xxl-1",
            "value": ".pb-xxl-1"
          },
          {
            "id": ".pb-2",
            "label": ".pb-2",
            "value": ".pb-2"
          },
          {
            "id": ".pb-sm-2",
            "label": ".pb-sm-2",
            "value": ".pb-sm-2"
          },
          {
            "id": ".pb-md-2",
            "label": ".pb-md-2",
            "value": ".pb-md-2"
          },
          {
            "id": ".pb-lg-2",
            "label": ".pb-lg-2",
            "value": ".pb-lg-2"
          },
          {
            "id": ".pb-xl-2",
            "label": ".pb-xl-2",
            "value": ".pb-xl-2"
          },
          {
            "id": ".pb-xxl-2",
            "label": ".pb-xxl-2",
            "value": ".pb-xxl-2"
          },
          {
            "id": ".pb-3",
            "label": ".pb-3",
            "value": ".pb-3"
          },
          {
            "id": ".pb-sm-3",
            "label": ".pb-sm-3",
            "value": ".pb-sm-3"
          },
          {
            "id": ".pb-md-3",
            "label": ".pb-md-3",
            "value": ".pb-md-3"
          },
          {
            "id": ".pb-lg-3",
            "label": ".pb-lg-3",
            "value": ".pb-lg-3"
          },
          {
            "id": ".pb-xl-3",
            "label": ".pb-xl-3",
            "value": ".pb-xl-3"
          },
          {
            "id": ".pb-xxl-3",
            "label": ".pb-xxl-3",
            "value": ".pb-xxl-3"
          },
          {
            "id": ".pb-4",
            "label": ".pb-4",
            "value": ".pb-4"
          },
          {
            "id": ".pb-sm-4",
            "label": ".pb-sm-4",
            "value": ".pb-sm-4"
          },
          {
            "id": ".pb-md-4",
            "label": ".pb-md-4",
            "value": ".pb-md-4"
          },
          {
            "id": ".pb-lg-4",
            "label": ".pb-lg-4",
            "value": ".pb-lg-4"
          },
          {
            "id": ".pb-xl-4",
            "label": ".pb-xl-4",
            "value": ".pb-xl-4"
          },
          {
            "id": ".pb-xxl-4",
            "label": ".pb-xxl-4",
            "value": ".pb-xxl-4"
          },
          {
            "id": ".pb-5",
            "label": ".pb-5",
            "value": ".pb-5"
          },
          {
            "id": ".pb-sm-5",
            "label": ".pb-sm-5",
            "value": ".pb-sm-5"
          },
          {
            "id": ".pb-md-5",
            "label": ".pb-md-5",
            "value": ".pb-md-5"
          },
          {
            "id": ".pb-lg-5",
            "label": ".pb-lg-5",
            "value": ".pb-lg-5"
          },
          {
            "id": ".pb-xl-5",
            "label": ".pb-xl-5",
            "value": ".pb-xl-5"
          },
          {
            "id": ".pb-xxl-5",
            "label": ".pb-xxl-5",
            "value": ".pb-xxl-5"
          },
          {
            "id": ".pe-0",
            "label": ".pe-0",
            "value": ".pe-0"
          },
          {
            "id": ".pe-sm-0",
            "label": ".pe-sm-0",
            "value": ".pe-sm-0"
          },
          {
            "id": ".pe-md-0",
            "label": ".pe-md-0",
            "value": ".pe-md-0"
          },
          {
            "id": ".pe-lg-0",
            "label": ".pe-lg-0",
            "value": ".pe-lg-0"
          },
          {
            "id": ".pe-xl-0",
            "label": ".pe-xl-0",
            "value": ".pe-xl-0"
          },
          {
            "id": ".pe-xxl-0",
            "label": ".pe-xxl-0",
            "value": ".pe-xxl-0"
          },
          {
            "id": ".pe-1",
            "label": ".pe-1",
            "value": ".pe-1"
          },
          {
            "id": ".pe-sm-1",
            "label": ".pe-sm-1",
            "value": ".pe-sm-1"
          },
          {
            "id": ".pe-md-1",
            "label": ".pe-md-1",
            "value": ".pe-md-1"
          },
          {
            "id": ".pe-lg-1",
            "label": ".pe-lg-1",
            "value": ".pe-lg-1"
          },
          {
            "id": ".pe-xl-1",
            "label": ".pe-xl-1",
            "value": ".pe-xl-1"
          },
          {
            "id": ".pe-xxl-1",
            "label": ".pe-xxl-1",
            "value": ".pe-xxl-1"
          },
          {
            "id": ".pe-2",
            "label": ".pe-2",
            "value": ".pe-2"
          },
          {
            "id": ".pe-sm-2",
            "label": ".pe-sm-2",
            "value": ".pe-sm-2"
          },
          {
            "id": ".pe-md-2",
            "label": ".pe-md-2",
            "value": ".pe-md-2"
          },
          {
            "id": ".pe-lg-2",
            "label": ".pe-lg-2",
            "value": ".pe-lg-2"
          },
          {
            "id": ".pe-xl-2",
            "label": ".pe-xl-2",
            "value": ".pe-xl-2"
          },
          {
            "id": ".pe-xxl-2",
            "label": ".pe-xxl-2",
            "value": ".pe-xxl-2"
          },
          {
            "id": ".pe-3",
            "label": ".pe-3",
            "value": ".pe-3"
          },
          {
            "id": ".pe-sm-3",
            "label": ".pe-sm-3",
            "value": ".pe-sm-3"
          },
          {
            "id": ".pe-md-3",
            "label": ".pe-md-3",
            "value": ".pe-md-3"
          },
          {
            "id": ".pe-lg-3",
            "label": ".pe-lg-3",
            "value": ".pe-lg-3"
          },
          {
            "id": ".pe-xl-3",
            "label": ".pe-xl-3",
            "value": ".pe-xl-3"
          },
          {
            "id": ".pe-xxl-3",
            "label": ".pe-xxl-3",
            "value": ".pe-xxl-3"
          },
          {
            "id": ".pe-4",
            "label": ".pe-4",
            "value": ".pe-4"
          },
          {
            "id": ".pe-sm-4",
            "label": ".pe-sm-4",
            "value": ".pe-sm-4"
          },
          {
            "id": ".pe-md-4",
            "label": ".pe-md-4",
            "value": ".pe-md-4"
          },
          {
            "id": ".pe-lg-4",
            "label": ".pe-lg-4",
            "value": ".pe-lg-4"
          },
          {
            "id": ".pe-xl-4",
            "label": ".pe-xl-4",
            "value": ".pe-xl-4"
          },
          {
            "id": ".pe-xxl-4",
            "label": ".pe-xxl-4",
            "value": ".pe-xxl-4"
          },
          {
            "id": ".pe-5",
            "label": ".pe-5",
            "value": ".pe-5"
          },
          {
            "id": ".pe-sm-5",
            "label": ".pe-sm-5",
            "value": ".pe-sm-5"
          },
          {
            "id": ".pe-md-5",
            "label": ".pe-md-5",
            "value": ".pe-md-5"
          },
          {
            "id": ".pe-lg-5",
            "label": ".pe-lg-5",
            "value": ".pe-lg-5"
          },
          {
            "id": ".pe-xl-5",
            "label": ".pe-xl-5",
            "value": ".pe-xl-5"
          },
          {
            "id": ".pe-xxl-5",
            "label": ".pe-xxl-5",
            "value": ".pe-xxl-5"
          },
          {
            "id": ".ps-0",
            "label": ".ps-0",
            "value": ".ps-0"
          },
          {
            "id": ".ps-sm-0",
            "label": ".ps-sm-0",
            "value": ".ps-sm-0"
          },
          {
            "id": ".ps-md-0",
            "label": ".ps-md-0",
            "value": ".ps-md-0"
          },
          {
            "id": ".ps-lg-0",
            "label": ".ps-lg-0",
            "value": ".ps-lg-0"
          },
          {
            "id": ".ps-xl-0",
            "label": ".ps-xl-0",
            "value": ".ps-xl-0"
          },
          {
            "id": ".ps-xxl-0",
            "label": ".ps-xxl-0",
            "value": ".ps-xxl-0"
          },
          {
            "id": ".ps-1",
            "label": ".ps-1",
            "value": ".ps-1"
          },
          {
            "id": ".ps-sm-1",
            "label": ".ps-sm-1",
            "value": ".ps-sm-1"
          },
          {
            "id": ".ps-md-1",
            "label": ".ps-md-1",
            "value": ".ps-md-1"
          },
          {
            "id": ".ps-lg-1",
            "label": ".ps-lg-1",
            "value": ".ps-lg-1"
          },
          {
            "id": ".ps-xl-1",
            "label": ".ps-xl-1",
            "value": ".ps-xl-1"
          },
          {
            "id": ".ps-xxl-1",
            "label": ".ps-xxl-1",
            "value": ".ps-xxl-1"
          },
          {
            "id": ".ps-2",
            "label": ".ps-2",
            "value": ".ps-2"
          },
          {
            "id": ".ps-sm-2",
            "label": ".ps-sm-2",
            "value": ".ps-sm-2"
          },
          {
            "id": ".ps-md-2",
            "label": ".ps-md-2",
            "value": ".ps-md-2"
          },
          {
            "id": ".ps-lg-2",
            "label": ".ps-lg-2",
            "value": ".ps-lg-2"
          },
          {
            "id": ".ps-xl-2",
            "label": ".ps-xl-2",
            "value": ".ps-xl-2"
          },
          {
            "id": ".ps-xxl-2",
            "label": ".ps-xxl-2",
            "value": ".ps-xxl-2"
          },
          {
            "id": ".ps-3",
            "label": ".ps-3",
            "value": ".ps-3"
          },
          {
            "id": ".ps-sm-3",
            "label": ".ps-sm-3",
            "value": ".ps-sm-3"
          },
          {
            "id": ".ps-md-3",
            "label": ".ps-md-3",
            "value": ".ps-md-3"
          },
          {
            "id": ".ps-lg-3",
            "label": ".ps-lg-3",
            "value": ".ps-lg-3"
          },
          {
            "id": ".ps-xl-3",
            "label": ".ps-xl-3",
            "value": ".ps-xl-3"
          },
          {
            "id": ".ps-xxl-3",
            "label": ".ps-xxl-3",
            "value": ".ps-xxl-3"
          },
          {
            "id": ".ps-4",
            "label": ".ps-4",
            "value": ".ps-4"
          },
          {
            "id": ".ps-sm-4",
            "label": ".ps-sm-4",
            "value": ".ps-sm-4"
          },
          {
            "id": ".ps-md-4",
            "label": ".ps-md-4",
            "value": ".ps-md-4"
          },
          {
            "id": ".ps-lg-4",
            "label": ".ps-lg-4",
            "value": ".ps-lg-4"
          },
          {
            "id": ".ps-xl-4",
            "label": ".ps-xl-4",
            "value": ".ps-xl-4"
          },
          {
            "id": ".ps-xxl-4",
            "label": ".ps-xxl-4",
            "value": ".ps-xxl-4"
          },
          {
            "id": ".ps-5",
            "label": ".ps-5",
            "value": ".ps-5"
          },
          {
            "id": ".ps-sm-5",
            "label": ".ps-sm-5",
            "value": ".ps-sm-5"
          },
          {
            "id": ".ps-md-5",
            "label": ".ps-md-5",
            "value": ".ps-md-5"
          },
          {
            "id": ".ps-lg-5",
            "label": ".ps-lg-5",
            "value": ".ps-lg-5"
          },
          {
            "id": ".ps-xl-5",
            "label": ".ps-xl-5",
            "value": ".ps-xl-5"
          },
          {
            "id": ".ps-xxl-5",
            "label": ".ps-xxl-5",
            "value": ".ps-xxl-5"
          },
          {
            "id": ".pt-0",
            "label": ".pt-0",
            "value": ".pt-0"
          },
          {
            "id": ".pt-sm-0",
            "label": ".pt-sm-0",
            "value": ".pt-sm-0"
          },
          {
            "id": ".pt-md-0",
            "label": ".pt-md-0",
            "value": ".pt-md-0"
          },
          {
            "id": ".pt-lg-0",
            "label": ".pt-lg-0",
            "value": ".pt-lg-0"
          },
          {
            "id": ".pt-xl-0",
            "label": ".pt-xl-0",
            "value": ".pt-xl-0"
          },
          {
            "id": ".pt-xxl-0",
            "label": ".pt-xxl-0",
            "value": ".pt-xxl-0"
          },
          {
            "id": ".pt-1",
            "label": ".pt-1",
            "value": ".pt-1"
          },
          {
            "id": ".pt-sm-1",
            "label": ".pt-sm-1",
            "value": ".pt-sm-1"
          },
          {
            "id": ".pt-md-1",
            "label": ".pt-md-1",
            "value": ".pt-md-1"
          },
          {
            "id": ".pt-lg-1",
            "label": ".pt-lg-1",
            "value": ".pt-lg-1"
          },
          {
            "id": ".pt-xl-1",
            "label": ".pt-xl-1",
            "value": ".pt-xl-1"
          },
          {
            "id": ".pt-xxl-1",
            "label": ".pt-xxl-1",
            "value": ".pt-xxl-1"
          },
          {
            "id": ".pt-2",
            "label": ".pt-2",
            "value": ".pt-2"
          },
          {
            "id": ".pt-sm-2",
            "label": ".pt-sm-2",
            "value": ".pt-sm-2"
          },
          {
            "id": ".pt-md-2",
            "label": ".pt-md-2",
            "value": ".pt-md-2"
          },
          {
            "id": ".pt-lg-2",
            "label": ".pt-lg-2",
            "value": ".pt-lg-2"
          },
          {
            "id": ".pt-xl-2",
            "label": ".pt-xl-2",
            "value": ".pt-xl-2"
          },
          {
            "id": ".pt-xxl-2",
            "label": ".pt-xxl-2",
            "value": ".pt-xxl-2"
          },
          {
            "id": ".pt-3",
            "label": ".pt-3",
            "value": ".pt-3"
          },
          {
            "id": ".pt-sm-3",
            "label": ".pt-sm-3",
            "value": ".pt-sm-3"
          },
          {
            "id": ".pt-md-3",
            "label": ".pt-md-3",
            "value": ".pt-md-3"
          },
          {
            "id": ".pt-lg-3",
            "label": ".pt-lg-3",
            "value": ".pt-lg-3"
          },
          {
            "id": ".pt-xl-3",
            "label": ".pt-xl-3",
            "value": ".pt-xl-3"
          },
          {
            "id": ".pt-xxl-3",
            "label": ".pt-xxl-3",
            "value": ".pt-xxl-3"
          },
          {
            "id": ".pt-4",
            "label": ".pt-4",
            "value": ".pt-4"
          },
          {
            "id": ".pt-sm-4",
            "label": ".pt-sm-4",
            "value": ".pt-sm-4"
          },
          {
            "id": ".pt-md-4",
            "label": ".pt-md-4",
            "value": ".pt-md-4"
          },
          {
            "id": ".pt-lg-4",
            "label": ".pt-lg-4",
            "value": ".pt-lg-4"
          },
          {
            "id": ".pt-xl-4",
            "label": ".pt-xl-4",
            "value": ".pt-xl-4"
          },
          {
            "id": ".pt-xxl-4",
            "label": ".pt-xxl-4",
            "value": ".pt-xxl-4"
          },
          {
            "id": ".pt-5",
            "label": ".pt-5",
            "value": ".pt-5"
          },
          {
            "id": ".pt-sm-5",
            "label": ".pt-sm-5",
            "value": ".pt-sm-5"
          },
          {
            "id": ".pt-md-5",
            "label": ".pt-md-5",
            "value": ".pt-md-5"
          },
          {
            "id": ".pt-lg-5",
            "label": ".pt-lg-5",
            "value": ".pt-lg-5"
          },
          {
            "id": ".pt-xl-5",
            "label": ".pt-xl-5",
            "value": ".pt-xl-5"
          },
          {
            "id": ".pt-xxl-5",
            "label": ".pt-xxl-5",
            "value": ".pt-xxl-5"
          },
          {
            "id": ".px-0",
            "label": ".px-0",
            "value": ".px-0"
          },
          {
            "id": ".px-sm-0",
            "label": ".px-sm-0",
            "value": ".px-sm-0"
          },
          {
            "id": ".px-md-0",
            "label": ".px-md-0",
            "value": ".px-md-0"
          },
          {
            "id": ".px-lg-0",
            "label": ".px-lg-0",
            "value": ".px-lg-0"
          },
          {
            "id": ".px-xl-0",
            "label": ".px-xl-0",
            "value": ".px-xl-0"
          },
          {
            "id": ".px-xxl-0",
            "label": ".px-xxl-0",
            "value": ".px-xxl-0"
          },
          {
            "id": ".px-1",
            "label": ".px-1",
            "value": ".px-1"
          },
          {
            "id": ".px-sm-1",
            "label": ".px-sm-1",
            "value": ".px-sm-1"
          },
          {
            "id": ".px-md-1",
            "label": ".px-md-1",
            "value": ".px-md-1"
          },
          {
            "id": ".px-lg-1",
            "label": ".px-lg-1",
            "value": ".px-lg-1"
          },
          {
            "id": ".px-xl-1",
            "label": ".px-xl-1",
            "value": ".px-xl-1"
          },
          {
            "id": ".px-xxl-1",
            "label": ".px-xxl-1",
            "value": ".px-xxl-1"
          },
          {
            "id": ".px-2",
            "label": ".px-2",
            "value": ".px-2"
          },
          {
            "id": ".px-sm-2",
            "label": ".px-sm-2",
            "value": ".px-sm-2"
          },
          {
            "id": ".px-md-2",
            "label": ".px-md-2",
            "value": ".px-md-2"
          },
          {
            "id": ".px-lg-2",
            "label": ".px-lg-2",
            "value": ".px-lg-2"
          },
          {
            "id": ".px-xl-2",
            "label": ".px-xl-2",
            "value": ".px-xl-2"
          },
          {
            "id": ".px-xxl-2",
            "label": ".px-xxl-2",
            "value": ".px-xxl-2"
          },
          {
            "id": ".px-3",
            "label": ".px-3",
            "value": ".px-3"
          },
          {
            "id": ".px-sm-3",
            "label": ".px-sm-3",
            "value": ".px-sm-3"
          },
          {
            "id": ".px-md-3",
            "label": ".px-md-3",
            "value": ".px-md-3"
          },
          {
            "id": ".px-lg-3",
            "label": ".px-lg-3",
            "value": ".px-lg-3"
          },
          {
            "id": ".px-xl-3",
            "label": ".px-xl-3",
            "value": ".px-xl-3"
          },
          {
            "id": ".px-xxl-3",
            "label": ".px-xxl-3",
            "value": ".px-xxl-3"
          },
          {
            "id": ".px-4",
            "label": ".px-4",
            "value": ".px-4"
          },
          {
            "id": ".px-sm-4",
            "label": ".px-sm-4",
            "value": ".px-sm-4"
          },
          {
            "id": ".px-md-4",
            "label": ".px-md-4",
            "value": ".px-md-4"
          },
          {
            "id": ".px-lg-4",
            "label": ".px-lg-4",
            "value": ".px-lg-4"
          },
          {
            "id": ".px-xl-4",
            "label": ".px-xl-4",
            "value": ".px-xl-4"
          },
          {
            "id": ".px-xxl-4",
            "label": ".px-xxl-4",
            "value": ".px-xxl-4"
          },
          {
            "id": ".px-5",
            "label": ".px-5",
            "value": ".px-5"
          },
          {
            "id": ".px-sm-5",
            "label": ".px-sm-5",
            "value": ".px-sm-5"
          },
          {
            "id": ".px-md-5",
            "label": ".px-md-5",
            "value": ".px-md-5"
          },
          {
            "id": ".px-lg-5",
            "label": ".px-lg-5",
            "value": ".px-lg-5"
          },
          {
            "id": ".px-xl-5",
            "label": ".px-xl-5",
            "value": ".px-xl-5"
          },
          {
            "id": ".px-xxl-5",
            "label": ".px-xxl-5",
            "value": ".px-xxl-5"
          },
          {
            "id": ".py-0",
            "label": ".py-0",
            "value": ".py-0"
          },
          {
            "id": ".py-sm-0",
            "label": ".py-sm-0",
            "value": ".py-sm-0"
          },
          {
            "id": ".py-md-0",
            "label": ".py-md-0",
            "value": ".py-md-0"
          },
          {
            "id": ".py-lg-0",
            "label": ".py-lg-0",
            "value": ".py-lg-0"
          },
          {
            "id": ".py-xl-0",
            "label": ".py-xl-0",
            "value": ".py-xl-0"
          },
          {
            "id": ".py-xxl-0",
            "label": ".py-xxl-0",
            "value": ".py-xxl-0"
          },
          {
            "id": ".py-1",
            "label": ".py-1",
            "value": ".py-1"
          },
          {
            "id": ".py-sm-1",
            "label": ".py-sm-1",
            "value": ".py-sm-1"
          },
          {
            "id": ".py-md-1",
            "label": ".py-md-1",
            "value": ".py-md-1"
          },
          {
            "id": ".py-lg-1",
            "label": ".py-lg-1",
            "value": ".py-lg-1"
          },
          {
            "id": ".py-xl-1",
            "label": ".py-xl-1",
            "value": ".py-xl-1"
          },
          {
            "id": ".py-xxl-1",
            "label": ".py-xxl-1",
            "value": ".py-xxl-1"
          },
          {
            "id": ".py-2",
            "label": ".py-2",
            "value": ".py-2"
          },
          {
            "id": ".py-sm-2",
            "label": ".py-sm-2",
            "value": ".py-sm-2"
          },
          {
            "id": ".py-md-2",
            "label": ".py-md-2",
            "value": ".py-md-2"
          },
          {
            "id": ".py-lg-2",
            "label": ".py-lg-2",
            "value": ".py-lg-2"
          },
          {
            "id": ".py-xl-2",
            "label": ".py-xl-2",
            "value": ".py-xl-2"
          },
          {
            "id": ".py-xxl-2",
            "label": ".py-xxl-2",
            "value": ".py-xxl-2"
          },
          {
            "id": ".py-3",
            "label": ".py-3",
            "value": ".py-3"
          },
          {
            "id": ".py-sm-3",
            "label": ".py-sm-3",
            "value": ".py-sm-3"
          },
          {
            "id": ".py-md-3",
            "label": ".py-md-3",
            "value": ".py-md-3"
          },
          {
            "id": ".py-lg-3",
            "label": ".py-lg-3",
            "value": ".py-lg-3"
          },
          {
            "id": ".py-xl-3",
            "label": ".py-xl-3",
            "value": ".py-xl-3"
          },
          {
            "id": ".py-xxl-3",
            "label": ".py-xxl-3",
            "value": ".py-xxl-3"
          },
          {
            "id": ".py-4",
            "label": ".py-4",
            "value": ".py-4"
          },
          {
            "id": ".py-sm-4",
            "label": ".py-sm-4",
            "value": ".py-sm-4"
          },
          {
            "id": ".py-md-4",
            "label": ".py-md-4",
            "value": ".py-md-4"
          },
          {
            "id": ".py-lg-4",
            "label": ".py-lg-4",
            "value": ".py-lg-4"
          },
          {
            "id": ".py-xl-4",
            "label": ".py-xl-4",
            "value": ".py-xl-4"
          },
          {
            "id": ".py-xxl-4",
            "label": ".py-xxl-4",
            "value": ".py-xxl-4"
          },
          {
            "id": ".py-5",
            "label": ".py-5",
            "value": ".py-5"
          },
          {
            "id": ".py-sm-5",
            "label": ".py-sm-5",
            "value": ".py-sm-5"
          },
          {
            "id": ".py-md-5",
            "label": ".py-md-5",
            "value": ".py-md-5"
          },
          {
            "id": ".py-lg-5",
            "label": ".py-lg-5",
            "value": ".py-lg-5"
          },
          {
            "id": ".py-xl-5",
            "label": ".py-xl-5",
            "value": ".py-xl-5"
          },
          {
            "id": ".py-xxl-5",
            "label": ".py-xxl-5",
            "value": ".py-xxl-5"
          }
        ]
      },
      {
        "label":"Gutter Spacing",
        "options":[
          {
            "id": ".g-0",
            "label": ".g-0",
            "value": ".g-0"
          },
          {
            "id": ".g-sm-0",
            "label": ".g-sm-0",
            "value": ".g-sm-0"
          },
           {
            "id": ".g-md-0",
            "label": ".g-md-0",
            "value": ".g-md-0"
          },
           {
            "id": ".g-lg-0",
            "label": ".g-lg-0",
            "value": ".g-lg-0"
          },
           {
            "id": ".g-xl-0",
            "label": ".g-xl-0",
            "value": ".g-xl-0"
          },
          {
            "id": ".g-xxl-0",
            "label": ".g-xxl-0",
            "value": ".g-xxl-0"
          },
          {
            "id": ".g-1",
            "label": ".g-1",
            "value": ".g-1"
          },
          {
            "id": ".g-sm-1",
            "label": ".g-sm-1",
            "value": ".g-sm-1"
          },
           {
            "id": ".g-md-1",
            "label": ".g-md-1",
            "value": ".g-md-1"
          },
           {
            "id": ".g-lg-1",
            "label": ".g-lg-1",
            "value": ".g-lg-1"
          },
           {
            "id": ".g-xl-1",
            "label": ".g-xl-1",
            "value": ".g-xl-1"
          },
          {
            "id": ".g-xxl-1",
            "label": ".g-xxl-1",
            "value": ".g-xxl-1"
          },
          {
            "id": ".g-2",
            "label": ".g-2",
            "value": ".g-2"
          },
          {
            "id": ".g-sm-2",
            "label": ".g-sm-2",
            "value": ".g-sm-2"
          },
           {
            "id": ".g-md-2",
            "label": ".g-md-2",
            "value": ".g-md-2"
          },
           {
            "id": ".g-lg-2",
            "label": ".g-lg-2",
            "value": ".g-lg-2"
          },
           {
            "id": ".g-xl-2",
            "label": ".g-xl-2",
            "value": ".g-xl-2"
          },
          {
            "id": ".g-xxl-2",
            "label": ".g-xxl-2",
            "value": ".g-xxl-2"
          },
          {
            "id": ".g-3",
            "label": ".g-3",
            "value": ".g-3"
          },
          {
            "id": ".g-sm-3",
            "label": ".g-sm-3",
            "value": ".g-sm-3"
          },
           {
            "id": ".g-md-3",
            "label": ".g-md-3",
            "value": ".g-md-3"
          },
           {
            "id": ".g-lg-3",
            "label": ".g-lg-3",
            "value": ".g-lg-3"
          },
           {
            "id": ".g-xl-3",
            "label": ".g-xl-3",
            "value": ".g-xl-3"
          },
          {
            "id": ".g-xxl-3",
            "label": ".g-xxl-3",
            "value": ".g-xxl-3"
          },
          {
            "id": ".g-4",
            "label": ".g-4",
            "value": ".g-4"
          },
          {
            "id": ".g-sm-4",
            "label": ".g-sm-4",
            "value": ".g-sm-4"
          },
           {
            "id": ".g-md-4",
            "label": ".g-md-4",
            "value": ".g-md-4"
          },
           {
            "id": ".g-lg-4",
            "label": ".g-lg-4",
            "value": ".g-lg-4"
          },
           {
            "id": ".g-xl-4",
            "label": ".g-xl-4",
            "value": ".g-xl-4"
          },
          {
            "id": ".g-xxl-4",
            "label": ".g-xxl-4",
            "value": ".g-xxl-4"
          },
          {
            "id": ".g-5",
            "label": ".g-5",
            "value": ".g-5"
          },
          {
            "id": ".g-sm-5",
            "label": ".g-sm-5",
            "value": ".g-sm-5"
          },
           {
            "id": ".g-md-5",
            "label": ".g-md-5",
            "value": ".g-md-5"
          },
           {
            "id": ".g-lg-5",
            "label": ".g-lg-5",
            "value": ".g-lg-5"
          },
           {
            "id": ".g-xl-5",
            "label": ".g-xl-5",
            "value": ".g-xl-5"
          },
          {
            "id": ".g-xxl-5",
            "label": ".g-xxl-5",
            "value": ".g-xxl-5"
          },
      
          {
            "id": ".gap-0",
            "label": ".gap-0",
            "value": ".gap-0"
          },
          {
            "id": ".gap-sm-0",
            "label": ".gap-sm-0",
            "value": ".gap-sm-0"
          },
          {
            "id": ".gap-md-0",
            "label": ".gap-md-0",
            "value": ".gap-md-0"
          },
          {
            "id": ".gap-lg-0",
            "label": ".gap-lg-0",
            "value": ".gap-lg-0"
          },
          {
            "id": ".gap-xl-0",
            "label": ".gap-xl-0",
            "value": ".gap-xl-0"
          },
          {
            "id": ".gx-0",
            "label": ".gx-0",
            "value": ".gx-0"
          },
          {
            "id": ".gx-sm-0",
            "label": ".gx-sm-0",
            "value": ".gx-sm-0"
          },
          {
            "id": ".gx-md-0",
            "label": ".gx-md-0",
            "value": ".gx-md-0"
          },
          {
            "id": ".gx-lg-0",
            "label": ".gx-lg-0",
            "value": ".gx-lg-0"
          },
          {
            "id": ".gx-xl-0",
            "label": ".gx-xl-0",
            "value": ".gx-xl-0"
          },
          {
            "id": ".gx-1",
            "label": ".gx-1",
            "value": ".gx-1"
          },
          {
            "id": ".gx-sm-1",
            "label": ".gx-sm-1",
            "value": ".gx-sm-1"
          },
           {
            "id": ".gx-md-1",
            "label": ".gx-md-1",
            "value": ".gx-md-1"
          },
           {
            "id": ".gx-lg-1",
            "label": ".gx-lg-1",
            "value": ".gx-lg-1"
          },
           {
            "id": ".gx-xl-1",
            "label": ".gx-xl-1",
            "value": ".gx-xl-1"
          },
          {
            "id": ".gx-xxl-1",
            "label": ".gx-xxl-1",
            "value": ".gx-xxl-1"
          },
          {
            "id": ".gx-2",
            "label": ".gx-2",
            "value": ".gx-2"
          },
          {
            "id": ".gx-sm-2",
            "label": ".gx-sm-2",
            "value": ".gx-sm-2"
          },
           {
            "id": ".gx-md-2",
            "label": ".gx-md-2",
            "value": ".gx-md-2"
          },
           {
            "id": ".gx-lg-2",
            "label": ".gx-lg-2",
            "value": ".gx-lg-2"
          },
           {
            "id": ".gx-xl-2",
            "label": ".gx-xl-2",
            "value": ".gx-xl-2"
          },
          {
            "id": ".gx-xxl-2",
            "label": ".gx-xxl-2",
            "value": ".gx-xxl-2"
          },
          {
            "id": ".gx-3",
            "label": ".gx-3",
            "value": ".gx-3"
          },
          {
            "id": ".gx-sm-3",
            "label": ".gx-sm-3",
            "value": ".gx-sm-3"
          },
           {
            "id": ".gx-md-3",
            "label": ".gx-md-3",
            "value": ".gx-md-3"
          },
           {
            "id": ".gx-lg-3",
            "label": ".gx-lg-3",
            "value": ".gx-lg-3"
          },
           {
            "id": ".gx-xl-3",
            "label": ".gx-xl-3",
            "value": ".gx-xl-3"
          },
          {
            "id": ".gx-xxl-3",
            "label": ".gx-xxl-3",
            "value": ".gx-xxl-3"
          },
          {
            "id": ".gx-4",
            "label": ".gx-4",
            "value": ".gx-4"
          },
          {
            "id": ".gx-sm-4",
            "label": ".gx-sm-4",
            "value": ".gx-sm-4"
          },
           {
            "id": ".gx-md-4",
            "label": ".gx-md-4",
            "value": ".gx-md-4"
          },
           {
            "id": ".gx-lg-4",
            "label": ".gx-lg-4",
            "value": ".gx-lg-4"
          },
           {
            "id": ".gx-xl-4",
            "label": ".gx-xl-4",
            "value": ".gx-xl-4"
          },
          {
            "id": ".gx-xxl-4",
            "label": ".gx-xxl-4",
            "value": ".gx-xxl-4"
          },
          {
            "id": ".gx-5",
            "label": ".gx-5",
            "value": ".gx-5"
          },
          {
            "id": ".gx-sm-5",
            "label": ".gx-sm-5",
            "value": ".gx-sm-5"
          },
           {
            "id": ".gx-md-5",
            "label": ".gx-md-5",
            "value": ".gx-md-5"
          },
           {
            "id": ".gx-lg-5",
            "label": ".gx-lg-5",
            "value": ".gx-lg-5"
          },
           {
            "id": ".gx-xl-5",
            "label": ".gx-xl-5",
            "value": ".gx-xl-5"
          },
          {
            "id": ".gx-xxl-5",
            "label": ".gx-xxl-5",
            "value": ".gx-xxl-5"
          },
          {
            "id": ".gy-0",
            "label": ".gy-0",
            "value": ".gy-0"
          },
          {
            "id": ".gy-sm-0",
            "label": ".gy-sm-0",
            "value": ".gy-sm-0"
          },
          {
            "id": ".gy-md-0",
            "label": ".gy-md-0",
            "value": ".gy-md-0"
          },
          {
            "id": ".gy-lg-0",
            "label": ".gy-lg-0",
            "value": ".gy-lg-0"
          },
          {
            "id": ".gy-xl-0",
            "label": ".gy-xl-0",
            "value": ".gy-xl-0"
          },
          {
            "id": ".gy-1",
            "label": ".gy-1",
            "value": ".gy-1"
          },
          {
            "id": ".gy-sm-1",
            "label": ".gy-sm-1",
            "value": ".gy-sm-1"
          },
           {
            "id": ".gy-md-1",
            "label": ".gy-md-1",
            "value": ".gy-md-1"
          },
           {
            "id": ".gy-lg-1",
            "label": ".gy-lg-1",
            "value": ".gy-lg-1"
          },
           {
            "id": ".gy-xl-1",
            "label": ".gy-xl-1",
            "value": ".gy-xl-1"
          },
          {
            "id": ".gy-xxl-1",
            "label": ".gy-xxl-1",
            "value": ".gy-xxl-1"
          },
          {
            "id": ".gy-2",
            "label": ".gy-2",
            "value": ".gy-2"
          },
          {
            "id": ".gy-sm-2",
            "label": ".gy-sm-2",
            "value": ".gy-sm-2"
          },
           {
            "id": ".gy-md-2",
            "label": ".gy-md-2",
            "value": ".gy-md-2"
          },
           {
            "id": ".gy-lg-2",
            "label": ".gy-lg-2",
            "value": ".gy-lg-2"
          },
           {
            "id": ".gy-xl-2",
            "label": ".gy-xl-2",
            "value": ".gy-xl-2"
          },
          {
            "id": ".gy-xxl-2",
            "label": ".gy-xxl-2",
            "value": ".gy-xxl-2"
          },
          {
            "id": ".gy-3",
            "label": ".gy-3",
            "value": ".gy-3"
          },
          {
            "id": ".gy-sm-3",
            "label": ".gy-sm-3",
            "value": ".gy-sm-3"
          },
           {
            "id": ".gy-md-3",
            "label": ".gy-md-3",
            "value": ".gy-md-3"
          },
           {
            "id": ".gy-lg-3",
            "label": ".gy-lg-3",
            "value": ".gy-lg-3"
          },
           {
            "id": ".gy-xl-3",
            "label": ".gy-xl-3",
            "value": ".gy-xl-3"
          },
          {
            "id": ".gy-xxl-3",
            "label": ".gy-xxl-3",
            "value": ".gy-xxl-3"
          },
          {
            "id": ".gy-4",
            "label": ".gy-4",
            "value": ".gy-4"
          },
          {
            "id": ".gy-sm-4",
            "label": ".gy-sm-4",
            "value": ".gy-sm-4"
          },
           {
            "id": ".gy-md-4",
            "label": ".gy-md-4",
            "value": ".gy-md-4"
          },
           {
            "id": ".gy-lg-4",
            "label": ".gy-lg-4",
            "value": ".gy-lg-4"
          },
           {
            "id": ".gy-xl-4",
            "label": ".gy-xl-4",
            "value": ".gy-xl-4"
          },
          {
            "id": ".gy-xxl-4",
            "label": ".gy-xxl-4",
            "value": ".gy-xxl-4"
          },
          {
            "id": ".gy-5",
            "label": ".gy-5",
            "value": ".gy-5"
          },
          {
            "id": ".gy-sm-5",
            "label": ".gy-sm-5",
            "value": ".gy-sm-5"
          },
           {
            "id": ".gy-md-5",
            "label": ".gy-md-5",
            "value": ".gy-md-5"
          },
           {
            "id": ".gy-lg-5",
            "label": ".gy-lg-5",
            "value": ".gy-lg-5"
          },
           {
            "id": ".gy-xl-5",
            "label": ".gy-xl-5",
            "value": ".gy-xl-5"
          },
          {
            "id": ".gy-xxl-5",
            "label": ".gy-xxl-5",
            "value": ".gy-xxl-5"
          },
          {
            "id": ".no-gutters",
            "label": ".no-gutters",
            "value": ".no-gutters"
          }
        ]
      }
    ]
  },
  {
    "category":"Grid",
    "items":[
      {
        "label":"Grid",
        "options":[
          {
            "id": ".col",
            "label": ".col",
            "value": ".col"
          },
          {
            "id": ".col-sm",
            "label": ".col-sm",
            "value": ".col-sm"
          },
          {
            "id": ".col-md",
            "label": ".col-md",
            "value": ".col-md"
          },
          {
            "id": ".col-lg",
            "label": ".col-lg",
            "value": ".col-lg"
          },
          {
            "id": ".col-xl",
            "label": ".col-xl",
            "value": ".col-xl"
          },
          {
            "id": ".col-1",
            "label": ".col-1",
            "value": ".col-1"
          },
          {
            "id": ".col-sm-1",
            "label": ".col-sm-1",
            "value": ".col-sm-1"
          },
          {
            "id": ".col-md-1",
            "label": ".col-md-1",
            "value": ".col-md-1"
          },
          {
            "id": ".col-lg-1",
            "label": ".col-lg-1",
            "value": ".col-lg-1"
          },
          {
            "id": ".col-xl-1",
            "label": ".col-xl-1",
            "value": ".col-xl-1"
          },
           {
            "id": ".col-xxl-1",
            "label": ".col-xxl-1",
            "value": ".col-xxl-1"
          },
          {
            "id": ".col-2",
            "label": ".col-2",
            "value": ".col-2"
          },
          {
            "id": ".col-sm-2",
            "label": ".col-sm-2",
            "value": ".col-sm-2"
          },
          {
            "id": ".col-md-2",
            "label": ".col-md-2",
            "value": ".col-md-2"
          },
          {
            "id": ".col-lg-2",
            "label": ".col-lg-2",
            "value": ".col-lg-2"
          },
          {
            "id": ".col-xl-2",
            "label": ".col-xl-2",
            "value": ".col-xl-2"
          },
          {
            "id": ".col-xxl-2",
            "label": ".col-xxl-2",
            "value": ".col-xxl-2"
          },
          {
            "id": ".col-3",
            "label": ".col-3",
            "value": ".col-3"
          },
          {
            "id": ".col-sm-3",
            "label": ".col-sm-3",
            "value": ".col-sm-3"
          },
          {
            "id": ".col-md-3",
            "label": ".col-md-3",
            "value": ".col-md-3"
          },
          {
            "id": ".col-lg-3",
            "label": ".col-lg-3",
            "value": ".col-lg-3"
          },
          {
            "id": ".col-xl-3",
            "label": ".col-xl-3",
            "value": ".col-xl-3"
          },
          {
            "id": ".col-xxl-3",
            "label": ".col-xxl-3",
            "value": ".col-xxl-3"
          },
          {
            "id": ".col-4",
            "label": ".col-4",
            "value": ".col-4"
          },
          {
            "id": ".col-sm-4",
            "label": ".col-sm-4",
            "value": ".col-sm-4"
          },
          {
            "id": ".col-md-4",
            "label": ".col-md-4",
            "value": ".col-md-4"
          },
          {
            "id": ".col-lg-4",
            "label": ".col-lg-4",
            "value": ".col-lg-4"
          },
          {
            "id": ".col-xl-4",
            "label": ".col-xl-4",
            "value": ".col-xl-4"
          },
          {
            "id": ".col-xxl-4",
            "label": ".col-xxl-4",
            "value": ".col-xxl-4"
          },
          {
            "id": ".col-5",
            "label": ".col-5",
            "value": ".col-5"
          },
          {
            "id": ".col-sm-5",
            "label": ".col-sm-5",
            "value": ".col-sm-5"
          },
          {
            "id": ".col-md-5",
            "label": ".col-md-5",
            "value": ".col-md-5"
          },
          {
            "id": ".col-lg-5",
            "label": ".col-lg-5",
            "value": ".col-lg-5"
          },
          {
            "id": ".col-xl-5",
            "label": ".col-xl-5",
            "value": ".col-xl-5"
          },
          {
            "id": ".col-xxl-5",
            "label": ".col-xxl-5",
            "value": ".col-xxl-5"
          },
          {
            "id": ".col-6",
            "label": ".col-6",
            "value": ".col-6"
          },
          {
            "id": ".col-sm-6",
            "label": ".col-sm-6",
            "value": ".col-sm-6"
          },
          {
            "id": ".col-md-6",
            "label": ".col-md-6",
            "value": ".col-md-6"
          },
          {
            "id": ".col-lg-6",
            "label": ".col-lg-6",
            "value": ".col-lg-6"
          },
          {
            "id": ".col-xl-6",
            "label": ".col-xl-6",
            "value": ".col-xl-6"
          },
          {
            "id": ".col-xxl-6",
            "label": ".col-xxl-6",
            "value": ".col-xxl-6"
          },
          {
            "id": ".col-7",
            "label": ".col-7",
            "value": ".col-7"
          },
          {
            "id": ".col-sm-7",
            "label": ".col-sm-7",
            "value": ".col-sm-7"
          },
          {
            "id": ".col-md-7",
            "label": ".col-md-7",
            "value": ".col-md-7"
          },
          {
            "id": ".col-lg-7",
            "label": ".col-lg-7",
            "value": ".col-lg-7"
          },
          {
            "id": ".col-xl-7",
            "label": ".col-xl-7",
            "value": ".col-xl-7"
          },
          {
            "id": ".col-xxl-7",
            "label": ".col-xxl-7",
            "value": ".col-xxl-7"
          },
          {
            "id": ".col-8",
            "label": ".col-8",
            "value": ".col-8"
          },
          {
            "id": ".col-sm-8",
            "label": ".col-sm-8",
            "value": ".col-sm-8"
          },
          {
            "id": ".col-md-8",
            "label": ".col-md-8",
            "value": ".col-md-8"
          },
          {
            "id": ".col-lg-8",
            "label": ".col-lg-8",
            "value": ".col-lg-8"
          },
          {
            "id": ".col-xl-8",
            "label": ".col-xl-8",
            "value": ".col-xl-8"
          },
          {
            "id": ".col-xxl-8",
            "label": ".col-xxl-8",
            "value": ".col-xxl-8"
          },
          {
            "id": ".col-9",
            "label": ".col-9",
            "value": ".col-9"
          },
          {
            "id": ".col-sm-9",
            "label": ".col-sm-9",
            "value": ".col-sm-9"
          },
          {
            "id": ".col-md-9",
            "label": ".col-md-9",
            "value": ".col-md-9"
          },
          {
            "id": ".col-lg-9",
            "label": ".col-lg-9",
            "value": ".col-lg-9"
          },
          {
            "id": ".col-xl-9",
            "label": ".col-xl-9",
            "value": ".col-xl-9"
          },
          {
            "id": ".col-xxl-9",
            "label": ".col-xxl-9",
            "value": ".col-xxl-9"
          },
          {
            "id": ".col-10",
            "label": ".col-10",
            "value": ".col-10"
          },
          {
            "id": ".col-sm-10",
            "label": ".col-sm-10",
            "value": ".col-sm-10"
          },
          {
            "id": ".col-md-10",
            "label": ".col-md-10",
            "value": ".col-md-10"
          },
          {
            "id": ".col-lg-10",
            "label": ".col-lg-10",
            "value": ".col-lg-10"
          },
          {
            "id": ".col-xl-10",
            "label": ".col-xl-10",
            "value": ".col-xl-10"
          },
          {
            "id": ".col-xxl-10",
            "label": ".col-xxl-10",
            "value": ".col-xxl-10"
          },
          {
            "id": ".col-11",
            "label": ".col-11",
            "value": ".col-11"
          },
          {
            "id": ".col-sm-11",
            "label": ".col-sm-11",
            "value": ".col-sm-11"
          },
          {
            "id": ".col-md-11",
            "label": ".col-md-11",
            "value": ".col-md-11"
          },
          {
            "id": ".col-lg-11",
            "label": ".col-lg-11",
            "value": ".col-lg-11"
          },
          {
            "id": ".col-xl-11",
            "label": ".col-xl-11",
            "value": ".col-xl-11"
          },
          {
            "id": ".col-xxl-11",
            "label": ".col-xxl-11",
            "value": ".col-xxl-11"
          },
          {
            "id": ".col-12",
            "label": ".col-12",
            "value": ".col-12"
          },
          {
            "id": ".col-sm-12",
            "label": ".col-sm-12",
            "value": ".col-sm-12"
          },
          {
            "id": ".col-md-12",
            "label": ".col-md-12",
            "value": ".col-md-12"
          },
          {
            "id": ".col-lg-12",
            "label": ".col-lg-12",
            "value": ".col-lg-12"
          },
          {
            "id": ".col-xl-12",
            "label": ".col-xl-12",
            "value": ".col-xl-12"
          },
          {
            "id": ".col-xxl-12",
            "label": ".col-xxl-12",
            "value": ".col-xxl-12"
          },
          {
            "id": ".col-auto",
            "label": ".col-auto",
            "value": ".col-auto"
          },
          {
            "id": ".row",
            "label": ".row",
            "value": ".row"
          },
          {
            "id": ".row-cols-auto",
            "label": ".row-cols-auto",
            "value": ".row-cols-auto"
          },
          {
            "id": ".row-cols-sm-auto",
            "label": ".row-cols-sm-auto",
            "value": ".row-cols-sm-auto"
          },
          {
            "id": ".row-cols-md-auto",
            "label": ".row-cols-md-auto",
            "value": ".row-cols-md-auto"
          },
          {
            "id": ".row-cols-lg-auto",
            "label": ".row-cols-lg-auto",
            "value": ".row-cols-lg-auto"
          },
          {
            "id": ".row-cols-xl-auto",
            "label": ".row-cols-xl-auto",
            "value": ".row-cols-xl-auto"
          },
          {
            "id": ".row-cols-xxl-auto",
            "label": ".row-cols-xxl-auto",
            "value": ".row-cols-xxl-auto"
          },
          {
            "id": ".row-cols-1",
            "label": ".row-cols-1",
            "value": ".row-cols-1"
          },
          {
            "id": ".row-cols-sm-1",
            "label": ".row-cols-sm-1",
            "value": ".row-cols-sm-1"
          },
          {
            "id": ".row-cols-md-1",
            "label": ".row-cols-md-1",
            "value": ".row-cols-md-1"
          },
          {
            "id": ".row-cols-lg-1",
            "label": ".row-cols-lg-1",
            "value": ".row-cols-lg-1"
          },
          {
            "id": ".row-cols-xl-1",
            "label": ".row-cols-xl-1",
            "value": ".row-cols-xl-1"
          },
          {
            "id": ".row-cols-xxl-1",
            "label": ".row-cols-xxl-1",
            "value": ".row-cols-xxl-1"
          },
          {
            "id": ".row-cols-2",
            "label": ".row-cols-2",
            "value": ".row-cols-2"
          },
          {
            "id": ".row-cols-sm-2",
            "label": ".row-cols-sm-2",
            "value": ".row-cols-sm-2"
          },
          {
            "id": ".row-cols-md-2",
            "label": ".row-cols-md-2",
            "value": ".row-cols-md-2"
          },
          {
            "id": ".row-cols-lg-2",
            "label": ".row-cols-lg-2",
            "value": ".row-cols-lg-2"
          },
          {
            "id": ".row-cols-xl-2",
            "label": ".row-cols-xl-2",
            "value": ".row-cols-xl-2"
          },
          {
            "id": ".row-cols-xxl-2",
            "label": ".row-cols-xxl-2",
            "value": ".row-cols-xxl-2"
          },
          {
            "id": ".row-cols-3",
            "label": ".row-cols-3",
            "value": ".row-cols-3"
          },
          {
            "id": ".row-cols-sm-3",
            "label": ".row-cols-sm-3",
            "value": ".row-cols-sm-3"
          },
          {
            "id": ".row-cols-md-3",
            "label": ".row-cols-md-3",
            "value": ".row-cols-md-3"
          },
          {
            "id": ".row-cols-lg-3",
            "label": ".row-cols-lg-3",
            "value": ".row-cols-lg-3"
          },
          {
            "id": ".row-cols-xl-3",
            "label": ".row-cols-xl-3",
            "value": ".row-cols-xl-3"
          },
          {
            "id": ".row-cols-xxl-3",
            "label": ".row-cols-xxl-3",
            "value": ".row-cols-xxl-3"
          },
          {
            "id": ".row-cols-4",
            "label": ".row-cols-4",
            "value": ".row-cols-4"
          },
          {
            "id": ".row-cols-sm-4",
            "label": ".row-cols-sm-4",
            "value": ".row-cols-sm-4"
          },
          {
            "id": ".row-cols-md-4",
            "label": ".row-cols-md-4",
            "value": ".row-cols-md-4"
          },
          {
            "id": ".row-cols-lg-4",
            "label": ".row-cols-lg-4",
            "value": ".row-cols-lg-4"
          },
          {
            "id": ".row-cols-xl-4",
            "label": ".row-cols-xl-4",
            "value": ".row-cols-xl-4"
          },
          {
            "id": ".row-cols-xxl-4",
            "label": ".row-cols-xxl-4",
            "value": ".row-cols-xxl-4"
          },
          {
            "id": ".row-cols-5",
            "label": ".row-cols-5",
            "value": ".row-cols-5"
          },
          {
            "id": ".row-cols-sm-5",
            "label": ".row-cols-sm-5",
            "value": ".row-cols-sm-5"
          },
          {
            "id": ".row-cols-md-5",
            "label": ".row-cols-md-5",
            "value": ".row-cols-md-5"
          },
          {
            "id": ".row-cols-lg-5",
            "label": ".row-cols-lg-5",
            "value": ".row-cols-lg-5"
          },
          {
            "id": ".row-cols-xl-5",
            "label": ".row-cols-xl-5",
            "value": ".row-cols-xl-5"
          },
          {
            "id": ".row-cols-xxl-5",
            "label": ".row-cols-xxl-5",
            "value": ".row-cols-xxl-5"
          },
          {
            "id": ".row-cols-6",
            "label": ".row-cols-6",
            "value": ".row-cols-6"
          },
          {
            "id": ".row-cols-sm-6",
            "label": ".row-cols-sm-6",
            "value": ".row-cols-sm-6"
          },
          {
            "id": ".row-cols-md-6",
            "label": ".row-cols-md-6",
            "value": ".row-cols-md-6"
          },
          {
            "id": ".row-cols-lg-6",
            "label": ".row-cols-lg-6",
            "value": ".row-cols-lg-6"
          },
          {
            "id": ".row-cols-xl-6",
            "label": ".row-cols-xl-6",
            "value": ".row-cols-xl-6"
          },
          {
            "id": ".row-cols-xxl-6",
            "label": ".row-cols-xxl-6",
            "value": ".row-cols-xxl-6"
          },
          {
            "id": "container",
            "label": "container",
            "value": ".container"
          },
          {
            "id": "container-sm",
            "label": "container-sm",
            "value": ".container-sm"
          },
          {
            "id": "container-md",
            "label": "container-md",
            "value": ".container-md"
          },
          {
            "id": "container-lg",
            "label": "container-lg",
            "value": ".container-lg"
          },
          {
            "id": "container-xl",
            "label": "container-xl",
            "value": ".container-xl"
          },
          {
            "id": "container-xxl",
            "label": "container-xxl",
            "value": ".container-xxl"
          },
          {
            "id": "container-fluid",
            "label": "container-fluid",
            "value": ".container-fluid"
          }
        ]
      }
    ]
  },
  {
    "category":"Helpers",
    "items":[
      {
        "label": "Borders",
        "options":[
          {
            "id": ".border",
            "label": ".border",
            "value": ".border"
          },
          {
            "id": ".border-top",
            "label": ".border-top",
            "value": ".border-top"
          },
          {
            "id": ".border-end",
            "label": ".border-end",
            "value": ".border-end"
          },
          {
            "id": ".border-bottom",
            "label": ".border-bottom",
            "value": ".border-bottom"
          },
          {
            "id": ".border-start",
            "label": ".border-start",
            "value": ".border-start"
          },
          {
            "id": ".border-0",
            "label": ".border-0",
            "value": ".border-0"
          },
          {
            "id": ".border-top-0",
            "label": ".border-top-0",
            "value": ".border-top-0"
          },
          {
            "id": ".border-end-0",
            "label": ".border-end-0",
            "value": ".border-end-0"
          },
          {
            "id": ".border-bottom-0",
            "label": ".border-bottom-0",
            "value": ".border-bottom-0"
          },
          {
            "id": ".border-start-0",
            "label": ".border-start-0",
            "value": ".border-start-0"
          },
          {
            "id": ".border-1",
            "label": ".border-1",
            "value": ".border-1"
          },
          {
            "id": ".border-2",
            "label": ".border-2",
            "value": ".border-2"
          },
          {
            "id": ".border-3",
            "label": ".border-3",
            "value": ".border-3"
          },
          {
            "id": ".border-4",
            "label": ".border-4",
            "value": ".border-4"
          },
          {
            "id": ".border-5",
            "label": ".border-5",
            "value": ".border-5"
          },
          {
            "id": ".border-6",
            "label": ".border-6",
            "value": ".border-6"
          }
        ]
      },
      {
        "label":"Positions",
        "options":[
          {
            "id": ".position-static",
            "label": ".position-static",
            "value": ".position-static"
          },
          {
            "id": ".position-relative",
            "label": ".position-relative",
            "value": ".position-relative"
          },
          {
            "id": ".position-absolute",
            "label": ".position-absolute",
            "value": ".position-absolute"
          },
          {
            "id": ".position-fixed",
            "label": ".position-fixed",
            "value": ".position-fixed"
          },
          {
            "id": ".position-sticky",
            "label": ".position-sticky",
            "value": ".position-sticky"
          },
          {
            "id": ".top-0",
            "label": ".top-0",
            "value": ".top-0"
          },
          {
            "id": ".top-50",
            "label": ".top-50",
            "value": ".top-50"
          },
          {
            "id": ".top-100",
            "label": ".top-100",
            "value": ".top-100"
          },
          {
            "id": ".end-0",
            "label": ".end-0",
            "value": ".end-0"
          },
          {
            "id": ".end-50",
            "label": ".end-50",
            "value": ".end-50"
          },
          {
            "id": ".end-100",
            "label": ".end-100",
            "value": ".end-100"
          },
          {
            "id": ".start-0",
            "label": ".start-0",
            "value": ".start-0"
          },
          {
            "id": ".start-50",
            "label": ".start-50",
            "value": ".start-50"
          },
          {
            "id": ".start-100",
            "label": ".start-100",
            "value": ".start-100"
          },
          {
            "id": ".bottom-0",
            "label": ".bottom-0",
            "value": ".bottom-0"
          },
          {
            "id": ".bottom-50",
            "label": ".bottom-50",
            "value": ".bottom-50"
          },
          {
            "id": ".bottom-100",
            "label": ".bottom-100",
            "value": ".bottom-100"
          },
          {
            "id": ".fixed-bottom",
            "label": ".fixed-bottom",
            "value": ".fixed-bottom"
          },
          {
            "id": ".fixed-top",
            "label": ".fixed-top",
            "value": ".fixed-top"
          }
        ]
      },
      {
        "label":"Misc",
        "options":[
          {
            "id": ".overflow-auto",
            "label": ".overflow-auto",
            "value": ".overflow-auto"
          },
          {
            "id": ".overflow-hidden",
            "label": ".overflow-hidden",
            "value": ".overflow-hidden"
          },
          {
            "id": ".overflow-visible",
            "label": ".overflow-visible",
            "value": ".overflow-visible"
          },
          {
            "id": ".overflow-scroll",
            "label": ".overflow-scroll",
            "value": ".overflow-scroll"
          },
          {
            "id": ".visible",
            "label": ".visible",
            "value": ".visible"
          },
          {
            "id": ".invisible",
            "label": ".invisible",
            "value": ".invisible"
          }
        ]
      }
    ]
  }
]


export default styles;
