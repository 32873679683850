const getHtml = () => {
  return `
    <button type="button" class="btn btn-light">Light</button>
    `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const LightButton = { getHtml, getCss, getJs };

export default LightButton;
