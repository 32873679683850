const getHtml = () => {
  return `
      <div>
        <h1>title</h1>
        <ul class="list-group">
          <li class="list-group-item">Cras justo odio</li>
          <li class="list-group-item">Dapibus ac facilisis in</li>
          <li class="list-group-item">Morbi leo risus</li>
          <li class="list-group-item">Porta ac consectetur ac</li>
          <li class="list-group-item">Vestibulum at eros</li>
        </ul>
      </div>
    `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const PrimaryList = { getHtml, getCss, getJs };

export default PrimaryList;
