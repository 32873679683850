const getHtml = () => {
    return `
    <div class="container ms-4" data-gjs-type="default" >
    <div class="row">
      <div class="col " data-gjs-type="default" style="min-height : 50px;"></div>
    </div>
  </div>

    `;
  };
  
  const getCss = () => {
    return ``;
  };
  
  const getJs = () => {
    return ``;
  };
  
  const MediumSpacing = { getHtml, getCss, getJs };
  
  export default MediumSpacing;
  