/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Col, Form, Input, Modal, Row, Table, Typography } from 'antd';
import React, { useState, useEffect } from 'react';
import uuid from 'react-uuid';
import ColorPicker from '../../../../common/ColorPicker/ColorPicker';

const { Title } = Typography;

const ColorForm = ({ colorsInputFeild, setColorsInputFeild }) => {
  const [colorForm] = Form.useForm();

  const [editRecord, setEditRecord] = useState(null);

  useEffect(() => {
    if (editRecord) {
      colorForm.setFieldsValue({
        name: editRecord.name,
        variableName: editRecord.variableName,
        hexcode: editRecord.hexcode,
      });
    }
  }, [editRecord]);

  //------------------- columns for colors -----------------------------//
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Variable Name',
      dataIndex: 'variableName',
      key: 'variableName',
    },
    {
      title: 'Hex Code',
      dataIndex: 'hexcode',
      key: 'hexcode',
    },
    {
      title: 'Delete',
      dataIndex: '',
      key: '',
      render: (_, record) => {
        return (
          <a
            onClick={() => {
              Modal.confirm({
                title: 'Delete Alert',
                content: 'Do you want to delete it?',
                okText: 'Yes',
                cancelText: 'No',
                onOk: () => {
                  const updatedValues = colorsInputFeild.filter(
                    ele => ele.id !== record.id
                  );
                  setColorsInputFeild(updatedValues);
                },
              });
            }}
          >
            Delete
          </a>
        );
      },
    },
    {
      title: 'Edit',
      dataIndex: '',
      key: '',
      render: (_, record) => {
        return (
          <a
            onClick={() => {
              setEditRecord(record);
            }}
          >
            Edit
          </a>
        );
      },
    },
  ];
  //-------------------------------------------------------------------//
  const onFinish = values => {
    values.name = values.name.trim();
    values.variableName = values.variableName.trim();
    const keys = Object.keys(values);

    if (editRecord) {
      const newColors = colorsInputFeild.map((color, index) => {
        if (color.id === editRecord.id) {
          color = {
            id: color.id,
            name: values.name,
            variableName: values.variableName,
            hexcode: values.colorPicker.hex.toUpperCase(),
          };
        }
        return color;
      });
      setColorsInputFeild([...newColors]);
      setEditRecord(null);
      colorForm.resetFields();
    } else {
      keys.forEach(key => {
        if (values[key].hex) {
          const valueToPush = {
            id: uuid(),
            name: values.name,
            variableName: values.variableName,
            hexcode: values[key].hex.toUpperCase(),
          };
          setColorsInputFeild([...colorsInputFeild, valueToPush]);
          colorForm.resetFields();
        }
      });
    }
  };

  return (
    <>
      <h3>Colors:</h3>
      <Row gutter={20}>
        <Col span={6}>
          <Title level={5} style={{ textAlign: 'center' }}>
            {editRecord ? 'Edit Color' : 'Add Color'}
          </Title>
          <Form
            name="colorsForm"
            form={colorForm}
            onFinish={onFinish}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            labelAlign="left"
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please input Name',
                },
                {
                  pattern: /^[A-Za-z0-9_-]+$/,
                  message: "Invalid Input Special Character's not allowed",
                },
                { whitespace: true },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
            <Form.Item
              label="Variable Name"
              name="variableName"
              rules={[
                {
                  required: true,
                  message: 'Please input Variable Name',
                },
                {
                  pattern: /^[A-Za-z0-9_-]+$/,
                  message: "Invalid Input Special Character's not allowed",
                },
                { whitespace: true },
              ]}
            >
              <Input placeholder="Variable Name" />
            </Form.Item>

            <ColorPicker name="colorPicker" editRecord={editRecord} />

            <Button type="primary" onClick={() => colorForm.submit()}>
              {editRecord ? 'Edit Color' : 'Add Color'}
            </Button>
          </Form>
        </Col>
        <Col span={18}>
          {colorsInputFeild && colorsInputFeild.length > 0 && (
            <Table
              dataSource={colorsInputFeild}
              columns={columns}
              pagination={false}
              bordered
              size="small"
              scroll={{
                y: 240,
              }}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default ColorForm;
