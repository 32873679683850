const getHtml = () => {
    return `
    <section class="card-area pb-5">
       <div class="container">
          <div class="row justify-content-center">
             <div class="col-lg-4 col-md-7 col-sm-9">
                <div class="single-card card-style-one">
                   <div class="card-image">
                      <img src="https://cdn.ayroui.com/1.0/images/card/card-1.jpg" alt="Image" />
                   </div>
                   <div class="card-content">
                      <h4 class="card-title">
                         <a href="javascript:void(0)">Item title is here</a>
                      </h4>
                      <p class="text">
                         Short description for the ones who look for something new
                      </p>
                   </div>
                </div>

             </div>

             <div class="col-lg-4 col-md-7 col-sm-9">
                <div class="single-card card-style-one">
                   <div class="card-image">
                      <img src="https://cdn.ayroui.com/1.0/images/card/card-2.jpg" alt="Image" />
                   </div>
                   <div class="card-content">
                      <h4 class="card-title">
                         <a href="javascript:void(0)">Item title is here</a>
                      </h4>
                      <p class="text">
                         Short description for the ones who look for something new
                      </p>
                   </div>
                </div>

             </div>

             <div class="col-lg-4 col-md-7 col-sm-9">
                <div class="single-card card-style-one">
                   <div class="card-image">
                      <img src="https://cdn.ayroui.com/1.0/images/card/card-3.jpg" alt="Image" />
                   </div>
                   <div class="card-content">
                      <h4 class="card-title">
                         <a href="javascript:void(0)">Item title is here</a>
                      </h4>
                      <p class="text">
                         Short description for the ones who look for something new
                      </p>
                   </div>
                </div>

             </div>

          </div>

       </div>

    </section>
    `;
  };
  
  const getCss = () => {
    return `
    document.getElementById('slider').addEventListener(
      'click', () =>{
        console.log('from slider click')
      }
    )
    `;
  };
  
  const getJs = () => {
    return ``;
  };
  
  const Cards = { getHtml, getCss, getJs };
  
  export default Cards;
  