const getHtml = () => {
    return `
      <div class="form-group">
        <label for="inputNumber">Enter Number</label>
        <input type="Number" class="form-control" id="inputName" aria-describedby="number" placeholder="Enter Number">
      </div>
    `;
  };
  
  const getCss = () => {
    return ``;
  };
  
  const getJs = () => {
    return ``;
  };
  
  const NumberInput = { getHtml, getCss, getJs };
  
  export default NumberInput;
  