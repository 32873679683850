import * as TYPES from './action-types';


const initialState = {
     globalTraits: []
};

const GlobalTraits = (state = initialState, { type, data }) => {
    switch (type) {
        case TYPES.SET_GLOBAL_TRAITS:
          return {
            ...state,
            globalTraits: data,
          };
        default:
          return state;
      }
};

export default GlobalTraits;
