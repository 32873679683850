import AtomsLibrary from '../../utils/library/atoms';

const ATOMS = [
  {
    label: 'Spacing',
    component: 'Spacing',
    key: 'spacing',
    defaultVariation: AtomsLibrary.Spacing.SmallSpacing.getHtml(),
    variations: [
      {
        label: 'Small Spacing',
        key: 'small_spacing',
        html: AtomsLibrary.Spacing.SmallSpacing.getHtml(),
      },
      {
        label: 'Medium Spacing',
        key: 'medium_spacing',
        html: AtomsLibrary.Spacing.MediumSpacing.getHtml(),
      },
      {
        label: 'Large Spacing',
        key: 'large_spacing',
        html: AtomsLibrary.Spacing.LargeSpacing.getHtml(),
      },
    ],
  },
  {
    label: 'Alert',
    component: 'Alert',
    key: 'alert',
    defaultVariation: AtomsLibrary.Alert.Default.getHtml(),
    variations: [
      {
        label: 'Primary',
        key: 'alert_primary',
        html: AtomsLibrary.Alert.Primary.getHtml(),
      },
      {
        label: 'Secondary',
        key: 'alert_secondary',
        html: AtomsLibrary.Alert.Secondary.getHtml(),
      },
    ],
  },
  {
    label: 'Button',
    component: 'Button',
    key: 'buttton',
    defaultVariation: AtomsLibrary.Button.Default.getHtml(),
    variations: [
      {
        label: 'Primary',
        key: 'button_primary',
        html: AtomsLibrary.Button.Primary.getHtml(),
      },
      {
        label: 'Secondary',
        key: 'button_secondary',
        html: AtomsLibrary.Button.Secondary.getHtml(),
      },
    ],
  },
  {
    label: 'Icon',
    component: 'Icon',
    key: 'icon',
    defaultVariation: AtomsLibrary.Icon.Default.getHtml(),
    variations: [
      {
        label: 'Social Media',
        key: 'icon_social_media',
        html: AtomsLibrary.Icon.Primary.getHtml(),
      },
    ],
  },
  {
    label: 'Image',
    component: 'Image',
    key: 'image',
    defaultVariation: AtomsLibrary.Image.Default.getHtml(),
    variations: [
      {
        label: 'Default',
        key: 'image_default',
        html: AtomsLibrary.Image.Primary.getHtml(),
      },
    ],
  },
  {
    label: 'Input',
    component: 'Input',
    key: 'input',
    defaultVariation: AtomsLibrary.Input.Default.getHtml(),
    variations: [
      {
        label: 'Text',
        key: 'input_text',
        html: AtomsLibrary.Input.Primary.getHtml(),
      },
      {
        label: 'CheckBox',
        key: 'input_checkbox',
        html: AtomsLibrary.Input.CheckBoxInput.getHtml(),
      },
      {
        label: 'Radio',
        key: 'input_radio',
        html: AtomsLibrary.Input.RadioInput.getHtml(),
      },
      {
        label: 'File',
        key: 'input_file',
        html: AtomsLibrary.Input.FileInput.getHtml(),
      },
      {
        label: 'Email',
        key: 'input_email',
        html: AtomsLibrary.Input.EmailInput.getHtml(),
      },
      {
        label: 'Password',
        key: 'input_password',
        html: AtomsLibrary.Input.PasswordInput.getHtml(),
      },
      {
        label: 'Number',
        key: 'input_number',
        html: AtomsLibrary.Input.NumberInput.getHtml(),
      },
    ],
  },
  {
    label: 'Link',
    component: 'Link',
    key: 'link',
    defaultVariation: AtomsLibrary.Link.Default.getHtml(),
    variations: [
      {
        label: 'Default',
        key: 'link_default',
        html: AtomsLibrary.Link.Primary.getHtml(),
      },
      {
        label: 'Button Link',
        key: 'link_button',
        html: AtomsLibrary.Link.ButtonLink.getHtml(),
      },
      {
        label: 'Warning Link',
        key: 'link_warning',
        html: AtomsLibrary.Link.WarningLink.getHtml(),
      }
    ],
  },
  {
    label: 'List',
    component: 'List',
    key: 'list',
    defaultVariation: AtomsLibrary.List.Default.getHtml(),
    variations: [
      {
        label: 'unordered',
        key: 'list_unordered',
        html: AtomsLibrary.List.Primary.getHtml(),
      },
      {
        label: 'Ordered',
        key: 'list_ordered',
        html: AtomsLibrary.List.Secondary.getHtml(),
      },
      {
        label: 'Horizontal',
        key: 'list_horizontal',
        html: AtomsLibrary.List.HorizontalList.getHtml(),
      },
    ],
  },
  {
    label: 'Text',
    component: 'Text',
    key: 'text',
    defaultVariation: AtomsLibrary.Text.Default.getHtml(),
    variations: [
      {
        label: 'Success',
        key: 'text_success',
        html: AtomsLibrary.Text.Primary.getHtml(),
      },
      {
        label: 'Danger',
        key: 'text_danger',
        html: AtomsLibrary.Text.Secondary.getHtml(),
      },
    ],
  },
  {
    label: 'Video',
    component: 'Video',
    key: 'video',
    defaultVariation: AtomsLibrary.Video.Default.getHtml(),
    variations: [
      {
        label: 'Default',
        key: 'video_default',
        html: AtomsLibrary.Video.Primary.getHtml(),
      },
    ],
  },
  {
    label: 'Table',
    component: 'Table',
    key: 'table',
    defaultVariation: AtomsLibrary.Table.Default.getHtml(),
    variations: [
      {
        label: 'Default',
        key: 'table_default',
        html: AtomsLibrary.Table.Primary.getHtml(),
      },
      {
        label: 'Border Less',
        key: 'table_woborder',
        html: AtomsLibrary.Table.Secondary.getHtml(),
      },
    ],
  },
  {
    label: 'Grids',
    component: 'Grids',
    key: 'grids',
    defaultVariation: AtomsLibrary.Grids.OneColumn.getHtml(),
    variations: [
      {
        label: 'One Column',
        key: 'one_column',
        html: AtomsLibrary.Grids.OneColumn.getHtml(),
        css: AtomsLibrary.Grids.OneColumn.getCss()
      },
      {
        label: 'Two Columns',
        key: 'two_columns',
        html: AtomsLibrary.Grids.TwoColumn.getHtml(),
      },
      {
        label: 'Three Columns',
        key: 'three_columns',
        html: AtomsLibrary.Grids.ThreeColumn.getHtml(),
      },
      {
        label: 'Four Columns',
        key: 'four_columns',
        html: AtomsLibrary.Grids.FourColumn.getHtml(),
      },
      {
        label: 'Five Columns',
        key: 'five_columns',
        html: AtomsLibrary.Grids.FiveColumn.getHtml(),
      },
      {
        label: 'Six Columns',
        key: 'six_columns',
        html: AtomsLibrary.Grids.SixColumn.getHtml(),
      },
      {
        label: 'Seven Columns',
        key: 'seven_columns',
        html: AtomsLibrary.Grids.SevenColumn.getHtml(),
      },
      {
        label: 'Eight Columns',
        key: 'eight_columns',
        html: AtomsLibrary.Grids.EightColumn.getHtml(),
      },
      {
        label: 'Nine Columns',
        key: 'nine_columns',
        html: AtomsLibrary.Grids.NineColumn.getHtml(),
      },
      {
        label: 'Ten Columns',
        key: 'ten_columns',
        html: AtomsLibrary.Grids.TenColumn.getHtml(),
      },
      {
        label: 'Eleven Columns',
        key: 'eleven_columns',
        html: AtomsLibrary.Grids.ElevenColumn.getHtml(),
      },
      {
        label: 'Twelve Columns',
        key: 'twelve_columns',
        html: AtomsLibrary.Grids.TwelveColumn.getHtml(),
      },
    ],
  },
];

export default ATOMS;
