import Axios from 'axios';
import { store } from '../../store';
import { setToken } from '../../redux/login/action';

const axiosInstance = Axios.create({
  timeout: 60000,
  baseURL: process.env.REACT_APP_SERVER_BASE_URL,
  headers: {
    common: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  },
});

axiosInstance.interceptors.request.use(
  config => {
    const token = store.getState().Token.token;
    config.headers.token = token;
    return config;
  },

  error => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  res => {
    return res;
  },

  error => {
    if (error.response.status === 401) {
      store.dispatch(setToken(null));
      const port =
        window.location.port === '' ? '' : `:${window.location.port}`;
      window.location.assign(
        window.location.protocol + '//' + window.location.hostname + port
      );
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
