import { loremIpsum } from 'lorem-ipsum';

const ExecuteLoop = (key, html, cardinality) => {
  var pattern = new RegExp(
    `<!--START-LOOP-{{(${key})}}-->([\\s\\S]*?)<!--END-LOOP-{{\\1}}-->`
  );

  var matches = html.match(pattern);

  if (matches) {
    let modifiedhtml = '';
    for (let i = 0; i < cardinality; i++) {
      modifiedhtml += matches[2];
    }
    return html.replace(pattern, modifiedhtml);
  }
  return null;
};

export const replaceVariables = ({ component ,traits, html }) => {
  console.log("bhala",component);
  traits &&
    traits.forEach(trait => {
      if (trait.type === 'text_plain') {
        if (trait.defaultValue && trait.defaultValue !== '') {
          html = html.replaceAll(`{{ ${trait.name} }}`, trait.defaultValue);
          html = html.replaceAll(
            `start_curly_${trait.name}_end_curly`,
            trait.defaultValue
          );
        } else {
          html = html.replaceAll(
            `{{ ${trait.name} }}`,
            loremIpsum({
              count: 1,
              format: 'plain',
              paragraphLowerBound: 3,
              paragraphUpperBound: 7,
              random: Math.random,
              sentenceLowerBound: 5,
              sentenceUpperBound: 10,
              units: 'sentence',
            })
          );
          html = html.replaceAll(
            `start_curly_${trait.name}_end_curly`,
            loremIpsum({
              count: 1,
              format: 'plain',
              paragraphLowerBound: 3,
              paragraphUpperBound: 7,
              random: Math.random,
              sentenceLowerBound: 5,
              sentenceUpperBound: 10,
              units: 'sentence',
            })
          );
        }
      } else if (trait.type === 'text_link') {
        if (trait.defaultValue && trait.defaultValue !== '') {
          html = html.replaceAll(`{{ ${trait.name}.url }}`, trait.defaultValue);
          html = html.replaceAll(`{{ ${trait.name}.title }}`, trait.title);
          html = html.replaceAll(
            `start_curly_${trait.name}_dot_url_end_curly`,
            trait.defaultValue
          );
          html = html.replaceAll(
            `start_curly_${trait.name}_dot_title_end_curly`,
            trait.title
          );
        } else if (trait.name.includes('icon')) {
          html = html.replaceAll(
            `{{ ${trait.name}.url }}`,
            'https://www.aes.com/themes/custom/aes_frontend/assets/rgb/logo-rgb-global.svg'
          );
          html = html.replaceAll(
            `start_curly_${trait.name}_url_end_curly`,
            'https://www.aes.com/themes/custom/aes_frontend/assets/rgb/logo-rgb-global.svg'
          );
        } else
          html = html.replaceAll(
            `{{ ${trait.name}.url }}`,
            'https://www.aes-india.com/sites/default/files/2021-01/Port-Bonython-Rollout-Birdseye-PS-2048x1150.jpg'
          );
        html = html.replaceAll(
          `start_curly_${trait.name}_url_end_curly`,
          'https://www.aes-india.com/sites/default/files/2021-01/Port-Bonython-Rollout-Birdseye-PS-2048x1150.jpg'
        );
      } else if (trait.type === 'paragraph') {
        html = ExecuteLoop(trait.name, html, trait.cardinality) || html;

        trait.children?.forEach(child1 => {
          if (child1.type === 'paragraph') {
            html =
              ExecuteLoop(
                `${trait.name.slice(0, -1)}.${child1.name}`,
                html,
                child1.cardinality
              ) || html;

            child1.children?.forEach(child2 => {
              if (child2.type === 'paragraph') {
                html =
                  ExecuteLoop(
                    `${trait.name.slice(0, -1)}.${child1.name.slice(0, -1)}.${
                      child2.name
                    }`,
                    html,
                    child2.cardinality
                  ) || html;

                child2.children?.forEach(child3 => {
                  if (child3.type === 'image') {
                    child3.children?.forEach(child4 => {
                      // html = html.replaceAll(`${trait.name}.`, '');
                      // html = html.replaceAll(
                      //   `start_curly_${trait.name}_dot_`,
                      //   ''
                      // );
                      // html = html.replaceAll('_end_curly', '');

                      if (child4.type === 'text_plain') {
                        if (child4.defaultValue && child4.defaultValue != '') {
                          html = html.replaceAll(
                            `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                              0,
                              -1
                            )}.${child2.name.slice(0, -1)}.${child3.name}.${
                              child4.name
                            } }}`,
                            child4.defaultValue
                          );
                          html = html.replaceAll(
                            `start_curly_${trait.name.slice(
                              0,
                              -1
                            )}_dot_${child1.name.slice(
                              0,
                              -1
                            )}_dot_${child2.name.slice(0, -1)}_dot_${
                              child3.name
                            }_dot_${child4.name}_end_curly`,
                            child4.defaultValue
                          );
                        } else {
                          html = html.replaceAll(
                            `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                              0,
                              -1
                            )}.${child2.name.slice(0, -1)}.${child3.name}.${
                              child4.name
                            } }}`,
                            ''
                          );

                          html = html.replaceAll(
                            `start_curly_${trait.name.slice(
                              0,
                              -1
                            )}_dot_${child1.name.slice(
                              0,
                              -1
                            )}_dot_${child2.name.slice(0, -1)}_dot_${
                              child3.name
                            }_dot_${child4.name}_end_curly`,
                            ''
                          );
                        }
                      } else {
                        if (child4.defaultValue && child4.defaultValue != '') {
                          html = html.replaceAll(
                            `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                              0,
                              -1
                            )}.${child2.name.slice(0, -1)}.${child3.name}.${
                              child4.name
                            } }}`,
                            // `<img src=${child4.defaultValue} alt=${child4.defaultValue} />`
                            child4.defaultValue
                          );
                          html = html.replaceAll(
                            `start_curly_${trait.name.slice(
                              0,
                              -1
                            )}_dot_${child1.name.slice(
                              0,
                              -1
                            )}_dot_${child2.name.slice(0, -1)}_dot_${
                              child3.name
                            }_dot_${child4.name}_end_curly`,
                            // `<img src=${child4.defaultValue} alt=${child4.defaultValue} />`
                            child4.defaultValue
                          );
                        } else {
                          html = html.replaceAll(
                            `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                              0,
                              -1
                            )}.${child2.name.slice(0, -1)}.${child3.name}.${
                              child4.name
                            } }}`,
                            'https://www.aes-india.com/sites/default/files/2021-01/Port-Bonython-Rollout-Birdseye-PS-2048x1150.jpg'
                          );
                          html = html.replaceAll(
                            `start_curly_${trait.name.slice(
                              0,
                              -1
                            )}_dot_${child1.name.slice(
                              0,
                              -1
                            )}_dot_${child2.name.slice(0, -1)}_dot_${
                              child3.name
                            }_dot_${child4.name}_end_curly`,
                            'https://www.aes-india.com/sites/default/files/2021-01/Port-Bonython-Rollout-Birdseye-PS-2048x1150.jpg'
                          );
                        }
                      }
                    });
                  } else if (child3.type === 'picture') {
                    child3.children?.forEach(child4 => {
                      if (child4.type === 'image') {
                        child4?.children?.forEach(child5 => {
                          if (child5.type === 'text_link') {
                            if (child5.defaultValue) {
                              html = html.replaceAll(
                                `{{ ${trait.name.slice(
                                  0,
                                  -1
                                )}.${child1.name.slice(
                                  0,
                                  -1
                                )}.${child2.name.slice(0, -1)}.${child3.name}.${
                                  child4.name
                                }.${child5.name} }}`,
                                `<img src=${child5.defaultValue} />`
                              );
                              html = html.replaceAll(
                                `start_curly_${trait.name.slice(
                                  0,
                                  -1
                                )}_dot_${child1.name.slice(
                                  0,
                                  -1
                                )}_dot_${child2.name.slice(0, -1)}_dot_${
                                  child3.name
                                }_dot_${child4.name}_dot_${
                                  child5.name
                                }_end_curly`,
                                `<img src=${child5.defaultValue} />`
                              );
                              // html = html.replaceAll(
                              //   `start_curly_${trait.name}_dot_${child2.name}_dot_${child3.name}_end_curly`,
                              //   `<img src=${child3.defaultValue} />`
                              // );
                            } else {
                              html = html.replaceAll(
                                `{{ ${trait.name.slice(
                                  0,
                                  -1
                                )}.${child1.name.slice(
                                  0,
                                  -1
                                )}.${child2.name.slice(0, -1)}.${child3.name}.${
                                  child4.name
                                }.${child5.name} }}`,
                                'https://dev-fragmatic-builder-node.oslabs.app/public/assets/pexels-pixabay-38537.jpeg'
                              );

                              html = html.replaceAll(
                                `start_curly_${trait.name.slice(
                                  0,
                                  -1
                                )}_dot_${child1.name.slice(
                                  0,
                                  -1
                                )}_dot_${child2.name.slice(0, -1)}_dot_${
                                  child3.name
                                }_dot_${child4.name}_dot_${
                                  child5.name
                                }_end_curly`,
                                'https://dev-fragmatic-builder-node.oslabs.app/public/assets/pexels-pixabay-38537.jpeg'
                              );
                              // html = html.replaceAll(
                              //   `start_curly_${trait.name}_dot_${child2.name}_dot_${child3.name}_end_curly`,
                              //   'https://dev-fragmatic-builder-node.oslabs.app/public/assets/pexels-pixabay-38537.jpeg'
                              // );
                            }
                          } else {
                            if (child5.defaultValue) {
                              html = html.replaceAll(
                                `{{ ${trait.name.slice(
                                  0,
                                  -1
                                )}.${child1.name.slice(
                                  0,
                                  -1
                                )}.${child2.name.slice(0, -1)}.${child3.name}.${
                                  child4.name
                                }.${child5.name} }}`,
                                child5.defaultValue
                              );

                              html = html.replaceAll(
                                `start_curly_${trait.name.slice(
                                  0,
                                  -1
                                )}_dot_${child1.name.slice(
                                  0,
                                  -1
                                )}_dot_${child2.name.slice(0, -1)}_dot_${
                                  child3.name
                                }_dot_${child4.name}_dot_${
                                  child5.name
                                }_end_curly`,
                                child5.defaultValue
                              );
                              // html = html.replaceAll(
                              //   `start_curly_${trait.name}_dot_${child2.name}_dot_${child3.name}_end_curly`,
                              //   child3.defaultValue
                              // );
                            } else {
                              html = html.replaceAll(
                                `{{ ${trait.name.slice(
                                  0,
                                  -1
                                )}.${child1.name.slice(
                                  0,
                                  -1
                                )}.${child2.name.slice(0, -1)}.${child3.name}.${
                                  child4.name
                                }.${child5.name} }}`,
                                loremIpsum({
                                  count: 1,
                                  format: 'plain',
                                  paragraphLowerBound: 3,
                                  paragraphUpperBound: 7,
                                  random: Math.random,
                                  sentenceLowerBound: 5,
                                  sentenceUpperBound: 10,
                                  units: 'sentence',
                                })
                              );

                              html = html.replaceAll(
                                `start_curly_${trait.name.slice(
                                  0,
                                  -1
                                )}_dot_${child1.name.slice(
                                  0,
                                  -1
                                )}_dot_${child2.name.slice(0, -1)}_dot_${
                                  child3.name
                                }_dot_${child4.name}_dot_${
                                  child5.name
                                }_end_curly`,
                                loremIpsum({
                                  count: 1,
                                  format: 'plain',
                                  paragraphLowerBound: 3,
                                  paragraphUpperBound: 7,
                                  random: Math.random,
                                  sentenceLowerBound: 5,
                                  sentenceUpperBound: 10,
                                  units: 'sentence',
                                })
                              );
                              // html = html.replaceAll(
                              //   `start_curly_${trait.name}_dot_${child2.name}_dot_${child3.name}_end_curly`,
                              //   loremIpsum({
                              //     count: 1,
                              //     format: 'plain',
                              //     paragraphLowerBound: 3,
                              //     paragraphUpperBound: 7,
                              //     random: Math.random,
                              //     sentenceLowerBound: 5,
                              //     sentenceUpperBound: 10,
                              //     units: 'sentence',
                              //   })
                              // );
                            }
                          }
                        });
                      } else {
                        // child4.children?.forEach((child5, index) => {
                        // html = html.replaceAll(
                        //   `{{ ${trait.name.slice(
                        //     0,
                        //     -1
                        //   )}_${child1.name.slice(
                        //     0,
                        //     -1
                        //   )}_${child2.name.slice(0, -1)}_${
                        //     child3.name
                        //   }_alternate_source_${index} }}`,
                        //   `<img src=${child5.source} />`
                        // );
                        // });
                        const pictureClass =
                          child3.children[0].children[2].defaultValue;
                        const pictureAlt =
                          child3.children[0].children[1].defaultValue;
                        const pictureSrc =
                          child3.children[0].children[0].defaultValue;

                        html = html.replaceAll(
                          `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                            0,
                            -1
                          )}.${child2.name.slice(0, -1)}.${child3.name} }}`,
                          `<picture>${child4.children
                            .map(
                              child =>
                                `<source media="${child.media}" srcset="${child.source}" />`
                            )
                            .join('')}
                              <img class=${pictureClass} src=${pictureSrc} alt=${pictureAlt} />
                              </picture>`
                          // `<img src=${child5.source} />`
                        );
                        html = html.replaceAll(
                          `start_curly_${trait.name.slice(
                            0,
                            -1
                          )}_dot_${child1.name.slice(
                            0,
                            -1
                          )}_dot_${child2.name.slice(0, -1)}_dot_${
                            child3.name
                          }_end_curly`,
                          `<picture>${child4.children
                            .map(
                              child =>
                                `<source media="${child.media}" srcset="${child.source}" />`
                            )
                            .join('')}
                              <img class=${pictureClass} src=${pictureSrc} alt=${pictureAlt} />
                              </picture>`

                          // `<img src=${child5.source} />`
                        );
                      }
                    });
                  } else if (child3.type === 'text_link'){
                    if(child3.defaultValue){
                      html = html.replaceAll(
                        `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                          0,
                          -1
                        )}.${child2.name.slice(0, -1)}.${child3.name}.title }}`,
                        child3.title
                      );
                      html = html.replaceAll(
                        `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                          0,
                          -1
                        )}.${child2.name.slice(0, -1)}.${child3.name}.url }}`,
                        child3.defaultValue
                      );
                      html = html.replaceAll(
                        `start_curly_${trait.name.slice(
                          0,
                          -1
                        )}_dot_${child1.name.slice(
                          0,
                          -1
                        )}_dot_${child2.name.slice(0, -1)}_dot_${
                          child3.name
                        }_dot_title_end_curly`,
                        child3.title
                      );
                      html = html.replaceAll(
                        `start_curly_${trait.name.slice(
                          0,
                          -1
                        )}_dot_${child1.name.slice(
                          0,
                          -1
                        )}_dot_${child2.name.slice(0, -1)}_dot_${
                          child3.name
                        }_dot_url_end_curly`,
                        child3.defaultValue
                      );

                    }
                  }
                  else {
                    if (child3.defaultValue) {
                      html = html.replaceAll(
                        `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                          0,
                          -1
                        )}.${child2.name.slice(0, -1)}.${child3.name} }}`,
                        child3.defaultValue
                      );
                      html = html.replaceAll(
                        `start_curly_${trait.name.slice(
                          0,
                          -1
                        )}_dot_${child1.name.slice(
                          0,
                          -1
                        )}_dot_${child2.name.slice(0, -1)}_dot_${
                          child3.name
                        }_end_curly`,
                        child3.defaultValue
                      );
                    } else {
                      html = html.replaceAll(
                        `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                          0,
                          -1
                        )}.${child2.name.slice(0, -1)}.${child3.name} }}`,
                        loremIpsum({
                          count: 1,
                          format: 'plain',
                          paragraphLowerBound: 3,
                          paragraphUpperBound: 7,
                          random: Math.random,
                          sentenceLowerBound: 5,
                          sentenceUpperBound: 10,
                          units: 'sentence',
                        })
                      );
                      html = html.replaceAll(
                        `start_curly_${trait.name.slice(
                          0,
                          -1
                        )}_dot_${child1.name.slice(
                          0,
                          -1
                        )}_dot_${child2.name.slice(0, -1)}_dot_${
                          child3.name
                        }_end_curly`,
                        loremIpsum({
                          count: 1,
                          format: 'plain',
                          paragraphLowerBound: 3,
                          paragraphUpperBound: 7,
                          random: Math.random,
                          sentenceLowerBound: 5,
                          sentenceUpperBound: 10,
                          units: 'sentence',
                        })
                      );
                    }
                  }
                });
              } else {
                if (child2.type === 'image') {
                  child2.children?.forEach(child3 => {
                    // html = html.replaceAll(`${trait.name}.`, '');
                    // html = html.replaceAll(
                    //   `start_curly_${trait.name}_dot_`,
                    //   ''
                    // );
                    // html = html.replaceAll('_end_curly', '');

                    if (child3.type === 'text_plain') {
                      if (child3.defaultValue && child3.defaultValue != '') {
                        html = html.replaceAll(
                          `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                            0,
                            -1
                          )}.${child2.name}.${child3.name} }}`,
                          child3.defaultValue
                        );
                        html = html.replaceAll(
                          `start_curly_${trait.name.slice(
                            0,
                            -1
                          )}_dot_${child1.name.slice(0, -1)}_dot_${
                            child2.name
                          }_dot_${child3.name}_end_curly`,
                          child3.defaultValue
                        );
                        // html = html.replaceAll(
                        //   `${trait.name.slice(0, -1)}_${child1.name.slice(
                        //     0,
                        //     -1
                        //   )}_${child2.name}_${child3.name}`,
                        //   child3.defaultValue
                        // );
                      } else {
                        html = html.replaceAll(
                          `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                            0,
                            -1
                          )}.${child2.name}.${child3.name} }}`,
                          ''
                        );
                        html = html.replaceAll(
                          `start_curly_${trait.name.slice(
                            0,
                            -1
                          )}_dot_${child1.name.slice(0, -1)}_dot_${
                            child2.name
                          }_dot_${child3.name}_end_curly`,
                          ''
                        );
                      }
                    } else {
                      if (child3.defaultValue && child3.defaultValue != '') {
                        html = html.replaceAll(
                          `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                            0,
                            -1
                          )}.${child2.name}.${child3.name} }}`,
                          // `<img src=${child3.defaultValue} alt=${child3.defaultValue} />`
                          child3.defaultValue
                        );
                        html = html.replaceAll(
                          `start_curly_${trait.name.slice(
                            0,
                            -1
                          )}_dot_${child1.name.slice(0, -1)}_dot_${
                            child2.name
                          }_dot_${child3.name}_end_curly`,
                          // `<img src=${child3.defaultValue} alt=${child3.defaultValue} />`
                          child3.defaultValue
                        );
                      } else {
                        html = html.replaceAll(
                          `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                            0,
                            -1
                          )}.${child2.name}.${child3.name} }}`,
                          'https://www.aes-india.com/sites/default/files/2021-01/Port-Bonython-Rollout-Birdseye-PS-2048x1150.jpg'
                        );
                        html = html.replaceAll(
                          `start_curly_${trait.name.slice(
                            0,
                            -1
                          )}_dot_${child1.name.slice(0, -1)}_dot_${
                            child2.name
                          }_dot_${child3.name}_end_curly`,
                          'https://www.aes-india.com/sites/default/files/2021-01/Port-Bonython-Rollout-Birdseye-PS-2048x1150.jpg'
                        );
                      }
                    }

                    ////////////////////////////////////////
                  });
                } else if (child2.type === 'picture') {
                  child2.children?.forEach(child3 => {
                    if (child3.type === 'image') {
                      child3?.children?.forEach(child4 => {
                        if (child4.type === 'text_link') {
                          if (child4.defaultValue) {
                            html = html.replaceAll(
                              `{{ ${trait.name.slice(
                                0,
                                -1
                              )}.${child1.name.slice(0, -1)}.${child2.name}.${
                                child3.name
                              }.${child4.name} }}`,
                              `<img src=${child4.defaultValue} />`
                            );
                            html = html.replaceAll(
                              `start_curly_${trait.name.slice(
                                0,
                                -1
                              )}_dot_${child1.name.slice(0, -1)}_dot_${
                                child2.name
                              }_dot_${child3.name}_dot_${
                                child4.name
                              }_end_curly`,
                              `<img src=${child4.defaultValue} />`
                            );
                            // html = html.replaceAll(
                            //   `start_curly_${trait.name}_dot_${child2.name}_dot_${child3.name}_end_curly`,
                            //   `<img src=${child3.defaultValue} />`
                            // );
                          } else {
                            html = html.replaceAll(
                              `{{ ${trait.name.slice(
                                0,
                                -1
                              )}.${child1.name.slice(0, -1)}.${child2.name}.${
                                child3.name
                              }.${child4.name} }}`,
                              'https://dev-fragmatic-builder-node.oslabs.app/public/assets/pexels-pixabay-38537.jpeg'
                            );
                            html = html.replaceAll(
                              `start_curly_${trait.name.slice(
                                0,
                                -1
                              )}_dot_${child1.name.slice(0, -1)}_dot_${
                                child2.name
                              }_dot_${child3.name}_dot_${
                                child4.name
                              }_end_curly`,
                              'https://dev-fragmatic-builder-node.oslabs.app/public/assets/pexels-pixabay-38537.jpeg'
                            );
                            // html = html.replaceAll(
                            //   `start_curly_${trait.name}_dot_${child2.name}_dot_${child3.name}_end_curly`,
                            //   'https://dev-fragmatic-builder-node.oslabs.app/public/assets/pexels-pixabay-38537.jpeg'
                            // );
                          }
                        } else {
                          if (child4.defaultValue) {
                            html = html.replaceAll(
                              `{{ ${trait.name.slice(
                                0,
                                -1
                              )}.${child1.name.slice(0, -1)}.${child2.name}.${
                                child3.name
                              }.${child4.name} }}`,
                              child4.defaultValue
                            );
                            html = html.replaceAll(
                              `start_curly_${trait.name.slice(
                                0,
                                -1
                              )}_dot_${child1.name.slice(0, -1)}_dot_${
                                child2.name
                              }_dot_${child3.name}_dot_${
                                child4.name
                              }_end_curly`,
                              child4.defaultValue
                            );
                            // html = html.replaceAll(
                            //   `start_curly_${trait.name}_dot_${child2.name}_dot_${child3.name}_end_curly`,
                            //   child3.defaultValue
                            // );
                          } else {
                            html = html.replaceAll(
                              `{{ ${trait.name.slice(
                                0,
                                -1
                              )}.${child1.name.slice(0, -1)}.${child2.name}.${
                                child3.name
                              }.${child4.name} }}`,
                              loremIpsum({
                                count: 1,
                                format: 'plain',
                                paragraphLowerBound: 3,
                                paragraphUpperBound: 7,
                                random: Math.random,
                                sentenceLowerBound: 5,
                                sentenceUpperBound: 10,
                                units: 'sentence',
                              })
                            );
                            html = html.replaceAll(
                              `start_curly_${trait.name.slice(
                                0,
                                -1
                              )}_dot_${child1.name.slice(0, -1)}_dot_${
                                child2.name
                              }_dot_${child3.name}_dot_${
                                child4.name
                              }_end_curly`,
                              loremIpsum({
                                count: 1,
                                format: 'plain',
                                paragraphLowerBound: 3,
                                paragraphUpperBound: 7,
                                random: Math.random,
                                sentenceLowerBound: 5,
                                sentenceUpperBound: 10,
                                units: 'sentence',
                              })
                            );
                            // html = html.replaceAll(
                            //   `start_curly_${trait.name}_dot_${child2.name}_dot_${child3.name}_end_curly`,
                            //   loremIpsum({
                            //     count: 1,
                            //     format: 'plain',
                            //     paragraphLowerBound: 3,
                            //     paragraphUpperBound: 7,
                            //     random: Math.random,
                            //     sentenceLowerBound: 5,
                            //     sentenceUpperBound: 10,
                            //     units: 'sentence',
                            //   })
                            // );
                          }
                        }
                      });
                    } else {
                      // child3.children?.forEach((child4, index) => {
                      // html = html.replaceAll(
                      //   `{{ ${trait.name.slice(0, -1)}_${child1.name.slice(
                      //     0,
                      //     -1
                      //   )}_${child2.name}_alternate_source_${index} }}`,
                      //   `<img src=${child4.source} />`
                      // );
                      // });

                      const pictureClass =
                        child2.children[0].children[2].defaultValue;
                      const pictureAlt =
                        child2.children[0].children[1].defaultValue;
                      const pictureSrc =
                        child2.children[0].children[0].defaultValue;

                      html = html.replaceAll(
                        `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                          0,
                          -1
                        )}.${child2.name} }}`,
                        `<picture>${child3.children
                          .map(
                            child =>
                              `<source media="${child.media}" srcset="${child.source}" />`
                          )
                          .join('')}
                            <img class=${pictureClass} src=${pictureSrc} alt=${pictureAlt} />
                            </picture>`
                        // `<img src=${child4.source} />`
                      );
                      html = html.replaceAll(
                        `start_curly_${trait.name.slice(
                          0,
                          -1
                        )}_dot_${child1.name.slice(0, -1)}_dot_${
                          child2.name
                        }_end_curly`,
                        `<picture>${child3.children
                          .map(
                            child =>
                              `<source media="${child.media}" srcset="${child.source}" />`
                          )
                          .join('')}
                            <img class=${pictureClass} src=${pictureSrc} alt=${pictureAlt} />
                            </picture>`
                        // `<img src=${child4.source} />`
                      );
                    }
                  });

                  // });
                } else if (child2.type === 'text_link')
                {
                  if (child2.defaultValue) {
                    html = html.replaceAll(
                      `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                        0,
                        -1
                      )}.${child2.name}.url }}`,
                      child2.defaultValue
                    );
                    html = html.replaceAll(
                      `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                        0,
                        -1
                      )}.${child2.name}.title }}`,
                      child2.title
                    );
                    html = html.replaceAll(
                      `start_curly_${trait.name.slice(
                        0,
                        -1
                      )}_dot_${child1.name.slice(0, -1)}_dot_${
                        child2.name
                      }_dot_url_end_curly`,
                      child2.defaultValue
                    );
                    html = html.replaceAll(
                      `start_curly_${trait.name.slice(
                        0,
                        -1
                      )}_dot_${child1.name.slice(0, -1)}_dot_${
                        child2.name
                      }_dot_title_end_curly`,
                      child2.title
                    );

                  }
                }
                else {
                  if (child2.defaultValue) {
                    html = html.replaceAll(
                      `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                        0,
                        -1
                      )}.${child2.name} }}`,
                      child2.defaultValue
                    );
                    html = html.replaceAll(
                      `start_curly_${trait.name.slice(
                        0,
                        -1
                      )}_dot_${child1.name.slice(0, -1)}_dot_${
                        child2.name
                      }_end_curly`,
                      child2.defaultValue
                    );
                  } else {
                    html = html.replaceAll(
                      `{{ ${trait.name.slice(0, -1)}.${child1.name.slice(
                        0,
                        -1
                      )}.${child2.name} }}`,
                      loremIpsum({
                        count: 1,
                        format: 'plain',
                        paragraphLowerBound: 3,
                        paragraphUpperBound: 7,
                        random: Math.random,
                        sentenceLowerBound: 5,
                        sentenceUpperBound: 10,
                        units: 'sentence',
                      })
                    );
                    html = html.replaceAll(
                      `start_curly_${trait.name.slice(
                        0,
                        -1
                      )}_dot_${child1.name.slice(0, -1)}_dot_${
                        child2.name
                      }_end_curly`,
                      loremIpsum({
                        count: 1,
                        format: 'plain',
                        paragraphLowerBound: 3,
                        paragraphUpperBound: 7,
                        random: Math.random,
                        sentenceLowerBound: 5,
                        sentenceUpperBound: 10,
                        units: 'sentence',
                      })
                    );
                  }
                }
              }
            });
          } else {
            if (child1.type === 'image') {
              child1.children?.forEach(child2 => {
                //////////////////////////////////////////
                // html = html.replaceAll(`${trait.name}.`, '');
                // html = html.replaceAll(`start_curly_${trait.name}_dot_`, '');
                // html = html.replaceAll('_end_curly', '');

                if (child2.type === 'text_plain') {
                  if (child2.defaultValue && child2.defaultValue != '') {
                    html = html.replaceAll(
                      `{{ ${trait.name.slice(0, -1)}.${child1.name}.${
                        child2.name
                      } }}`,
                      child2.defaultValue
                    );
                    html = html.replaceAll(
                      `start_curly_${trait.name.slice(0, -1)}_dot_${
                        child1.name
                      }_dot_${child2.name}_end_curly`,
                      child2.defaultValue
                    );
                    // html = html.replaceAll(
                    //   `${trait.name.slice(0, -1)}_${child1.name}_${
                    //     child2.name
                    //   }`,
                    //   child2.defaultValue
                    // );
                  } else
                    html = html.replaceAll(
                      `{{ ${trait.name.slice(0, -1)}.${child1.name}.${
                        child2.name
                      } }}`,
                      ''
                    );
                  html = html.replaceAll(
                    `start_curly_${trait.name.slice(0, -1)}_dot_${
                      child1.name
                    }_dot_${child2.name}_end_curly`,
                    ''
                  );
                } else {
                  if (child2.defaultValue && child2.defaultValue != '') {
                    html = html.replaceAll(
                      `{{ ${trait.name.slice(0, -1)}.${child1.name}.${
                        child2.name
                      } }}`,
                      // `<img src=${child2.defaultValue} alt=${child2.defaultValue} />`
                      child2.defaultValue
                    );
                    html = html.replaceAll(
                      `start_curly_${trait.name.slice(0, -1)}_dot_${
                        child1.name
                      }_dot_${child2.name}_end_curly`,
                      // `<img src=${child2.defaultValue} alt=${child2.defaultValue} />`
                      child2.defaultValue
                    );
                  } else {
                    html = html.replaceAll(
                      `{{ ${trait.name.slice(0, -1)}.${child1.name}.${
                        child2.name
                      } }}`,
                      'https://www.aes-india.com/sites/default/files/2021-01/Port-Bonython-Rollout-Birdseye-PS-2048x1150.jpg'
                    );
                    html = html.replaceAll(
                      `start_curly_${trait.name.slice(0, -1)}_dot_${
                        child1.name
                      }_dot_${child2.name}_end_curly`,
                      'https://www.aes-india.com/sites/default/files/2021-01/Port-Bonython-Rollout-Birdseye-PS-2048x1150.jpg'
                    );
                  }
                }

                ////////////////////////////////////////
              });
            } else if (child1.type === 'picture') {
              child1.children?.forEach(child2 => {
                if (child2.type === 'image') {
                  child2?.children?.forEach(child3 => {
                    if (child3.type === 'text_link') {
                      if (child3.defaultValue) {
                        html = html.replaceAll(
                          `{{ ${trait.name.slice(0, -1)}.${child1.name}.${
                            child2.name
                          }.${child3.name} }}`,
                          `<img src=${child3.defaultValue} />`
                        );
                        html = html.replaceAll(
                          `start_curly_${trait.name.slice(0, -1)}_dot_${
                            child1.name
                          }_dot_${child2.name}_dot_${child3.name}_end_curly`,
                          `<img src=${child3.defaultValue} />`
                        );
                      } else {
                        html = html.replaceAll(
                          `{{ ${trait.name.slice(0, -1)}.${child1.name}.${
                            child2.name
                          }.${child3.name} }}`,
                          'https://dev-fragmatic-builder-node.oslabs.app/public/assets/pexels-pixabay-38537.jpeg'
                        );

                        html = html.replaceAll(
                          `start_curly_${trait.name.slice(0, -1)}_dot_${
                            child1.name
                          }_dot_${child2.name}_dot_${child3.name}_end_curly`,
                          'https://dev-fragmatic-builder-node.oslabs.app/public/assets/pexels-pixabay-38537.jpeg'
                        );
                      }
                    } else {
                      if (child3.defaultValue) {
                        html = html.replaceAll(
                          `{{ ${trait.name.slice(0, -1)}.${child1.name}.${
                            child2.name
                          }.${child3.name} }}`,
                          child3.defaultValue
                        );
                        html = html.replaceAll(
                          `start_curly_${trait.name.slice(0, -1)}_dot_${
                            child1.name
                          }_dot_${child2.name}_dot_${child3.name}_end_curly`,
                          child3.defaultValue
                        );
                      } else {
                        html = html.replaceAll(
                          `{{ ${trait.name.slice(0, -1)}.${child1.name}.${
                            child2.name
                          }.${child3.name} }}`,
                          loremIpsum({
                            count: 1,
                            format: 'plain',
                            paragraphLowerBound: 3,
                            paragraphUpperBound: 7,
                            random: Math.random,
                            sentenceLowerBound: 5,
                            sentenceUpperBound: 10,
                            units: 'sentence',
                          })
                        );
                        html = html.replaceAll(
                          `start_curly_${trait.name.slice(0, -1)}_dot_${
                            child1.name
                          }_dot_${child2.name}_dot_${child3.name}_end_curly`,
                          loremIpsum({
                            count: 1,
                            format: 'plain',
                            paragraphLowerBound: 3,
                            paragraphUpperBound: 7,
                            random: Math.random,
                            sentenceLowerBound: 5,
                            sentenceUpperBound: 10,
                            units: 'sentence',
                          })
                        );
                      }
                    }
                  });
                } else {
                  const pictureClass =
                    child1?.children[0]?.children[2]?.defaultValue;
                  const pictureAlt =
                    child1?.children[0]?.children[1]?.defaultValue;
                  const pictureSrc =
                    child1?.children[0]?.children[0]?.defaultValue;

                  html = html.replaceAll(
                    `{{ ${trait.name.slice(0, -1)}.${child1.name} }}`,
                    `<picture>${child2.children
                      .map(
                        child =>
                          `<source media="${child.media}" srcset="${child.source}" />`
                      )
                      .join('')}
                        <img class=${pictureClass} src=${pictureSrc} alt=${pictureAlt}  />
                        </picture>`
                  );
                  html = html.replaceAll(
                    `start_curly_${trait.name.slice(0, -1)}_dot_${
                      child1.name
                    }_end_curly`,
                    `<picture>${child2.children
                      .map(
                        child =>
                          `<source media="${child.media}" srcset="${child.source}" />`
                      )
                      .join('')}
                        <img class=${pictureClass} src=${pictureSrc} alt=${pictureAlt}  />
                        </picture>`
                  );
                }
              });
            } else if (child1.type === 'text_link')
            {

              // console.log("aala",`{{ ${trait.name.slice(0, -1)}.${child1.name}.url }}`);
              html = html.replaceAll(
                `{{ ${trait.name.slice(0, -1)}.${child1.name}.url }}`,
                child1.defaultValue
              );
              html = html.replaceAll(
                `start_curly_${trait.name.slice(0, -1)}_dot_${
                  child1.name
                }_dot_url_end_curly`,
                child1.defaultValue
              );
              html = html.replaceAll(
                `{{ ${trait.name.slice(0, -1)}.${child1.name}.title }}`,
                child1.title
              );
              html = html.replaceAll(
                `start_curly_${trait.name.slice(0, -1)}_dot_${
                  child1.name
                }_dot_title_end_curly`,
                child1.title
              );
            }
            else {
              if (child1.defaultValue) {
                html = html.replaceAll(
                  `{{ ${trait.name.slice(0, -1)}.${child1.name} }}`,
                  child1.defaultValue
                );
                html = html.replaceAll(
                  `start_curly_${trait.name.slice(0, -1)}_dot_${
                    child1.name
                  }_end_curly`,
                  child1.defaultValue
                );
              } else {
                html = html.replaceAll(
                  `{{ ${trait.name.slice(0, -1)}.${child1.name} }}`,
                  loremIpsum({
                    count: 1,
                    format: 'plain',
                    paragraphLowerBound: 3,
                    paragraphUpperBound: 7,
                    random: Math.random,
                    sentenceLowerBound: 5,
                    sentenceUpperBound: 10,
                    units: 'sentence',
                  })
                );
                html = html.replaceAll(
                  `start_curly_${trait.name.slice(0, -1)}_dot_${
                    child1.name
                  }_end_curly`,
                  loremIpsum({
                    count: 1,
                    format: 'plain',
                    paragraphLowerBound: 3,
                    paragraphUpperBound: 7,
                    random: Math.random,
                    sentenceLowerBound: 5,
                    sentenceUpperBound: 10,
                    units: 'sentence',
                  })
                );
              }
            }
          }
        });

        /////////////////////////////////
      } else if (trait.type === 'image') {
        trait.children.forEach(child => {
          // html = html.replaceAll(`${trait.name}.`, '');
          // html = html.replaceAll(`start_curly_${trait.name}_dot_`, '');
          // html = html.replaceAll('_end_curly', '');

          if (child.type === 'text_plain') {
            if (child.defaultValue && child.defaultValue != '') {
              html = html.replaceAll(`{{ ${trait.name}.${child.name} }}`, child.defaultValue);
              html = html.replaceAll(
                `start_curly_${trait.name}_dot_${child.name}_end_curly`,
                child.defaultValue
              );
            } else {
              html = html.replaceAll(`{{ ${trait.name}.${child.name} }}`, '');
              html = html.replaceAll(`start_curly_${trait.name}_dot_${child.name}_end_curly`, '');
            }
          } else {
            if (child.defaultValue && child.defaultValue != '') {
              html = html.replaceAll(
                `{{ ${trait.name}.${child.name} }}`,
                // `<img src=${child.defaultValue} alt=${child.defaultValue} />`
                child.defaultValue
              );
              html = html.replaceAll(
                `start_curly_${trait.name}_dot_${child.name}_end_curly`,
                // `<img src=${child.defaultValue} alt=${child.defaultValue} />`
                child.defaultValue
              );
            } else {
              html = html.replaceAll(
                `{{ ${trait.name}.${child.name} }}`,
                'https://www.aes-india.com/sites/default/files/2021-01/Port-Bonython-Rollout-Birdseye-PS-2048x1150.jpg'
              );
              html = html.replaceAll(
                `start_curly_${trait.name}_dot_${child.name}_end_curly`,
                'https://www.aes-india.com/sites/default/files/2021-01/Port-Bonython-Rollout-Birdseye-PS-2048x1150.jpg'
              );
            }
          }
        });
      } else if (trait.type === 'picture') {
        trait.children?.forEach(child_1 => {
          if (child_1.type === 'image') {
            // child_1.children?.forEach(child_2 => {
            //   if (child_2.type === 'text_link') {
            //     if (child_2.defaultValue) {
            //       html = html.replaceAll(
            //         `{{ ${trait.name}.${child_1.name}.${child_2.name} }}`,
            //         `<img src=${child_2.defaultValue} />`
            //       );
            //       html = html.replaceAll(
            //         `start_curly_${trait.name}_dot_${child_1.name}_dot_${child_2.name}_end_curly`,
            //         `<img src=${child_2.defaultValue} />`
            //       );
            //     } else {
            //       html = html.replaceAll(
            //         `{{ ${trait.name}.${child_1.name}.${child_2.name} }}`,
            //         'https://dev-fragmatic-builder-node.oslabs.app/public/assets/pexels-pixabay-38537.jpeg'
            //       );
            //       html = html.replaceAll(
            //         `start_curly_${trait.name}_dot_${child_1.name}_dot_${child_2.name}_end_curly`,
            //         'https://dev-fragmatic-builder-node.oslabs.app/public/assets/pexels-pixabay-38537.jpeg'
            //       );
            //     }
            //   } else {
            //     if (child_2.defaultValue) {
            //       html = html.replaceAll(
            //         `{{ ${trait.name}.${child_1.name}.${child_2.name} }}`,
            //         child_2.defaultValue
            //       );
            //       html = html.replaceAll(
            //         `start_curly_${trait.name}_dot_${child_1.name}_dot_${child_2.name}_end_curly`,
            //         child_2.defaultValue
            //       );
            //     } else {
            //       html = html.replaceAll(
            //         `{{ ${trait.name}.${child_1.name}.${child_2.name} }}`,
            //         loremIpsum({
            //           count: 1,
            //           format: 'plain',
            //           paragraphLowerBound: 3,
            //           paragraphUpperBound: 7,
            //           random: Math.random,
            //           sentenceLowerBound: 5,
            //           sentenceUpperBound: 10,
            //           units: 'sentence',
            //         })
            //       );
            //       html = html.replaceAll(
            //         `start_curly_${trait.name}_dot_${child_1.name}_dot_${child_2.name}_end_curly`,
            //         loremIpsum({
            //           count: 1,
            //           format: 'plain',
            //           paragraphLowerBound: 3,
            //           paragraphUpperBound: 7,
            //           random: Math.random,
            //           sentenceLowerBound: 5,
            //           sentenceUpperBound: 10,
            //           units: 'sentence',
            //         })
            //       );
            //     }
            //   }
            // });
          } else {
            const pictureClass = trait.children[0].children[2].defaultValue;
            const pictureAlt = trait.children[0].children[1].defaultValue;
            const pictureSrc = trait.children[0].children[0].defaultValue;

            html = html.replaceAll(
              `{{ ${trait.name} }}`,
              `<picture>${child_1.children
                .map(
                  child =>
                    `<source media="${child.media}" srcset="${child.source}" />`
                )
                .join('')}
                  <img class=${pictureClass} src=${pictureSrc} alt=${pictureAlt}  />
                  </picture>`
            );
            html = html.replaceAll(
              `start_curly_${trait.name}_end_curly`,
              `<picture>${child_1.children
                .map(
                  child =>
                    `<source media="${child.media}" srcset="${child.source}" />`
                )
                .join('')}
                  <img class=${pictureClass} src=${pictureSrc} alt=${pictureAlt}  />
                  </picture>`
            );
          }
        });
      } else {
        if (trait.defaultValue && trait.defaultValue !== '') {
          html = html.replaceAll(`{{ ${trait.name} }}`, trait.defaultValue);
          html = html.replaceAll(
            `start_curly_${trait.name}_end_curly`,
            trait.defaultValue
          );
        } else {
          html = html.replaceAll(
            `{{ ${trait.name} }}`,
            loremIpsum({
              count: 1,
              format: 'plain',
              paragraphLowerBound: 3,
              paragraphUpperBound: 7,
              random: Math.random,
              sentenceLowerBound: 5,
              sentenceUpperBound: 25,
              suffix: '\n',
              units: 'sentence',
            })
          );
          html = html.replaceAll(
            `start_curly_${trait.name}_end_curly`,
            loremIpsum({
              count: 1,
              format: 'plain',
              paragraphLowerBound: 3,
              paragraphUpperBound: 7,
              random: Math.random,
              sentenceLowerBound: 5,
              sentenceUpperBound: 25,
              suffix: '\n',
              units: 'sentence',
            })
          );
        }
      }
    });
  return html;
};
