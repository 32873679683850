const getHtml = () => {
  return `
  <div class="alert alert-light" role="alert">
  A simple light alert—check it out!
</div>
            `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const LightAlert = { getHtml, getCss, getJs };

export default LightAlert;
