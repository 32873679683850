import { Children } from 'react';
import AtomsLibrary from '../../../../utils/library/atoms';

const expt = 'export-template';
const osm = 'open-sm';
const otm = 'open-tm';
const blockList = [
  {
    id: 'accordion',
    category: 'Accordion',
    label: 'Accordion',
    select: true,
    content: AtomsLibrary.Accordion.Default.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'alert_primary',
    label: 'Primary',
    category: 'Alert',
    select: true,
    content: AtomsLibrary.Alert.Primary.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'alert_secondary',
    label: 'Secondary',
    category: 'Alert',
    select: true,
    content: AtomsLibrary.Alert.Secondary.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'alert_danger',
    label: 'Danger',
    category: 'Alert',
    select: true,
    content: AtomsLibrary.Alert.Danger.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'alert_dark',
    label: 'Dark',
    category: 'Alert',
    select: true,
    content: AtomsLibrary.Alert.Dark.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'alert_info',
    label: 'Info',
    category: 'Alert',
    select: true,
    content: AtomsLibrary.Alert.Info.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'alert_light',
    label: 'Light',
    category: 'Alert',
    select: true,
    content: AtomsLibrary.Alert.Light.getHtml(),
    type: 'Bootstrap Components',
  },

  {
    id: 'alert_success',
    label: 'Success',
    category: 'Alert',
    select: true,
    content: AtomsLibrary.Alert.Success.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'alert_warning',
    label: 'Warning',
    category: 'Alert',
    select: true,
    content: AtomsLibrary.Alert.Warning.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'button_primary',
    label: 'Primary',
    category: 'Button',
    select: true,
    content: AtomsLibrary.Button.Primary.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'button_secondary',
    label: 'Secondary',
    category: 'Button',
    select: true,
    content: AtomsLibrary.Button.Secondary.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'button_danger',
    label: 'Danger',
    category: 'Button',
    select: true,
    content: AtomsLibrary.Button.Danger.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'button_success',
    label: 'Success',
    category: 'Button',
    select: true,
    content: AtomsLibrary.Button.Success.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'button_warning',
    label: 'Warning',
    category: 'Button',
    select: true,
    content: AtomsLibrary.Button.Warning.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'button_dark',
    label: 'Dark',
    category: 'Button',
    select: true,
    content: AtomsLibrary.Button.Dark.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'button_info',
    label: 'Info',
    category: 'Button',
    select: true,
    content: AtomsLibrary.Button.Info.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'button_light',
    label: 'Light',
    category: 'Button',
    select: true,
    content: AtomsLibrary.Button.Light.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'button_link',
    label: 'Link',
    category: 'Button',
    select: true,
    content: AtomsLibrary.Button.Link.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'badge_h1',
    label: 'H1',
    category: 'Badge',
    select: true,
    content: AtomsLibrary.Badge.H1.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'badge_h2',
    label: 'H2',
    category: 'Badge',
    select: true,
    content: AtomsLibrary.Badge.H2.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'badge_h3',
    label: 'H3',
    category: 'Badge',
    select: true,
    content: AtomsLibrary.Badge.H3.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'badge_h4',
    label: 'H4',
    category: 'Badge',
    select: true,
    content: AtomsLibrary.Badge.H4.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'badge_h5',
    label: 'H5',
    category: 'Badge',
    select: true,
    content: AtomsLibrary.Badge.H5.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'badge_h6',
    label: 'H6',
    category: 'Badge',
    select: true,
    content: AtomsLibrary.Badge.H6.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'breadcrumb',
    label: 'Breadcrumb',
    category: 'Breadcrumb',
    select: true,
    content: AtomsLibrary.Breadcrumb.Default.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'close_button_active',
    label: 'Active',
    category: 'Close Button',
    select: true,
    content: AtomsLibrary.CloseButton.Active.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'close_button_disable',
    label: 'Disable',
    category: 'Close Button',
    select: true,
    content: AtomsLibrary.CloseButton.Disable.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'collapse',
    label: 'Collapse',
    category: 'Collapse',
    select: true,
    content: AtomsLibrary.Collapse.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'dropdown_down',
    label: 'Down',
    category: 'Dropdown',
    select: true,
    content: AtomsLibrary.Dropdown.Down.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'dropdown_up',
    label: 'UP',
    category: 'Dropdown',
    select: true,
    content: AtomsLibrary.Dropdown.Up.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'dropdown_left',
    label: 'Left',
    category: 'Dropdown',
    select: true,
    content: AtomsLibrary.Dropdown.Left.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'dropdown_right',
    label: 'Right',
    category: 'Dropdown',
    select: true,
    content: AtomsLibrary.Dropdown.Right.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'dropdown_primary',
    label: 'Primary',
    category: 'Dropdown',
    select: true,
    content: AtomsLibrary.Dropdown.Primary.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'dropdown_secondary',
    label: 'Secondary',
    category: 'Dropdown',
    select: true,
    content: AtomsLibrary.Dropdown.Secondary.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'dropdown_success',
    label: 'Success',
    category: 'Dropdown',
    select: true,
    content: AtomsLibrary.Dropdown.Success.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'dropdown_info',
    label: 'Info',
    category: 'Dropdown',
    select: true,
    content: AtomsLibrary.Dropdown.Info.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'dropdown_warning',
    label: 'Warning',
    category: 'Dropdown',
    select: true,
    content: AtomsLibrary.Dropdown.Warning.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'dropdown_danger',
    label: 'Danger',
    category: 'Dropdown',
    select: true,
    content: AtomsLibrary.Dropdown.Danger.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'pagination_basic',
    label: 'Basic',
    category: 'Pagination',
    select: true,
    content: AtomsLibrary.Pagination.Basic.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'pagination_icon',
    label: 'Icon',
    category: 'Pagination',
    select: true,
    content: AtomsLibrary.Pagination.Icon.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'pagination_center',
    label: 'Center',
    category: 'Pagination',
    select: true,
    content: AtomsLibrary.Pagination.Center.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'pagination_end',
    label: 'End',
    category: 'Pagination',
    select: true,
    content: AtomsLibrary.Pagination.End.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'progress_basic',
    label: 'Basic',
    category: 'Progress',
    select: true,
    content: AtomsLibrary.Progress.Basic.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'progress_label',
    label: 'Label',
    category: 'Progress',
    select: true,
    content: AtomsLibrary.Progress.Label.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'progress_striped',
    label: 'Striped',
    category: 'Progress',
    select: true,
    content: AtomsLibrary.Progress.Striped.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'spinner_border',
    label: 'Border',
    category: 'Spinner',
    select: true,
    content: AtomsLibrary.Spinner.Border.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'spinner_growing',
    label: 'Growing',
    category: 'Spinner',
    select: true,
    content: AtomsLibrary.Spinner.Growing.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'link',
    label: 'Link',
    category: 'Link',
    select: true,
    content: AtomsLibrary.Link.Default.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'icon',
    label: 'Icon',
    category: 'Icon',
    select: true,
    content: AtomsLibrary.Icon.Default.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'image',
    label: 'Image',
    category: 'Image',
    select: true,
    content: AtomsLibrary.Image.Default.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'list',
    label: 'List',
    category: 'List',
    select: true,
    content: AtomsLibrary.List.Default.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'text',
    label: 'Text',
    category: 'Text',
    select: true,
    content: AtomsLibrary.Text.Default.getHtml(),
    type: 'Bootstrap Components',
  },
  {
    id: 'input',
    label: 'Input',
    category: 'Input',
    select: true,
    content: AtomsLibrary.Input.Default.getHtml(),
    type: 'Bootstrap Components',
  },
];
const EditorConfig = () => ({
  height: 900,
  storageManager: false,
  blockManager: {
    appendOnClick: false,
    custom: true,
    blocks: blockList,
    appendOnClick: false,

  },
});

export default EditorConfig;
