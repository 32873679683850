import Default from './default';
import Primary from './primary';
import Secondary from './secondary';
import Danger from './danger';
import Dark from './dark';
import Info from './info';
import Light from './light';
import Link from './link';
import Success from './success';
import Warning from './warning';

const Button = {
  Default,
  Primary,
  Secondary,
  Danger,
  Dark,
  Info,
  Light,
  Link,
  Success,
  Warning,
};

export default Button;
