import ComponentListing from './ComponentListing';
import { Alert } from 'antd';

const WarningModal = ({
  usingComponent,
  selectedUpdatedComponent,
  usedInfo,
}) => {
  let keys = Object.keys(usingComponent);
  let fullData = {};
  const collectData = data => {
    fullData = { ...fullData, ...data };
    selectedUpdatedComponent(fullData);
  };
if(usedInfo === 'childrenDetails'){
  delete usingComponent.childCss;
}
  return (
    <>
      {usedInfo === 'childrenDetails' ? (
        ''
      ) : usedInfo ? (
        <p>Following higher level components use this component.</p>
      ) : (
        <>
          <p>
            Following higher level components use this component. Please select
            the components for auto-updation.
          </p>
        </>
      )}
      {keys?.map((componentType, index) => {
        if (
          usingComponent[componentType] &&
          usingComponent[componentType].length > 0
        ) {
          return (
            <ComponentListing
              key={index}
              options={usingComponent[componentType]}
              componentType={componentType}
              collectData={collectData}
              usedInfo={usedInfo}
            />
          );
        }
      })}
    </>
  );
};

export default WarningModal;
