const getHtml = () => {
  return `
    <a href="#" class="link-secondary">Secondary link</a>
  `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const SecondaryLink = { getHtml, getCss, getJs };

export default SecondaryLink;
