const getHtml = () => {
  return `
    <div class="alert alert-secondary" role="alert">
      This is a secondary alert.
    </div>
  `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const SecondaryAlert = { getHtml, getCss, getJs };

export default SecondaryAlert;
