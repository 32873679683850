const getHtml = () => {
  return `
    <button type="button" class="btn-close" disabled aria-label="Close"></button>     `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const Disable = { getHtml, getCss, getJs };

export default Disable;
