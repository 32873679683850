import Default from './default';
import HorizontalList from './horizontal';
import Primary from './primary';
import Secondary from './secondary';

const List = {
  Default,
  Primary,
  Secondary,
  HorizontalList
};

export default List;
