import Alert from './alert';
import Button from './button';
import Grids from './grids';
import Icon from './icon';
import Image from './image';
import Input from './input';
import Link from './link';
import List from './list';
import Spacing from './spacing';
import Table from './table';
import Text from './text';
import Video from './video';
import Accordion from './accordion';
import Badge from './badge';
import Breadcrumb from './breadcrumb';
import CloseButton from './closeButton';
import Collapse from './collapse';
import Dropdown from './dropdown';
import Pagination from './pagination';
import Progress from './progress';
import Spinner from './spinner';
import Tooltips from './tooltips';

const AtomsLibrary = {
  Spacing,
  Alert,
  Button,
  List,
  Icon,
  Image,
  Input,
  Link,
  Table,
  Text,
  Video,
  Grids,
  Accordion,
  Badge,
  Breadcrumb,
  CloseButton,
  Collapse,
  Dropdown,
  Pagination,
  Progress,
  Spinner,
  Tooltips,
};

export default AtomsLibrary;
