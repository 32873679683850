const getHtml = () => {
  return `
        <h5>Example heading <span class="badge bg-secondary">New</span></h5>
        `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const H5Badge = { getHtml, getCss, getJs };

export default H5Badge;
