const getHtml = () => {
  return `
  <nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="#">Home</a></li>
    <li class="breadcrumb-item"><a href="#">Library</a></li>
    <li class="breadcrumb-item active" aria-current="page">Data</li>
  </ol>
</nav>      `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const Default = { getHtml, getCss, getJs };

export default Default;
