/* eslint-disable jsx-a11y/anchor-is-valid */
import { Table, Col, Row, Modal, Select, Input, InputNumber } from 'antd';
import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
// import index from '../../../../../../utils/helpers/scripts';
import { Form } from 'antd';

const DependencyTable = ({ Entity, setEntity, selectedEntity }) => {
  const [data, setData] = useState([]);

  const [editLibrary] = Form.useForm();

  const [editModal, showEditModal] = useState(false);

  const [warning, showWarning] = useState('');

  const oldRecord = useRef(null);
  const record_showing_warning = useRef(null);

  const EditDependencies = values => {
    const newEntity = [...Entity];
    newEntity.forEach(entity => {
      if (entity.variations) {
        entity.variations.forEach(variation => {
          if (variation.key === selectedEntity.key) {
            if (variation.dependencies) {
              variation.dependencies[`${oldRecord.current.type}`].forEach(
                (url, index) => {
                  if (url === oldRecord.current.url) {
                    if (oldRecord.current.type === values.select_library) {
                      variation.dependencies[`${oldRecord.current.type}`][
                        index
                      ] = values.cdn_link;
                    } else {
                      variation.dependencies[
                        `${oldRecord.current.type}`
                      ].splice(index, 1);
                      variation.dependencies[
                        `${values.select_library}`
                      ].push(values.cdn_link);
                    }
                  }
                  setEntity(newEntity);
                  editLibrary.resetFields();
                  showEditModal(false);
                }
              );
            }
          }
        });
      } else {
        if (entity.key === selectedEntity.key) {
          if (entity.dependencies) {
            entity.dependencies[`${oldRecord.current.type}`].forEach(
              (url, index) => {
                if (url === oldRecord.current.url) {
                  if (oldRecord.current.type === values.select_library) {
                    entity.dependencies[`${oldRecord.current.type}`][index] =
                      values.cdn_link;
                  } else {
                    entity.dependencies[`${oldRecord.current.type}`].splice(
                      index,
                      1
                    );
                    entity.dependencies[`${values.select_library}`].push(
                      values.cdn_link
                    );
                  }
                }
                setEntity(newEntity);
                editLibrary.resetFields();
                showEditModal(false);
              }
            );
          }
        }
      }
    });
  };

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Url',
      dataIndex: 'url',
      key: 'url',
    },
    {
      title: 'Priority Order (0 is max priority)',
      dataIndex: '',
      key: '',
      render: (_, record) => {
        let index = -1;
        selectedEntity?.dependencies[`${record.type}`].forEach((ele, i) => {
          if (ele === record.url) {
            index = i;
          }
        });
        return (
          <>
            <InputNumber
              type="number"
              value={index}
              onChange={e => {
                showWarning('');
                const newEntity = [...Entity];
                newEntity.forEach((entity, i) => {
                  if (entity.variations) {
                    entity.variations.forEach(variation => {
                      if (variation.key === selectedEntity.key) {
                        if (variation.dependencies) {
                          if (
                            e <=
                            variation?.dependencies[`${record.type}`]
                              .length -
                              1
                          ) {
                            variation?.dependencies[
                              `${record.type}`
                            ].splice(index, 1);
                            variation?.dependencies[
                              `${record.type}`
                            ].splice(e, 0, record.url);
                            setEntity(newEntity);
                          } else {
                            record_showing_warning.current = record;
                            showWarning(
                              `Input value can only be from ${0} to ${
                                variation?.dependencies[`${record.type}`]
                                  .length - 1
                              }`
                            );
                          }
                        }
                      }
                    });
                  } else {
                    if (entity.key === selectedEntity.key) {
                      if (entity.dependencies) {
                        if (
                          e <=
                          entity?.dependencies[`${record.type}`].length - 1
                        ) {
                          entity?.dependencies[`${record.type}`].splice(
                            index,
                            1
                          );
                          entity?.dependencies[`${record.type}`].splice(
                            e,
                            0,
                            record.url
                          );
                          setEntity(newEntity);
                        } else {
                          record_showing_warning.current = record;
                          showWarning(
                            `Input value can only be from ${0} to ${
                              entity?.dependencies[`${record.type}`]
                                .length - 1
                            }`
                          );
                        }
                      }
                    }
                  }
                });
              }}
            />
            {warning && record_showing_warning.current === record && (
              <p style={{ color: 'red' }}>{warning}</p>
            )}
          </>
        );
      },
    },
    {
      title: 'Delete',
      dataIndex: '',
      key: '',
      render: (_, record) => {
        return (
          <a
            onClick={() => {
              const newEntity = [...Entity];
              newEntity.forEach(entity => {
                if (entity.variations) {
                  entity.variations.forEach(variation => {
                    if (variation.key === selectedEntity.key) {
                      if (variation.dependencies) {
                        variation.dependencies[`${record.type}`].forEach(
                          (ele, index) => {
                            if (ele === record.url) {
                              variation.dependencies[
                                `${record.type}`
                              ].splice(index, 1);
                              setEntity(newEntity);
                            }
                          }
                        );
                      }
                    }
                  });
                } else {
                  if (entity.key === selectedEntity.key) {
                    if (entity.dependencies) {
                      entity.dependencies[`${record.type}`].forEach(
                        (ele, index) => {
                          if (ele === record.url) {
                            entity.dependencies[`${record.type}`].splice(
                              index,
                              1
                            );
                            setEntity(newEntity);
                          }
                        }
                      );
                    }
                  }
                }
              });
            }}
          >
            Delete
          </a>
        );
      },
    },
    {
      title: 'Edit',
      dataIndex: '',
      key: '',
      render: (_, record) => {
        return (
          <a
            onClick={() => {
              showEditModal(true);
              editLibrary.setFieldsValue({
                select_library: record.type,
                cdn_link: record.url,
              });
              oldRecord.current = record;
            }}
          >
            Edit
          </a>
        );
      },
    },
  ];
  useEffect(() => {
    if (selectedEntity) {
      let jsArray = selectedEntity?.dependencies?.js;
      let cssArray = selectedEntity?.dependencies?.css;
      let finalData = [];
      jsArray?.forEach(element => {
        let entry = { type: 'js', url: element };
        finalData.push(entry);
      });
      cssArray?.forEach(element => {
        let entry = { type: 'css', url: element };
        finalData.push(entry);
      });
      setData(finalData);
    }
    // console.log(data);
  }, [selectedEntity, Entity, setEntity]);

  return (
    <>
      <Modal
        title="Edit"
        closable={false}
        open={editModal}
        okText="Edit"
        onOk={() => {
          if (editLibrary) {
            editLibrary.submit();
          }
        }}
        onCancel={() => showEditModal(false)}
      >
        <Form
          name="editLibrary"
          form={editLibrary}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={EditDependencies}
        >
          <Form.Item
            label="Select Library"
            name="select_library"
            rules={[
              { required: true, message: 'Please select type of Library ' },
            ]}
          >
            <Select
              style={{ width: 120 }}
              options={[
                {
                  value: 'css',
                  label: 'CSS',
                },
                {
                  value: 'js',
                  label: 'JS',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Enter CDN Link"
            name="cdn_link"
            rules={[
              { required: true, message: 'Please input CDN Link' },
              {
                type: 'url',
                message: 'Please enter a valid URL for the CDN link',
              },
            ]}
          >
            <Input style={{ width: 200 }} />
          </Form.Item>
        </Form>
      </Modal>

      {data?.length > 0 && (
        <>
          <h3>External Libraries</h3>
          <Table
            dataSource={data}
            columns={columns}
            pagination={false}
            size="small"
          />
        </>
      )}
    </>
  );
};

export default DependencyTable;
