import CheckBoxInput from './checkbox';
import Default from './default';
import EmailInput from './email';
import FileInput from './file';
import NumberInput from './number';
import PasswordInput from './password';
import Primary from './primary';
import RadioInput from './radio';
import Secondary from './secondary';

const Input = {
  Default,
  Primary,
  Secondary,
  CheckBoxInput,
  RadioInput,
  FileInput,
  PasswordInput,
  EmailInput,
  NumberInput,
};

export default Input;
