import { DownloadOutlined } from '@ant-design/icons';
import {
  Button,
  Modal,
  Spin,
  Table,
  message,
  Select,
  Row,
  Col,
  Checkbox,
  Input,
  Alert,
} from 'antd';
import axios from '../../../utils/axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Moment from 'react-moment';
import ErrorPage from '../../common/ErrorPage';
import PrevExports from '../../common/PrevExports';
import { connect } from 'react-redux';

const Export = ({ userId, userhuMain }) => {
  const [showExportModal, setShowExportModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [exportLink, setExportLink] = useState(undefined);
  const [allComponents, setAllComponents] = useState([]);
  const [componentsId, setComponentsId] = useState([]);
  const [exportType, setExportType] = useState({});
  const [exportWrappers, setExportWrappers] = useState({});
  const [componentCollection, setComponentCollection] = useState([]);
  const [isComponentLoading, setIsComponentLoading] = useState(true);
  const [netError, setNetError] = useState(false);
  // const [useIncludes, setUseIncludes] = useState(true);
  const [includeAssets, setIncludeAssets] = useState(true);
  const [isPushToRepo, setIsPushToRepo] = useState(false);
  const [repoURL, setRepoURL] = useState();
  const [branchName, setBranchName] = useState();
  const [isUserSSHKey, setIsUserSSHKey] = useState(false);
  const [subFolder,setSubFolder] = useState();
  const [commitMessage,setCommitMessage] = useState();


  const [messageApi, contextHolder] = message.useMessage();

  const { projectId, step } = useParams();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  console.log('userhuMain', userhuMain);
  const columns = [
    {
      title: 'Component Name',
      dataIndex: 'label',
    },
    {
      title: 'Export Type',
      dataIndex: 'formats',
      render: (text, record) => {
        return record.hasOwnProperty('children') ? (
          ''
        ) : (
          <Select
            mode="multiple"
            labelInValue
            defaultValue={{
              value: 'drupal',
              label: 'Drupal',
            }}
            style={{
              width: 200,
            }}
            onChange={e => handleChange(e, record._id)}
            options={[
              {
                value: 'drupal',
                label: 'Drupal',
              },
              {
                value: 'react',
                label: 'React',
              },
            ]}
          />
        );
      },
    },
    {
      title: 'Wrapper',
      dataIndex: 'wrappers',
      render: (text, record) => {
        return record.hasOwnProperty('children') ? (
          ''
        ) : (
          <Select
            mode="multiple"
            labelInValue
            style={{
              width: 200,
            }}
            onChange={e => handleWrapperChange(e, record._id)}
            options={[
              {
                value: 'block',
                label: 'Block',
              },
              {
                value: 'paragraph',
                label: 'Paragraph',
              },
              {
                value: 'header',
                label: 'Header',
              },
              {
                value: 'footer',
                label: 'Footer',
              },
            ]}
          />
        );
      },
    },
    {
      title: 'Last Updated',
      dataIndex: 'updatedAt',
      render: (text, record) => {
        return text == '-' ? (
          <>-</>
        ) : (
          <>
            <Moment format="YYYY/MM/DD">{text}</Moment>{' '}
            <Moment format="hh:mm a">{text}</Moment>
          </>
        );
      },
    },
    {
      title: 'Created date',
      dataIndex: 'createdAt',
      render: (text, record) => {
        return text == '-' ? (
          <>-</>
        ) : (
          <>
            <Moment format="YYYY/MM/DD">{text}</Moment>{' '}
            <Moment format="hh:mm a">{text}</Moment>
          </>
        );
      },
    },
  ];
  const handleChange = (value, id) => {
    const exportValue = [];
    value.map(item => {
      exportValue.push(item.value);
    });

    setExportType({ ...exportType, [id]: exportValue });
  };

  const handleWrapperChange = (value, id) => {
    const exportValue = [];
    value.map(item => {
      exportValue.push(item.value);
    });

    setExportWrappers({ ...exportWrappers, [id]: exportValue });
  };

  const onSelectChange = newSelectedRowKeys => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    checkStrictly: false,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const alertmessage = (type, massage) => {
    messageApi.open({
      type: type,
      content: massage,
    });
  };

  useEffect(() => {
    let defaultSelectedComponents = [];
    axios
      .get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/components`
      )
      .then(res => {
        // converting response for table tree structure
        let components = [
          {
            key: 'atom',
            label: 'Atom',
            createdAt: '-',
            updatedAt: '-',
            children: [...res.data.atoms],
          },
          {
            key: 'molecules',
            label: 'Molecules',
            createdAt: '-',
            updatedAt: '-',
            children: [...res.data.molecules],
          },
          {
            key: 'organisms',
            label: 'Organisms',
            createdAt: '-',
            updatedAt: '-',
            children: [...res.data.organisms],
          },
          {
            key: 'layouts',
            label: 'Drupal Components',
            createdAt: '-',
            updatedAt: '-',
            children: [...res.data.layouts],
          },
          {
            key: 'Pages',
            label: 'Pages',
            createdAt: '-',
            updatedAt: '-',
            children: [...res.data.pages],
          },
        ];

        components.map(item => {
          item.children.map(item => {
            item['formats'] = ['drupal'];
          });
        });
        // setting all components for dataSource of table
        setAllComponents(components);

        //setting default selected rows in first render
        components.map(item => {
          if (item.key !== 'atom') {
            item.children.map(item => {
              defaultSelectedComponents = [
                ...defaultSelectedComponents,
                item.key,
              ];
              setSelectedRowKeys(defaultSelectedComponents);
            });
          }
        });

        setIsComponentLoading(false);
      })
      .catch(error => {
        if (!error.status) {
          setNetError(true);
        }
        setIsComponentLoading(false);
        alertmessage('error', 'Error: Something wrong please refresh the page');
      });
  }, []);

  useEffect(() => {
    // collecting all component into an array
    let totalComponent = [];

    allComponents.map(item => {
      item.children.map(component => {
        // changeing export type of component
        if (exportType[component._id]) {
          component.formats = exportType[component._id];
        }
        if (exportWrappers[component._id]) {
          component.wrappers = exportWrappers[component._id];
        }

        totalComponent.push(component);
      });
    });
    setComponentCollection(totalComponent);

    //filter selected component from totalComponent Array

    const selectedComponent = totalComponent.filter(item =>
      selectedRowKeys.includes(item.key)
    );

    // find selected component id and push into componentsId state
    const selectedComponentId = {};

    selectedComponent.map(item => (selectedComponentId[item._id] = {
      formats: item.formats,
      wrappers: item.wrappers
    }));
    setComponentsId(selectedComponentId);
  }, [selectedRowKeys, exportType, exportWrappers]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/repositories/${userId}`
      )
      .then(res => {
        setBranchName(res.data.branchName);
        setRepoURL(res.data.gitRepoURL);
        setSubFolder(res.data.subFolder);
      })
      .catch(error => {
        console.log(error);
      });

    axios
      .get(`${process.env.REACT_APP_SERVER_BASE_URL}/users/${userId}`)
      .then(res => {
        console.log('scdcscscscsdcs', res);
        setIsUserSSHKey(res.data.isSSHKey);
      })
      .catch(error => {
        console.log(error);
      });
  }, [showExportModal]);

  return netError ? (
    <ErrorPage errorMessage="Please check your Internet connection" />
  ) : (
    <>
      {contextHolder}
      <Modal
        open={showExportModal}
        title="Export Configuration"
        footer={false}
        onCancel={() => {
          setShowExportModal(false);
          setIncludeAssets(true);
          // setUseIncludes(true);
          setExportLink(undefined);
          setIsPushToRepo(false);
          setBranchName('');
          setCommitMessage('');
          setRepoURL('');
          setSubFolder('');

        }}
      >
            {!isUserSSHKey ? (
              <>
                {' '}
                <Alert message="Please Add SSH key for Git." type="info" />
                <Checkbox disabled>Push to Repo</Checkbox>
              </>
            ) : (
              <Checkbox
                checked={isPushToRepo}
                onChange={() => setIsPushToRepo(!isPushToRepo)}
              >
                Push to Repo
              </Checkbox>
            )}
            <br />
            {isPushToRepo && (
              <>
                <div style={{ margin: '10px' }}>
                  <Input
                    placeholder="Git Repo URL"
                    value={repoURL}
                    onChange={e => {
                      setRepoURL(e.target.value);
                    }}
                  ></Input>
                </div>

                <div style={{ margin: '10px' }}>
                  <Input
                    placeholder="Branch Name"
                    value={branchName}
                    onChange={e => {
                      setBranchName(e.target.value);
                    }}
                  ></Input>
                </div>
                <div style={{ margin: '10px' }}>
                  <Input
                    placeholder="Sub Folder Name"
                    value={subFolder}
                    onChange={e => {
                      setSubFolder(e.target.value);
                    }}
                  ></Input>
                </div>
                <div style={{ margin: '10px' }}>
                  <Input
                    placeholder="Commit Message"
                    value={commitMessage}
                    onChange={e => {
                      setCommitMessage(e.target.value);
                    }}
                  ></Input>
                </div>
              </>
            )}
            <Checkbox
              checked={includeAssets}
              onChange={() => setIncludeAssets(!includeAssets)}
            >
              Includes Assets
            </Checkbox>
            <br />
            <Button
              type="primary"
              onClick={() => {
                setLoading(true);
                axios
                  .post(
                    `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}`,
                    {
                      components: componentsId,
                      includeAssets: includeAssets,
                      pushToRepo: isPushToRepo,
                      gitRepoURL: repoURL,
                      gitBranchName: branchName,
                      gitSubFolder: subFolder,
                      gitCommitMessage: commitMessage,
                    }
                  )
                  .then(res => {
               // Show the success message when the request is added to the queue
               message.success('Your request added to the queue');
                  })
                  .catch(error => {
                    alertmessage('error', 'error: unabe to export. Try again ');
                    setShowExportModal(false);
                    setIncludeAssets(true);
                    setExportLink(undefined);
                  });
                  setShowExportModal(false);
              }}
              style={{ display: 'flex', margin: 'auto' }}
            >
              Export
            </Button>
      </Modal>
      <Row justify="end">
        <Col>
          <PrevExports forceUpdate={loading} />
        </Col>
        <Col>
          <Button
            type="primary"
            disabled={hasSelected ? false : true}
            onClick={() => {
              setShowExportModal(true);
              setLoading(false);
            }}
          >
            Export
          </Button>
        </Col>
      </Row>

      <div>
        <div
          style={{
            marginBottom: 16,
          }}
        >
          <span
            style={{
              marginLeft: 8,
            }}
          >
            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
          </span>
        </div>
        {isComponentLoading ? (
          <Spin
            tip="Loading..."
            size="large"
            style={{ display: 'flex', justifyContent: 'center' }}
          />
        ) : (
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={allComponents}
            pagination={false}
            size="small"
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    userId: state.User.id,
    userhuMain: state.User,
  };
};
export default connect(mapStateToProps, null)(Export);
