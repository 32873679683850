import React from 'react';
import { Layout, Typography, Avatar, Dropdown, Space } from 'antd';
import { Link, useParams } from 'react-router-dom';

import { setToken } from '../../../redux/login/action';
import { connect } from 'react-redux';
import { UserOutlined } from '@ant-design/icons';

const { Header: AntHeader } = Layout;
const { Title } = Typography;

const Header = ({ setToken, islogout }) => {
  const { userId } = useParams();

  const handleLogout = () => {
    setToken(null);
  };
  const profileId = window.localStorage.getItem('userId');

  const items = userId
    ? [
        {
          label: (
            <Link to="/" onClick={handleLogout}>
              Logout
            </Link>
          ),

          key: '0',
        },
      ]
    : [
        {
          label: <Link to={`/users/${profileId}/edit`}>My Profile</Link>,
          key: '0',
        },
        {
          label: (
            <Link to="/" onClick={handleLogout}>
              Logout
            </Link>
          ),

          key: '1',
        },
      ];

  return (
    <>
      <AntHeader
        style={{
          padding: '10px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Title style={{ color: 'white', paddingTop: '10px' }} level={2}>
          Proton Pattern
        </Title>
        {islogout ? (
          <Dropdown
            menu={{
              items,
            }}
            trigger={['click']}
          >
            <a onClick={e => e.preventDefault()}>
              <Avatar style={{ backgroundColor: '#fff' }}>
                <UserOutlined style={{ color: '#1890ff' }} />
              </Avatar>
            </a>
          </Dropdown>
        ) : (
          ''
        )}
      </AntHeader>
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  setToken: token => {
    dispatch(setToken(token));
  },
});

export default connect(null, mapDispatchToProps)(Header);
