const getHtml = () => {
  return `
    <div>
      <h1>title</h1>
      <div>
        <p>This is the body of default Video</p>
      </div>
    </div>
  `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const DefaultVideo = { getHtml, getCss, getJs };

export default DefaultVideo;
