import Login from '../../components/Login';
import ProjectList from '../../components/Projects/ProjectList';
import Steps from '../../components/steps';
import Preview from '../../components/preview';
import UserListing from '../../components/users/UserListing';
import UserProfile from '../../components/users/UserProfile';
const routes = [
  {
    title: 'Login',
    route: '/',
    component: <Login />,
    layout: true,
    authenticated: false,
  },
  {
    title: 'Project Listing',
    route: '/projects',
    component: <ProjectList />,
    layout: true,
    authenticated: true,
  },
  {
    title: 'User Listing',
    route: '/users',
    component: <UserListing />,
    layout: true,
    authenticated: true,
  },
  {
    title: 'User Profile',
    route: '/users/:userId/edit',
    component: <UserProfile />,
    layout: true,
    authenticated: true,
  },
  {
    title: 'Home',
    route: '/projects/:projectId/:step',
    component: <Steps />,
    layout: true,
    authenticated: true,
  },
  {
    title: 'Preview',
    route: '/projects/:projectId/:step/:preview',
    component: <Preview />,
    layout: false,
  },
  {
    title: 'ProjectId',
    route: '/projects/:projectId',
    component: <Steps />,
    layout: true,
    authenticated: true,
  },
];
export default routes;
