const getHtml = () => {
    return `
      <a href="#" class="btn btn-primary stretched-link">Button link</a>
    `;
  };
  
  const getCss = () => {
    return ``;
  };
  
  const getJs = () => {
    return ``;
  };
  
  const ButtonLink = { getHtml, getCss, getJs };
  
  export default ButtonLink;
  