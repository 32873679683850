const getHtml = () => {
  return `
      <h2>Example heading <span class="badge bg-secondary">New</span></h2>
      `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const H2Badge = { getHtml, getCss, getJs };

export default H2Badge;
