import React from 'react';
import { Layout } from 'antd';

const { Footer: AntFooter } = Layout;

const Footer = () => {
  return (
    <>
      <AntFooter style={{ textAlign: 'center' }}>
        Proton Pattern ©2023
      </AntFooter>
    </>
  );
};

export default Footer;
