// import { Collapse } from 'antd';
import { UploadOutlined, PlusOutlined, CopyOutlined } from '@ant-design/icons';
import {
  Button,
  message,
  Modal,
  Space,
  Table,
  Upload,
  Form,
  Radio,
  Spin,
  Row,
  Col,
  Tooltip,
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useSearchParams, useParams } from 'react-router-dom';
import { setGlobalAssets } from '../../../redux/global-configurations/actions';
import axios from '../../../utils/axios';
import ErrorPage from '../../common/ErrorPage';

const GlobalAssets = ({
  setCurrentAssets,
  currentAssets,
  setIsNextDisabled,
  accessToken,
  projectRole,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [totalAssets, setTotalAssets] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openAddModal, setOpenAddModal] = useState(false);
  const [assetId, setAssetId] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [recordToEdit, setRecordToEdit] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [netError, setNetError] = useState(false);
  const isAssetsUpdated = useRef(true);

  const { projectId, step } = useParams();

  const [AddAssetForm] = Form.useForm();
  const [EditAssetForm] = Form.useForm();

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Link',
      dataIndex: 'file',
      key: 'file',
      render: (text, record) => (
        <>
          <a href={text} target="_blank" style={{ marginRight: '8px' }}>
            Link
          </a>
          <CopyOutlined
            style={{ fontSize: '16px', color: '#08c' }}
            theme="outlined"
            onClick={() => handleCopyLink(text)}
          />
        </>
      ),
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: '',
      render: (_, record) => (
        <Space size="middle">
          <Tooltip
            title={
              projectRole === 'developer'
                ? 'Request team lead for updating these changes.'
                : ''
            }
          >
            <a
              onClick={() => {
                setShowModal(true);
                setRecordToDelete(record);
              }}
              disabled={projectRole === 'developer'}
            >
              Delete
            </a>
          </Tooltip>
          <Tooltip
            title={
              projectRole === 'developer'
                ? 'Request team lead for updating these changes.'
                : ''
            }
          >
            <a
              onClick={() => {
                setOpenEditModal(true);
                setRecordToEdit(record);
              }}
              disabled={projectRole === 'developer'}
            >
              Edit
            </a>
          </Tooltip>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (recordToEdit) {
      EditAssetForm.setFieldsValue({
        excludeFromExport: recordToEdit.excludeFromExport,
      });
    }
  }, [recordToEdit]);

  useEffect(() => {
    let pageNoFromQuery = window.location.search.split('=');
    let offset = activePage ? (activePage - 1) * 10 : 0;
    setActivePage(activePage ? activePage : 1);
    // setSearchParams({
    //   page: offset+1,
    // });
    if (currentAssets && isAssetsUpdated.current) {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}?limit=10&offset=${offset}`
        )
        .then(data => {
          if (data?.data) {
            setTotalAssets(data.data.totalDocs);
            setCurrentAssets(data.data.docs);
            setIsNextDisabled(false);
            setIsLoading(false);
          }
        })
        .catch(err => {
          if (!err.status) {
            setNetError(true);
            setIsLoading(false);
          }
          console.log(err);
        });
      isAssetsUpdated.current = false;
    }
  }, [currentAssets]);

  const updateGlobalAssets = currentPage => {
    let offset = currentPage ? (currentPage - 1) * 10 : 0;
    setSearchParams({
      page: currentPage,
    });
    setActivePage(currentPage);
    axios
      .get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}?limit=10&offset=${offset}`
      )
      .then(data => {
        if (data?.data) {
          setCurrentAssets(data.data.docs);
          isAssetsUpdated.current = true;
          setIsLoading(false);
        }
      })
      .catch(err => {
        if (!err.status) {
          setNetError(true);
          setIsLoading(false);
        }

        console.log(err);
      });
  };

  const handleDelete = () => {
    if (recordToDelete) {
      const name = recordToDelete.name;

      axios
        .delete(
          `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}/${recordToDelete._id}`
        )
        .then(res => {
          message.success(`${name} file is deleted successfully`);
          updateGlobalAssets(1);
        })
        .catch(error => {
          if (!error.status) {
            setNetError(true);
            setIsLoading(false);
          }

          message.error(`something went wrong! Please try again`);
        });
    }
  };

  const handleCopyLink = link => {
    // this method will copy link recieved to clipboard.
    navigator.clipboard
      .writeText(link)
      .then(() => {
        message.success('Link copied to clipboard');
      })
      .catch(() => {
        message.error('Failed to copy link to clipboard');
      });
  };

  const props = {
    name: 'file',
    action: `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}`,
    headers: {
      authorization: 'authorization-text',
      token: accessToken,
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
      }
      if (info.file.status === 'done') {
        setAssetId(info.file.response._id);
        message.success(`${info.file.name} file uploaded successfully`);
        updateGlobalAssets(Math.floor(totalAssets / 10) + 1);
      } else if (info.file.status === 'error') {
        if (info.file?.response?.errors[0].msg.includes('already exists')) {
          message.error(
            `${info.file.name} ${info.file.response.errors[0].msg}.`
          );
        } else {
          message.error(`${info.file.name} file upload failed.`);
        }
      }
    },
  };
  const handleOk = () => {
    setShowModal(false);
    setIsLoading(true);
    handleDelete();
  };
  const handleCancel = () => {
    setShowModal(false);
  };

  const handleAddAssetOk = () => {
    AddAssetForm.submit();
  };

  const onFinish = async values => {
    setOpenAddModal(false);
    setOpenEditModal(false);
    await axios
      .patch(
        `${
          process.env.REACT_APP_SERVER_BASE_URL
        }/projects/${projectId}/${step}/${
          recordToEdit ? recordToEdit._id : assetId
        }`,
        { excludeFromExport: values.excludeFromExport }
      )
      .then(res => {
        message.success(`${recordToEdit ? 'Updated' : 'Added'} successfully`);
        setAssetId(null);
        setIsLoading(false);
      })
      .catch(error => message.error(`Error : Something worng. Try Again`));

    AddAssetForm.resetFields();
    EditAssetForm.resetFields();

    setRecordToEdit(null);
  };

  return isLoading ? (
    <Spin
      tip="Loading..."
      size="large"
      style={{ display: 'flex', justifyContent: 'center' }}
    />
  ) : netError ? (
    <ErrorPage errorMessage="Please check your Internet connection" />
  ) : (
    <center>
      <Modal open={showModal} onOk={handleOk} onCancel={handleCancel}>
        <h3>Are you sure? You want to delete this asset. </h3>
      </Modal>

      <Modal
        open={openAddModal}
        onCancel={() => {
          setOpenAddModal(false);
          AddAssetForm.resetFields();
        }}
        onOk={handleAddAssetOk}
        title="Add Asset"
      >
        <Form
          form={AddAssetForm}
          initialValues={{ excludeFromExport: false }}
          onFinish={onFinish}
          name="Add Asset Form"
        >
          <Form.Item
            label="Upload Asset"
            name="asset"
            rules={[{ required: true, message: 'Please Upload Asset' }]}
          >
            <Upload {...props}>
              <Button icon={<UploadOutlined />}>Upload File</Button>
            </Upload>
          </Form.Item>

          <Form.Item label="Exclude from export" name="excludeFromExport">
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false} defaultChecked>
                No
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Edit Asset"
        name="editExcludeFromExportModal"
        open={openEditModal}
        onCancel={() => {
          setOpenEditModal(false);
          EditAssetForm.resetFields();
          setRecordToEdit(null);
        }}
        onOk={() => {
          setIsLoading(true);
          EditAssetForm.submit();
        }}
      >
        <Form form={EditAssetForm} onFinish={onFinish}>
          <Form.Item label="Exclude from export " name="excludeFromExport">
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>

      <Row justify="end" style={{ marginTop: 20, marginBottom: 20 }}>
        <Col>
          <Button type="primary" onClick={() => setOpenAddModal(true)}>
            <PlusOutlined />
            Add Asset
          </Button>
        </Col>
      </Row>

      {currentAssets?.length > 0 && (
        <Table
          dataSource={currentAssets}
          size="small"
          columns={columns}
          onChange={e => {
            setIsLoading(true);
            updateGlobalAssets(e.current);
          }}
          pagination={{
            defaultCurrent: 1,
            total: totalAssets,
            current: activePage,
            showSizeChanger: false,
          }}
        />
      )}
    </center>
  );
};

const mapStateToProps = state => {
  return {
    currentAssets: state.GlobalConfigurations.initialAssets,
    accessToken: state.Token.token,
    projectRole: state.ProjectRole.projectRole,
  };
};

const mapDispatchToProps = dispatch => ({
  setCurrentAssets: assets => {
    dispatch(setGlobalAssets(assets));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalAssets);
