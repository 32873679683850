import { Alert, Button, Modal, Spin, Table, message } from 'antd';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import axios from '../../../utils/axios';
import ErrorPage from '../ErrorPage';
import { store } from '../../../store';

const BackupList = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [backupData, setBackupData] = useState([]);
  const [totalDocs, setTotalDocs] = useState();
  const [netError, setNetError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [messageApi, contextHolder] = message.useMessage();

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
      render: text => {
        return <>{(text / 1024).toFixed(2)} KB</>;
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'creates_at',
      render: text => {
        return !text ? (
          <>-</>
        ) : (
          <>
            <Moment format="YYYY/MM/DD">{text}</Moment>{' '}
            <Moment format="hh:mm a">{text}</Moment>
          </>
        );
      },
    },
    {
      title: 'Download Link',
      dataIndex: 'file',
      key: 'download_link',
      render: (text, record) => {
        return (
          <a
            type="primary"
            onClick={() => {
              fetch(
                `${process.env.REACT_APP_SERVER_BASE_URL}/backups/${record._id}/download`,
                {
                  headers: {
                    token: store.getState().Token.token,
                  },
                }
              ).then(response => {
                response.blob().then(blob => {
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = `${record.name}`;
                  a.click();
                });
              });
            }}
          >
            Download
          </a>
        );
      },
    },
  ];

  useEffect(() => {
    let offset = currentPage ? (currentPage - 1) * 10 : 0;
    axios
      .get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/backups?limit=10&offset=${offset}`
      )
      .then(res => {
        setBackupData(res.data.docs);
        setTotalDocs(res.data.totalDocs);
        setIsLoading(false);
      })
      .catch(error => {
        if (!error.status) {
          setNetError(true);
          setIsLoading(false);
        }
        messageApi.open({
          type: 'error',
          content: 'Error: Something went wrong!!!',
        });
        console.log(error);
      });
  }, [isModalOpen, currentPage]);

  return netError ? (
    <ErrorPage errorMessage="Please check your Internet connection" />
  ) : (
    <>
      {contextHolder}
      <Button
        type="link"
        onClick={() => setIsModalOpen(true)}
        style={{ right: '10px' }}
      >
        Backups
      </Button>
      <Modal
        title="Backups"
        open={isModalOpen}
        width={1200}
        style={{ top: '50px' }}
        onOk={() => {
          setIsModalOpen(false);
          setCurrentPage(1);
        }}
        onCancel={() => {
          setIsModalOpen(false);
          setCurrentPage(1);
        }}
      >
        <Spin
          tip="Loading..."
          size="large"
          spinning={isLoading}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Alert
            message="Note: Backups are automatically deleted after 7 days."
            type="info"
            style={{ marginBottom: 20 }}
          />
          {backupData ? (
            <Table
              dataSource={backupData}
              size="small"
              columns={columns}
              onChange={e => {
                setCurrentPage(e.current);
                setIsLoading(true);
              }}
              pagination={{
                defaultCurrent: 1,
                total: totalDocs,
                current: currentPage,
                showSizeChanger: false,
              }}
            />
          ) : (
            <p>No Backups found.</p>
          )}
        </Spin>
      </Modal>
    </>
  );
};

export default BackupList;
