import { Form, Input, Select } from 'antd';
import { useEffect, useState } from 'react';

const ImageTraits = ({
  form,
  globalImageStyleOptions,
  child_level_1_index,
  child_level_2_index,
  child_level_3_index,
}) => {

  const [selectedImageStyleOptions, setSelectedImageStyleOptions] = useState(
    []
  );

  const handleImageTraits = label => {
    setSelectedImageStyleOptions(label);
  };

  const naming_condition = field => {
    if (child_level_3_index !== undefined) {
      return `${field}_${child_level_1_index}_${child_level_2_index}_${child_level_3_index}`;
    } else if (child_level_2_index !== undefined) {
      return `${field}_${child_level_1_index}_${child_level_2_index}`;
    } else if (child_level_1_index !== undefined) {
      return `${field}_${child_level_1_index}`;
    } else {
      return `${field}`;
    }
  };

  let name_for_source = naming_condition('image_src_link');
  let name_for_alt_text = naming_condition('image_alt_text');
  let name_for_style = naming_condition('image_style');

  return (
    <>
      <Form.Item
        label="Default Image source"
        name={name_for_source}
        rules={[
          {
            required: true,
            message: 'Please input image source link',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Default Image Alt Text"
        name={name_for_alt_text}
        rules={[
          {
            required: true,
            message: 'Please input image alt text',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Default Image styles"
        name={name_for_style}
        rules={[
          {
            required: true,
            message: 'Please select any of image style from the list',
          },
        ]}
      >
        <Select
          style={{ width: 120 }}
          onChange={(value, label) => {
            handleImageTraits(label);
          }}
          options={[...globalImageStyleOptions]}
        />
      </Form.Item>
    </>
  );
};

export default ImageTraits;
