const getHtml = () => {
  return `
      <div>
        <h1>title</h1>
        <div>
          <p>This is the body of primary Text</p>
        </div>
      </div>
    `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const PrimaryText = { getHtml, getCss, getJs };

export default PrimaryText;
