import { Divider, Table, Tooltip } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';

const ComponentListing = ({
  options,
  componentType,
  collectData,
  usedInfo,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState();
  useEffect(() => {
    let defaultSelected = [];
    options?.map(item => {
      if (!defaultSelected.includes(item.key)) {
        defaultSelected.push(item.key);
      }
    });
    setSelectedRowKeys([...defaultSelected]);
  }, []);

  useEffect(() => {
    let selectedData = {};
    selectedData[componentType] = [];
    selectedRowKeys?.map(key => {
      options?.map(item => {
        if (key == item.key) {
          !selectedData[componentType].includes(item.id) &&
            selectedData[componentType].push(item.id);
        }
      });
    });
    collectData({ ...selectedData });
  }, [selectedRowKeys]);

  const columns =
    usedInfo !== 'childrenDetails'
      ? [
          {
            key: 'disabled',
            width: 50,
            render: (text, record) => {
              return record.disabled ? (
                <Tooltip title="This component uses traits. You may have to add the traits in the markup again">
                  <WarningOutlined style={{ color: 'orange' }} />
                </Tooltip>
              ) : (
                ''
              );
            },
          },
          {
            title: 'Name',
            dataIndex: 'label',
          },
        ]
      : [
          {
            key: 'disabled',
            width: 50,
            render: (text, record) => {
              return record.disabled ? (
                <Tooltip title="This component uses traits. You may have to add the traits in the markup again">
                  <WarningOutlined style={{ color: 'orange' }} />
                </Tooltip>
              ) : (
                ''
              );
            },
          },
          {
            title: 'Name',
            dataIndex: 'label',
          },
          {
            title: 'Count',
            dataIndex: 'count',
          },
        ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    selectedRowKeys,
    onChange: newSelectedRowKeys => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    checkStrictly: false,
  };

  return (
    <>
      {usedInfo !== 'childrenDetails' && <Divider />}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p style={{ color: '#40a9ff', textTransform: 'capitalize' }}>
          {componentType}
        </p>
        {!usedInfo && (
          <p>
            {selectedRowKeys?.length} {componentType} selected out of{' '}
            {options?.length}
          </p>
        )}
      </div>
      <Table
        rowSelection={usedInfo ? false : rowSelection}
        size="small"
        columns={columns}
        dataSource={options}
        scroll={{ y: 240 }}
        pagination={false}
      />
    </>
  );
};
export default ComponentListing;
