import {
  PlusOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  MinusOutlined,
  LockFilled,
} from '@ant-design/icons';
import _ from 'lodash';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Row,
  Typography,
  Table,
  Tooltip,
  Spin,
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import uuid from 'react-uuid';
import {
  setAtoms,
  setMolecules,
  setOrganisms,
} from '../../../../redux/design-components/actions';
import CONSTANTS from '../../../../utils/constants';
import Editor from '../../../common/editor';
import EditorConfig from './editor-config';
import axios from '../../../../utils/axios';
import InputCode from '../../../common/InputCode';
import { Select } from 'antd';
import DependencyTable from '../../../common/DependencyTable';
import LastModified from '../../../common/LastModified.js';
import WarningModal from '../../../common/modals/WarningModal';
import { replaceVariables } from '../../../../utils/helpers/replaceTraits';

const { Item: MenuItem, SubMenu } = Menu;
const { Text } = Typography;

const updatedBlocks = (atoms, type) => {
  const configs = { ...EditorConfig() };
  atoms.forEach(atom => {
    atom.variations.forEach(variation => {
      variation._id = atom._id;
      variation.category = atom.label;
      variation.type = type;
      variation.content = `<div>${variation.html}</div> <style>${variation.css}</style>`;
      configs.blockManager.blocks.push(variation);
    });
  });
  return configs;
};

const MoleculesEditor = ({
  atoms,
  molecules,
  setMolecules,
  globalConfigs,
  organisms,
  currentConfigs,
  projectRole,
}) => {
  const [selectedMolecule, setSelectedMolecule] = useState(null);
  const [currentNameofMolecule, setCurrentNameofMolecule] = useState(undefined);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedVariation, setSelectedVariation] = useState(null);
  const [isNewMolecule, setIsNewMolecule] = useState(false);
  const [addNewVariationForm] = Form.useForm();
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [showJSBlock, setShowJSBlock] = useState(false);
  const [currentCompJs, setCurrentCompJs] = useState('');
  const { projectId, step } = useParams();
  const [collapsed, setCollapsed] = useState(false);
  const [externalLibraryModal, setExternalLibraryModal] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [usingComponent, setUsingComponent] = useState();
  const [variationData, setVariationData] = useState();
  const [updatedComponent, setUpdatedComponent] = useState({
    organisms: [],
    layouts: [],
    pages: [],
  });
  const [usedInfo, setUsedInfo] = useState(false);
  const [usagesDetail, setUsagesDetail] = useState({});
  const [childrenInfoModalOpen, setChildrenInfoModalOpen] = useState(false);
  const [usedChildren, setUsedChildren] = useState();
  // const [unlockComponent, setUnlockComponent] = useState(false);
  const [isPreviewMode, setIsPreviewMode] = useState(true);
  const [externalLibrary] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);

  const editorRef = useRef(null);

  const didMoleculesUpdate = useRef(false);

  const [draggedcomphtml, setdraggedcomphtml] = useState([]);
  const isUsedChildrenLoaded = useRef(false);

  console.log(molecules);

  useEffect(() => {
    let newArray = [];

    if (selectedMolecule && draggedcomphtml.length === 0) {
      const newMolecules = [...molecules];
      const selectedMoleculeIndex = molecules.findIndex(
        molecule => molecule.key === selectedMolecule.key
      );
      const selectedVariationIndex = newMolecules[
        selectedMoleculeIndex
      ].variations.findIndex(variation => variation.key === selectedVariation);

      newMolecules[selectedMoleculeIndex].variations[
        selectedVariationIndex
      ]?.traits?.forEach(trait => {
        if (trait.parent) {
          let html;

          if (trait.parent.type === 'atom') {
            atoms.forEach(atom => {
              if (atom._id === trait.parent.id) {
                atom.variations.forEach(variation => {
                  if (variation.key === trait.parent.key) {
                    html = variation.html;
                  }
                });
              }
            });
          }

          newArray.push({
            key: trait.parent.key,
            prefix: trait.name
              .replace(trait.parentname, '')
              .trim()
              .slice(0, -1),
            html: html,
          });
        }
      });

      const uniqueArray = [
        ...new Set(newArray.map(obj => JSON.stringify(obj))),
      ].map(str => JSON.parse(str));

      setdraggedcomphtml(uniqueArray);
    }
  }, [molecules, selectedMolecule]);

  useEffect(() => {
    if (molecules && selectedVariation && didMoleculesUpdate.current) {
      molecules.forEach(molecule => {
        if (molecule.variations) {
          const moleculeVariation = molecule.variations.find(
            variation => selectedVariation === variation.key
          );
          if (moleculeVariation) {
            let editorComponents = moleculeVariation.html
              ? moleculeVariation.html
              : moleculeVariation.html;
            if (moleculeVariation.css) {
              editorComponents = `${editorComponents}<style>${moleculeVariation.css}</style>`;
            }
            if (editorRef.current) {
              updateEditorComponents(editorRef.current, editorComponents);
            }
            didMoleculesUpdate.current = false;
            isUsedChildrenLoaded.current = false;
          }
        }
      });
    }
  }, [molecules]);

  useEffect(() => {
    if (selectedMolecule && selectedVariation) {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/components/molecules/${selectedMolecule._id}/variations/${selectedVariation}/usages`
        )
        .then(res => {
          setUsagesDetail(res.data);
          setUsingComponent(res.data);
        })
        .catch(error => {
          console.log(error);
        });

      axios
        .get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/components/molecules/${selectedMolecule._id}/variations/${selectedVariation}/children`
        )
        .then(res => {
          setUsedChildren(res.data);
          if (res.status === 200) {
            isUsedChildrenLoaded.current = true;
            setIsLoading(false);
          }
        })
        .catch(error => {
          console.log(error);
          setIsLoading(false);
        });
    }
  }, [
    selectedMolecule,
    selectedVariation,
    isWarningModalOpen,
    molecules,
    draggedcomphtml,
  ]);

  const handleInlineCss = e => {
    const newMolecules = [...molecules];
    const selectedMoleculeIndex = molecules.findIndex(
      molecule => molecule.key === selectedMolecule.key
    );
    const selectedVariationIndex = newMolecules[
      selectedMoleculeIndex
    ].variations.findIndex(variation => variation.key === selectedVariation);
    newMolecules[selectedMoleculeIndex].variations[
      selectedVariationIndex
    ].inLineCss = e.target.checked;
    setMolecules(newMolecules);
  };

  const addDependencies = values => {
    const newMolecules = [...molecules];

    newMolecules.forEach(molecule => {
      if (molecule.key === selectedMolecule.key) {
        molecule.variations.forEach(variation => {
          if (variation.key === selectedVariation) {
            if (variation.dependencies) {
              variation?.dependencies[values.select_library].push(
                values.cdn_link
              );
            } else {
              variation.dependencies = { js: [], css: [] };
              variation?.dependencies[values.select_library].push(
                values.cdn_link
              );
            }
          }
        });
      }
    });

    setMolecules(newMolecules);
    setExternalLibraryModal(false);
    externalLibrary.resetFields();
  };

  const handleDeleteMolecule = async moleculeToDelete => {
    Modal.confirm({
      title: 'Delete Alert.',
      content: 'Are you sure? This will Permanently Delete Entire Molecule!',
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        const updatedMolecules = molecules.filter(
          molecule => molecule.key !== moleculeToDelete.key
        );
        await axios
          .delete(
            `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}/molecules/${moleculeToDelete._id}`
          )
          .then(res => {
            setMolecules(updatedMolecules);
            <Alert message="Molecule deleted successfully" type="success" />;
          })
          .catch(error => {
            <Alert message="Molecule not deleted!" type="error" />;
            console.log('molecule not deleted', error);
          });
      },
    });
  };

  const onFinish = values => {
    const { variationName, moleculeName } = values;
    if (isNewMolecule) {
      const newMolecules = [...molecules];

      const existingMoleculeIndex = newMolecules.findIndex(
        molecule => molecule.label === moleculeName
      );

      if (existingMoleculeIndex !== -1) {
        message.error('Molecule with Same Name Already Exist.');
      } else {
        const newVariationKey = uuid();
        const createMolecule = {
          label: moleculeName,
          key: newVariationKey,
          variations: [
            {
              label: variationName,
              key: `default-${newVariationKey}`,
              html: ``,
              devices: [],
              html: '',
            },
          ],
          defaultVariation: '',
        };
        newMolecules.push(createMolecule);

        addNewVariationForm.resetFields();

        axios
          .post(
            `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}/molecules`,
            createMolecule
          )
          .then(data => {
            setMolecules(newMolecules);
            message.success('Molecule created');
            axios
              .get(
                `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}`
              )
              .then(data => {
                if (data) {
                  didMoleculesUpdate.current = true;
                  setAtoms([...data.data?.atoms]);
                  setMolecules([...data.data?.molecules]);
                  setOrganisms([...data.data?.organisms]);
                }
              })
              .catch(err => {
                console.log(err, 'error');
                message.error('Something went Wrong, Please refresh');
              });
          })
          .catch(error => {
            console.error(error);
            message.error('Molecule not created');
          });
        setIsModalOpen(false);
        setIsNewMolecule(false);
      }
    } else {
      const selectedMoleculeIndex = molecules.findIndex(
        molecule => molecule.key === selectedMolecule?.key
      );
      const newMolecules = [...molecules];

      const existingVariationIndex = newMolecules[
        selectedMoleculeIndex
      ].variations.findIndex(
        (variation, index) => variation.label === variationName
      );

      if (existingVariationIndex !== -1) {
        message.error('Variation with Same Name Already Exist.');
      } else {
        const newVariationKey = uuid();
        const createVariation = {
          label: variationName,
          key: newVariationKey,
          html: selectedMolecule.defaultVariation,
          css: '',
          js: '',
          devices: [],
        };
        newMolecules[selectedMoleculeIndex].variations.push(createVariation);
        addNewVariationForm.resetFields();

        axios
          .post(
            `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/components/molecules/${newMolecules[selectedMoleculeIndex]._id}/variations`,
            { ...createVariation, components: updatedComponent }
          )
          .then(res => {
            setMolecules(newMolecules);
            message.success('Variation created successfully');
          })
          .catch(error => {
            console.log(error);
            message.success('Error: Variation not created');
          });

        setIsModalOpen(false);
        setSelectedVariation(newVariationKey);
      }
    }
  };

  const handleSaveVariation = (selectedAtom, selectedVariation) => {
    if (editorRef.current)
      if (editorRef.current) {
        const html = editorRef.current.getHtml();
        const css = editorRef.current.getCss();

        console.log('html',html);

        const selectedMoleculeIndex = molecules.findIndex(
          atom => atom.key === selectedAtom.key
        );
        const newMolecules = [...molecules];

        const selectedVariationIndex = molecules[
          selectedMoleculeIndex
        ].variations.findIndex(
          variation => variation.key === selectedVariation.key
        );
        const variationForUpdate =
          newMolecules[selectedMoleculeIndex].variations[
            selectedVariationIndex
          ];

        if (variationForUpdate.html) {
          variationForUpdate.html = html;
          variationForUpdate.html = html;
        } else {
          variationForUpdate.html = html;
        }

        variationForUpdate.css = css;

        let modifiedHTML = html;

        if (draggedcomphtml?.length) {
          console.log(draggedcomphtml);

          const atomCounts = {};

          draggedcomphtml.forEach(comp => {
            const key = comp.key;

            if (!atomCounts.hasOwnProperty(key)) {
              atomCounts[key] = 0;
            }

            const count = atomCounts[key];
            atomCounts[key] += 1;

            const pattern = `<!--START-ATOM-{{${key}}}-->([\\s\\S]*?)<!--END-ATOM-{{${key}}}-->`;

            let modifiedContent = comp.html.replace(
              /{{([^}]+)}}|start_curly([^]+?)_end_curly|<!--(.*?)-->/g,
              function (subMatch, p1, p2, p3) {
                if (p1) {
                  console.log(p1);
                  return `{{ ${comp.prefix}_${p1.trim()} }}`;
                } else if (p2) {
                  console.log(p2);
                  return `start_curly_${comp.prefix}${p2}_end_curly`;
                } else if (p3) {
                  console.log(p3);
                  return `<!--${p3}-->`;
                }
              }
            );

            console.log({ modifiedContent });

            // update if loops are used -----
            modifiedContent = modifiedContent.replace(
              /<!--(START-LOOP|END-LOOP).*?{{([^}]+)}}.*?-->/g,
              function (subMatch, loopType, doubleBracesContent) {
                console.log(loopType);
                console.log(doubleBracesContent);
                return `<!--${loopType}-{{${
                  comp.prefix
                }_${doubleBracesContent.trim()}}}-->`;
              }
            );

            console.log({ modifiedContent });

            var occurrences = 0;
            modifiedHTML = modifiedHTML.replace(
              new RegExp(pattern, 'g'),
              function (match) {
                return occurrences++ === count ? modifiedContent : match;
              }
            );
          });

          variationForUpdate.html = modifiedHTML;
        }

        /////////////////////////////////////////////

        delete newMolecules[selectedMoleculeIndex].__v;
        newMolecules[selectedMoleculeIndex].variations?.map(
          (variation, index) => {
            delete newMolecules[selectedMoleculeIndex].variations[index]
              .devices;
          }
        );

        axios
          .patch(
            `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}/molecules/${newMolecules[selectedMoleculeIndex]._id}/variations/${variationForUpdate.key}`,
            { ...variationForUpdate, components: updatedComponent }
          )
          .then(data => {
            message.success('Variation saved');
            axios
              .get(
                `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}`
              )
              .then(data => {
                if (data) {
                  didMoleculesUpdate.current = true;
                  setAtoms([...data.data?.atoms]);
                  setMolecules([...data.data?.molecules]);
                  setOrganisms([...data.data?.organisms]);
                  setIsLoading(false);
                }
              })
              .catch(err => {
                console.log(err, 'error');
                message.error('Something went Wrong! please refresh');
                setIsLoading(false);
              });
          })
          .catch(error => {
            console.error(error);
            message.error('Variation not saved');
            setIsLoading(false);
          });
      }
    setdraggedcomphtml([]);
  };

  const handleOk = () => {
    if (addNewVariationForm) {
      addNewVariationForm.submit();
    }
  };

  const handleDelete = () => {
    const newMolecules = [...molecules];
    const selectedMoleculeIndex = molecules.findIndex(
      molecule => molecule.key === selectedMolecule.key
    );
    let updatedVariations = newMolecules[
      selectedMoleculeIndex
    ].variations.filter(variation => variation.key !== selectedVariation);
    newMolecules[selectedMoleculeIndex].variations = updatedVariations;

    axios
      .delete(
        `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}/molecules/${selectedMolecule._id}/variations/${selectedVariation}`
      )
      .then(data => {
        message.success('Variation deleted');
        axios
          .get(
            `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}`
          )
          .then(data => {
            if (data) {
              didMoleculesUpdate.current = true;
              setAtoms([...data.data?.atoms]);
              setMolecules([...data.data?.molecules]);
              setOrganisms([...data.data?.organisms]);
            }
          })
          .catch(err => {
            console.log(err, 'error');
            message.error('Something went Wrong! please refresh');
          });
      })
      .catch(error => {
        console.error(error);
        message.error('Variation not deleted');
      });
    setIsDeleteModalOpen(false);
    isUsedChildrenLoaded.current = false;
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setIsNewMolecule(false);
    setIsDeleteModalOpen(false);
    setIsRenameModalOpen(false);
  };

  const handleMenuItemSelect = ({ key }) => {
    if (!key.startsWith(CONSTANTS.KEYS.ADD_NEW_VARIATION_MENU_ITEM_KEY)) {
      setSelectedVariation(key);
    }
  };

  const handleAddNewVariation = molecule => {
    if (molecule) setSelectedMolecule(molecule);
    setIsModalOpen(true);
  };

  const handleAddNewMolecule = () => {
    setIsModalOpen(true);
    setIsNewMolecule(true);
  };

  const handleRename = () => {
    const newMolecules = [...molecules];
    const selectedMoleculeIndex = molecules.findIndex(
      molecule => molecule.key === selectedMolecule.key
    );
    newMolecules[selectedMoleculeIndex].variations.forEach(variation => {
      if (variation.key === selectedVariation) {
        variation.label = currentNameofMolecule;
      }
    });
    setMolecules(newMolecules);
    setIsRenameModalOpen(false);
  };

  const handleAddJS = () => {
    const selectedMoleculeIndex = molecules.findIndex(
      atom => atom.key === selectedMolecule.key
    );
    const newMolecules = [...molecules];
    const selectedVariationIndex = molecules[
      selectedMoleculeIndex
    ].variations.findIndex(variation => variation.key === selectedVariation);
    newMolecules[selectedMoleculeIndex].variations[selectedVariationIndex].js =
      currentCompJs;
    setMolecules(newMolecules);
    setShowJSBlock(false);
  };

  const importTheRequiredProperties = properties => {
    console.log({ properties });
    let newMolecules = [...molecules];
    const selectedMoleculeIndex = molecules.findIndex(
      molecule => molecule.key === selectedMolecule.key
    );
    const selectedVariationIndex = molecules[
      selectedMoleculeIndex
    ].variations.findIndex(variation => variation.key === selectedVariation);

    let traitsToBeCopied = properties.traits ? [...properties.traits] : [];

    const pattern = new RegExp(`<!--START-ATOM-{{${properties.key}}}-->`, 'g');
    const count = (editorRef.current.getHtml().match(pattern) || []).length;

    traitsToBeCopied = traitsToBeCopied.map((trait, index) => {
      let modifiedChildren = trait?.children;

      trait = {
        ...trait,
        key: uuid(),
        name: _.snakeCase(
          `${newMolecules[selectedMoleculeIndex].label}_${newMolecules[selectedMoleculeIndex].variations[selectedVariationIndex].label}_${count}_${trait.name}`
        ),
        label: `${newMolecules[selectedMoleculeIndex].label} ${newMolecules[selectedMoleculeIndex].variations[selectedVariationIndex].label} ${trait.label} ${count}`,
        children: modifiedChildren,
        // drupal_field: '',
        parent: {
          type: properties.type,
          id: properties._id,
          name: trait.name,
          traitKey: trait.key,
          key: properties.key,
          sequenceNumber: count,
          draggedComponent: _.snakeCase(
            `${properties.category}_${properties.label}`
          ),
        },
      };
      return trait;
    });

    const columns = [
      {
        title: 'Trait Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Parent Name',
        dataIndex: 'parentname',
        key: 'parentname',
      },
      {
        title: 'Trait Type',
        dataIndex: 'type',
        key: 'traittype',
      },
      {
        title: 'Default value',
        dataIndex: 'defaultValue',
        key: 'defaultvalue',
        render: (text, record) =>
          record.defaultValue ? (
            <Input
              defaultValue={text}
              onChange={e => {
                record.defaultValue = e.target.value; // Update the value in traitsToBeCopied array
              }}
            />
          ) : null,
      },
      {
        title: 'Options',
        dataIndex: 'options',
        key:'options',
        render: (text, record) => 
          record.options ? (
            <Input
              defaultValue={text}
              onChange={e => {
                record.options = e.target.value.split(','); // Update the value in traitsToBeCopied array
              }}
            /> 
        ) : null,
      },
    ];

    const data = traitsToBeCopied.map(trait => {
      return {
        ...trait,
        parentname: trait.parent.name,
      };
    });

    traitsToBeCopied.length > 0 &&
      Modal.confirm({
        title: 'Copy Traits',
        onOk: () => {
          ////////////////////////
          // setting state

          setdraggedcomphtml(prevState => [
            ...prevState,
            {
              key: properties.key,
              html: properties.html,
              prefix: _.snakeCase(
                `${newMolecules[selectedMoleculeIndex].label}_${newMolecules[selectedMoleculeIndex].variations[selectedVariationIndex].label}_${count}`
              ),
            },
          ]);

          ///////////////////

          if (
            newMolecules[selectedMoleculeIndex].variations[
              selectedVariationIndex
            ].traits
          ) {
            data.forEach(incomingTrait => {
              if (
                !newMolecules[selectedMoleculeIndex].variations[
                  selectedVariationIndex
                ].traits.some(oldTrait => oldTrait.name === incomingTrait.name)
              ) {
                newMolecules[selectedMoleculeIndex].variations[
                  selectedVariationIndex
                ].traits.push(incomingTrait);
              }
            });
          } else {
            newMolecules[selectedMoleculeIndex].variations[
              selectedVariationIndex
            ].traits = data;
          }
        },
        // onCancel: handleCancel,
        okText: 'Save',
        width: 1350,
        content: (
          <Table
            columns={columns}
            dataSource={data}
            pagination={{ pageSize: 5 }}
          />
        ),
      });

    if (
      newMolecules[selectedMoleculeIndex].variations[selectedVariationIndex]
        .baseComponents
    ) {
      newMolecules[selectedMoleculeIndex].variations[
        selectedVariationIndex
      ].baseComponents.push(properties.key);
    } else {
      newMolecules[selectedMoleculeIndex].variations[
        selectedVariationIndex
      ].baseComponents = [properties.key];
    }

    newMolecules[selectedMoleculeIndex].variations[selectedVariationIndex].js =
      newMolecules[selectedMoleculeIndex].variations[selectedVariationIndex].js
        ? `${newMolecules[selectedMoleculeIndex].variations[selectedVariationIndex].js} ${properties.js}`
        : `${properties.js}`;

    // handling external dependencies -----
    if (properties.dependencies) {
      const JSdependenciesToBeCopied = [...properties.dependencies?.js];
      const CSSdependenciesToBeCopied = [...properties.dependencies?.css];
      let Dependency =
        newMolecules[selectedMoleculeIndex].variations[selectedVariationIndex]
          .dependencies;
      if (Dependency) {
        Dependency.js = Array.from(
          new Set(Dependency?.js.concat(JSdependenciesToBeCopied))
        );
        Dependency.css = Array.from(
          new Set(Dependency?.css.concat(CSSdependenciesToBeCopied))
        );
      } else {
        newMolecules[selectedMoleculeIndex].variations[
          selectedVariationIndex
        ].dependencies = {
          js: [...JSdependenciesToBeCopied],
          css: [...CSSdependenciesToBeCopied],
        };
      }
    }

    setMolecules(newMolecules);
  };

  const updateEditorComponents = (editorInstance, components) => {
    editorInstance.DomComponents.getWrapper().set('content', '');
    editorInstance.setComponents(components);
  };

  const selectedUpdatedComponent = collection => {
    setUpdatedComponent({ ...updatedComponent, ...collection });
  };

  const updateRef = () => {
    didMoleculesUpdate.current = true;
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  return (
    <>
      <Modal
        closable={false}
        open={showJSBlock}
        onOk={handleAddJS}
        onCancel={() => setShowJSBlock(false)}
        title="Add JavaScript"
        width={1200}
      >
        <InputCode
          language="javascript"
          defaultValue={currentCompJs}
          setValue={setCurrentCompJs}
        />
      </Modal>
      {/* warning modal */}
      <Modal
        centered
        key={selectedVariation}
        open={isWarningModalOpen}
        okText={usedInfo ? '' : 'Ok'}
        onOk={() => {
          handleSaveVariation(selectedMolecule, variationData);
          setIsWarningModalOpen(false);
          setIsLoading(true);
        }}
        onCancel={() => {
          setIsWarningModalOpen(false);
          setUsedInfo(false);
        }}
        width={1000}
        title={
          usedInfo ? 'Component Usages' : 'Auto-Update Higher Level Components'
        }
      >
        <WarningModal
          usingComponent={usingComponent}
          selectedUpdatedComponent={selectedUpdatedComponent}
          usedInfo={usedInfo}
        />
      </Modal>
      <Modal
        open={childrenInfoModalOpen}
        onCancel={() => {
          setChildrenInfoModalOpen(false);
        }}
        onOk={() => {
          setChildrenInfoModalOpen(false);
        }}
        title="Child Components."
      >
        <WarningModal
          usingComponent={usedChildren}
          selectedUpdatedComponent={selectedUpdatedComponent}
          usedInfo="childrenDetails"
        />
      </Modal>

      <Modal
        title={isNewMolecule ? 'Create New Molecule' : 'Add New Variation'}
        okText="Create"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          name="addNewVariationForm"
          form={addNewVariationForm}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
        >
          {isNewMolecule && (
            <Form.Item
              label="Molecule Name"
              name="moleculeName"
              rules={[
                { required: true, message: 'Please input variation name' },
              ]}
            >
              <Input />
            </Form.Item>
          )}
          <Form.Item
            label="Variation Name"
            name="variationName"
            rules={[{ required: true, message: 'Please input variation name' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      {/* Modal for External Libraries */}

      <Modal
        title="Add External JS or CSS Library"
        closable={false}
        open={externalLibraryModal}
        onOk={() => {
          if (externalLibrary) {
            externalLibrary.submit();
          }
        }}
        onCancel={() => setExternalLibraryModal(false)}
      >
        <Form
          name="externalLibrary"
          form={externalLibrary}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={addDependencies}
        >
          <Form.Item
            label="Select Library"
            name="select_library"
            rules={[
              { required: true, message: 'Please select type of Library ' },
            ]}
          >
            <Select
              style={{ width: 120 }}
              options={[
                {
                  value: 'css',
                  label: 'CSS',
                },
                {
                  value: 'js',
                  label: 'JS',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Enter CDN Link"
            name="cdn_link"
            rules={[
              { required: true, message: 'Please input CDN Link' },
              {
                type: 'url',
                message: 'Please enter a valid URL for the CDN link',
              },
            ]}
          >
            <Input style={{ width: 200 }} />
          </Form.Item>
        </Form>
      </Modal>

      {/* End of External Library Modal */}

      <Modal
        // okText="Add"
        closable={false}
        open={isRenameModalOpen}
        onOk={handleRename}
        onCancel={handleCancel}
      >
        <Input
          value={currentNameofMolecule}
          onChange={e => setCurrentNameofMolecule(e.target.value)}
        />
      </Modal>

      <Modal
        open={isDeleteModalOpen}
        onOk={handleDelete}
        onCancel={handleCancel}
      >
        <p>Are you sure? You want to delete this variation !</p>
      </Modal>

      <Row>
        <Col md={collapsed ? 1 : 4} style={{ transition: '1s' }}>
          <Button
            type="primary"
            onClick={toggleCollapsed}
            style={{
              marginBottom: 16,
            }}
          >
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </Button>
          <Menu
            mode="inline"
            onSelect={handleMenuItemSelect}
            inlineCollapsed={collapsed}
          >
            {molecules.map(molecule => (
              <SubMenu key={`${molecule.key}`} title={molecule.label}>
                {molecule.variations.map(subMenuItem => (
                  <MenuItem
                    key={subMenuItem.key}
                    onClick={() => {
                      setSelectedMolecule(molecule);
                      setdraggedcomphtml([]);
                      if (isUsedChildrenLoaded.current) {
                        isUsedChildrenLoaded.current = false;
                      }
                    }}
                  >
                    {subMenuItem.label}
                  </MenuItem>
                ))}
                <MenuItem
                  key={`${CONSTANTS.KEYS.ADD_NEW_VARIATION_MENU_ITEM_KEY}-${molecule.key}}`}
                  onClick={() => {
                    handleAddNewVariation(molecule);
                  }}
                >
                  <PlusOutlined />
                  <Text>Add Molecule's New Variation</Text>
                </MenuItem>

                {projectRole === CONSTANTS.USER_ROLES.DEVELOPER ? (
                  <Tooltip title="Request team lead for updating these changes">
                    <Button type="link" key={molecule._id} disabled>
                      <MinusOutlined style={{ color: '#ff4d4f' }} />
                      <Text type="danger">Delete Molecule</Text>
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    type="link"
                    key={molecule._id}
                    onClick={() => {
                      handleDeleteMolecule(molecule);
                      isUsedChildrenLoaded.current = false;
                    }}
                  >
                    <MinusOutlined style={{ color: '#ff4d4f' }} />
                    <Text type="danger">Delete Molecule</Text>
                  </Button>
                )}
              </SubMenu>
            ))}
            <MenuItem
              key="add_molecule_icon"
              onClick={() => {
                handleAddNewMolecule();
              }}
            >
              <PlusOutlined />
              <Text>Add New Molecule</Text>
            </MenuItem>
          </Menu>
        </Col>
        <Col md={collapsed ? 23 : 20} style={{ transition: '1s' }}>
          {selectedVariation && isUsedChildrenLoaded.current ? (
            molecules?.map(molecule => {
              return molecule.variations.map(variation =>
                selectedVariation === variation.key ? (
                  <Spin spinning={isLoading}>
                    <Editor
                      id={`molecules-editor-${variation.key}`}
                      key={`molecules-editor-${variation.key}`}
                      childCss={usedChildren?.childCss}
                      onInit={editor => {
                        let editorComponents = variation.html
                          ? variation.html
                          : variation.html;
                        if (variation.css) {
                          editorComponents = `${editorComponents}<style>${variation.css}</style>`;
                        }
                        if (variation.html) {
                          const previewButton = editor.Panels.getButton(
                            'options',
                            'preview'
                          );

                          previewButton.set('active', true, {
                            fromListen: true,
                          });
                          editor.on('stop:preview', () => {
                            editor.setComponents(
                              `${variation.html}<style>${variation.css}</style>`
                            );
                            setIsPreviewMode(false);
                            document.getElementsByClassName(
                              'gjs-pn-panel gjs-pn-devices-c gjs-one-bg gjs-two-color'
                            )[0].style.display = 'block';
                            document.getElementsByClassName(
                              'gjs-pn-panel gjs-pn-views-container gjs-one-bg gjs-two-color'
                            )[0].style.display = 'block';
                          });
                          editor.on('run:preview', async () => {
                            const replacedHtml = await replaceVariables({
                              component: variation,
                              traits: variation.traits,
                              html: variation.html,
                            });
                            editor.setComponents(
                              `${replacedHtml}<style>${variation.css}</style>`
                            );
                            setIsPreviewMode(true);
                            document.getElementsByClassName(
                              'gjs-pn-panel gjs-pn-devices-c gjs-one-bg gjs-two-color'
                            )[0].style.display = 'none';
                            document.getElementsByClassName(
                              'gjs-pn-panel gjs-pn-views-container gjs-one-bg gjs-two-color'
                            )[0].style.display = 'none';
                          });
                        } else {
                          const previewButton = editor.Panels.getButton(
                            'options',
                            'preview'
                          );
                          previewButton.set('disable', true, {
                            fromListen: true,
                          });
                          setIsPreviewMode(false);
                        }

                        updateEditorComponents(editor, editorComponents);
                        editor.on(`block:drag:stop`, (component, block) => {
                          importTheRequiredProperties(block.attributes);
                        });
                        return (editorRef.current = editor);
                      }}
                      {...updatedBlocks(atoms, 'atom')}
                    ></Editor>
                    <Row
                      style={{
                        marginTop: '10px',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <LastModified
                        entityType="molecules"
                        entityId={molecule._id}
                        forceUpdate={didMoleculesUpdate.current}
                        updateRef={updateRef}
                        projectRole={projectRole}
                      />
                      <Button
                        type="link"
                        onClick={() => {
                          if (
                            usagesDetail.organisms?.length > 0 ||
                            usagesDetail.layouts?.length > 0 ||
                            usagesDetail.pages?.length > 0
                          ) {
                            setUsedInfo(true);
                            setIsWarningModalOpen(true);
                          }
                        }}
                      >
                        Used in {usagesDetail?.organisms?.length} organisms
                        {', '}
                        {usagesDetail?.layouts?.length} layouts{', '}
                        {usagesDetail?.pages?.length} pages{' '}
                      </Button>
                    </Row>
                    <Row justify={'end'}>
                      <Button
                        type="link"
                        onClick={() => {
                          if (usedChildren?.atoms?.length) {
                            setChildrenInfoModalOpen(true);
                          }
                        }}
                        style={{ float: 'right' }}
                      >
                        Uses {usedChildren?.atoms?.length || 0} atom.
                      </Button>
                    </Row>
                    <Row justify="end" style={{ marginTop: '1em' }}>
                      <Col style={{ margin: '1em' }}>
                        <Button
                          type="primary"
                          onClick={() => {
                            setShowJSBlock(true);
                            const selectedMoleculeIndex = molecules.findIndex(
                              atom => atom.key === selectedMolecule.key
                            );
                            const newMolecules = [...molecules];
                            const selectedVariationIndex = molecules[
                              selectedMoleculeIndex
                            ].variations.findIndex(
                              variation => variation.key === selectedVariation
                            );
                            setCurrentCompJs(
                              newMolecules[selectedMoleculeIndex].variations[
                                selectedVariationIndex
                              ].js
                            );
                          }}
                        >
                          Add JavaScript
                        </Button>
                      </Col>
                      <Col style={{ margin: '1em' }}>
                        <Button
                          type="primary"
                          disabled={isPreviewMode}
                          onClick={async () => {
                            try {
                              let isUsages = false;
                              let res = await axios.get(
                                `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/components/molecules/${selectedMolecule._id}/variations/${selectedVariation}/usages`
                              );
                              const componnetsKeys = Object.keys(res.data);
                              componnetsKeys?.map(component => {
                                if (res.data[component].length) {
                                  isUsages = true;
                                }
                              });

                              if (isUsages) {
                                setIsWarningModalOpen(true);
                                setUsingComponent(res.data);
                                setVariationData(variation);
                              } else {
                                handleSaveVariation(molecule, variation);
                                setIsLoading(true);
                              }
                            } catch (err) {
                              console.log(err);
                            }
                          }}
                        >
                          Save
                        </Button>
                      </Col>

                      <Col style={{ margin: '1em' }}>
                        {usagesDetail.organisms?.length > 0 ||
                        usagesDetail.layouts?.length > 0 ||
                        usagesDetail.pages?.length > 0 ||
                        projectRole === CONSTANTS.USER_ROLES.DEVELOPER ? (
                          <Tooltip
                            title={
                              projectRole === CONSTANTS.USER_ROLES.DEVELOPER
                                ? 'Request team lead for updating these changes.'
                                : 'This component cannot be deleted since it has been used in higher level components.'
                            }
                          >
                            <Button type="primary" disabled={true}>
                              Delete Variation
                            </Button>
                          </Tooltip>
                        ) : (
                          <Button
                            type="primary"
                            onClick={() => setIsDeleteModalOpen(true)}
                          >
                            Delete Variation
                          </Button>
                        )}
                      </Col>

                      <Col style={{ margin: '1em' }}>
                        <Button
                          type="primary"
                          onClick={() => {
                            let index = molecule.variations.findIndex(
                              ele => ele.key === variation.key
                            );
                            setCurrentNameofMolecule(
                              molecule.variations[index].label
                            );

                            setIsRenameModalOpen(true);
                          }}
                        >
                          Rename
                        </Button>
                      </Col>

                      <Col style={{ margin: '1em' }}>
                        <Button
                          type="primary"
                          onClick={() => {
                            setExternalLibraryModal(true);
                          }}
                        >
                          Add External Library{' '}
                        </Button>
                      </Col>

                      <Col style={{ margin: '1em' }}>
                        <Button
                          type="primary"
                          onClick={() => {
                            window.open(
                              window.location.href +
                                `/preview?key=${variation.key}&type=molecule&id=${selectedMolecule._id}`
                            );
                          }}
                        >
                          Preview
                        </Button>
                      </Col>
                      <Col style={{ margin: '1em' }}>
                        <Checkbox
                          checked={
                            variation.inLineCss ? variation.inLineCss : false
                          }
                          onChange={handleInlineCss}
                        >
                          Inline CSS
                        </Checkbox>
                      </Col>
                    </Row>
                    {(variation.dependencies?.js?.length ||
                      variation.dependencies?.css?.length) && (
                      <Row style={{ marginTop: '30px' }}>
                        <Col justify="start" span={12}>
                          <DependencyTable
                            setEntity={setMolecules}
                            Entity={molecules}
                            selectedEntity={variation}
                          />
                        </Col>
                      </Row>
                    )}
                  </Spin>
                ) : null
              );
            })
          ) : (
            <>
              <Row justify="center" align="middle" style={{ height: '100%' }}>
                <Col>
                  <Text type="warning">
                    Please select a component to proceed
                  </Text>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = state => {
  return {
    atoms: state.DesignComponents.atoms,
    molecules: state.DesignComponents.molecules,
    currentConfigs: state.GlobalConfigurations.finalConfigs,
    organisms: state.DesignComponents.organisms,
    globalConfigs:
      state.GlobalConfigurations.finalConfigs?.global_configuration,
    projectRole: state.ProjectRole.projectRole,
  };
};

const mapDispatchToProps = dispatch => ({
  setAtoms: atoms => {
    dispatch(setAtoms(atoms));
  },
  setMolecules: molecules => {
    dispatch(setMolecules(molecules));
  },
  setOrganisms: organisms => {
    dispatch(setMolecules(organisms));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MoleculesEditor);
