const getHtml = () => {
  return `
    <img src="https://dummyimage.com/600x400/000000/fff" class="img-fluid" alt="6000x400">
  `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const PrimaryImage = { getHtml, getCss, getJs };

export default PrimaryImage;
