import ATOMS from './atoms';
import MOLECULES from './molecules';
import ORGANISMS from './organisms';
import LAYOUTS from './layouts'
import PAGES from './pages'

const ADD_NEW_VARIATION_MENU_ITEM_KEY = 'ADD_NEW_VARIATION_MENU_ITEM_KEY';

const BOOTSTRAP_CSS_CDN =
  'https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/css/bootstrap.min.css';

const BOOTSTRAP_ICON_CSS_CDN =
  'https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.2/font/bootstrap-icons.css';

const BOOTSTRAP_JS_CDN =
  'https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/js/bootstrap.bundle.min.js';

const BOOTSTRAP_WRAPPER_CLASS = 'bootstrap-styles';

const COMPONENTS = {
  ATOMS,
  MOLECULES,
  ORGANISMS,
  LAYOUTS,
  PAGES,
};

const KEYS = {
  ADD_NEW_VARIATION_MENU_ITEM_KEY,
};

const STEPS = {
  GLOBAL_CONFIGURATIONS: 0,
  DESIGN_COMPONENTS: 1,
  EXPORT: 2,
};
const USER_ROLES = {
  ADMINISTRATOR: 'administrator',
  DEVELOPER: 'developer',
  TEAM_LEAD: 'team_lead'
}

const CONSTANTS = {
  BOOTSTRAP_CSS_CDN,
  BOOTSTRAP_ICON_CSS_CDN,
  BOOTSTRAP_JS_CDN,
  BOOTSTRAP_WRAPPER_CLASS,
  COMPONENTS,
  KEYS,
  STEPS,
  USER_ROLES,
};

export default CONSTANTS;
