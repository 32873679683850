import {
  PlusOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Row,
  Select,
  Table,
  Typography,
  Spin,
  Tooltip,
} from 'antd';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import uuid from 'react-uuid';
import DependencyTable from '../../../common/DependencyTable/index';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  setAtoms,
  setLayouts,
  setMolecules,
  setOrganisms,
  setPages,
} from '../../../../redux/design-components/actions';
import Editor from '../../../common/editor';
import EditorConfig from './editor-config';
import axios from '../../../../utils/axios';
import InputCode from '../../../common/InputCode';
import LastModified from '../../../common/LastModified.js';
import WarningModal from '../../../common/modals/WarningModal';
import CONSTANTS from '../../../../utils/constants';
import { replaceVariables } from '../../../../utils/helpers/replaceTraits';
import { Switch, InputNumber } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';

const { Item: MenuItem, SubMenu } = Menu;
const { Text } = Typography;
const PagesEditor = ({
  atoms,
  molecules,
  organisms,
  layouts,
  pages,
  setOrganisms,
  setLayouts,
  setPages,
  globalConfigs,
  projectRole,
}) => {
  const editorRef = useRef(null);

  const { projectId, step } = useParams();

  const didPagesUpdate = useRef(true);

  const [nameOfPageToRename, setNameOfPageToRename] = useState(undefined);
  const [selectedPage, setSelectedPage] = useState(null);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addNewPageForm] = Form.useForm();
  const [showJSBlock, setShowJSBlock] = useState(false);
  const [currentCompJs, setCurrentCompJs] = useState('');
  const [childrenInfoModalOpen, setChildrenInfoModalOpen] = useState(false);
  const [usedChildren, setUsedChildren] = useState();
  const [updatedComponent, setUpdatedComponent] = useState({});

  const [globalMediaOptions, setGlobalMediaOptions] = useState([]);

  const [globalImageStyleOptions, setGlobalImageStyleOptions] = useState([]);
  const [isPreviewMode, setIsPreviewMode] = useState(true);
  const [pictureSourceCollection, setPictureSourceCollection] = useState([
    [
      { type: 'text_link', name: 'srcset', label: '', defaultValue: '' },
      {
        type: 'text_plain',
        name: 'media',
        label: 'media',
        defaultValue: '',
        options: globalMediaOptions,
      },
    ],
  ]);
  const [selectedDependencyType, setSelectedDependencyType] =
    useState(undefined);
  const [enteredCdnLink, setEnteredCdnLink] = useState(undefined);

  const [externalLibraryModal, setExternalLibraryModal] = useState(false);

  const [collapsed, setCollapsed] = useState(false);

  const [externalLibrary] = Form.useForm();

  const [draggedcomphtml, setdraggedcomphtml] = useState([]);
  const isUsedChildrenLoaded = useRef(false);
  const [isLoading, setIsLoading] = useState(true);

  const [checked, setChecked] = useState(false);
  const [draggedModal, setDraggedModal] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [insertData, setInsertData] = useState(null);
  const [draggedForm] = Form.useForm();

  const columns = [
    {
      title: 'Trait Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Parent Name',
      dataIndex: 'parentname',
      key: 'parentname',
    },
    {
      title: 'Trait Type',
      dataIndex: 'type',
      key: 'traittype',
    },
    {
      title: 'Default value',
      dataIndex: 'defaultValue',
      key: 'defaultvalue',
      render: (text, record) =>
        record.defaultValue ? (
          <Input
            defaultValue={text}
            onChange={e => {
              record.defaultValue = e.target.value; // Update the value in traitsToBeCopied array
            }}
          />
        ) : null,
    },
    {
      title: 'Options',
      dataIndex: 'options',
      key:'options',
      render: (text, record) => 
        record.options ? (
          <Input
            defaultValue={text}
            onChange={e => {
              record.options = e.target.value.split(','); // Update the value in traitsToBeCopied array
            }}
          /> 
      ) : null,
    },
  ];

  useEffect(() => {
    if (pages && selectedPage && didPagesUpdate.current) {
      const page = pages.find(
        pageToFind => pageToFind.key === selectedPage.key
      );
      if (page) {
        let editorComponents = page.html;
        if (page.css) {
          editorComponents = `${editorComponents}<style>${page.css}</style>`;
        }
        if (editorRef.current) {
          updateEditorComponents(editorRef.current, editorComponents);
        }
        didPagesUpdate.current = false;
        isUsedChildrenLoaded.current = false;
      }
    }
    if (pages && selectedPage) {
      // page children api call
      axios
        .get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/components/pages/${selectedPage._id}/children`
        )
        .then(res => {
          setUsedChildren(res.data);
          if (res.status === 200) {
            isUsedChildrenLoaded.current = true;
            setIsLoading(false);
          }
        })
        .catch(error => {
          console.log(error);
          setIsLoading(false);
        });
    }
  }, [pages, selectedPage]);

  const handleInlineCss = e => {
    const newPages = [...pages];
    newPages.forEach(page => {
      if (page.key === selectedPage.key) {
        page.inLineCss = e.target.checked;
      }
    });
    setLayouts(newPages);
  };

  let configArray = [];

  const updatedBlocks = (entities, type) => {
    const configs = { ...EditorConfig() };
    entities.forEach(entity => {
      if (entity.variations) {
        entity.variations?.forEach(variation => {
          variation._id = entity._id;
          variation.type = type;
          variation.category = entity.label;
          variation.content = `<div>${variation.html}</div> <style>${variation.css}</style>`;
          configArray = [...configArray, variation];
        });
      } else {
        // entity._id = entity._id;
        entity.type = type;
        entity.category = entity.label;
        entity.content = `<div>${entity.html}</div> <style>${entity.css}</style>`;
        configArray = [...configArray, entity];
      }
    });

    configArray.forEach((ele, i) => {
      configs.blockManager.blocks.push(ele);
    });

    return configs;
  };

  const handleSaveVariation = selectedLayout => {
    if (editorRef.current)
      if (editorRef.current) {
        const html = editorRef.current.getHtml();
        const css = editorRef.current.getCss();
        // const js = editorRef.current.getJs();
        let updatedPage = [...pages];
        updatedPage.forEach(page => {
          if (page.key === selectedPage.key) {
            page.html = html;
            page.css = css;

            let modifiedHTML = html;

            if (draggedcomphtml?.length) {
              draggedcomphtml.forEach(comp => {
                var pattern = new RegExp(
                  `<!--START-${comp.type.toUpperCase()}-{{${
                    comp.key
                  }}}-->([\\s\\S]*?)<!--END-${comp.type.toUpperCase()}-{{${
                    comp.key
                  }}}-->`
                );

                let modifiedContent = comp?.html?.replace(
                  /{{([^}]+)}}|start_curly([^]+?)_end_curly|<!--(.*?)-->/g,
                  function (subMatch, p1, p2, p3) {
                    if (p1) {
                      return `{{ ${comp.prefix}_${p1.trim()} }}`;
                    } else if (p2) {
                      return `start_curly_${comp.prefix}${p2}_end_curly`;
                    } else if (p3) {
                      return `<!--${p3}-->`;
                    }
                  }
                );

                // update if loops are used -----
                modifiedContent = modifiedContent.replace(
                  /<!--(START-LOOP|END-LOOP).*?{{([^}]+)}}.*?-->/g,
                  function (subMatch, loopType, doubleBracesContent) {
                    console.log(loopType);
                    console.log(doubleBracesContent);
                    return `<!--${loopType}-{{${
                      comp.prefix
                    }_${doubleBracesContent.trim()}}}-->`;
                  }
                );

                if (comp.type === 'atom') {
                  let matchedPatterns = [];

                  let layoutPattern =
                    /<!--START-LAYOUT-(.*?)-->(.*?)<!--END-LAYOUT-\1-->/gs;

                  modifiedHTML = modifiedHTML.replace(
                    layoutPattern,
                    function (match, p1, p2) {
                      // matchedPatterns.push(match); // Store the matched pattern
                      // return 'temp'; // Replace with an empty string
                      let uniqueIdentifier = `TEMP_${matchedPatterns.length}`;
                      matchedPatterns.push({
                        identifier: uniqueIdentifier,
                        pattern: match,
                      });
                      return uniqueIdentifier;
                    }
                  );

                  let organismPattern =
                    /<!--START-ORGANISM-(.*?)-->(.*?)<!--END-ORGANISM-\1-->/gs;

                  modifiedHTML = modifiedHTML.replace(
                    organismPattern,
                    function (match, p1, p2) {
                      // matchedPatterns.push(match); // Store the matched pattern
                      // return 'temp'; // Replace with an empty string
                      let uniqueIdentifier = `TEMP_${matchedPatterns.length}`;
                      matchedPatterns.push({
                        identifier: uniqueIdentifier,
                        pattern: match,
                      });
                      return uniqueIdentifier;
                    }
                  );

                  let moleculePattern =
                    /<!--START-MOLECULE-(.*?)-->(.*?)<!--END-MOLECULE-\1-->/gs;

                  modifiedHTML = modifiedHTML.replace(
                    moleculePattern,
                    function (match, p1, p2) {
                      // matchedPatterns.push(match); // Store the matched pattern
                      // return 'temp'; // Replace with an empty string
                      let uniqueIdentifier = `TEMP_${matchedPatterns.length}`;
                      matchedPatterns.push({
                        identifier: uniqueIdentifier,
                        pattern: match,
                      });
                      return uniqueIdentifier;
                    }
                  );

                  let occurrences = 0;

                  modifiedHTML = modifiedHTML.replace(
                    new RegExp(pattern, 'g'),
                    function (match) {
                      console.log('ok matched', comp.count);
                      return ++occurrences === comp.count
                        ? modifiedContent
                        : match;
                    }
                  );

                  // Restore the matched patterns
                  matchedPatterns.forEach(function (pattern) {
                    // modifiedHTML = modifiedHTML.replace('temp', pattern);
                    modifiedHTML = modifiedHTML.replace(
                      pattern.identifier,
                      pattern.pattern
                    );
                  });
                } else if (comp.type === 'molecule') {
                  let matchedPatterns = [];

                  let layoutPattern =
                    /<!--START-LAYOUT-(.*?)-->(.*?)<!--END-LAYOUT-\1-->/gs;

                  modifiedHTML = modifiedHTML.replace(
                    layoutPattern,
                    function (match, p1, p2) {
                      // matchedPatterns.push(match); // Store the matched pattern
                      // return 'temp'; // Replace with an empty string
                      let uniqueIdentifier = `TEMP_${matchedPatterns.length}`;
                      matchedPatterns.push({
                        identifier: uniqueIdentifier,
                        pattern: match,
                      });
                      return uniqueIdentifier;
                    }
                  );

                  let organismPattern =
                    /<!--START-ORGANISM-(.*?)-->(.*?)<!--END-ORGANISM-\1-->/gs;

                  modifiedHTML = modifiedHTML.replace(
                    organismPattern,
                    function (match, p1, p2) {
                      // matchedPatterns.push(match); // Store the matched pattern
                      // return 'temp'; // Replace with an empty string
                      let uniqueIdentifier = `TEMP_${matchedPatterns.length}`;
                      matchedPatterns.push({
                        identifier: uniqueIdentifier,
                        pattern: match,
                      });
                      return uniqueIdentifier;
                    }
                  );

                  let occurrences = 0;

                  modifiedHTML = modifiedHTML.replace(
                    new RegExp(pattern, 'g'),
                    function (match) {
                      console.log('ok matched', comp.count);
                      return ++occurrences === comp.count
                        ? modifiedContent
                        : match;
                    }
                  );

                  // Restore the matched patterns
                  matchedPatterns.forEach(function (pattern) {
                    // modifiedHTML = modifiedHTML.replace('temp', pattern);
                    modifiedHTML = modifiedHTML.replace(
                      pattern.identifier,
                      pattern.pattern
                    );
                  });
                } else if (comp.type === 'organism') {
                  let matchedPatterns = [];

                  let layoutPattern =
                    /<!--START-LAYOUT-(.*?)-->(.*?)<!--END-LAYOUT-\1-->/gs;

                  modifiedHTML = modifiedHTML.replace(
                    layoutPattern,
                    function (match, p1, p2) {
                      // matchedPatterns.push(match); // Store the matched pattern
                      // return 'temp'; // Replace with an empty string
                      let uniqueIdentifier = `TEMP_${matchedPatterns.length}`;
                      matchedPatterns.push({
                        identifier: uniqueIdentifier,
                        pattern: match,
                      });
                      return uniqueIdentifier;
                    }
                  );

                  let occurrences = 0;

                  modifiedHTML = modifiedHTML.replace(
                    new RegExp(pattern, 'g'),
                    function (match) {
                      console.log('ok matched', comp.count);
                      return ++occurrences === comp.count
                        ? modifiedContent
                        : match;
                    }
                  );

                  // Restore the matched patterns
                  matchedPatterns.forEach(function (pattern) {
                    // modifiedHTML = modifiedHTML.replace('temp', pattern);
                    modifiedHTML = modifiedHTML.replace(
                      pattern.identifier,
                      pattern.pattern
                    );
                  });
                } else {
                  let occurrences = 0;
                  modifiedHTML = modifiedHTML.replace(
                    new RegExp(pattern, 'g'),
                    function (match) {
                      console.log('after', occurrences);
                      // console.log("before",count);
                      return ++occurrences === comp.count
                        ? modifiedContent
                        : match;
                    }
                  );
                }
              });

              page.html = modifiedHTML;
            }

            delete page.__v;

            axios
              .patch(
                `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}/pages/${page._id}`,
                { ...page, components: updatedComponent }
              )
              .then(data => {
                message.success('Layout Saved successfully');
                axios
                  .get(
                    `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}`
                  )
                  .then(data => {
                    if (data) {
                      didPagesUpdate.current = true;
                      setAtoms([...data.data?.atoms]);
                      setMolecules([...data.data?.molecules]);
                      setOrganisms([...data.data?.organisms]);
                      setLayouts([...data.data?.layouts]);
                      setPages([...data.data?.pages]);
                      setIsLoading(false);
                    }
                  })
                  .catch(err => {
                    console.log(err, 'error');
                    setIsLoading(false);
                  });
              })
              .catch(error => {
                console.error(error);
                message.error('Something went wrong ! Please try again.');
                setIsLoading(false);
              });
          }
        });
        setPages(updatedPage);
      }
    setdraggedcomphtml([]);
  };

  const onFinish = values => {
    let { pageName } = values;
    const newPages = [...pages];
    const existingPageIndex = newPages.findIndex(
      page => page.label === pageName
    );

    if (existingPageIndex !== -1) {
      message.error('Organism with Same Name Already Exist.');
    } else {
      const newPageKey = uuid();
      newPages.push({
        label: pageName,
        key: newPageKey,
        html: '',
        css: '',
      });
      addNewPageForm.resetFields();
      axios
        .post(
          `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}/pages`,
          {
            label: pageName,
            key: newPageKey,
            html: '',
            css: '',
          }
        )
        .then(result => {
          message.success('Page Created successfully');
          axios(
            `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}`
          )
            .then(data => {
              if (data) {
                didPagesUpdate.current = true;
                setAtoms([...data.data?.atoms]);
                setMolecules([...data.data?.molecules]);
                setOrganisms([...data.data?.organisms]);
                setLayouts([...data.data?.layouts]);
                setPages([...data.data?.pages]);
              }
            })
            .catch(err => console.log(err, 'error'));
        })
        .catch(error => console.log('error', error));
      setPages(newPages);
      setIsModalOpen(false);
    }
  };

  const handleAddNewPage = () => {
    setIsModalOpen(true);
  };

  const handleAddJS = () => {
    let updatedPages = [...pages];
    updatedPages.forEach(page => {
      if (page.key === selectedPage.key) {
        page.js = currentCompJs;
        setSelectedPage(page);
      }
    });

    setPages(updatedPages);
    setShowJSBlock(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    addNewPageForm.resetFields();
  };

  const handleOk = () => {
    if (addNewPageForm) {
      addNewPageForm.submit();
    }
  };
  const handleDeleteOrganism = () => {
    let updatedPage = pages.filter(page => page.key !== selectedPage.key);

    axios
      .delete(
        `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}/pages/${selectedPage._id}`
      )
      .then(data => {
        message.success('Page deleted successfully');
      })
      .catch(error => {
        console.error(error);
        message.error('Something went wrong ! Please try again.');
      });
    setPages(updatedPage);
    setDeleteConfirmation(false);
    isUsedChildrenLoaded.current = false;
  };

  const addDependencies = values => {
    const newPages = [...pages];
    newPages.forEach(page => {
      if (page.key === selectedPage.key) {
        if (page.dependencies) {
          page?.dependencies[values.select_library].push(values.cdn_link);
        } else {
          page.dependencies = { js: [], css: [] };
          page?.dependencies[values.select_library].push(values.cdn_link);
        }
      }
    });
    setPages(newPages);
    setExternalLibraryModal(false);
    externalLibrary.resetFields();
  };

  const importTheRequiredProperties = component => {
    let newPages = [...pages];

    newPages?.forEach(page => {
      if (page.key === selectedPage.key) {
        if (page?.dependencies) {
          const JSdependenciesToBeCopied = [...component?.dependencies?.js];
          const CSSdependenciesToBeCopied = [...component?.dependencies?.css];

          if (page.dependencies) {
            page.dependencies.js = Array.from(
              new Set(page.dependencies?.js.concat(JSdependenciesToBeCopied))
            );
            page.dependencies.css = Array.from(
              new Set(page.dependencies?.css.concat(CSSdependenciesToBeCopied))
            );
          } else {
            page.dependencies = {
              js: [...JSdependenciesToBeCopied],
              css: [...CSSdependenciesToBeCopied],
            };
          }
        }

        /************************************************************* */

        let traitsToBeCopied = component.traits ? [...component.traits] : [];

        let currentHTML = editorRef.current.getHtml();
        let count;

        const pattern = new RegExp(
          component.type === 'atom'
            ? `<!--START-ATOM-{{${component.key}}}-->`
            : component.type === 'molecule'
            ? `<!--START-MOLECULE-{{${component.key}}}-->`
            : component.type === 'organism'
            ? `<!--START-ORGANISM-{{${component.key}}}-->`
            : component.type === 'layout'
            ? `<!--START-LAYOUT-{{${component.key}}}-->`
            : component.type === 'page'
            ? `<!--START-PAGE-{{${component.key}}}-->`
            : '',
          'g'
        );

        // to not allow atom count inside molecule & organism & layout if it is an atom
        if (component.type === 'atom') {
          let matchedPatterns = [];

          let layoutPattern =
            /<!--START-LAYOUT-(.*?)-->(.*?)<!--END-LAYOUT-\1-->/gs;

          currentHTML = currentHTML.replace(
            layoutPattern,
            function (match, p1, p2) {
              matchedPatterns.push(match); // Store the matched pattern
              return 'temp'; // Replace with an empty string
            }
          );

          let organismPattern =
            /<!--START-ORGANISM-(.*?)-->(.*?)<!--END-ORGANISM-\1-->/gs;

          currentHTML = currentHTML.replace(
            organismPattern,
            function (match, p1, p2) {
              matchedPatterns.push(match); // Store the matched pattern
              return 'temp'; // Replace with an empty string
            }
          );

          let moleculePattern =
            /<!--START-MOLECULE-(.*?)-->(.*?)<!--END-MOLECULE-\1-->/gs;

          currentHTML = currentHTML.replace(
            moleculePattern,
            function (match, p1, p2) {
              matchedPatterns.push(match); // Store the matched pattern
              return 'temp'; // Replace with an empty string
            }
          );

          count = (currentHTML.match(pattern) || []).length;
        } else if (component.type === 'molecule') {
          let matchedPatterns = [];

          let layoutPattern =
            /<!--START-LAYOUT-(.*?)-->(.*?)<!--END-LAYOUT-\1-->/gs;

          currentHTML = currentHTML.replace(
            layoutPattern,
            function (match, p1, p2) {
              matchedPatterns.push(match); // Store the matched pattern
              return 'temp'; // Replace with an empty string
            }
          );

          // to not allow molecule count inside molecule & organism & layout if it is an atom
          let organismPattern =
            /<!--START-ORGANISM-(.*?)-->(.*?)<!--END-ORGANISM-\1-->/gs;

          currentHTML = currentHTML.replace(
            organismPattern,
            function (match, p1, p2) {
              matchedPatterns.push(match); // Store the matched pattern
              return 'temp'; // Replace with an empty string
            }
          );

          count = (currentHTML.match(pattern) || []).length;
        } else if (component.type === 'organism') {
          let matchedPatterns = [];

          // to not allow molecule count inside molecule & organism if it is an atom
          let layoutPattern =
            /<!--START-LAYOUT-(.*?)-->(.*?)<!--END-LAYOUT-\1-->/gs;

          currentHTML = currentHTML.replace(
            layoutPattern,
            function (match, p1, p2) {
              matchedPatterns.push(match); // Store the matched pattern
              return 'temp'; // Replace with an empty string
            }
          );

          count = (currentHTML.match(pattern) || []).length;
        } else {
          count = (editorRef.current.getHtml().match(pattern) || []).length;
        }

        traitsToBeCopied = traitsToBeCopied.map((trait, index) => {
          let modifiedChildren = trait?.children;

          trait = {
            ...trait,
            key: uuid(),
            name: _.snakeCase(`${selectedPage.label}_${count}_${trait.name}`),
            label: `${selectedPage.label} ${trait.label} ${count}`,
            children: modifiedChildren,
            // drupal_field: '', // added to not include drupal_field because it is to be unique everywhere and setted manually.
            parent: {
              type: component.type,
              id: component._id,
              name: trait.name,
              traitKey: trait.key,
              key: component.key,
              sequenceNumber: count,
              draggedComponent:
                component.type !== 'organism' && component.type !== 'layout'
                  ? _.snakeCase(`${component.category}_${component.label}`)
                  : _.snakeCase(`${component.category}`),
            },
          };

          return trait;
        });

        // const columns = [
        //   {
        //     title: 'Trait Name',
        //     dataIndex: 'name',
        //     key: 'name',
        //   },
        //   {
        //     title: 'Parent Name',
        //     dataIndex: 'parentname',
        //     key: 'parentname',
        //   },
        //   {
        //     title: 'Trait Type',
        //     dataIndex: 'type',
        //     key: 'traittype',
        //   },
        //   {
        //     title: 'Default value',
        //     dataIndex: 'defaultValue',
        //     key: 'defaultvalue',
        //     render: (text, record) =>
        //       record.defaultValue ? (
        //         <Input
        //           defaultValue={text}
        //           onChange={e => {
        //             record.defaultValue = e.target.value; // Update the value in traitsToBeCopied array
        //           }}
        //         />
        //       ) : null,
        //   },
        // ];

        const data = traitsToBeCopied.map(trait => {
          return {
            ...trait,
            parentname: trait.parent.name,
          };
        });

        setTableData(data);
        setDraggedModal(true);
        setInsertData(
          {
            key: component.key,
            html: component.html,
            prefix: _.snakeCase(`${selectedPage.label}_${count}`),
            type: component.type,
            traits: component.traits,
            count: count,
          }
        )

        // traitsToBeCopied.length > 0 &&
        //   Modal.confirm({
        //     title: 'Copy Traits',
        //     onOk: () => {
        //       setdraggedcomphtml(prevState => [
        //         ...prevState,
                // {
                //   key: component.key,
                //   html: component.html,
                //   prefix: _.snakeCase(`${selectedPage.label}_${count}`),
                //   type: component.type,
                //   count: count,
                // },
        //       ]);

        //       if (page.traits) {
        //         data.forEach(incomingTrait => {
        //           if (
        //             !page.traits.some(
        //               oldTrait => oldTrait.name === incomingTrait.name
        //             )
        //           ) {
        //             // layout.traits.push(incomingTrait);
        //             page.traits = [...page.traits, incomingTrait];
        //           }
        //         });
        //       } else {
        //         page.traits = [...data];
        //       }
        //     },
        //     // onCancel: handleCancel,
        //     okText: 'Save',
        //     width: 1350,
        //     content: (
        //       <Table
        //         columns={columns}
        //         dataSource={data}
        //         pagination={{ pageSize: 5 }}
        //       />
        //     ),
        //   }); /************************************************************** */

        if (
          page.baseComponents &&
          page.baseComponents.includes(component.key)
        ) {
        } else {
          if (page.baseComponents) page.baseComponents.push(component.key);
          else page.baseComponents = [component.key];
          page.js = page.js ? `${page.js} ${component.js}` : component.js;
          setPages(newPages);
        }
      }
    });
  };

  const updateEditorComponents = (editorInstance, components) => {
    editorInstance.DomComponents?.getWrapper()?.set('content', '');
    editorInstance.setComponents(components);
  };
  const updateRef = () => {
    didPagesUpdate.current = true;
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const selectedUpdatedComponent = collection => {
    setUpdatedComponent({ ...updatedComponent, ...collection });
  };

  const handleDragDrop = () => {
    if (!checked) {
      setdraggedcomphtml(prevState => [...prevState, insertData]);

      const newPages = [...pages];
      newPages.forEach(page => {
        if (page.key === selectedPage.key) {
          if (page.traits) {
            tableData.forEach(incomingTrait => {
              if (
                !page.traits.some(
                  oldTrait => oldTrait.name === incomingTrait.name
                )
              ) {
                page.traits = [...page.traits, incomingTrait];
              }
            });
          } else {
            page.traits = [...tableData];
          }
        }
      });
      setPages(newPages);
    } else {
      const newPages = [...pages];
      newPages.forEach(page => {
        if (page.key === selectedPage.key) {
          const formData = draggedForm.getFieldsValue();
          console.log(formData);
          const newParagraphTrait = {
            key: uuid(),
            type: 'paragraph',
            dropAsPara: true,
            name: _.snakeCase(`${selectedPage.label}_${formData.label}`),
            label: `${selectedPage.label} ${formData.label}`,
            cardinality: formData.cardinality,
            children: tableData,
            drupal_field: formData.drupal_field,
          };
          page.traits = page.traits ? [...page.traits , newParagraphTrait] : [newParagraphTrait];
        }
      });
      setPages(newPages);
    }
    setDraggedModal(false);
    setChecked(false);
    setTableData(null);
    setInsertData(null);
  };



  return (
    <>

    {/* Dragged component modal */}
    <Modal
        title="Copy Traits"
        open={draggedModal}
        onOk={handleDragDrop}
        onCancel={() => setDraggedModal(false)}
        okText="Save"
        width={1350}
      >
        <br />
        <Tooltip title="This will wrap all dragged components trait into a single Paragraph.">
          <InfoCircleTwoTone style={{ marginRight: '10px' }} />
        </Tooltip>
        Wrap in a Paragraph Trait
        <Switch
          style={{ marginLeft: '15px' }}
          checked={checked}
          onChange={() => {
            setChecked(!checked);
          }}
        />
        <br />
        <br />
        <br />
        {checked ? (
          <Form
            name="draggedForm"
            form={draggedForm}
            labelCol={{
              span: 8,
            }}
            style={{
              maxWidth: 600,
            }}
            autoComplete="off"
          >
            <Form.Item
              label="Label"
              name="label"
              rules={[
                {
                  required: true,
                  message: 'Please input Label!',
                },
                {
                  validator: (_, value) => {
                    if (!value.endsWith('s')) {
                      return Promise.reject('Label must end with "s"');
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Drupal field"
              name="drupal_field"
              rules={[
                {
                  required: true,
                  message: 'Please input drupal_field!',
                },
                {
                  validator: (_, value) => {
                    if (!value.endsWith('s')) {
                      return Promise.reject('drupal_field must end with "s"');
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Cardinality"
              name="cardinality"
              rules={[
                { required: true, message: 'Please enter a cardinality' },
                { type: 'integer', message: 'Cardinality must be an integer' },
              ]}
            >
          <InputNumber min={1} />
            </Form.Item>
          </Form>
        ) : (
          <Table
            columns={columns}
            dataSource={tableData}
            pagination={{ pageSize: 5 }}
          />
        )}
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        open={deleteConfirmation}
        onOk={handleDeleteOrganism}
        onCancel={() => setDeleteConfirmation(false)}
      >
        <p>Are you sure ? You want to delete this Page !</p>
      </Modal>

      {/* Add JavaScript block */}
      <Modal
        closable={false}
        open={showJSBlock}
        onOk={handleAddJS}
        onCancel={() => setShowJSBlock(false)}
        title="Add JavaScript"
        width={1200}
      >
        <InputCode
          language="javascript"
          defaultValue={currentCompJs}
          setValue={setCurrentCompJs}
        />
      </Modal>

      <Modal
        open={childrenInfoModalOpen}
        onCancel={() => {
          setChildrenInfoModalOpen(false);
        }}
        onOk={() => {
          setChildrenInfoModalOpen(false);
        }}
        title="Child Components."
      >
        <WarningModal
          usingComponent={usedChildren}
          selectedUpdatedComponent={selectedUpdatedComponent}
          usedInfo="childrenDetails"
        />
      </Modal>

      {/* Add new Page */}
      <Modal
        title={'Create New Page'}
        okText="Create"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ width: '50vw' }}
      >
        <Form
          name="addNewPageForm"
          form={addNewPageForm}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
        >
          <Form.Item
            label="Page Name"
            name="pageName"
            rules={[{ required: true, message: 'Please input Page name' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      {/* Rename Modal */}
      <Modal
        open={isRenameModalOpen}
        closable={false}
        onOk={() => {
          setIsRenameModalOpen(false);
          let updatedPages = [...pages];
          updatedPages.forEach(page => {
            if (page.key === selectedPage.key) {
              page.label = nameOfPageToRename;

              axios
                .patch(
                  `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}/pages/${page._id}`,
                  page
                )
                .then(data => {
                  message.success('Page Renamed successfully');
                  axios
                    .get(
                      `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}`
                    )
                    .then(data => {
                      if (data) {
                        didPagesUpdate.current = true;
                        setAtoms([...data.data?.atoms]);
                        setMolecules([...data.data?.molecules]);
                        setOrganisms([...data.data?.organisms]);
                        setLayouts([...data.data?.layouts]);
                        setPages([...data.data?.pages]);
                      }
                    })
                    .catch(err => console.log(err, 'error'));
                })
                .catch(error => {
                  console.error(error);
                  message.error('Something went wrong ! Please try again.');
                });
            }
          });
          setPages([...updatedPages]);
        }}
        onCancel={() => setIsRenameModalOpen(false)}
      >
        <Input
          value={nameOfPageToRename}
          onChange={e => setNameOfPageToRename(e.target.value)}
        />
      </Modal>

      <Modal
        title="Add External JS or CSS Library"
        closable={false}
        open={externalLibraryModal}
        onOk={() => {
          if (externalLibrary) {
            externalLibrary.submit();
          }
        }}
        onCancel={() => setExternalLibraryModal(false)}
      >
        <Form
          name="externalLibrary"
          form={externalLibrary}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={addDependencies}
        >
          <Form.Item
            label="Select Library"
            name="select_library"
            rules={[
              { required: true, message: 'Please select type of Library ' },
            ]}
          >
            <Select
              style={{ width: 120 }}
              options={[
                {
                  value: 'css',
                  label: 'CSS',
                },
                {
                  value: 'js',
                  label: 'JS',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Enter CDN Link"
            name="cdn_link"
            rules={[
              { required: true, message: 'Please input CDN Link' },
              {
                type: 'url',
                message: 'Please enter a valid URL for the CDN link',
              },
            ]}
          >
            <Input style={{ width: 200 }} />
          </Form.Item>
        </Form>
      </Modal>

      {/* End of Modals */}

      <Row>
        <Col md={collapsed ? 1 : 4} style={{ transition: '1s' }}>
          <Button
            type="primary"
            onClick={toggleCollapsed}
            style={{
              marginBottom: 16,
            }}
          >
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </Button>
          <Menu
            mode="inline"
            // onSelect={handleMenuItemSelect}
            inlineCollapsed={collapsed}
          >
            {pages &&
              pages?.map(page => (
                <MenuItem
                  key={page?.key}
                  onClick={() => {
                    setSelectedPage(page);
                    if (isUsedChildrenLoaded.current) {
                      isUsedChildrenLoaded.current = false;
                    }
                  }}
                >
                  {page?.label}
                </MenuItem>
              ))}
            <MenuItem
              key="add_page_icon"
              onClick={() => {
                handleAddNewPage();
              }}
            >
              <PlusOutlined />
              <Text>Add New Page</Text>
            </MenuItem>
          </Menu>
        </Col>
        <Col md={collapsed ? 23 : 20} style={{ transition: '1s' }}>
          {selectedPage && isUsedChildrenLoaded.current === true ? (
            pages?.map(page => {
              return selectedPage.key === page.key ? (
                <Spin spinning={isLoading}>
                  <Editor
                    id={`layout-editor-${page.key}`}
                    childCss={usedChildren?.childCss}
                    // onInit={editor => {
                    //   let editorComponents = page.html;
                    //   if (page.css) {
                    //     editorComponents = `${editorComponents}<style>${page.css}</style>`;
                    //   }
                    //   updateEditorComponents(editor, editorComponents);
                    //   editor.on(`block:drag:stop`, (component, block) => {
                    //     importTheRequiredProperties(block.attributes);
                    //   });
                    //   return (editorRef.current = editor);
                    // }}
                    onInit={editor => {
                      let editorComponents = page.html ? page.html : page.html;
                      if (page.css) {
                        editorComponents = `${editorComponents}<style>${page.css}</style>`;
                      }
                      if (page.html) {
                        const previewButton = editor.Panels.getButton(
                          'options',
                          'preview'
                        );
                        previewButton.set('active', true, {
                          fromListen: true,
                        });
                        editor.on('stop:preview', () => {
                          editor.setComponents(
                            `${page.html}<style>${page.css}</style>`
                          );
                          setIsPreviewMode(false);
                          document.getElementsByClassName(
                            'gjs-pn-panel gjs-pn-devices-c gjs-one-bg gjs-two-color'
                          )[0].style.display = 'block';
                          document.getElementsByClassName(
                            'gjs-pn-panel gjs-pn-views-container gjs-one-bg gjs-two-color'
                          )[0].style.display = 'block';
                        });
                        editor.on('run:preview', async () => {
                          const replacedHtml = await replaceVariables({
                            component: page,
                            traits: page.traits,
                            html: page.html,
                          });
                          editor.setComponents(
                            `${replacedHtml}<style>${page.css}</style>`
                          );
                          setIsPreviewMode(true);
                          document.getElementsByClassName(
                            'gjs-pn-panel gjs-pn-devices-c gjs-one-bg gjs-two-color'
                          )[0].style.display = 'none';
                          document.getElementsByClassName(
                            'gjs-pn-panel gjs-pn-views-container gjs-one-bg gjs-two-color'
                          )[0].style.display = 'none';
                        });
                      } else {
                        const previewButton = editor.Panels.getButton(
                          'options',
                          'preview'
                        );
                        previewButton.set('disable', true, {
                          fromListen: true,
                        });
                        setIsPreviewMode(false);
                      }

                      updateEditorComponents(editor, editorComponents);
                      editor.on(`block:drag:stop`, (component, block) => {
                        importTheRequiredProperties(block.attributes);
                      });
                      return (editorRef.current = editor);
                    }}
                    {...updatedBlocks(atoms, 'atom')}
                    {...updatedBlocks(molecules, 'molecule')}
                    {...updatedBlocks(organisms, 'organism')}
                    {...updatedBlocks(layouts, 'layout')}
                  ></Editor>
                  <Row style={{ marginTop: '10px' }}>
                    <LastModified
                      entityType="pages"
                      entityId={page._id}
                      forceUpdate={didPagesUpdate.current}
                      updateRef={updateRef}
                      projectRole={projectRole}
                    />
                  </Row>

                  <Row justify={'end'}>
                    <Button
                      type="link"
                      onClick={() => {
                        if (
                          usedChildren?.atoms?.length ||
                          usedChildren?.molecules?.length ||
                          usedChildren?.organisms?.length ||
                          usedChildren?.layouts?.length
                        ) {
                          setChildrenInfoModalOpen(true);
                        }
                      }}
                      style={{ float: 'right' }}
                    >
                      Uses {usedChildren?.atoms?.length || 0} atoms,
                      {usedChildren?.molecules?.length || 0} molecule,
                      {usedChildren?.organisms?.length || 0} organisms,
                      {usedChildren?.layouts?.length || 0} layouts.
                    </Button>
                  </Row>

                  <Row justify="end" style={{ marginTop: '1em' }}>
                    <Col style={{ margin: '1em' }}>
                      <Button
                        type="primary"
                        onClick={() => {
                          setShowJSBlock(true);
                          setCurrentCompJs(selectedPage.js);
                        }}
                      >
                        Add JavaScript
                      </Button>
                    </Col>

                    <Col style={{ margin: '1em' }}>
                      <Button
                        type="primary"
                        onClick={() => {
                          handleSaveVariation(page);
                          setIsLoading(true);
                        }}
                      >
                        Save
                      </Button>
                    </Col>

                    <Col style={{ margin: '1em' }}>
                      {projectRole === CONSTANTS.USER_ROLES.DEVELOPER ? (
                        <Tooltip title="Request team lead for updating these changes.">
                          <Button type="primary" disabled={true}>
                            Delete
                          </Button>
                        </Tooltip>
                      ) : (
                        <Button
                          type="primary"
                          onClick={() => {
                            setDeleteConfirmation(true);
                          }}
                        >
                          Delete
                        </Button>
                      )}
                    </Col>

                    <Col style={{ margin: '1em' }}>
                      <Button
                        type="primary"
                        onClick={() => {
                          setIsRenameModalOpen(true);
                          setNameOfPageToRename(selectedPage.label);
                        }}
                      >
                        Rename
                      </Button>
                    </Col>

                    <Col style={{ margin: '1em' }}>
                      <Button
                        type="primary"
                        onClick={() => {
                          setExternalLibraryModal(true);
                        }}
                      >
                        Add External Library{' '}
                      </Button>
                    </Col>

                    <Col style={{ margin: '1em' }}>
                      <Button
                        type="primary"
                        onClick={() => {
                          window.open(
                            window.location.href +
                              `/preview?key=${selectedPage.key}&type=pages&id=${selectedPage._id}`
                          );
                        }}
                      >
                        Preview
                      </Button>
                    </Col>

                    <Col style={{ margin: '1em' }}>
                      <Checkbox
                        checked={
                          selectedPage.inLineCss
                            ? selectedPage.inLineCss
                            : false
                        }
                        onChange={handleInlineCss}
                      >
                        Inline CSS
                      </Checkbox>
                    </Col>
                  </Row>
                  {(page.dependencies?.js?.length ||
                    page.dependencies?.css?.length) && (
                    <Row style={{ marginTop: '30px' }}>
                      <Col justify="start" span={12}>
                        <DependencyTable
                          setEntity={setPages}
                          Entity={pages}
                          selectedEntity={page}
                        />
                      </Col>
                    </Row>
                  )}
                </Spin>
              ) : null;
            })
          ) : (
            <>
              <Row justify="center" align="middle" style={{ height: '100%' }}>
                <Col>
                  <Text type="warning">
                    Please select a component to proceed
                  </Text>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = state => {
  return {
    atoms: state.DesignComponents.atoms,
    molecules: state.DesignComponents.molecules,
    organisms: state.DesignComponents.organisms,
    layouts: state.DesignComponents.layouts,
    pages: state.DesignComponents.pages,
    globalConfigs:
      state.GlobalConfigurations.finalConfigs?.global_configuration,
    projectRole: state.ProjectRole.projectRole,
  };
};

const mapDispatchToProps = dispatch => ({
  setAtoms: atoms => {
    dispatch(setAtoms(atoms));
  },
  setMolecules: molecules => {
    dispatch(setMolecules(molecules));
  },
  setOrganisms: organisms => {
    dispatch(setOrganisms(organisms));
  },
  setLayouts: layouts => {
    dispatch(setLayouts(layouts));
  },
  setPages: pages => {
    dispatch(setPages(pages));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PagesEditor);
