const getHtml = () => {
  return `
    <button type="button" class="btn btn-info">Info</button>
    `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const InfoButton = { getHtml, getCss, getJs };

export default InfoButton;
