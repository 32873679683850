import * as TYPES from './action-types';

import CONSTANTS from '../../utils/constants';

const initialState = {
  step: CONSTANTS.STEPS.GLOBAL_CONFIGURATIONS,
};

const FragmaticBuilder = (state = initialState, { type, data }) => {
  switch (type) {
    case TYPES.SET_STEP:
      return {
        ...state,
        step: data,
      };
    default:
      return state;
  }
};

export default FragmaticBuilder;
