const getHtml = () => {
  return `
    <div class="spinner-grow" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>`;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const Growing = { getHtml, getCss, getJs };

export default Growing;
