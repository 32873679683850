const getHtml = () => {
    return `
    <div class="container" data-gjs-type="div" style="min-height: 526px; width: auto; background: #fdf">
    
  </div>
      `;
  };
  
  const getJs = () =>{
      return `  
      `
  }
  
  const getCss = () =>{
      return `
      `
  }
  
  const HeroWithBackgroundColor = { getHtml, getCss, getJs };
    
  export default HeroWithBackgroundColor;