const EditorConfig = () => ({
  height: 900,
  storageManager: false,
  blockManager: {
    custom: true,
    appendOnClick: false,
    blocks: [],
  },
});

export default EditorConfig;
