const getHtml = () => {
  return `
    <button type="button" class="btn btn-warning">Warning</button>
    `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const WarningButton = { getHtml, getCss, getJs };

export default WarningButton;
