import { Form, Input, Select, Button, InputNumber, Tooltip } from 'antd';
import { useState, useEffect } from 'react';
import ImageTraits from './ImageTraits';
import PictureTraits from './PictureTraits';
import ParaTraits from './ParaTraits';
import { uuid } from 'react-uuid';
import { InfoCircleTwoTone } from '@ant-design/icons';

const TraitForm = ({
  form,
  globalImageStyleOptions,
  globalMediaOptions,
  onFinish,
  handleAddChildren,
  children,
  handleAddNested,
  handleAddAnotherSource,
  handleAddPictureCollection,
  pictureSourceCollection,
  setPictureSourceCollection,
  handleRemoveNested,
}) => {
  const [isSelectedTraitType, setIsSelectedTraitType] = useState(undefined);
  const [isSelectedTraitParagraph, setIsSelectedTraitParagraph] =
    useState(false);
  const [isSelectedTraitLink ,setIsSelectedTraitLink] = useState(false);

  return (
    <Form
      name="traitform"
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinish}
    >
      <Form.Item
        label="Type"
        name="type"
        rules={[{ required: true, message: 'Please select a Trait Type' }]}
      >
        {/* <Input /> */}
        <Select
          style={{
            width: 120,
          }}
          onChange={value => {
            if (value === 'paragraph') {
              setIsSelectedTraitParagraph(true);
              setIsSelectedTraitType(undefined);
              setIsSelectedTraitLink(false);
            } else if (value === 'image' || value === 'picture') {
              setIsSelectedTraitParagraph(false);
              setIsSelectedTraitType(value);
              setPictureSourceCollection([
                { type: 'text_link', source: '', media: '' },
              ]);
              setIsSelectedTraitLink(false);
            } else if(value === 'text_link'){
              // console.log("deepak")
              setIsSelectedTraitLink(true);
              setIsSelectedTraitType(value);
              setIsSelectedTraitParagraph(false);
              setPictureSourceCollection([
                { type: 'text_link', source: '', media: '' },
              ]);

              // setIsSelectedTraitType(undefined);
            }
            else if(value === 'text_plain' || value === 'text_formatted'){
              setIsSelectedTraitParagraph(false);
              setPictureSourceCollection([
                { type: 'text_link', source: '', media: '' },
              ]);

              setIsSelectedTraitType(value);
              setIsSelectedTraitLink(false);
            }
          }}
          options={[
            {
              value: 'text_link',
              label: 'Text Link',
            },
            {
              value: 'image',
              label: 'Image',
            },
            {
              value: 'picture',
              label: 'Picture',
            },
            {
              value: 'text_plain',
              label: 'Text Plain',
            },
            {
              value: 'text_formatted',
              label: 'Text Formatted',
            },
            {
              value: 'paragraph',
              label: 'Paragraph',
            },
          ]}
        />
      </Form.Item>

      {isSelectedTraitParagraph && (
        <Form.Item
          label={
            <span>
              <Tooltip title="Cardinality is number of times the markup between START LOOP and END LOOP comments repeats in the preview component.">
                <InfoCircleTwoTone style={{ marginRight: '5px' }} />
              </Tooltip>
              Cardinality
            </span>
          }
          name="cardinality"
          rules={[
            { required: true, message: 'Please enter a cardinality' },
            { type: 'integer', message: 'Cardinality must be an integer' },
          ]}
        >
          <InputNumber min={1} />
        </Form.Item>
      )}

      <Form.Item
        label="Label"
        name="label"
        rules={[
          { required: true, message: 'Please input variation label' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              const typeFieldValue = getFieldValue('type');
              if (typeFieldValue === 'paragraph') {
                if (!value.match(/s$/)) {
                  return Promise.reject(
                    'Label must end with "s" for paragraph type'
                  );
                }
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Drupal field name"
        name="drupal_field_name"
        rules={[
          { required: true, message: 'Please Enter Drupal Field Name' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              const typeFieldValue = getFieldValue('type');
              if (typeFieldValue === 'paragraph') {
                if (!value.match(/s$/)) {
                  return Promise.reject(
                    'drupal_field must end with "s" for paragraph type'
                  );
                }
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input maxLength={32}/>
      </Form.Item>




      {
        isSelectedTraitLink && (
          <Form.Item
          label="Title"
          name="title"
          rules={[
            { required: true, message: 'Please input Link Title' },
          ]}
        >
          <Input />
        </Form.Item>
        )
      }

      {isSelectedTraitType === 'image' ? (
        <ImageTraits
          form={form}
          globalImageStyleOptions={globalImageStyleOptions}
        />
      ) : isSelectedTraitType === 'picture' ? (
        <PictureTraits
          form={form}
          globalImageStyleOptions={globalImageStyleOptions}
          pictureSourceCollection={pictureSourceCollection}
          handleAddPictureCollection={handleAddPictureCollection}
          globalMediaOptions={globalMediaOptions}
          handleAddAnotherSource={handleAddAnotherSource}
        />
      ) : null}

      {isSelectedTraitParagraph ? (
        <ParaTraits
          form={form}
          children={children}
          handleAddChildren={handleAddChildren}
          handleAddNested={handleAddNested}
          globalImageStyleOptions={globalImageStyleOptions}
          globalMediaOptions={globalMediaOptions}
          handleAddAnotherSource={handleAddAnotherSource}
          handleAddPictureCollection={handleAddPictureCollection}
          pictureSourceCollection={pictureSourceCollection}
          handleRemoveNested={handleRemoveNested}
        />
      ) : null}

      {!isSelectedTraitParagraph && (isSelectedTraitType === 'text_plain' || isSelectedTraitType === 'text_formatted' || isSelectedTraitType === 'text_link') ? (
        <Form.Item label="Default value" name="defaultValue">
          <Input />
        </Form.Item>
      ) : null}

{
        isSelectedTraitType === 'text_plain' && (
          <Form.Item
          label="Options"
          name="options"
        >
          <Input />
        </Form.Item>
        )
      }

    </Form>
  );
};

export default TraitForm;
