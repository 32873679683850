const getHtml = () => {
  return `
    <h1>Example heading <span class="badge bg-secondary">New</span></h1>
    `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const H1Badge = { getHtml, getCss, getJs };

export default H1Badge;
