import { Form, Input, Select, Button, Modal, InputNumber, Tooltip } from 'antd';
import { useState, useEffect } from 'react';
import ImageTraits from './ImageTraits';
import PictureTraits from './PictureTraits';
import ParaTraits from './ParaTraits';
import { uuid } from 'react-uuid';
import { InfoCircleTwoTone } from '@ant-design/icons';

const EditForm = ({
  form,
  globalImageStyleOptions,
  globalMediaOptions,
  onFinish,
  setEditData,
  handleAddChildren,
  children,
  handleAddNested,
  handleAddAnotherSource,
  handleAddPictureCollection,
  pictureSourceCollection,
  setPictureSourceCollection,
  handleRemoveNested,
  editModal,
  handleOk,
  setEditTraitData,
  setIsModalOpen,
  setChildren,
  showEditModal,
}) => {
  const [isSelectedTraitType, setIsSelectedTraitType] = useState(undefined);
  const [isSelectedTraitParagraph, setIsSelectedTraitParagraph] =
    useState(false);
  const [isSelectedTraitLink, setIsSelectedTraitLink] = useState(false);

  const handleCancel = () => {
    setEditTraitData(null);
    setIsModalOpen(false);
    setChildren([{ type: '', label: '', name: '', defaultValue: '' }]);
    setPictureSourceCollection([{ type: 'text_link', source: '', media: '' }]);
    form.resetFields();
    showEditModal(false);
    setIsSelectedTraitParagraph(false);
    setIsSelectedTraitType(undefined);
    setIsSelectedTraitLink(false);
  };

  useEffect(() => {
    if (setEditData) {
      if (setEditData?.type === 'paragraph') {
        setIsSelectedTraitParagraph(true);
        setIsSelectedTraitType(undefined);
      } else if (
        setEditData?.type === 'picture' ||
        setEditData?.type === 'image'
      ) {
        setIsSelectedTraitParagraph(false);
        setIsSelectedTraitType(setEditData.type);
      } else if(setEditData?.type === 'text_link'){
        setIsSelectedTraitLink(true);
        setIsSelectedTraitParagraph(false);
        setIsSelectedTraitType(setEditData.type);
      }
      else if(setEditData?.type === 'text_plain' || setEditData?.type === 'text_formatted'){
        setIsSelectedTraitParagraph(false);
        setIsSelectedTraitType(setEditData?.type);
      }

      if (setEditData) {
        console.log("setEditData",setEditData);
        form.setFieldsValue({
          type: setEditData.type,
          label: setEditData.label,
          drupal_field_name: setEditData.drupal_field,
          defaultValue: setEditData.defaultValue,
          ...(setEditData.cardinality && {
            cardinality: setEditData.cardinality,
          }),
          ...(setEditData.title && {
            title: setEditData.title,
          }),
          ...(setEditData.options && {
            options: setEditData.options.join(','),
          }),
        });
      }
    }
  }, [setEditData]);

  return (
    <Modal
      title={'Edit Trait'}
      closable={false}
      open={editModal}
      onOk={handleOk}
      okText={'Edit'}
      onCancel={() => {
        handleCancel();
      }}
      width={650}
    >
      <Form
        name="traitform"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
      >
        <Form.Item
          label="Type"
          name="type"
          rules={[{ required: true, message: 'Please select a Trait Type' }]}
        >
          {/* <Input /> */}
          <Select
            style={{
              width: 120,
            }}
            onChange={value => {
              if (value === 'paragraph') {
                setIsSelectedTraitParagraph(true);
                setIsSelectedTraitType(undefined);
                setIsSelectedTraitLink(false);
              } else if (value === 'image' || value === 'picture') {
                setIsSelectedTraitParagraph(false);
                setIsSelectedTraitType(value);
                setPictureSourceCollection([
                  { type: 'text_link', source: '', media: '' },
                ]);
                setIsSelectedTraitLink(false);
              } else if (value === 'text_link') {
                setIsSelectedTraitLink(true);
                setIsSelectedTraitParagraph(false);
                setPictureSourceCollection([
                  { type: 'text_link', source: '', media: '' },
                ]);

                setIsSelectedTraitType(undefined);
              } else {
                setIsSelectedTraitParagraph(false);
                setPictureSourceCollection([
                  { type: 'text_link', source: '', media: '' },
                ]);

                setIsSelectedTraitType(undefined);
                setIsSelectedTraitLink(false);
              }
            }}
            options={[
              {
                value: 'text_link',
                label: 'Text Link',
              },
              {
                value: 'image',
                label: 'Image',
              },
              {
                value: 'picture',
                label: 'Picture',
              },
              {
                value: 'text_plain',
                label: 'Text Plain',
              },
              {
                value: 'text_formatted',
                label: 'Text Formatted',
              },
              {
                value: 'paragraph',
                label: 'Paragraph',
              },
            ]}
          />
        </Form.Item>

        {isSelectedTraitParagraph && (
          <Form.Item
            label={
              <span>
                <Tooltip title="Cardinality is number of times the markup between START LOOP and END LOOP comments repeats in the preview component.">
                  <InfoCircleTwoTone style={{ marginRight: '5px' }} />
                </Tooltip>
                Cardinality
              </span>
            }
            name="cardinality"
            rules={[
              { required: true, message: 'Please enter a cardinality' },
              { type: 'integer', message: 'Cardinality must be an integer' },
            ]}
          >
            <InputNumber min={1} />
          </Form.Item>
        )}

        <Form.Item
          label="Label"
          name="label"
          rules={[
            { required: true, message: 'Please input variation label' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                const typeFieldValue = getFieldValue('type');
                if (typeFieldValue === 'paragraph') {
                  if (!value.match(/s$/)) {
                    return Promise.reject(
                      'Label must end with "s" for paragraph type'
                    );
                  }
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
        label="Drupal field name"
        name="drupal_field_name"
        rules={[
          { required: true, message: 'Please Enter Drupal Field Name' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              const typeFieldValue = getFieldValue('type');
              if (typeFieldValue === 'paragraph') {
                if (!value.match(/s$/)) {
                  return Promise.reject(
                    'drupal_field must end with "s" for paragraph type'
                  );
                }
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input maxLength={32}/>
      </Form.Item>

        {isSelectedTraitLink && (
          <Form.Item label="Title" name="title">
            <Input />
          </Form.Item>
        )}

        {isSelectedTraitType === 'image' ? (
          <ImageTraits
            form={form}
            globalImageStyleOptions={globalImageStyleOptions}
            EditData={setEditData}
          />
        ) : isSelectedTraitType === 'picture' ? (
          <PictureTraits
            form={form}
            EditData={setEditData}
            globalImageStyleOptions={globalImageStyleOptions}
            pictureSourceCollection={pictureSourceCollection}
            handleAddPictureCollection={handleAddPictureCollection}
            globalMediaOptions={globalMediaOptions}
            handleAddAnotherSource={handleAddAnotherSource}
            setPictureSourceCollection={setPictureSourceCollection}
          />
        ) : null}

        {isSelectedTraitParagraph ? (
          <ParaTraits
            form={form}
            children={children}
            handleAddChildren={handleAddChildren}
            EditData={setEditData}
            handleAddNested={handleAddNested}
            globalImageStyleOptions={globalImageStyleOptions}
            globalMediaOptions={globalMediaOptions}
            handleAddAnotherSource={handleAddAnotherSource}
            handleAddPictureCollection={handleAddPictureCollection}
            pictureSourceCollection={pictureSourceCollection}
            handleRemoveNested={handleRemoveNested}
            setPictureSourceCollection={setPictureSourceCollection}
          />
        ) : null}

        {!isSelectedTraitParagraph && (isSelectedTraitType === 'text_plain' || isSelectedTraitType === 'text_formatted' || isSelectedTraitType === 'text_link')  ? (
          <Form.Item label="Default value" name="defaultValue">
            <Input />
          </Form.Item>
        ) : null}

{
        isSelectedTraitType === 'text_plain' && (
          <Form.Item
          label="Options"
          name="options"
        >
          <Input />
        </Form.Item>
        )
      }
      </Form>
    </Modal>
  );
};

export default EditForm;
