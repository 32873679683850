const getHtml = () => {
  return `
    <div class="alert alert-danger" role="alert">
    A simple danger alert—check it out!
  </div>
        `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const DangerAlert = { getHtml, getCss, getJs };

export default DangerAlert;
