const getHtml = () => {
  return `
    <button type="button" class="btn btn-primary">Primary</button>
  `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const PrimaryButton = { getHtml, getCss, getJs };

export default PrimaryButton;
