const getHtml = () => {
  return `
    <div class="form-group">
      <label for="inputName">Name</label>
      <input type="text" class="form-control" id="inputName" aria-describedby="nameHelp" placeholder="Enter Name">
      <small id="nameHelp" class="form-text text-muted">Please enter your full name.</small>
    </div>
  `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const SecondaryInput = { getHtml, getCss, getJs };

export default SecondaryInput;
