const getHtml = () => {
  return `
    <div class="alert alert-success" role="alert">
    A simple success alert—check it out!
  </div>
      `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const SuccessAlert = { getHtml, getCss, getJs };

export default SuccessAlert;
