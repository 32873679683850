const getHtml = () => {
    return `
      <div class="input-group-text">
        <input type="checkbox" id="inputCheckbox" aria-describedby="checkbox">
      </div>
    `;
  };
  
  const getCss = () => {
    return ``;
  };
  
  const getJs = () => {
    return ``;
  };
  
  const CheckBoxInput = { getHtml, getCss, getJs };
  
  export default CheckBoxInput;
  