/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Col, Form, Input, Modal, Row, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import CSSPropertyWithUnit from '../../../../common/CSSPropertyWithUnit';

const { Title } = Typography;

const BreakPointForm = ({ breakPointsFields, setBreakPointsFields }) => {
  const [breakPointForm] = Form.useForm();
  const [editRecord, setEditRecord] = useState(null);
  //------------------ columns for break points ----------------------//

  const parseValueAndUnit = value => {
    const numericValue = parseInt(value, 10);
    const unit = value.replace(numericValue, '');
    return [numericValue, unit];
  };

  useEffect(() => {
    if (editRecord) {
      const initialValues = {
        width: {
          value: parseValueAndUnit(editRecord.width)[0],
          unit: parseValueAndUnit(editRecord.width)[1],
        },
        max_width: {
          value: parseValueAndUnit(editRecord.max_width)[0],
          unit: parseValueAndUnit(editRecord.max_width)[1],
        },
        'font-size': {
          value: parseValueAndUnit(editRecord['font-size'])[0],
          unit: parseValueAndUnit(editRecord['font-size'])[1],
        },
      };
      breakPointForm.setFieldsValue({
        name: editRecord.name,
        ...initialValues,
      });
    }
  }, [editRecord]);

  const breakpointsColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Min width',
      dataIndex: 'width',
      key: 'width',
    },
    {
      title: 'Max width',
      dataIndex: 'max_width',
      key: 'max_width',
    },
    {
      title: 'Root Font Size',
      dataIndex: 'font-size',
      key: 'font-size',
    },
    {
      title: 'Delete',
      dataIndex: '',
      key: '',
      render: (_, record) => {
        return (
          <a
            onClick={() => {
              Modal.confirm({
                title: 'Delete Alert.',
                content: 'Do you want to delete it?',
                okText: 'Yes',
                cancelText: 'No',
                onOk: () => {
                  const updatedValues = breakPointsFields.filter(
                    ele => ele.id !== record.id
                  );
                  setBreakPointsFields(updatedValues);
                },
              });
            }}
          >
            Delete
          </a>
        );
      },
    },
    {
      title: 'Edit',
      dataIndex: '',
      key: '',
      render: (_, record) => {
        return (
          <a
            onClick={() => {
              setEditRecord(record);
            }}
          >
            Edit
          </a>
        );
      },
    },
  ];
  //------------------------------------------------------------------//

  const onFinish = values => {
    values.name = values.name.trim();
    const object = Object.keys(values);
    object.map(item => {
      if (typeof values[item] == 'object') {
        values[item] = '' + values[item].value + values[item].unit;
      }
    });
    if (editRecord) {
      const newFields = breakPointsFields.map(field => {
        if (field.id === editRecord.id) {
          field = {
            id: field.id,
            ...values,
          };
        }
        return field;
      });
      setBreakPointsFields(newFields);
      setEditRecord(null);
      breakPointForm.resetFields();
    } else {
      const valueToPush = { id: uuid(), ...values };
      setBreakPointsFields([...breakPointsFields, valueToPush]);
      breakPointForm.resetFields();
    }
  };
  return (
    <>
      <h3>Breakpoints:</h3>
      <Row gutter={20}>
        <Col span={6}>
          <Title level={5} style={{ textAlign: 'center' }}>
            {editRecord ? 'Edit Breakpoint' : 'Add Breakpoint'}
          </Title>
          <Form
            name="breakPointForm"
            form={breakPointForm}
            onFinish={onFinish}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            labelAlign="left"
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please input Name',
                },
                {
                  pattern: /^[A-Za-z0-9_-]+$/,
                  message: "Invalid Input Special Character's not allowed",
                },
                { whitespace: true },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>

            <CSSPropertyWithUnit fieldName="width" placeholder="Min width" />

            <CSSPropertyWithUnit
              fieldName="max_width"
              placeholder="Max width"
            />

            <CSSPropertyWithUnit
              fieldName="font-size"
              placeholder="Base Font size"
            />
            <Button type="primary" onClick={() => breakPointForm.submit()}>
              {editRecord ? 'Edit Breakpoint' : 'Add Breakpoint'}
            </Button>
          </Form>
        </Col>
        <Col span={18}>
          {breakPointsFields && breakPointsFields.length > 0 && (
            <Table
              dataSource={breakPointsFields}
              columns={breakpointsColumns}
              pagination={false}
              bordered
              size="small"
              scroll={{
                y: 240,
              }}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default BreakPointForm;
