import Down from './down';
import Up from './up';
import Left from './left';
import Right from './right';
import Primary from './primary';
import Secondary from './secondary';
import Success from './success';
import Info from './info';
import Warning from './warning';
import Danger from './danger';

const Dropdown = {
  Down,
  Up,
  Right,
  Left,
  Primary,
  Secondary,
  Success,
  Info,
  Warning,
  Danger,
};

export default Dropdown;
