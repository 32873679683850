const getHtml = () => {
  return `
  <div class="btn-group dropstart">
  <button type="button" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
    Dropstart
  </button>
  <ul class="dropdown-menu">
  <li><a class="dropdown-item" href="#">Action</a></li>
  <li><a class="dropdown-item" href="#">Another action</a></li>
  <li><a class="dropdown-item" href="#">Something else here</a></li>
  </ul>
</div>
`;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const Left = { getHtml, getCss, getJs };

export default Left;
