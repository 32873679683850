import { Button, Col, Divider, Row, Spin, Steps as AntSteps } from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useSearchParams, useParams, Link, Navigate } from 'react-router-dom';

import {
  setGlobalAssets,
  setGlobalCssCdn,
} from '../../redux/global-configurations/actions';
import DesignComponents from './design-components';
import Export from './export';
import GlobalAssets from './global-assets';
import GlobalConfigurations from './global-configurations';
import { setStep } from '../../redux/fragmatic-builder/actions';
import { useNavigate } from 'react-router-dom';
import axios from '../../utils/axios';
import { Breadcrumb } from 'antd';
import DefineTraits from './define-traits';
import { setProjectRole } from '../../redux/project-role/actions';
import GlobalTraits from './global-traits';
import { setGlobalTraits } from '../../redux/global-traits/actions';

const steps = [
  {
    title: 'Global Assets',
    content: GlobalAssets,
  },
  {
    title: 'Global Configurations',
    content: GlobalConfigurations,
  },
  {
    title: 'Design Components',
    content: DesignComponents,
  },
  {
    title: 'Global Traits',
    content: GlobalTraits,
  },
  {
    title: 'Define Traits',
    content: DefineTraits,
  },
  {
    title: 'Export',
    content: Export,
  },
];

const Steps = ({
  currentStep,
  setCurrentAssets,
  setCurrentStep,
  currentConfigs,
  setCurrentCssCDN,
  currentAtoms,
  currentMolecules,
  currentOrganisms,
  userId,
  setProjectRole,
  projectRole,
  globalTraits,
  setGlobalTraits
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const { projectId } = useParams();

  const navigate = useNavigate();

  const [projectName, setProjectName] = useState(undefined);

  useEffect(() => {
    setCurrentStep(0);
    setURL(currentStep);
    axios
      .get(`${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}`)
      .then(data => {
        setProjectName(data.data.name);
        setProjectRole(data.data.userRole);
      })
      .catch(err => console.log(err));

    axios
      .get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/assets?limit=10&offset=0`
      )
      .then(data => {
        if (data) {
          setCurrentAssets(data.data.docs);
          setIsNextDisabled(false);
          setIsLoading(false);
        }
      })
      .catch(err => console.log(err));

    axios
      .get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/configurations`
      )
      .then(data => {
        if (data) {
          setCurrentCssCDN(data.data.css);
          setIsLoading(false);
          setIsNextDisabled(false);
        }
      })
      .catch(err => console.log(err, 'error'));

      axios
      .get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/globalTraits`
      )
      .then(data => {
        if (data) {
          setGlobalTraits(data.data.globalTraits);
          setIsNextDisabled(false);
          setIsLoading(false);
        }
      })
      .catch(err => { 
        setGlobalTraits([]);
        console.log(err)
      });
  }, []);

  useEffect(() => {
    setSearchParams('');
    setURL(currentStep);
  }, [currentStep]);

  const setURL = currentStep => {
    let stepName = '';
    if (currentStep === 0) stepName = 'assets';
    else if (currentStep === 1) stepName = 'configurations';
    else if (currentStep === 2) stepName = 'components';
    else if (currentStep === 3) stepName = 'globalTraits';
    else if (currentStep === 4) stepName = 'traits';
    else if (currentStep === 5) stepName = 'export';    
    navigate(`/projects/${projectId}/${stepName}`);
  };

  const handleOnChange = value => {
    setCurrentStep(value);
    setURL(value);
  };

  const handleNextStep = () => {
    setIsLoading(true);
    if (currentStep === 0) {
      setCurrentStep(currentStep + 1);
      setIsLoading(false);
    } else if (currentStep === 1) {
      const variables = {};
      currentConfigs.global_configuration.colors.forEach(color => {
        variables[color.variableName] = color.hexcode;
      });

      axios
        .post(
          `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/configurations`,
          {
            ...currentConfigs,
            variables,
          }
        )
        .then(res => {
          setCurrentCssCDN(res.data.css);
          setCurrentStep(currentStep + 1);
          setIsLoading(false);
          if (projectRole === 'developer') {
            setIsNextDisabled(true);
          }
        })
        .catch(error => {
          console.log('error', error);
        });
    } else if (currentStep === 2) {
      setCurrentStep(currentStep + 1);
      setIsLoading(false);
      setIsNextDisabled(false);
    } else if (currentStep === 3) {
      setCurrentStep(currentStep + 1);
      setIsNextDisabled(false);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  return isLoading ? (
    <>
      <Spin tip="Loading" size="large">
        <div className="content" />
      </Spin>
    </>
  ) : (
    <>
      <Breadcrumb style={{ fontSize: '16px', marginLeft: '7px' }}>
        <Breadcrumb.Item style={{ textDecoration: 'underline' }}>
          <Link to="/projects">Projects</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{projectName}</Breadcrumb.Item>
      </Breadcrumb>
      <Divider />
      <AntSteps
        style={{ marginBottom: 20 }}
        current={currentStep}
        items={steps.map(step => ({ title: step.title }))}
        onChange={handleOnChange}
      />

      {React.createElement(steps[currentStep].content, { setIsNextDisabled })}
      <Divider />
      <Row justify={'space-between'} style={{ padding: '20px' }}>
        {currentStep > 0 && (
          <Col>
            <Button
              onClick={() => {
                setURL(currentStep - 1);
                handlePreviousStep();
              }}
            >
              Back
            </Button>
          </Col>
        )}
        {currentStep < steps.length - 1 && (
          <Col>
            <Button
              type="primary"
              onClick={() => {
                setURL(currentStep + 1);
                handleNextStep();
              }}
              disabled={isNextDisabled}
            >
              Next
            </Button>
          </Col>
        )}
      </Row>
    </>
  );
};

const mapStateToProps = state => {
  return {
    currentConfigs: state.GlobalConfigurations.finalConfigs,
    currentStep: state.FragmaticBuilder.step,
    currentCssCDN: state.GlobalConfigurations.initialCssCDN,
    currentAtoms: state.DesignComponents.atoms,
    currentMolecules: state.DesignComponents.molecules,
    currentOrganisms: state.DesignComponents.organisms,
    currentAssets: state.GlobalConfigurations.initialAssets,
    userId: state.User.id,
    projectRole: state.ProjectRole.projectRole,
    globalTraits: state.GlobalTraits.globalTraits,
  };
};

const mapDispatchToProps = dispatch => ({
  setCurrentStep: step => {
    dispatch(setStep(step));
  },
  setCurrentCssCDN: css => {
    dispatch(setGlobalCssCdn(css));
  },
  setCurrentAssets: assets => {
    dispatch(setGlobalAssets(assets));
  },
  setProjectRole: role => {
    dispatch(setProjectRole(role));
  },
  setGlobalTraits: globalTraits => {
    dispatch(setGlobalTraits(globalTraits));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Steps);
