const getHtml = () => {
  return `
    <a href="#" class="link-primary">Primary link</a>
  `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const PrimaryLink = { getHtml, getCss, getJs };

export default PrimaryLink;
