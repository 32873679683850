const getHtml = () => {
  return `
    <nav aria-label="Page navigation example">
    <ul class="pagination">
      <li class="page-item"><a class="page-link" href="#">Previous</a></li>
      <li class="page-item"><a class="page-link" href="#">1</a></li>
      <li class="page-item"><a class="page-link" href="#">2</a></li>
      <li class="page-item"><a class="page-link" href="#">3</a></li>
      <li class="page-item"><a class="page-link" href="#">Next</a></li>
    </ul>
  </nav>
          `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const Basic = { getHtml, getCss, getJs };

export default Basic;
