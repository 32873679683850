import { Form, Input, Modal, Radio, message,Select } from 'antd';
import { useState } from 'react';
import axios from '../../utils/axios';

const EditUser = props => {
  const [EditUserForm] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  // handle value of status radio button
  const [statusValue, setStatusValue] = useState(true);
  EditUserForm.setFieldsValue({
    firstName: props.values.firstName,
    lastName: props.values.lastName,
    status: props.values.status,
    email: props.values.email,
    role: props.values.role,
  });

  return (
    <>
    {contextHolder}
      <Modal
        title="Edit User"
        open={props.open}
        onCancel={() => props.setEditUserModal(false)}
        onOk={() => {
          EditUserForm.submit();
        }}
      >
        <Form
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 16,
          }}
          form={EditUserForm}
          name="Edit User Form"
          onFinish={value => {
            props.setEditUserModal(false);
            EditUserForm.resetFields();
            if(value.email===props.values.email){
              delete value.email;
            }
            axios
              .patch(
                `${process.env.REACT_APP_SERVER_BASE_URL}/users/${props.values._id}`,
                value
              )
              .then(res => {
                props.setListingData([]);
                props.isUpdatedHandler();
                props.infoMessage('success','User updated')

              })
              .catch(error => {
                console.log('error');
                props.infoMessage('error','User not updated')
              });
          }}
        >
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              { required: true, message: 'First Name is required' },
              { whitespace: true },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[
              { required: true, message: 'Last Name is required' },
              { whitespace: true },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Email is Required',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Role"
            name="role"
            rules={[
              { required: true, message: 'Role is required' },
              { whitespace: true },
            ]}
          >
            <Select
              options={[
                { label: 'Developer', value: 'developer' },
                { label: 'Tech Lead', value: 'team_lead' },
              ]}
            />
          </Form.Item>

          <Form.Item
            label="Status"
            name="status"
            rules={[{ required: false }]}
            value={statusValue}
            initialValue={true}
          >
            <Radio.Group
              onChange={e => setStatusValue(e.target.value)}
              defaultValue={true}
            >
              <Radio value={true}>Active</Radio>
              <Radio value={false}>Inactive</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default EditUser;
