const getHtml = () => {
  return `
  <button type="button" class="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">
  Tooltip on top
</button>`;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return `var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })`;
};

const Top = { getHtml, getCss, getJs };

export default Top;
