import * as TYPES from './action-types';

export const setGlobalConfigs = data => ({
  type: TYPES.SET_GLOBAL_CONFIGS,
  data,
});
export const setGlobalCssCdn = data => ({
  type: TYPES.SET_GLOBAL_CSS_CDN,
  data,
});
export const setGlobalAssets = data => ({
  type: TYPES.SET_GLOBAL_ASSETS,
  data,
});
