import React from 'react';
import { Layout as AntLayout } from 'antd';

import Header from './header';
import Footer from './footer';

const { Content } = AntLayout;

const Layout = ({ children, isLogout }) => {
  return (
    <>
      <Header islogout={isLogout} />
      <AntLayout style={{ minHeight: '85vh' }}>
        <Content style={{ padding: 25 }}>{children}</Content>
      </AntLayout>
      <Footer />
    </>
  );
};

export default Layout;
