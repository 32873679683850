import { Table, Button, message, Modal, Spin, Divider,Breadcrumb } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import AddUser from './AddUser';
import EditUser from './EditUser';
import axios from '../../utils/axios';
import ErrorPage from '../common/ErrorPage';
import ChangePassword from './ChangePassword';
import { connect } from 'react-redux';
import { Navigate,Link } from 'react-router-dom';

const UserListing = ({ userRole }) => {
  const [addUserModal, setAddUserModal] = useState(false);
  const [listingData, setListingData] = useState([]);
  const [editUserModal, setEditUserModal] = useState(false);
  const [editData, setEditData] = useState();
  const isUpdate = useRef(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [netError, setNetError] = useState(false);
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [selectedUser, setSelectedUser] = useState();

  const [messageApi, contextHolder] = message.useMessage();
  const [modal, confirmModalHolder] = Modal.useModal();

  const addModalOnOk = value => {
    axios
      .post(`${process.env.REACT_APP_SERVER_BASE_URL}/users`, value)
      .then(res => {
        setListingData([...listingData, res.data]);
        isUpdate.current = true;
        messageApi.open({
          type: 'success',
          content: 'User Added',
        });
      })
      .catch(error => {
        if (error?.response?.data?.errors[0]?.msg.includes('already exists')) {
          messageApi.open({
            type: 'error',
            content: error.response.data.errors[0].msg,
          });
        } else {
          messageApi.open({
            type: 'error',
            content: 'User not added',
          });
        }
      });
  };

  const infoMessage = (msgType, msgContent) => {
    messageApi.open({
      type: msgType,
      content: msgContent,
    });
  };

  useEffect(() => {
    if (listingData && isUpdate.current) {
      let offset = currentPage ? (currentPage - 1) * 10 : 0;
      axios
        .get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/users?limit=10&offset=${offset}`
        )
        .then(res => {
          res.data.docs?.map(user => {
            user.key = user._id;
          });
          setListingData(res.data?.docs);
          setTotalUsers(res.data.totalDocs);
          setIsLoading(false);
          if (res.data.totalPages < currentPage) {
            setCurrentPage(1);
            isUpdate.current = true;
          }
        })
        .catch(error => {
          if (!error.status) {
            // network error
            setNetError(true);
          }
          messageApi.open({
            type: 'error',
            content: 'Something Wrong!! please refresh!',
          });
        });
      isUpdate.current = false;
    }
  }, [listingData, currentPage]);

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      render: val => (val ? val : '-'),
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      render: val => (val ? val : '-'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: val => (val ? val : '-'),
    },
    {
      title: 'Role(Developer/Team Lead)',
      key: 'role',
      dataIndex: 'role',
      render: val => (val ? val : '-'),
    },
    {
      title: 'Status(Active/Inactive)',
      key: 'status',
      dataIndex: 'status',
      render: val => (
        <div className="status">{val ? 'Active' : 'Inactive'}</div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        return (
          <div style={{ display: 'flex' }}>
            <Button
              type="link"
              onClick={() => {
                setIsChangePassword(true);
                setSelectedUser(record);
              }}
            >
              Change Password
            </Button>
            <Button
              type="link"
              onClick={() => {
                setEditUserModal(true);
                setEditData(record);
              }}
            >
              Edit
            </Button>
            <Button
              type="link"
              onClick={() => {
                modal.confirm({
                  title: 'Confirm',
                  content: 'Are you want to delete?',
                  okText: 'Yes',
                  cancelText: 'No',
                  onOk: () => {
                    axios
                      .delete(
                        `${process.env.REACT_APP_SERVER_BASE_URL}/users/${record._id}`
                      )
                      .then(res => {
                        setListingData([]);
                        isUpdate.current = true;
                        messageApi.open({
                          type: 'success',
                          content: 'User Deleted',
                        });
                      })
                      .catch(error => {
                        messageApi.open({
                          type: 'error',
                          content: 'User not Deleted',
                        });
                      });
                  },
                });
              }}
              disabled={record.status}
            >
              {' '}
              Delete
            </Button>{' '}
          </div>
        );
      },
    },
  ];
  const isUpdatedHandler = () => {
    isUpdate.current = true;
  };

  return netError ? (
    <ErrorPage errorMessage="Please check your Internet connection" />
  ) : userRole === 'administrator' ? (
    <>
      {contextHolder}
      {confirmModalHolder}
      <Breadcrumb style={{ fontSize: '16px', marginLeft: '7px' }}>
        <Breadcrumb.Item style={{ textDecoration: 'underline' }}>
          <Link to="/projects">Projects</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Users</Breadcrumb.Item>
      </Breadcrumb>
      <Divider/>
      <Button
        type="primary"
        style={{ float: 'right', marginBottom: '5px' }}
        onClick={() => setAddUserModal(true)}
      >
        <PlusOutlined /> Add User
      </Button>
      <AddUser
        open={addUserModal}
        setAddUserModal={setAddUserModal}
        addModalOnOk={addModalOnOk}
      />
      {editUserModal && (
        <EditUser
          open={editUserModal}
          setEditUserModal={setEditUserModal}
          values={editData}
          setListingData={setListingData}
          isUpdatedHandler={isUpdatedHandler}
          infoMessage={infoMessage}
        />
      )}
      {isChangePassword && (
        <ChangePassword
          open={isChangePassword}
          setIsChangePassword={setIsChangePassword}
          id={selectedUser._id}
          setListingData={setListingData}
          isUpdatedHandler={isUpdatedHandler}
          infoMessage={infoMessage}
        />
      )}
      <Table
        columns={columns}
        dataSource={listingData}
        size="small"
        onChange={e => {
          isUpdate.current = true;
          setCurrentPage(e.current);
          setIsLoading(true);
        }}
        loading={isLoading}
        pagination={{
          defaultCurrent: 0,
          total: totalUsers,
          current: currentPage,
        }}
      />
    </>
  ) : (
    <Navigate to="/projects" replace={true} />
  );
};

const mapStateToProps = state => {
  return {
    userRole: state.User.role,
  };
};

export default connect(mapStateToProps, null)(UserListing);
