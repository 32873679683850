import { Button, Col, Form, Modal, Row, Select, message } from 'antd';
import { useEffect, useState } from 'react';
import axios from '../../utils/axios';
import AddUser from '../users/AddUser';
import { DeleteOutlined } from '@ant-design/icons';

const ManageUsers = props => {
  const [manageUsersForm] = Form.useForm();
  const [userOptions, setUserOption] = useState([]);
  const [addUserModal, setAddUserModal] = useState(false);
  const [userAdded, setUserAdded] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    let userData = [];
    axios
      .get(`${process.env.REACT_APP_SERVER_BASE_URL}/users?limit=10&offset=0`)
      .then(res => {
        axios
          .get(
            `${process.env.REACT_APP_SERVER_BASE_URL}/users?limit=${res.data.totalDocs}&offset=0`
          )
          .then(data => {
            data?.data?.docs?.map(user => {
              userData.push({
                label: `${user.firstName} ${user.lastName}`,
                value: user._id,
              });
            });
            setUserOption([...userData]);
          })
          .catch(error => {
            console.log(error);
          });
      })
      .catch(error => {
        console.log('error');
      });
  }, [userAdded]);

  const addModalOnOk = value => {
    axios
      .post(`${process.env.REACT_APP_SERVER_BASE_URL}/users`, value)
      .then(res => {
        setUserAdded(true);
        messageApi.open({
          type: 'success',
          content: 'User Added',
        });
      })
      .catch(error => {
        if (error?.response?.data?.errors[0]?.msg.includes('already exists')) {
          messageApi.open({
            type: 'error',
            content: error.response.data.errors[0].msg,
          });
        } else {
          messageApi.open({
            type: 'error',
            content: 'User not added',
          });
        }
      });
  };

  const handleRemove = i => {
    let value = props.manageUsersModalData;
    value.splice(i, 1);
    props.setManageUsersModalData([...value]);
    setUserAdded([...value]);
  };

  useEffect(() => {
    let obj = {};
    props.manageUsersModalData?.map((user, i) => {
      obj[`name_${i}`] = user.user._id;
      obj[`role_${i}`] = user.role;
    });
    manageUsersForm.setFieldsValue({
      ...obj,
    });
  }, [props.manageUsersModalData]);
  return (
    <>
      {contextHolder}
      <Modal
        open={props.manageUsersModal}
        onCancel={() => {
          props.setManageUsersModal(false);
          props.setManageUsersModalData([]);
          manageUsersForm.resetFields();
        }}
        onOk={() => {
          manageUsersForm.submit();
        }}
        title={`${props.projectName} - Manage Access`}
        width={1000}
        destroyOnClose={true}
      >
        <Form
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 16,
          }}
          form={manageUsersForm}
          onFinish={value => {
            let collectUsers = [];
            props.manageUsersModalData?.map((_, index) => {
              collectUsers[index] = {
                user: String(value[`name_${index}`]),
                role: String(value[`role_${index}`]),
                poject: String(props.selectedProjectID),
              };
            });
            axios
              .post(
                `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${props.selectedProjectID}/access`,
                [...collectUsers]
              )
              .then(res => {
                props.setManageUsersModal(false);
                props.setManageUsersModalData([]);
                manageUsersForm.resetFields();
                props.alertmessage('success', 'Access Added');
              })
              .catch(error => {
                console.log('eror');
                props.alertmessage('error', 'Access not Added');
              });
          }}
        >
          {props.manageUsersModalData?.map((user, i) => {
            return (
              <Row key={user.user._id || `row_${i}`}>
                <Col md={10}>
                  <Form.Item
                    label="Select User"
                    name={`name_${i}`}
                    key={`user${user.user._id}`}
                  >
                    <Select
                      options={[...userOptions]}
                      key={`select${user.user._id}`}
                    />
                  </Form.Item>
                </Col>
                <Col md={10}>
                  <Form.Item label="Select Role" name={`role_${i}`}                     key={`role${user.user._id}`}
>
                    <Select
                      options={[
                        { value: 'developer', label: 'Developer' },
                        { value: 'team_lead', label: 'Team Lead' },
                      ]}
                      key={`role_select${user.user._id}`}

                    />
                  </Form.Item>
                </Col>
                <Col md={2}>
                  <Button
                    icon={<DeleteOutlined />}
                    danger
                    onClick={() => {
                      handleRemove(i);
                    }}
                  />
                </Col>
              </Row>
            );
          })}
        </Form>
        <Button
          onClick={() => {
            props.setManageUsersModalData([
              ...props.manageUsersModalData,
              { user: '', role: '', projectId: props.selectedProjectID },
            ]);
          }}
        >
          Add more
        </Button>
        <Button
          type="primary"
          onClick={() => {
            setAddUserModal(true);
          }}
          style={{marginLeft:'10px'}}
        >
          Add User
        </Button>
      </Modal>
      <AddUser
        open={addUserModal}
        setAddUserModal={setAddUserModal}
        addModalOnOk={addModalOnOk}
      />
    </>
  );
};

export default ManageUsers;
