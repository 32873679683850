const getHtml = () => {
  return `
  <button type="button" class="btn btn-success">Success</button>
  `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const SuccessButton = { getHtml, getCss, getJs };

export default SuccessButton;
