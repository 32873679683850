import { Form, Select, Input, Button, Space, InputNumber, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import ImageTraits from './ImageTraits';
import PictureTraits from './PictureTraits';
import { InfoCircleTwoTone } from '@ant-design/icons';

const ParaTraits = ({
  form,
  children,
  handleAddChildren,
  handleAddNested,
  globalImageStyleOptions,
  globalMediaOptions,
  handleAddAnotherSource,
  handleAddPictureCollection,
  pictureSourceCollection,
  handleRemoveNested,
  setPictureSourceCollection,
}) => {
  const [selectedChildrenTraitType, setSelectedChildrenTraitType] = useState(
    {}
  );

  return (
    <>
      {children?.map((child, i) => {
        return (
          <>
            <div style={{ border: '3px solid red', padding: '10px' }}>
              <Form.Item
                label="Type"
                name={`child_type_${i}`}
                rules={[
                  {
                    required: true,
                    message: 'Please input variation name',
                  },
                ]}
              >
                {/* <Input /> */}
                <Select
                  style={{
                    width: 120,
                  }}
                  onChange={value => {
                    setSelectedChildrenTraitType({
                      ...selectedChildrenTraitType,
                      [`child_type_${i}`]: value,
                    });
                    if (value === 'paragraph') {
                      handleAddNested(i);
                    } else {
                      // handleRemoveNested(i);
                      child.children = [];
                    }
                    // setPictureSourceCollection([
                    //   { type: 'text_link', source: '', media: '' },
                    // ]);
                  }}
                  options={[
                    {
                      value: 'text_link',
                      label: 'Text Link',
                    },
                    {
                      value: 'text_plain',
                      label: 'Text Plain',
                    },
                    {
                      value: 'text_formatted',
                      label: 'Text Formatted',
                    },
                    {
                      value: 'image',
                      label: 'Image',
                    },
                    {
                      value: 'picture',
                      label: 'Picture',
                    },
                    {
                      value: 'paragraph',
                      label: 'paragraph',
                    },
                  ]}
                />
              </Form.Item>

              {selectedChildrenTraitType[`child_type_${i}`] === 'paragraph' && (
                <Form.Item
                  label={
                    <span>
                      <Tooltip title="Cardinality is number of times the markup between START LOOP and END LOOP comments repeats in the preview component.">
                        <InfoCircleTwoTone style={{ marginRight: '5px' }} />
                      </Tooltip>
                      Cardinality
                    </span>
                  }
                  name={`cardinality_${i}`}
                  rules={[
                    { required: true, message: 'Please enter a cardinality' },
                    {
                      type: 'integer',
                      message: 'Cardinality must be an integer',
                    },
                  ]}
                >
                  <InputNumber min={1} />
                </Form.Item>
              )}

              <Form.Item
                label="Label"
                name={`child_label_${i}`}
                rules={[
                  {
                    required: true,
                    message: 'Please input variation label',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const typeFieldValue = getFieldValue(`child_type_${i}`);
                      if (typeFieldValue === 'paragraph') {
                        if (!value.match(/s$/)) {
                          return Promise.reject(
                            'Label must end with "s" for paragraph type'
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Drupal field name"
                name={`child_drupal_field_name_${i}`}
                rules={[
                  { required: true, message: 'Please Enter Drupal Field Name' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const typeFieldValue = getFieldValue(`child_type_${i}`);
                      if (typeFieldValue === 'paragraph') {
                        if (!value.match(/s$/)) {
                          return Promise.reject(
                            'drupal_field must end with "s" for paragraph type'
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input maxLength={32} />
              </Form.Item>

              {selectedChildrenTraitType[`child_type_${i}`] === 'text_link' && (
                <Form.Item
                  label="Title"
                  name={`title_${i}`}
                  rules={[
                    { required: true, message: 'Please input Link Title' },
                  ]}
                >
                  <Input />
                </Form.Item>
              )}

              {selectedChildrenTraitType[`child_type_${i}`] === 'image' && (
                <ImageTraits
                  child_level_1_index={i}
                  form={form}
                  globalImageStyleOptions={globalImageStyleOptions}
                />
              )}

              {selectedChildrenTraitType[`child_type_${i}`] === 'picture' && (
                <PictureTraits
                  form={form}
                  globalImageStyleOptions={globalImageStyleOptions}
                  pictureSourceCollection={pictureSourceCollection}
                  setPictureSourceCollection={setPictureSourceCollection}
                  handleAddPictureCollection={handleAddPictureCollection}
                  globalMediaOptions={globalMediaOptions}
                  handleAddAnotherSource={handleAddAnotherSource}
                  child_level_1_index={i}
                />
              )}

              {selectedChildrenTraitType[`child_type_${i}`] !== 'image' &&
                selectedChildrenTraitType[`child_type_${i}`] !== 'picture' &&
                selectedChildrenTraitType[`child_type_${i}`] !==
                  'paragraph' && (
                  <Form.Item
                    label="Default value"
                    name={`child_defaultValue_${i}`}
                  >
                    <Input />
                  </Form.Item>
                )}

              {console.log('mitthu', child)}

              {/* {(child.type === 'paragraph' || child.type === '') && */}
              {child?.children?.map((childsChild, index) => {
                return (
                  <>
                    <div style={{ border: '3px solid green', padding: '10px' }}>
                      {/* <CloseOutlined
                          style={{ float: 'right' }}
                          onClick={() => {
                            // handleRemoveNested(index);
                            // form.setFieldsValue({
                            //   [`child_type_${i}`]: undefined,
                            // });
                          }}
                        /> */}
                      <Form.Item
                        label="Type"
                        name={`nested_child_type_${i}_${index}`}
                        rules={[
                          {
                            required: true,
                            message: 'Please input variation name',
                          },
                        ]}
                      >
                        <Select
                          style={{
                            width: 120,
                          }}
                          onChange={value => {
                            setSelectedChildrenTraitType({
                              ...selectedChildrenTraitType,
                              [`nested_child_type_${i}_${index}`]: value,
                            });
                            if (value === 'paragraph') {
                              handleAddNested(i, index);
                            } else {
                              // handleRemoveNested(i, index);
                              childsChild.children = [];
                            }
                          }}
                          options={[
                            {
                              value: 'text_link',
                              label: 'Text Link',
                            },
                            {
                              value: 'text_plain',
                              label: 'Text Plain',
                            },
                            {
                              value: 'text_formatted',
                              label: 'Text Formatted',
                            },
                            {
                              value: 'image',
                              label: 'Image',
                            },
                            {
                              value: 'picture',
                              label: 'Picture',
                            },
                            {
                              value: 'paragraph',
                              label: 'paragraph',
                            },
                          ]}
                        />
                      </Form.Item>

                      {selectedChildrenTraitType[
                        `nested_child_type_${i}_${index}`
                      ] === 'paragraph' && (
                        <Form.Item
                          label={
                            <span>
                              <Tooltip title="Cardinality is number of times the markup between START LOOP and END LOOP comments repeats in the preview component.">
                                <InfoCircleTwoTone
                                  style={{ marginRight: '5px' }}
                                />
                              </Tooltip>
                              Cardinality
                            </span>
                          }
                          name={`cardinality_${i}_${index}`}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter a cardinality',
                            },
                            {
                              type: 'integer',
                              message: 'Cardinality must be an integer',
                            },
                          ]}
                        >
                          <InputNumber min={1} />
                        </Form.Item>
                      )}

                      <Form.Item
                        label="Label"
                        name={`nested_child_label_${i}_${index}`}
                        rules={[
                          {
                            required: true,
                            message: 'Please input variation label',
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              const typeFieldValue = getFieldValue(
                                `nested_child_type_${i}_${index}`
                              );
                              if (typeFieldValue === 'paragraph') {
                                if (!value.match(/s$/)) {
                                  return Promise.reject(
                                    'Label must end with "s" for paragraph type'
                                  );
                                }
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="Drupal field name"
                        name={`child_drupal_field_name_${i}_${index}`}
                        rules={[
                          {
                            required: true,
                            message: 'Please Enter Drupal Field Name',
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              const typeFieldValue = getFieldValue(
                                `nested_child_type_${i}_${index}`
                              );
                              if (typeFieldValue === 'paragraph') {
                                if (!value.match(/s$/)) {
                                  return Promise.reject(
                                    'drupal_field must end with "s" for paragraph type'
                                  );
                                }
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input maxLength={32} />
                      </Form.Item>

                      {selectedChildrenTraitType[
                        `nested_child_type_${i}_${index}`
                      ] === 'text_link' && (
                        <Form.Item
                          label="Title"
                          name={`title_${i}_${index}`}
                          rules={[
                            {
                              required: true,
                              message: 'Please input Link Title',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      )}

                      {selectedChildrenTraitType[
                        `nested_child_type_${i}_${index}`
                      ] === 'image' && (
                        <ImageTraits
                          form={form}
                          child_level_1_index={i}
                          child_level_2_index={index}
                          globalImageStyleOptions={globalImageStyleOptions}
                        />
                      )}

                      {selectedChildrenTraitType[
                        `nested_child_type_${i}_${index}`
                      ] === 'picture' && (
                        <PictureTraits
                          form={form}
                          globalImageStyleOptions={globalImageStyleOptions}
                          pictureSourceCollection={pictureSourceCollection}
                          handleAddPictureCollection={
                            handleAddPictureCollection
                          }
                          setPictureSourceCollection={
                            setPictureSourceCollection
                          }
                          globalMediaOptions={globalMediaOptions}
                          handleAddAnotherSource={handleAddAnotherSource}
                          child_level_1_index={i}
                          child_level_2_index={index}
                        />
                      )}

                      {selectedChildrenTraitType[
                        `nested_child_type_${i}_${index}`
                      ] !== 'image' &&
                        selectedChildrenTraitType[
                          `nested_child_type_${i}_${index}`
                        ] !== 'picture' &&
                        selectedChildrenTraitType[
                          `nested_child_type_${i}_${index}`
                        ] !== 'paragraph' && (
                          <Form.Item
                            label="Default value"
                            name={`nested_child_defaultValue_${i}_${index}`}
                          >
                            <Input />
                          </Form.Item>
                        )}

                      {/* {(childsChild?.type === 'paragraph' || childsChild?.type === '') && */}
                      {childsChild?.children?.map((childsChildsChild, j) => {
                        return (
                          <>
                            <div
                              style={{
                                border: '3px solid blue',
                                padding: '10px',
                              }}
                            >
                              {/* <CloseOutlined
                                      style={{ float: 'right' }}
                                      onClick={() => {
                                        // handleRemoveNested(index, j);
                                        // form.setFieldsValue({
                                        //   [`nested_child_type_${i}_${index}`]:
                                        //     undefined,
                                        // });
                                      }}
                                    /> */}
                              <Form.Item
                                label="Type"
                                name={`nested_child_type_${i}_${index}_${j}`}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please input variation name',
                                  },
                                ]}
                              >
                                <Select
                                  style={{
                                    width: 120,
                                  }}
                                  onChange={value => {
                                    setSelectedChildrenTraitType({
                                      ...selectedChildrenTraitType,
                                      [`nested_child_type_${i}_${index}_${j}`]:
                                        value,
                                    });
                                    // setPictureSourceCollection([
                                    //   { type: 'text_link', source: '', media: '' },
                                    // ]);
                                  }}
                                  options={[
                                    {
                                      value: 'text_link',
                                      label: 'Text Link',
                                    },
                                    {
                                      value: 'text_plain',
                                      label: 'Text Plain',
                                    },
                                    {
                                      value: 'text_formatted',
                                      label: 'Text Formatted',
                                    },
                                    {
                                      value: 'image',
                                      label: 'Image',
                                    },
                                    {
                                      value: 'picture',
                                      label: 'Picture',
                                    },
                                  ]}
                                />
                              </Form.Item>

                              {selectedChildrenTraitType[
                                `nested_child_type_${i}_${index}_${j}`
                              ] === 'paragraph' && (
                                <Form.Item
                                  label={
                                    <span>
                                      <Tooltip title="Cardinality is number of times the markup between START LOOP and END LOOP comments repeats in the preview component.">
                                        <InfoCircleTwoTone
                                          style={{ marginRight: '5px' }}
                                        />
                                      </Tooltip>
                                      Cardinality
                                    </span>
                                  }
                                  name={`cardinality_${i}_${index}_${j}`}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please enter a cardinality',
                                    },
                                    {
                                      type: 'integer',
                                      message: 'Cardinality must be an integer',
                                    },
                                  ]}
                                >
                                  <InputNumber min={1} />
                                </Form.Item>
                              )}

                              <Form.Item
                                label="Label"
                                name={`nested_child_label_${i}_${index}_${j}`}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please input variation label',
                                  },
                                ]}
                              >
                                <Input />
                              </Form.Item>

                              <Form.Item
                                label="Drupal field name"
                                name={`child_drupal_field_name_${i}_${index}_${j}`}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please Enter Drupal Field Name',
                                  },
                                ]}
                              >
                                <Input maxLength={32} />
                              </Form.Item>

                              {selectedChildrenTraitType[
                                `nested_child_type_${i}_${index}_${j}`
                              ] === 'text_link' && (
                                <Form.Item
                                  label="Title"
                                  name={`title_${i}_${index}_${j}`}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input Link Title',
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              )}

                              {selectedChildrenTraitType[
                                `nested_child_type_${i}_${index}_${j}`
                              ] === 'image' && (
                                <ImageTraits
                                  child_level_1_index={i}
                                  child_level_2_index={index}
                                  child_level_3_index={j}
                                  form={form}
                                  globalImageStyleOptions={
                                    globalImageStyleOptions
                                  }
                                />
                              )}

                              {selectedChildrenTraitType[
                                `nested_child_type_${i}_${index}_${j}`
                              ] === 'picture' && (
                                <PictureTraits
                                  form={form}
                                  globalImageStyleOptions={
                                    globalImageStyleOptions
                                  }
                                  pictureSourceCollection={
                                    pictureSourceCollection
                                  }
                                  setPictureSourceCollection={
                                    setPictureSourceCollection
                                  }
                                  handleAddPictureCollection={
                                    handleAddPictureCollection
                                  }
                                  globalMediaOptions={globalMediaOptions}
                                  handleAddAnotherSource={
                                    handleAddAnotherSource
                                  }
                                  child_level_1_index={i}
                                  child_level_2_index={index}
                                  child_level_3_index={j}
                                />
                              )}

                              {selectedChildrenTraitType[
                                `nested_child_type_${i}_${index}_${j}`
                              ] !== 'image' &&
                                selectedChildrenTraitType[
                                  `nested_child_type_${i}_${index}_${j}`
                                ] !== 'picture' &&
                                selectedChildrenTraitType[
                                  `nested_child_type_${i}_${index}_${j}`
                                ] !== 'paragraph' && (
                                  <Form.Item
                                    label="Default value"
                                    name={`nested_child_defaultValue_${i}_${index}_${j}`}
                                  >
                                    <Input />
                                  </Form.Item>
                                )}
                              <Button
                                onClick={() => handleAddNested(i, index)}
                                style={{ marginTop: '10px' }}
                              >
                                Add Child
                              </Button>
                            </div>
                          </>
                        );
                      })}
                      <Button
                        onClick={() => handleAddNested(i)}
                        style={{ marginTop: '10px' }}
                      >
                        Add Child
                      </Button>
                    </div>
                  </>
                );
              })}
              <Button
                onClick={() => handleAddChildren()}
                style={{ marginTop: '10px' }}
              >
                Add Child
              </Button>
            </div>
          </>
        );
      })}
    </>
  );
};

export default ParaTraits;
