/* eslint-disable jsx-a11y/anchor-is-valid */
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import uuid from 'react-uuid';
import {
  PlusOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  MinusOutlined,
  LockFilled,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Row,
  Tooltip,
  Typography,
  Spin,
} from 'antd';

import Editor from '../../../common/editor';
import EditorConfig from './editor-config';

import {
  setAtoms,
  setMolecules,
  setOrganisms,
} from '../../../../redux/design-components/actions';

import CONSTANTS from '../../../../utils/constants';
import { useParams } from 'react-router-dom';
import axios from '../../../../utils/axios';
import InputCode from '../../../common/InputCode';
import { Select } from 'antd';
import DependencyTable from '../../../common/DependencyTable';
import LastModified from '../../../common/LastModified.js/index';
import WarningModal from '../../../common/modals/WarningModal';
import { replaceVariables } from '../../../../utils/helpers/replaceTraits';

const { Item: MenuItem, SubMenu } = Menu;
const { Text } = Typography;

const AtomsEditor = ({ atoms, setAtoms, globalConfigs, projectRole }) => {
  const [addNewVariationForm] = Form.useForm();

  const editorRef = useRef(null);

  const didAtomsUpdate = useRef(false);

  const { projectId, step } = useParams();

  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [currentNameofMolecule, setCurrentNameofMolecule] = useState(undefined);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAtom, setSelectedAtom] = useState(null);
  const [selectedVariation, setSelectedVariation] = useState(null);
  const [showJSBlock, setShowJSBlock] = useState(false);
  const [currentCompJs, setCurrentCompJs] = useState('');
  const [isCreatingNewAtom, setIsCreatingNewAtom] = useState(false);
  const [externalLibraryModal, setExternalLibraryModal] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [usingComponent, setUsingComponent] = useState();
  const [variationData, setVariationData] = useState();
  const [updatedComponent, setUpdatedComponent] = useState({
    molecules: [],
    organisms: [],
    layouts: [],
    pages: [],
  });
  const [usedInfo, setUsedInfo] = useState(false);
  const [usagesDetail, setUsagesDetail] = useState({});
  const [isPreviewMode, setIsPreviewMode] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [externalLibrary] = Form.useForm();

  console.log(atoms);


  const addDependencies = values => {
    const newAtoms = [...atoms];

    newAtoms.forEach(atom => {
      if (atom.key === selectedAtom.key) {
        atom.variations.forEach(variation => {
          if (variation.key === selectedVariation) {
            if (variation.dependencies) {
              variation.dependencies[values.select_library].push(
                values.cdn_link
              );
            } else {
              variation.dependencies = { js: [], css: [] };
              variation.dependencies[values.select_library].push(
                values.cdn_link
              );
            }
          }
        });
      }
    });

    setAtoms(newAtoms);
    setExternalLibraryModal(false);
    externalLibrary.resetFields();
  };

  useEffect(() => {
    if (selectedAtom && selectedVariation) {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/components/atoms/${selectedAtom._id}/variations/${selectedVariation}/usages`
        )
        .then(res => {
          setUsagesDetail(res.data);
          setUsingComponent(res.data);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [selectedAtom, selectedVariation, isWarningModalOpen]);

  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    if (atoms && selectedVariation && didAtomsUpdate.current) {
      atoms.forEach(atom => {
        if (atom.variations) {
          const atomVariation = atom.variations.find(
            variation => selectedVariation === variation.key
          );
          if (atomVariation) {
            let editorComponents = atomVariation.html
              ? atomVariation.html
              : atomVariation.html;
            if (atomVariation.css) {
              editorComponents = `${editorComponents}<style>${atomVariation.css}</style>`;
            }
            if (editorRef.current) {
              updateEditorComponents(editorRef.current, editorComponents);
            }
            didAtomsUpdate.current = false;
          }
        }
      });
    }
  }, [atoms]);

  const onFinish = values => {
    const { variationName, atom_Name } = values;

    if (isCreatingNewAtom) {
      const newAtoms = [...atoms];

      const existingAtomIndex = newAtoms.findIndex(
        atom => atom.label === atom_Name
      );

      if (existingAtomIndex !== -1) {
        message.error('Atom with Same Name Already Exist.');
      } else {
        const newVariationKey = uuid();
        const createAtom = {
          label: atom_Name,
          key: newVariationKey,
          variations: [
            {
              label: variationName,
              key: `default-${newVariationKey}`,
              html: '',
              css: '',
              js: '',
              html: '',
            },
          ],
          defaultVariation: '',
        };
        newAtoms.push(createAtom);
        addNewVariationForm.resetFields();

        axios
          .post(
            `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/components/atoms`,
            createAtom
          )
          .then(data => {
            message.success('Atom created successfully');
            setAtoms(newAtoms);

            axios
              .get(
                `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/components`
              )
              .then(data => {
                if (data) {
                  didAtomsUpdate.current = true;
                  setAtoms([...data.data?.atoms]);
                  setMolecules([...data.data?.molecules]);
                  setOrganisms([...data.data?.organisms]);
                }
              })
              .catch(err => {
                console.log(err, 'error');
                message.error('Error: Something went wrong please refresh ');
              });
          })
          .catch(error => {
            console.error(error);
            message.error('Error: Atom not created');
          });
        setIsModalOpen(false);
        setSelectedVariation(newVariationKey);
        setIsCreatingNewAtom(false);
      }
    } else {
      const selectedAtomIndex = atoms.findIndex(
        atom => atom.key === selectedAtom.key
      );
      const newAtoms = [...atoms];

      const existingVariationIndex = newAtoms[
        selectedAtomIndex
      ].variations.findIndex(
        (variation, index) => variation.label === variationName
      );

      if (existingVariationIndex !== -1) {
        message.error('Variation with Same Name Already Exist.');
      } else {
        const newVariationKey = uuid();
        const createVariation = {
          label: variationName,
          key: newVariationKey,
          html: selectedAtom.defaultVariation,
          category: selectedAtom.label,
          components: { ...updatedComponent },
        };
        newAtoms[selectedAtomIndex].variations.push(createVariation);
        addNewVariationForm.resetFields();

        axios
          .post(
            `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/components/atoms/${newAtoms[selectedAtomIndex]._id}/variations`,
            { ...createVariation, components: updatedComponent }
          )
          .then(res => {
            setAtoms(newAtoms);
            message.success('Variation created successfully');
          })
          .catch(error => {
            console.log(error);
            message.success('Error: Variation not created');
          });

        setIsModalOpen(false);
        setSelectedVariation(newVariationKey);
      }
    }
  };

  const handleMenuItemSelect = ({ key }) => {
    if (!key.startsWith(CONSTANTS.KEYS.ADD_NEW_VARIATION_MENU_ITEM_KEY)) {
      setSelectedVariation(key);
      setIsLoading(false);
    }
  };

  const handleAddNewVariation = atom => {
    setSelectedAtom(atom);
    setIsModalOpen(true);
  };

  const handleDeleteAtom = async atomToDelete => {
    Modal.confirm({
      title: 'Delete Alert.',
      content: 'Are you sure? This will Permanently Delete Entire Atom!',
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        const updatedAtoms = atoms.filter(
          atom => atom.key !== atomToDelete.key
        );
        await axios
          .delete(
            `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}/atoms/${atomToDelete._id}`
          )
          .then(res => {
            setAtoms(updatedAtoms);
            <Alert message="Atom deleted successfully!" type="success" />;
          })
          .catch(error => {
            <Alert message="Atom not deleted!" type="error" />;
            console.log('atom not deleted', error);
          });
      },
    });
  };

  const handleInlineCss = e => {
    const newAtoms = [...atoms];
    const selectedAtomIndex = atoms.findIndex(
      atom => atom.key === selectedAtom.key
    );
    const selectedVariationIndex = newAtoms[
      selectedAtomIndex
    ].variations.findIndex(variation => variation.key === selectedVariation);

    newAtoms[selectedAtomIndex].variations[selectedVariationIndex].inLineCss =
      e.target.checked;
    setAtoms(newAtoms);
  };

  const handleSaveVariation = (selectedAtom, selectedVariation) => {
    if (editorRef.current) {
      const html = editorRef.current.getHtml();
      const css = editorRef.current.getCss();
      const js = editorRef.current.getJs();

      const selectedAtomIndex = atoms.findIndex(
        atom => atom.key === selectedAtom.key
      );
      const newAtoms = [...atoms];
      const selectedVariationIndex = atoms[
        selectedAtomIndex
      ].variations.findIndex(
        variation => variation.key === selectedVariation.key
      );
      const variationForUpdate =
        newAtoms[selectedAtomIndex].variations[selectedVariationIndex];

      if (variationForUpdate.html) {
        variationForUpdate.html = html;
        variationForUpdate.html = html;
      } else {
        variationForUpdate.html = html;
      }

      variationForUpdate.css = css;
      variationForUpdate.content = html;

      delete newAtoms[selectedAtomIndex].__v;

      axios
        .patch(
          `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}/atoms/${newAtoms[selectedAtomIndex]._id}/variations/${variationForUpdate.key}`,
          { ...variationForUpdate, components: updatedComponent }
        )
        .then(data => {
          setAtoms(newAtoms);
          message.success('Variation saved');
          axios
            .get(
              `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}`
            )
            .then(data => {
              if (data) {
                didAtomsUpdate.current = true;
                setAtoms([...data.data?.atoms]);
                setMolecules([...data.data?.molecules]);
                setOrganisms([...data.data?.organisms]);
                setIsLoading(false);
              }
            })
            .catch(err => {
              console.log(err, 'error');
              message.error('Something went wrong! Please refresh');
              setIsLoading(false);
            });
        })
        .catch(error => {
          console.error(error);
          message.error('Variation not saved');
          setIsLoading(false);
        });
    }
  };

  const handleDeleteVariaton = () => {
    const newAtoms = [...atoms];
    const selectedAtomIndex = atoms.findIndex(
      atom => atom.key === selectedAtom.key
    );
    let updatedAtom = newAtoms[selectedAtomIndex].variations.filter(
      variation => variation.key !== selectedVariation
    );
    newAtoms[selectedAtomIndex].variations = updatedAtom;
    delete newAtoms[selectedAtomIndex].__v;
    axios
      .delete(
        `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}/atoms/${newAtoms[selectedAtomIndex]._id}/variations/${selectedVariation}`
      )
      .then(data => {
        setAtoms(newAtoms);
        message.success('Variation deleted successfully.');
        axios
          .get(
            `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}`
          )
          .then(data => {
            if (data) {
              didAtomsUpdate.current = true;
              setAtoms([...data.data?.atoms]);
              setMolecules([...data.data?.molecules]);
              setOrganisms([...data.data?.organisms]);
            }
          })
          .catch(err => {
            console.log(err, 'error');
            message.error('Something went wrong! please refresh.');
          });
      })
      .catch(error => {
        console.error(error);
        message.error('Error: Variation not deleted.');
      });

    setIsDeleteModalOpen(false);
  };

  const handleOk = () => {
    if (addNewVariationForm) {
      addNewVariationForm.submit();
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setIsDeleteModalOpen(false);
    setIsRenameModalOpen(false);
  };

  const handleRename = () => {
    const newAtoms = [...atoms];
    const selectedAtomIndex = atoms.findIndex(
      atom => atom.key === selectedAtom.key
    );
    newAtoms[selectedAtomIndex].variations.forEach(variation => {
      if (variation.key === selectedVariation) {
        variation.label = currentNameofMolecule;
      }
    });
    setAtoms(newAtoms);
    setIsRenameModalOpen(false);
  };
  const handleAddJS = () => {
    const selectedAtomIndex = atoms.findIndex(
      atom => atom.key === selectedAtom.key
    );
    const newAtoms = [...atoms];
    const selectedVariationIndex = atoms[
      selectedAtomIndex
    ].variations.findIndex(variation => {
      return variation.key === selectedVariation;
    });

    newAtoms[selectedAtomIndex].variations[selectedVariationIndex].js =
      currentCompJs;
    setAtoms(newAtoms);
    setShowJSBlock(false);
  };
  const handleCreateNewAtom = () => {
    setIsModalOpen(true);
    setIsCreatingNewAtom(true);
  };

  const updateEditorComponents = (editorInstance, components) => {
    editorInstance.DomComponents.getWrapper().set('content', '');
    editorInstance.setComponents(components);
  };

  const selectedUpdatedComponent = collection => {
    setUpdatedComponent({ ...updatedComponent, ...collection });
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const updateRef = () => {
    didAtomsUpdate.current = true;
  };

  return (
    <>
      <Modal
        closable={false}
        open={showJSBlock}
        onOk={handleAddJS}
        onCancel={() => setShowJSBlock(false)}
        title="Add JavaScript"
        width={1200}
      >
        <InputCode
          language="javascript"
          defaultValue={currentCompJs}
          setValue={setCurrentCompJs}
        />
      </Modal>

      {/* warning modal */}
      <Modal
        centered
        key={selectedVariation}
        open={isWarningModalOpen}
        okText={usedInfo ? '' : 'Ok'}
        onOk={() => {
          handleSaveVariation(selectedAtom, variationData);
          setIsWarningModalOpen(false);
          setIsLoading(true);
        }}
        onCancel={() => {
          setIsWarningModalOpen(false);
          setUsedInfo(false);
        }}
        width={1000}
        title={
          usedInfo ? 'Component Usages' : 'Auto-Update Higher Level Components'
        }
      >
        <WarningModal
          usingComponent={usingComponent}
          selectedUpdatedComponent={selectedUpdatedComponent}
          usedInfo={usedInfo}
        />
      </Modal>

      <Modal
        title={isCreatingNewAtom ? 'Create New Atom' : 'Add New Variation'}
        okText={'Create'}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={() => {
          setIsCreatingNewAtom(false);
          handleCancel();
        }}
      >
        <Form
          name="addNewVariationForm"
          form={addNewVariationForm}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
        >
          {isCreatingNewAtom && (
            <Form.Item
              label="Atom Name"
              name="atom_Name"
              rules={[
                { required: true, message: 'Please input variation name' },
              ]}
            >
              <Input />
            </Form.Item>
          )}
          <Form.Item
            label="Variation Name"
            name="variationName"
            rules={[{ required: true, message: 'Please input variation name' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      {/* Modal for External Libraries */}

      <Modal
        title="Add External JS or CSS Library"
        closable={false}
        open={externalLibraryModal}
        onOk={() => {
          if (externalLibrary) {
            externalLibrary.submit();
          }
        }}
        onCancel={() => setExternalLibraryModal(false)}
      >
        <Form
          name="externalLibrary"
          form={externalLibrary}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={addDependencies}
        >
          <Form.Item
            label="Select Library"
            name="select_library"
            rules={[
              { required: true, message: 'Please select type of Library ' },
            ]}
          >
            <Select
              style={{ width: 120 }}
              options={[
                {
                  value: 'css',
                  label: 'CSS',
                },
                {
                  value: 'js',
                  label: 'JS',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Enter CDN Link"
            name="cdn_link"
            rules={[
              { required: true, message: 'Please input CDN Link' },
              {
                type: 'url',
                message: 'Please enter a valid URL for the CDN link',
              },
            ]}
          >
            <Input style={{ width: 200 }} />
          </Form.Item>
        </Form>
      </Modal>

      {/* End of External Library Modal */}

      <Modal
        closable={false}
        open={isRenameModalOpen}
        onOk={handleRename}
        onCancel={handleCancel}
      >
        <Input
          value={currentNameofMolecule}
          onChange={e => setCurrentNameofMolecule(e.target.value)}
        />
      </Modal>

      <Modal
        open={isDeleteModalOpen}
        onOk={handleDeleteVariaton}
        onCancel={handleCancel}
      >
        <p>Are you sure? You want to delete this variation !</p>
      </Modal>

      <Row>
        <Col md={collapsed ? 1 : 4} style={{ transition: '1s' }}>
          <Button
            type="primary"
            onClick={toggleCollapsed}
            style={{
              marginBottom: 16,
            }}
          >
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </Button>
          <Menu
            mode="inline"
            onSelect={handleMenuItemSelect}
            inlineCollapsed={collapsed}
          >
            {atoms.map(atom =>
              atom.label !== 'Spacing' &&
              atom.label !== 'Spacing New' &&
              atom.label !== 'Grids' ?
               (
                <SubMenu key={`menu-${atom.key}`} title={atom.label}>
                  {atom.variations.map(subMenuItem => (
                    <MenuItem
                      key={subMenuItem.key}
                      onClick={() => {
                        setSelectedAtom(atom);
                      }}
                    >
                      {subMenuItem.label}
                    </MenuItem>
                  ))}
                  <MenuItem
                    key={`${CONSTANTS.KEYS.ADD_NEW_VARIATION_MENU_ITEM_KEY}-${atom.key}`}
                    onClick={() => handleAddNewVariation(atom)}
                  >
                    <PlusOutlined />
                    <Text>Add New Variation</Text>
                  </MenuItem>
                  {projectRole === CONSTANTS.USER_ROLES.DEVELOPER ? (
                    <Tooltip title="Request team lead for updating these changes">
                      <Button type="link" key={atom._id} disabled>
                        <MinusOutlined style={{ color: '#ff4d4f' }} />
                        <Text type="danger">Delete Atom</Text>
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      type="link"
                      key={atom._id}
                      onClick={() => handleDeleteAtom(atom)}
                    >
                      <MinusOutlined style={{ color: '#ff4d4f' }} />
                      <Text type="danger">Delete Atom</Text>
                    </Button>
                  )}
                </SubMenu>
              ) : null
            )}
            <MenuItem key="add_atom_icon" onClick={() => handleCreateNewAtom()}>
              <PlusOutlined />
              <Text>Create New Atom</Text>
            </MenuItem>
          </Menu>
        </Col>
        <Col md={collapsed ? 23 : 20} style={{ transition: '1s' }}>
          {selectedVariation ? (
            atoms.map(atom => {
              return atom.variations.map(variation =>
                selectedVariation === variation.key ? (
                  <Spin spinning={isLoading}>
                    <>
                      <Editor
                        id={`atoms-editor-${variation.key}`}
                        key={`atoms-editor-${variation.key}`}
                        onInit={editor => {
                          let editorComponents = variation.html
                            ? variation.html
                            : variation.html;
                          if (variation.css) {
                            editorComponents = `${editorComponents}<style>${variation.css}</style>`;
                          }
                          if (variation.html) {
                            const previewButton = editor.Panels.getButton(
                              'options',
                              'preview'
                            );
                            previewButton.set('active', true, {
                              fromListen: true,
                            });
                            editor.on('stop:preview', () => {
                              editor.setComponents(
                                `${variation.html}<style>${variation.css}</style>`
                              );

                              setIsPreviewMode(false);
                              document.getElementsByClassName(
                                'gjs-pn-panel gjs-pn-devices-c gjs-one-bg gjs-two-color'
                              )[0].style.display = 'block';
                              document.getElementsByClassName(
                                'gjs-pn-panel gjs-pn-views-container gjs-one-bg gjs-two-color'
                              )[0].style.display = 'block';
                            });
                            editor.on('run:preview', async () => {
                              const replacedHtml = await replaceVariables({
                                component: variation,
                                traits: variation.traits,
                                html: variation.html,
                              });
                              editor.setComponents(
                                `${replacedHtml}<style>${variation.css}</style>`
                              );
                              setIsPreviewMode(true);
                              document.getElementsByClassName(
                                'gjs-pn-panel gjs-pn-devices-c gjs-one-bg gjs-two-color'
                              )[0].style.display = 'none';
                              document.getElementsByClassName(
                                'gjs-pn-panel gjs-pn-views-container gjs-one-bg gjs-two-color'
                              )[0].style.display = 'none';
                            });
                          } else {
                            const previewButton = editor.Panels.getButton(
                              'options',
                              'preview'
                            );
                            previewButton.set('disable', true, {
                              fromListen: true,
                            });
                            setIsPreviewMode(false);
                          }
                          updateEditorComponents(editor, editorComponents);
                          return (editorRef.current = editor);
                        }}
                        {...EditorConfig()}
                      ></Editor>
                      <Row
                        style={{
                          marginTop: '10px',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <LastModified
                          entityType="atoms"
                          entityId={atom._id}
                          forceUpdate={didAtomsUpdate.current}
                          updateRef={updateRef}
                          projectRole={projectRole}
                        />
                        <Button
                          type="link"
                          onClick={() => {
                            if (
                              usagesDetail.molecules?.length > 0 ||
                              usagesDetail.organisms?.length > 0 ||
                              usagesDetail.layouts?.length > 0 ||
                              usagesDetail.pages?.length > 0
                            ) {
                              setUsedInfo(true);
                              setIsWarningModalOpen(true);
                            }
                          }}
                        >
                          Used in {usagesDetail?.molecules?.length} molecules
                          {', '}
                          {usagesDetail?.organisms?.length} organisms{', '}
                          {usagesDetail?.layouts?.length} layouts{', '}
                          {usagesDetail?.pages?.length} pages{' '}
                        </Button>
                      </Row>
                      <Row justify="end" style={{ marginTop: '1em' }}>
                        <Col>
                          <Button
                            type="primary"
                            onClick={() => {
                              setShowJSBlock(true);
                              const selectedAtomIndex = atoms.findIndex(
                                atom => atom.key === selectedAtom.key
                              );
                              const newAtoms = [...atoms];
                              const selectedVariationIndex = atoms[
                                selectedAtomIndex
                              ].variations.findIndex(
                                variation => variation.key === selectedVariation
                              );
                              setCurrentCompJs(
                                newAtoms[selectedAtomIndex].variations[
                                  selectedVariationIndex
                                ].js
                              );
                            }}
                          >
                            Add JavaScript
                          </Button>
                        </Col>
                        <Col style={{ marginLeft: '1em' }}>
                          <Button
                            type="primary"
                            disabled={isPreviewMode}
                            onClick={async () => {
                              try {
                                let isUsages = false;
                                let res = await axios.get(
                                  `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/components/atoms/${selectedAtom._id}/variations/${selectedVariation}/usages`
                                );
                                const componnetsKeys = Object.keys(res.data);
                                componnetsKeys?.map(component => {
                                  if (res.data[component].length) {
                                    isUsages = true;
                                  }
                                });

                                if (isUsages) {
                                  setIsWarningModalOpen(true);
                                  setUsingComponent(res.data);
                                  setVariationData(variation);
                                } else {
                                  handleSaveVariation(atom, variation);
                                  setIsLoading(true);
                                }
                              } catch (err) {
                                console.log(err);
                              }
                            }}
                          >
                            Save
                          </Button>
                        </Col>
                        <Col style={{ marginLeft: '1em' }}>
                          {usagesDetail.molecules?.length > 0 ||
                          usagesDetail.organisms?.length > 0 ||
                          usagesDetail.layouts?.length > 0 ||
                          usagesDetail.pages?.length > 0 ||
                          projectRole === CONSTANTS.USER_ROLES.DEVELOPER  ? (
                            <Tooltip
                              title={
                                projectRole === CONSTANTS.USER_ROLES.DEVELOPER
                                  ? 'Request team lead for updating these changes.'
                                  : 'This component cannot be deleted since it has been used in higher level components.'
                              }
                            >
                              <Button type="primary" disabled={true}>
                                Delete Variation
                              </Button>
                            </Tooltip>
                          ) : (
                            <Button
                              type="primary"
                              onClick={() => setIsDeleteModalOpen(true)}
                            >
                              Delete Variation
                            </Button>
                          )}
                        </Col>
                        <Col style={{ marginLeft: '1em' }}>
                          <Button
                            type="primary"
                            onClick={() => {
                              let index = atom.variations.findIndex(
                                ele => ele.key === variation.key
                              );
                              setCurrentNameofMolecule(
                                atom.variations[index].label
                              );
                              setIsRenameModalOpen(true);
                            }}
                          >
                            Rename
                          </Button>
                        </Col>
                        <Col style={{ marginLeft: '1em' }}>
                          <Button
                            type="primary"
                            onClick={() => {
                              setExternalLibraryModal(true);
                            }}
                          >
                            Add External Library{' '}
                          </Button>
                        </Col>
                        <Col style={{ marginLeft: '1em' }}>
                          <Button
                            type="primary"
                            onClick={() => {
                              window.open(
                                window.location.href +
                                  `/preview?key=${variation.key}`
                              );
                            }}
                          >
                            Preview
                          </Button>
                        </Col>
                        <Col style={{ marginLeft: '1em' }}>
                          <Checkbox
                            checked={
                              variation.inLineCss ? variation.inLineCss : false
                            }
                            onChange={handleInlineCss}
                          >
                            Inline CSS
                          </Checkbox>
                        </Col>
                      </Row>
                      {(variation.dependencies?.js?.length ||
                        variation.dependencies?.css?.length) && (
                        <Row style={{ marginTop: '30px' }}>
                          <Col justify="start" span={12}>
                            <DependencyTable
                              setEntity={setAtoms}
                              Entity={atoms}
                              selectedEntity={variation}
                            />
                          </Col>
                        </Row>
                      )}
                    </>
                  </Spin>
                ) : null
              );
            })
          ) : (
            <>
              <Row justify="center" align="middle" style={{ height: '100%' }}>
                <Col>
                  <Text type="warning">
                    Please select a component to proceed
                  </Text>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = state => {
  return {
    atoms: state.DesignComponents.atoms,
    molecules: state.DesignComponents.molecules,
    organisms: state.DesignComponents.organisms,
    globalConfigs:
      state.GlobalConfigurations.finalConfigs?.global_configuration,
    projectRole: state.ProjectRole.projectRole,
  };
};

const mapDispatchToProps = dispatch => ({
  setAtoms: atoms => {
    dispatch(setAtoms(atoms));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AtomsEditor);
