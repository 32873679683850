import Banner from "./Banner";
import Cards from "./Cards";
import CTA from "./CTA";
import Heros from "./hero";
const MoleculesLibrary = {
    Cards,
    CTA,
    Banner,
    Heros
}

export default MoleculesLibrary;