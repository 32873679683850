/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import uuid from 'react-uuid';
import {
  PlusOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Row,
  Select,
  Table,
  Typography,
  Spin,
} from 'antd';

import { useParams } from 'react-router-dom';

import CONSTANTS from '../../../utils/constants';
import axios from '../../../utils/axios/index.js';
import EditForm from '../../common/traits/editTraitComponent';
import TraitForm from '../../common/traits/CreateTraitComponent';
import { setGlobalTraits } from '../../../redux/global-traits/actions.js';

const GlobalTraits = ({
  globalConfigs,
  globalTraits,
  setGlobalTraits,
}) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [traitform] = Form.useForm();
  const [edittraitform] = Form.useForm();
  const [traitTable, setTraitTable] = useState([]);
  const [editModal, showEditModal] = useState(false);
  const [editTraitData, setEditTraitData] = useState(null);
  const [globalMediaOptions, setGlobalMediaOptions] = useState([]);
  const [globalImageStyleOptions, setGlobalImageStyleOptions] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const { projectId, step } = useParams();

  const handleSave = () => {
    if(traitTable && traitTable.length > 0)
    {
      axios
          .patch(
            `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/globalTraits`,
            traitTable
          )
          .then((res) => {
            console.log(res);
            setGlobalTraits(traitTable);
            setIsLoading(false);
            message.success(res.data.msg);
          })
          .catch(e =>  {
            console.log(e);
            message.error(e);
            setIsLoading(false);
          })
    }
  }

  const handleAddChildren = () => {
    setChildren([
      ...children,
      { type: '', label: '', name: '', defaultValue: '' },
    ]);
  };

  useEffect(() => {
    if (globalTraits?.length) {
      setTraitTable([...globalTraits]);
    }
  }, [globalTraits]);

  const handleRemoveNested = (parentIndex, childIndex) => {
    const newChildren = [...children];
    const parent = newChildren[parentIndex];

    if (childIndex === undefined) {
      if (parent.children && parent.children.length > 0) {
        parent.children.pop();
      }
    } else {
      const child = parent.children[childIndex];
      if (child.children && child.children.length > 0) {
        child.children.pop();
      }
    }

    setChildren(newChildren);
  };

  const handleAddAnotherSource = () => {
    let newFields = { type: 'text_link', source: '', media: '' };
    setPictureSourceCollection([...pictureSourceCollection, newFields]);
  };

  const handleAddPictureCollection = (event, index, key) => {
    let newPicCollection = [...pictureSourceCollection];
    if (key === 'select') newPicCollection[index]['media'] = event;
    else newPicCollection[index][event.target.name] = event.target.value;
    setPictureSourceCollection(newPicCollection);
  };

  const handleAddNested = (parentIndex, childIndex) => {
    const newChildren = [...children];
    const parent = newChildren[parentIndex];

    if (childIndex === undefined) {
      if (!parent.children) {
        parent.children = [];
      }
      parent.children.push({
        type: '',
        label: '',
        defaultValue: '',
        children: [],
      });
    } else {
      const child = parent.children[childIndex];
      if (!child.children) {
        child.children = [];
      }
      child.children.push({
        type: '',
        label: '',
        defaultValue: '',
      });
    }

    setChildren(newChildren);
  };

  const [children, setChildren] = useState([
    { type: '', label: '', name: '', defaultValue: '' },
  ]);
  const [pictureSourceCollection, setPictureSourceCollection] = useState([
    { type: 'text_link', source: '', media: '' },
  ]);

  const handleOk = () => {
    if (traitform && !editTraitData) {
      traitform.submit();
    }
    if (editTraitData) {
      edittraitform.submit();
    }
  };

  const onFinish = values => {
    let newGlobalTraits = [...globalTraits];
    let traitIndex;
    if (editTraitData) {
      traitIndex = newGlobalTraits.findIndex(trait => {
        return trait.key === editTraitData.key;
      });
    }
    const Traits = newGlobalTraits;

    const newLabel = ` ${values.label}`;
    const newName = `${_.snakeCase(values.label)}`;

    let newChildrenArr = undefined;

    if (values.type === 'paragraph') {
      newChildrenArr = [];
      children.forEach((child, i) => {
        if (values[`child_type_${i}`]) {
          if (values[`child_type_${i}`] === 'paragraph') {
            newChildrenArr.push({
              type: values[`child_type_${i}`],
              label: values[`child_label_${i}`],
              drupal_field: values[`child_drupal_field_name_${i}`],
              cardinality: values[`cardinality_${i}`],
              ...(child.parent && { parent: child.parent }),
              name: _.snakeCase(values[`child_label_${i}`]),
              children: child.children?.map((child_1, j) => {
                if (values[`nested_child_type_${i}_${j}`] === 'paragraph') {
                  return {
                    type: values[`nested_child_type_${i}_${j}`],
                    label: values[`nested_child_label_${i}_${j}`],
                    cardinality: values[`cardinality_${i}_${j}`],
                    drupal_field: values[`child_drupal_field_name_${i}_${j}`],
                    name: _.snakeCase(values[`nested_child_label_${i}_${j}`]),
                    children: child_1.children?.map((child_2, k) => {
                      if (
                        values[`nested_child_type_${i}_${j}_${k}`] === 'image'
                      ) {
                        return {
                          type: values[`nested_child_type_${i}_${j}_${k}`],
                          label: values[`nested_child_label_${i}_${j}_${k}`],
                          drupal_field:
                            values[`child_drupal_field_name_${i}_${j}_${k}`],
                          name: _.snakeCase(
                            values[`nested_child_label_${i}_${j}_${k}`]
                          ),
                          children: [
                            {
                              type: 'text_link',
                              name: _.snakeCase(
                                `${_.snakeCase(
                                  values[`nested_child_type_${i}_${j}_${k}`]
                                )}_img_source_${i}_${j}_${k}`
                              ),
                              label: ` ${
                                values[`nested_child_label_${i}_${j}_${k}`]
                              } image source`,
                              defaultValue:
                                values[`image_src_link_${i}_${j}_${k}`],
                            },
                            {
                              type: 'text_plain',
                              name: _.snakeCase(
                                `${_.snakeCase(
                                  values[`nested_child_type_${i}_${j}_${k}`]
                                )}_img_alt_text_${i}_${j}_${k}`
                              ),
                              label: `${
                                values[`nested_child_label_${i}_${j}_${k}`]
                              } image alt text`,
                              defaultValue:
                                values[`image_alt_text_${i}_${j}_${k}`],
                            },
                            {
                              type: 'text_plain',
                              name: _.snakeCase(
                                `${_.snakeCase(
                                  values[`nested_child_type_${i}_${j}_${k}`]
                                )}_img_styles_${i}_${j}_${k}`
                              ),
                              label: `${
                                values[`nested_child_label_${i}_${j}_${k}`]
                              } image styles`,
                              options: [...globalImageStyleOptions],
                              // defaultValue:
                              //   values[`image_style_${i}_${j}_${k}`],
                              defaultValue: (() => {
                                const modifiedValue = values[
                                  `image_style_${i}_${j}_${k}`
                                ]
                                  .replace(/(\.bootstrap-styles|\.|\s)/g, '')
                                  .trim();
                                return modifiedValue;
                              })(),
                            },
                          ],
                        };
                      } else if (
                        values[`nested_child_type_${i}_${j}_${k}`] === 'picture'
                      ) {
                        //////////////////////////

                        return {
                          type: values[`nested_child_type_${i}_${j}_${k}`],
                          label: values[`nested_child_label_${i}_${j}_${k}`],
                          name: _.snakeCase(
                            values[`nested_child_label_${i}_${j}_${k}`]
                          ),
                          drupal_field:
                            values[`child_drupal_field_name_${i}_${j}_${k}`],
                          children: [
                            {
                              type: 'image',
                              name: _.snakeCase(
                                `${
                                  values[`nested_child_label_${i}_${j}_${k}`]
                                }_fall_back_image_src_link`
                              ),
                              label: `${
                                values[`nested_child_label_${i}_${j}_${k}`]
                              } fall back image source`,
                              children: [
                                {
                                  type: 'text_link',
                                  name: 'image_src_link',
                                  label: 'Image src link',
                                  defaultValue:
                                    values[
                                      `fall_back_image_src_link_${i}_${j}_${k}`
                                    ],
                                },
                                {
                                  type: 'text_plain',
                                  name: 'image_alt_text',
                                  label: 'Image alt text',
                                  defaultValue:
                                    values[
                                      `fall_back_image_alt_text_${i}_${j}_${k}`
                                    ],
                                },
                                {
                                  type: 'text_link',
                                  name: 'image_styles',
                                  label: 'Image styles',
                                  options: [...globalImageStyleOptions],
                                  // defaultValue:
                                  //   values[
                                  //     `fall_back_image_style_${i}_${j}_${k}`
                                  //   ],
                                  defaultValue: (() => {
                                    const modifiedValue = values[
                                      `fall_back_image_style_${i}_${j}_${k}`
                                    ]
                                      .replace(
                                        /(\.bootstrap-styles|\.|\s)/g,
                                        ''
                                      )
                                      .trim();
                                    return modifiedValue;
                                  })(),
                                },
                              ],
                            },
                            {
                              type: 'sources',
                              name: _.snakeCase(
                                `${
                                  values[`nested_child_label_${i}_${j}_${k}`]
                                }_alternate_sources`
                              ),
                              label: `${
                                values[`nested_child_label_${i}_${j}_${k}`]
                              } alternate source`,
                              children: [...pictureSourceCollection],
                            },
                          ],
                        };

                        ///////////////////////////
                      } else {
                        return {
                          type: values[`nested_child_type_${i}_${j}_${k}`],
                          label: values[`nested_child_label_${i}_${j}_${k}`],
                          drupal_field:
                            values[`child_drupal_field_name_${i}_${j}_${k}`],

                          name: _.snakeCase(
                            values[`nested_child_label_${i}_${j}_${k}`]
                          ),
                          defaultValue:
                            values[`nested_child_defaultValue_${i}_${j}_${k}`],
                          ...(values[`title_${i}_${j}_${k}`] && {
                            title: values[`title_${i}_${j}_${k}`],
                          }),
                        };
                      }
                    }),
                    // ],
                  };
                }

                if (values[`nested_child_type_${i}_${j}`] === 'image') {
                  return {
                    type: values[`nested_child_type_${i}_${j}`],
                    label: values[`nested_child_label_${i}_${j}`],
                    drupal_field: values[`child_drupal_field_name_${i}_${j}`],
                    name: _.snakeCase(values[`nested_child_label_${i}_${j}`]),
                    // defaultValue: values[`nested_child_defaultValue_${i}_${j}`],
                    children: [
                      {
                        type: 'text_link',
                        name: _.snakeCase(
                          `${_.snakeCase(
                            values[`nested_child_type_${i}_${j}`]
                          )}_img_source_${i}_${j}`
                        ),
                        label: ` ${
                          values[`nested_child_label_${i}_${j}`]
                        } image source`,
                        defaultValue: values[`image_src_link_${i}_${j}`],
                      },
                      {
                        type: 'text_plain',
                        name: _.snakeCase(
                          `${_.snakeCase(
                            values[`nested_child_type_${i}_${j}`]
                          )}_img_alt_text_${i}_${j}`
                        ),
                        label: `${
                          values[`nested_child_label_${i}_${j}`]
                        } image alt text`,
                        defaultValue: values[`image_alt_text_${i}_${j}`],
                      },
                      {
                        type: 'text_plain',
                        name: _.snakeCase(
                          `${_.snakeCase(
                            values[`nested_child_type_${i}_${j}`]
                          )}_img_styles_${i}_${j}`
                        ),
                        label: `${
                          values[`nested_child_label_${i}_${j}`]
                        } image styles`,
                        options: [...globalImageStyleOptions],
                        defaultValue: values[`image_style_${i}_${j}`],
                      },
                    ],
                  };
                } else if (
                  values[`nested_child_type_${i}_${j}`] === 'picture'
                ) {
                  return {
                    type: values[`nested_child_type_${i}_${j}`],
                    label: values[`nested_child_label_${i}_${j}`],
                    drupal_field: values[`child_drupal_field_name_${i}_${j}`],
                    name: _.snakeCase(values[`nested_child_label_${i}_${j}`]),
                    children: [
                      {
                        type: 'image',
                        name: _.snakeCase(
                          `${
                            values[`nested_child_label_${i}_${j}`]
                          }_fall_back_image_src_link`
                        ),
                        label: `${
                          values[`nested_child_label_${i}_${j}`]
                        } fall back image source`,
                        children: [
                          {
                            type: 'text_link',
                            name: 'image_src_link',
                            label: 'Image src link',
                            defaultValue:
                              values[`fall_back_image_src_link_${i}_${j}`],
                          },
                          {
                            type: 'text_plain',
                            name: 'image_alt_text',
                            label: 'Image alt text',
                            defaultValue:
                              values[`fall_back_image_alt_text_${i}_${j}`],
                          },
                          {
                            type: 'text_link',
                            name: 'image_styles',
                            label: 'Image styles',
                            options: [...globalImageStyleOptions],
                            defaultValue:
                              values[`fall_back_image_style_${i}_${j}`],
                          },
                        ],
                      },
                      {
                        type: 'sources',
                        name: _.snakeCase(
                          `${
                            values[`nested_child_label_${i}_${j}`]
                          }_alternate_sources`
                        ),
                        label: `${
                          values[`nested_child_label_${i}_${j}`]
                        } alternate source`,
                        children: [...pictureSourceCollection],
                      },
                    ],
                  };
                } else {
                  return {
                    type: values[`nested_child_type_${i}_${j}`],
                    label: values[`nested_child_label_${i}_${j}`],
                    drupal_field: values[`child_drupal_field_name_${i}_${j}`],
                    name: _.snakeCase(values[`nested_child_label_${i}_${j}`]),
                    defaultValue: values[`nested_child_defaultValue_${i}_${j}`],
                    ...(values[`title_${i}_${j}`] && {
                      title: values[`title_${i}_${j}`],
                    }),
                  };
                }
              }),
              // }],
            });
          } else if (values[`child_type_${i}`] === 'image') {
            console.log('drupal', values[`child_drupal_field_name_${i}`]);

            newChildrenArr.push({
              type: values[`child_type_${i}`],
              label: values[`child_label_${i}`],
              ...(child.parent && { parent: child.parent }),
              drupal_field: values[`child_drupal_field_name_${i}`],
              name: _.snakeCase(values[`child_label_${i}`]),
              //////
              children: [
                {
                  type: 'text_link',
                  name: _.snakeCase(
                    `${_.snakeCase(values[`child_type_${i}`])}_img_source_${i}`
                  ),
                  label: ` ${values[`child_label_${i}`]} image source`,
                  defaultValue: values[`image_src_link_${i}`],
                },
                {
                  type: 'text_plain',
                  name: _.snakeCase(
                    `${_.snakeCase(
                      values[`child_type_${i}`]
                    )}_img_alt_text_${i}`
                  ),
                  label: `${values[`child_label_${i}`]} image alt text`,
                  defaultValue: values[`image_alt_text_${i}`],
                },
                {
                  type: 'text_plain',
                  name: _.snakeCase(
                    `${_.snakeCase(values[`child_type_${i}`])}_img_styles_${i}`
                  ),
                  label: `${values[`child_label_${i}`]} image styles`,
                  options: [...globalImageStyleOptions],
                  // defaultValue: values[`image_style_${i}`],
                  defaultValue: (() => {
                    const modifiedValue = values[`image_style_${i}`]
                      .replace(/(\.bootstrap-styles|\.|\s)/g, '')
                      .trim();
                    return modifiedValue;
                  })(),
                },
              ],
            });
          } else if (values[`child_type_${i}`] === 'picture') {
            /////////////////////////////////

            newChildrenArr.push({
              type: values[`child_type_${i}`],
              label: values[`child_label_${i}`],
              drupal_field: values[`child_drupal_field_name_${i}`],
              ...(child.parent && { parent: child.parent }),
              name: _.snakeCase(values[`child_label_${i}`]),
              children: [
                {
                  type: 'image',
                  name: _.snakeCase(
                    `${values[`child_label_${i}`]}_fall_back_image_src_link`
                  ),
                  label: `${values[`child_label_${i}`]} fall back image source`,
                  children: [
                    {
                      type: 'text_link',
                      name: 'image_src_link',
                      label: 'Image src link',
                      defaultValue: values[`fall_back_image_src_link_${i}`],
                    },
                    {
                      type: 'text_plain',
                      name: 'image_alt_text',
                      label: 'Image alt text',
                      defaultValue: values[`fall_back_image_alt_text_${i}`],
                    },
                    {
                      type: 'text_link',
                      name: 'image_styles',
                      label: 'Image styles',
                      options: [...globalImageStyleOptions],
                      // defaultValue: values[`fall_back_image_style_${i}`],
                      defaultValue: (() => {
                        const modifiedValue = values[
                          `fall_back_image_style_${i}`
                        ]
                          .replace(/(\.bootstrap-styles|\.|\s)/g, '')
                          .trim();
                        return modifiedValue;
                      })(),
                    },
                  ],
                },
                {
                  type: 'sources',
                  name: _.snakeCase(
                    `${values[`child_label_${i}`]}_alternate_sources`
                  ),
                  label: `${values[`child_label_${i}`]} alternate source`,
                  children: [...pictureSourceCollection],
                },
              ],
            });
          } else {
            console.log('drupal', values[`child_drupal_field_name_${i}`]);
            newChildrenArr.push({
              type: values[`child_type_${i}`],
              label: values[`child_label_${i}`],
              ...(child.parent && { parent: child.parent }),
              drupal_field: values[`child_drupal_field_name_${i}`],
              name: _.snakeCase(values[`child_label_${i}`]),
              defaultValue: values[`child_defaultValue_${i}`],
              // children: [],
              ...(values[`title_${i}`] && { title: values[`title_${i}`] }),
            });
          }
        } else {
          newChildrenArr.push({
            type: values[`child_type_${i}`],
            label: values[`child_label_${i}`],
            ...(child.parent && { parent: child.parent }),
            drupal_field: values[`child_drupal_field_name_${i}`],
            name: _.snakeCase(values[`child_label_${i}`]),
            defaultValue: values[`child_defaultValue_${i}`],
            // children: [],
          });
        }
      });
    } else if (values.type === 'picture') {
      newChildrenArr = [
        {
          type: 'image',
          name: _.snakeCase(`${values.label}_fall_back_image_src_link`),
          label: `${values.label} fall back image source`,
          children: [
            {
              type: 'text_link',
              name: 'image_src_link',
              label: 'Image src link',
              defaultValue: values.fall_back_image_src_link,
            },
            {
              type: 'text_plain',
              name: 'image_alt_text',
              label: 'Image alt text',
              defaultValue: values.fall_back_image_alt_text,
            },
            {
              type: 'text_link',
              name: 'image_styles',
              label: 'Image styles',
              options: [...globalImageStyleOptions],
              // defaultValue: values.fall_back_image_style,
              defaultValue: (() => {
                const modifiedValue = values.fall_back_image_style
                  .replace(/(\.bootstrap-styles|\.|\s)/g, '')
                  .trim();
                return modifiedValue;
              })(),
            },
          ],
        },
        {
          type: 'sources',
          name: _.snakeCase(`${values.label}_alternate_sources`),
          label: `${values.label} alternate source`,
          children: [...pictureSourceCollection],
        },
      ];
    } else if (values.type === 'image') {
      newChildrenArr = [
        {
          type: 'text_link',
          name: _.snakeCase(`img_source`),
          label: `image source`,
          defaultValue: values.image_src_link,
        },
        {
          type: 'text_plain',
          name: _.snakeCase(`img_alt_text`),
          label: `image alt text`,
          defaultValue: values.image_alt_text,
        },
        {
          type: 'text_plain',
          name: _.snakeCase(`img_styles`),
          label: `image styles`,
          options: [...globalImageStyleOptions],
          // defaultValue: values.image_style,
          defaultValue: (() => {
            const modifiedValue = values.image_style
              .replace(/(\.bootstrap-styles|\.|\s)/g, '')
              .trim();
            return modifiedValue;
          })(),
        },
      ];
    }

    if (editTraitData) {
      Traits[traitIndex] = {
        ...editTraitData,
        key: editTraitData.key,
        type: values.type,
        name: newName,
        label: newLabel,
        drupal_field: values.drupal_field_name,
        ...(values.cardinality && { cardinality: values.cardinality }),
        children: newChildrenArr,
        ...(values.defaultValue && { defaultValue: values.defaultValue }),
        ...(values.title && { title: values.title }),
        ...(values.options && { options: values.options.split(',') }),
      };
    } else {
      const newLabel = `${values.label}`;
      const newName = `${_.snakeCase(values.label)}`;
      if (newGlobalTraits && newGlobalTraits.length) {
        newGlobalTraits.push({
          key: uuid(),
          type: values.type,
          name: newName,
          label: newLabel,
          drupal_field: values.drupal_field_name,
          ...(values.cardinality && { cardinality: values.cardinality }),
          ...(values.title && { title: values.title }),
          ...(newChildrenArr?.length > 0 && { children: newChildrenArr }),
          defaultValue: values.defaultValue,
          ...(values.options && { options: values.options.split(',') }),
        });
        setTraitTable([...newGlobalTraits]);
      } else {
        newGlobalTraits = [
          {
            key: uuid(),
            type: values.type,
            name: newName,
            label: newLabel,
            drupal_field: values.drupal_field_name,
            ...(values.cardinality && { cardinality: values.cardinality }),
            ...(values.title && { title: values.title }),
            ...(newChildrenArr?.length > 0 && { children: newChildrenArr }),
            defaultValue: values.defaultValue,
          },
        ];
        setTraitTable([...newGlobalTraits]);
      }
    }
    setIsModalOpen(false);
    traitform.resetFields();
    if (editTraitData) {
      setTraitTable([...Traits]);
    }
    showEditModal(false);
    setEditTraitData(null);
    setPictureSourceCollection([{ type: 'text_link', source: '', media: '' }]);
    setChildren([{ type: '', label: '', name: '', defaultValue: '' }]);
  };

  const handleCancel = () => {
    setEditTraitData(null);
    setIsModalOpen(false);
    setChildren([{ type: '', label: '', name: '', defaultValue: '' }]);
    setPictureSourceCollection([{ type: 'text_link', source: '', media: '' }]);
    edittraitform.resetFields();
    traitform.resetFields();
    showEditModal(false);
  };

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: 'Delete',
      dataIndex: '',
      key: '',
      render: (_, record) => {
        const hasChildren = record.children && record.children.length > 0;
        if ((hasChildren && !record.key) || record.key === undefined) {
          return null;
        }
        return (
          <a
            onClick={() => {
              Modal.confirm({
                title: 'Delete Alert.',
                content: 'Do you want to delete it?',
                okText: 'Yes',
                cancelText: 'No',
                onOk: () => {
                  const updatedTraits = traitTable.filter(
                    e => e.name != record.name
                  );
                  setTraitTable([...updatedTraits]);
                },
              });
            }}
          >
            Delete
          </a>
        );
      },
    },
    {
      title: 'Edit',
      dataIndex: '',
      key: '',
      render: (_, record) => {
        const hasChildren = record.children && record.children.length > 0;
        if ((hasChildren && !record.key) || record.key === undefined) {
          return null;
        }
        return (
          <a
            onClick={() => {
              edittraitform.resetFields();

              showEditModal(true);
              setEditTraitData({ ...record });
              setChildren(record.children);
              if (record.type === 'picture') {
                setPictureSourceCollection(record.children?.[1]?.children);
              }
            }}
          >
            Edit
          </a>
        );
      },
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/configurations`
        );
        const fetchedOptions =
          response.data.global_configuration.globalImageStyles;
        const options =
          fetchedOptions?.map(opt => ({
            label: opt.name,
            value: `.${CONSTANTS.BOOTSTRAP_WRAPPER_CLASS} .${_.kebabCase(
              opt.name
            )}`,
          })) || [];
        const mediaOptions =
          response.data.global_configuration.breakpoints?.map(opt => {
            return {
              label: opt.name,
              value: `(min-width: ${opt.width}) and (max-width: ${opt.max_width})`,
            };
          }) || [];

        setGlobalImageStyleOptions([...options]);
        setGlobalMediaOptions([...mediaOptions]);
      } catch (error) {
        console.log('error', error);
      }
    };

    fetchData();
  }, [globalConfigs?.globalImageStyles, projectId]);

  return (
    <>
      <Spin spinning={isLoading}>
      <EditForm
        form={edittraitform}
        globalImageStyleOptions={globalImageStyleOptions}
        globalMediaOptions={globalMediaOptions}
        setEditData={editTraitData}
        onFinish={onFinish}
        handleAddChildren={handleAddChildren}
        children={children}
        handleAddAnotherSource={handleAddAnotherSource}
        handleAddPictureCollection={handleAddPictureCollection}
        pictureSourceCollection={pictureSourceCollection}
        setPictureSourceCollection={setPictureSourceCollection}
        handleAddNested={handleAddNested}
        editModal={editModal}
        handleOk={handleOk}
        setEditTraitData={setEditTraitData}
        setIsModalOpen={setIsModalOpen}
        setChildren={setChildren}
        showEditModal={showEditModal}
        handleRemoveNested={handleRemoveNested}
      />

      <Modal
        title={'Create New Trait'}
        okText={'Create'}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={() => {
          handleCancel();
        }}
        width={650}
      >
        <TraitForm
          form={traitform}
          globalImageStyleOptions={globalImageStyleOptions}
          globalMediaOptions={globalMediaOptions}
          handleAddChildren={handleAddChildren}
          onFinish={onFinish}
          children={children}
          handleAddNested={handleAddNested}
          handleAddAnotherSource={handleAddAnotherSource}
          handleAddPictureCollection={handleAddPictureCollection}
          pictureSourceCollection={pictureSourceCollection}
          setPictureSourceCollection={setPictureSourceCollection}
          handleRemoveNested={handleRemoveNested}
        />
      </Modal>

      <Row justify="end" style={{ marginTop: 40, marginBottom: 40 }}>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            <PlusOutlined />
            Create Global Traits
          </Button>
        </Col>
      </Row>

      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        {traitTable.length ? (
          <Col span={18}>
            <h3>Available Traits</h3>
            <Table
              dataSource={traitTable}
              columns={columns}
              pagination={{ pageSize: 8 }}
            />
          </Col>
        ) : null}
      </Row>

      <Row justify="center">
      <Col style={{ margin: '1em' }}>
          <Button
            type="primary"
            onClick={() => {
              setIsLoading(true);
              handleSave();
            }}
          >
            Save
          </Button>
        </Col>
        </Row>
        </Spin>
    </>
  );
};

const mapStateToProps = state => {
  return {
    globalConfigs:
      state.GlobalConfigurations.finalConfigs?.global_configuration,
    globalTraits: state.GlobalTraits.globalTraits,
  };
};

const mapDispatchToProps = dispatch => ({
  setGlobalTraits: globalTraits => {
    dispatch(setGlobalTraits(globalTraits));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalTraits);
