import EightColumn from './eightColumn';
import ElevenColumn from './elevenColumn';
import FiveColumn from './fiveColumn';
import FourColumn from './fourColumn';
import NineColumn from './nineColumn';
import OneColumn from './oneCol';
import SevenColumn from './sevenColumn';
import SixColumn from './sixColumn';
import TenColumn from './tenColumn';
import ThreeColumn from './threeColumn';
import TwelveColumn from './twelveColumn';
import TwoColumn from './twoColumn';

const Grids = {
  OneColumn,
  TwoColumn,
  ThreeColumn,
  FourColumn,
  FiveColumn,
  SixColumn,
  SevenColumn,
  EightColumn,
  NineColumn,
  TenColumn,
  ElevenColumn,
  TwelveColumn,
};

export default Grids;
