/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import uuid from 'react-uuid';

import {
  PlusOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Row,
  Select,
  Table,
  Typography,
  Spin,
} from 'antd';
import CKEditor from '../../../common/CKEditor/CKEditor';
import {
  setAtoms,
  setMolecules,
  setOrganisms,
} from '../../../../redux/design-components/actions';

import CONSTANTS from '../../../../utils/constants';
import { useParams } from 'react-router-dom';
import axios from '../../../../utils/axios/index';
import LastModified from '../../../common/LastModified.js';
// import EditForm from '../../../common/traits/editComponent/index';
import TraitForm from '../../../common/traits/CreateTraitComponent/index';
import EditForm from '../../../common/traits/editTraitComponent';
import { replaceVariables } from '../../../../utils/helpers/replaceTraits';
import { Radio } from 'antd';

const { Item: MenuItem, SubMenu } = Menu;
const { Text } = Typography;
const { TextArea } = Input;

const AtomsTraits = ({
  designComponents,
  atoms,
  setAtoms,
  globalConfigs,
  token,
  configuration,
  projectRole,
  globalTraits
}) => {
  const bootstrap_css = configuration.initialCDN;
  const [collapsed, setCollapsed] = useState(false);
  const [selectedVariation, setSelectedVariation] = useState(null);
  const [selectedAtom, setSelectedAtom] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [traitform] = Form.useForm();
  const [edittraitform] = Form.useForm();
  const [traitTable, setTraitTable] = useState([]);
  const [editModal, showEditModal] = useState(false);
  const [editTraitData, setEditTraitData] = useState(null);
  const [globalMediaOptions, setGlobalMediaOptions] = useState([]);
  const [globalImageStyleOptions, setGlobalImageStyleOptions] = useState([]);
  const [isSelectedTraitParagraph, setIsSelectedTraitParagraph] =
    useState(false);
  const [isSelectedTraitType, setIsSelectedTraitType] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [createTraitOptions, setCreateTraitOptions] = useState('create');
  const [didTraitsUpdate, setDidTraitsUpdate] = useState(false);
  const saveRef = useRef(null);
  const [choosenTraitValue , setChoosenTraitValue] = useState(null);

  const { Option } = Select;

  useEffect(() => {
    setDidTraitsUpdate(false);
  }, [atoms]);

  const editorRef = useRef(null);
  const [key, setKey] = useState(0); // initialize key state variable for ckeditor rerender

  const { projectId, step } = useParams();

  const handleAddTraits = (atom, variation) => {
    setIsModalOpen(true);
    setSelectedAtom(atom);
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleAddChildren = () => {
    setChildren([
      ...children,
      { type: '', label: '', name: '', defaultValue: '' },
    ]);
  };

  const handleAddAnotherSource = () => {
    let newFields = { type: 'text_link', source: '', media: '' };
    setPictureSourceCollection([...pictureSourceCollection, newFields]);
  };

  const handleAddPictureCollection = (event, index, key) => {
    let newPicCollection = [...pictureSourceCollection];
    if (key === 'select') newPicCollection[index]['media'] = event;
    else newPicCollection[index][event.target.name] = event.target.value;
    setPictureSourceCollection(newPicCollection);
  };

  const handleAddNested = (parentIndex, childIndex) => {
    const newChildren = [...children];
    const parent = newChildren[parentIndex];

    if (childIndex === undefined) {
      if (!parent.children) {
        parent.children = [];
      }
      parent.children.push({
        type: '',
        label: '',
        defaultValue: '',
        children: [],
      });
    } else {
      const child = parent.children[childIndex];
      if (!child.children) {
        child.children = [];
      }
      child.children.push({
        type: '',
        label: '',
        defaultValue: '',
      });
    }

    setChildren(newChildren);
  };

  const handleReset = () => {
    Modal.confirm({
      title: 'Reset Alert!',
      content: (
        <div>
          <Alert
            message="You will have to re-insert the tokens used in this component."
            type="warning"
          />
          <br></br>
          Do you want to Reset Component?
        </div>
      ),
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        const selectedAtomIndex = atoms.findIndex(
          atom => atom.key === selectedAtom.key
        );
        const newAtoms = [...atoms];
        const selectedVariationIndex = newAtoms[
          selectedAtomIndex
        ].variations.findIndex(
          variation => variation.key === selectedVariation
        );

        let html =
          newAtoms[selectedAtomIndex].variations[selectedVariationIndex].html;

        let modifiedContent = html.replace(
          /{{([^}]+)}}|start_curly([^]+?)_end_curly|<!--(.*?)-->/g,
          function (subMatch, p1, p2, p3) {
            if (p1) {
              return ``;
            } else if (p2) {
              return ``;
            } else if (p3) {
              console.log(p3);
              return `<!--${p3}-->`;
            }
          }
        );

        newAtoms[selectedAtomIndex].variations[selectedVariationIndex].html =
          modifiedContent;

        axios
          .patch(
            `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/components/atoms/${newAtoms[selectedAtomIndex]._id}/variations/${newAtoms[selectedAtomIndex].variations[selectedVariationIndex].key}`,
            {
              ...newAtoms[selectedAtomIndex].variations[selectedVariationIndex],
            }
          )
          .then(data => {
            setDidTraitsUpdate(true);
            setKey(key + 1); // to rerender ckeditor...
            message.success('Component Resetted.');
          });
        setAtoms(newAtoms);
      },
    });
  };

  const handleRemoveNested = (parentIndex, childIndex) => {
    const newChildren = [...children];
    const parent = newChildren[parentIndex];

    if (childIndex === undefined) {
      if (parent.children && parent.children.length > 0) {
        parent.children.pop();
      }
    } else {
      const child = parent.children[childIndex];
      if (child.children && child.children.length > 0) {
        child.children.pop();
      }
    }

    setChildren(newChildren);
  };

  const handleMenuItemSelect = ({ key }) => {
    if (!key.startsWith(CONSTANTS.KEYS.ADD_NEW_VARIATION_MENU_ITEM_KEY)) {
      setSelectedVariation(key);
    }
  };

  const [children, setChildren] = useState([
    { type: '', label: '', name: '', defaultValue: '' },
  ]);
  const [pictureSourceCollection, setPictureSourceCollection] = useState([
    { type: 'text_link', source: '', media: '' },
  ]);

  const handleOk = () => {
    if(createTraitOptions === 'choose')
    {
      const selectedGlobalTrait = globalTraits.find((trait) => trait.name === choosenTraitValue);
          
      const selectedAtomIndex = atoms.findIndex(
        atom => atom.key === selectedAtom.key
      );
  
      const newAtoms = [...atoms];
      const selectedVariationIndex = newAtoms[
        selectedAtomIndex
      ].variations.findIndex(variation => variation.key === selectedVariation);
  
      const currentVariation =
        newAtoms[selectedAtomIndex].variations[selectedVariationIndex];

        const newLabel = `${newAtoms[selectedAtomIndex].label} ${currentVariation.label} ${selectedGlobalTrait.label}`;
        const newName = `${_.snakeCase(
          newAtoms[selectedAtomIndex].label
        )}_${_.snakeCase(currentVariation.label)}_${_.snakeCase(selectedGlobalTrait.label)}`;

        const modifiedSelectedGlobalTrait = {
          ...selectedGlobalTrait,
          name : newName,
          label: newLabel
        }

      if(currentVariation.traits)
      {
        currentVariation.traits.push(modifiedSelectedGlobalTrait)
      }
      else{
        currentVariation.traits = [{
          ...modifiedSelectedGlobalTrait
        }]
      }
      setAtoms(newAtoms);
      setTraitTable(currentVariation.traits);
      setIsModalOpen(false);
      setCreateTraitOptions(null);
    }
    else{
      if (traitform && !editTraitData) {
        traitform.submit();
      }
      if (editTraitData) {
        edittraitform.submit();
      }
    }
  };

  const onFinish = values => {
    // console.log(values);

    const selectedAtomIndex = atoms.findIndex(
      atom => atom.key === selectedAtom.key
    );

    const newAtoms = [...atoms];
    const selectedVariationIndex = newAtoms[
      selectedAtomIndex
    ].variations.findIndex(variation => variation.key === selectedVariation);

    const currentVariation =
      newAtoms[selectedAtomIndex].variations[selectedVariationIndex];

    const componentTypeKeys = Object.keys(designComponents);

    // for (const key of componentTypeKeys) {
    //   for (const component of designComponents[key]) {
    //     if ((key === 'atoms' || key === 'molecules') && component.variations) {
    //       for (const variation of component.variations) {
    //         if (variation.traits) {
    //           for (const trait of variation.traits) {
    //             if (trait.drupal_field === values.drupal_field_name) {
    //               message.error('drupal_field must be unique...');
    //               return; // Stop the loop and exit the function
    //             }
    //           }
    //         }
    //       }
    //     } else if (component.traits && !component.variations) {
    //       for (const trait of component.traits) {
    //         if (trait.drupal_field === values.drupal_field_name) {
    //           message.error('drupal_field must be unique...');
    //           return;
    //         }
    //       }
    //     }
    //   }
    // }

    let traitIndex;

    if (editTraitData) {
      console.log('editData', editTraitData);
      traitIndex = newAtoms[selectedAtomIndex].variations[
        selectedVariationIndex
      ].traits.findIndex(trait => {
        return trait.key === editTraitData.key;
      });
    }

    const Traits =
      newAtoms[selectedAtomIndex].variations[selectedVariationIndex].traits;

    const newLabel = `${newAtoms[selectedAtomIndex].label} ${currentVariation.label} ${values.label}`;
    const newName = `${_.snakeCase(
      newAtoms[selectedAtomIndex].label
    )}_${_.snakeCase(currentVariation.label)}_${_.snakeCase(values.label)}`;

    let newChildrenArr = undefined;

    if (values.type === 'paragraph') {
      newChildrenArr = [];

      children.forEach((child, i) => {
        if (values[`child_type_${i}`]) {
          if (values[`child_type_${i}`] === 'paragraph') {
            newChildrenArr.push({
              type: values[`child_type_${i}`],
              label: values[`child_label_${i}`],
              drupal_field_name: values[`child_drupal_field_name_${i}`],
              cardinality: values[`cardinality_${i}`],
              name: _.snakeCase(values[`child_label_${i}`]),
              children: child.children?.map((child_1, j) => {
                if (values[`nested_child_type_${i}_${j}`] === 'paragraph') {
                  return {
                    type: values[`nested_child_type_${i}_${j}`],
                    label: values[`nested_child_label_${i}_${j}`],
                    drupal_field_name:
                      values[`child_drupal_field_name_${i}_${j}`],
                    cardinality: values[`cardinality_${i}_${j}`],
                    name: _.snakeCase(values[`nested_child_label_${i}_${j}`]),
                    children: child_1.children?.map((child_2, k) => {
                      if (
                        values[`nested_child_type_${i}_${j}_${k}`] === 'image'
                      ) {
                        return {
                          type: values[`nested_child_type_${i}_${j}_${k}`],
                          label: values[`nested_child_label_${i}_${j}_${k}`],
                          name: _.snakeCase(
                            values[`nested_child_label_${i}_${j}_${k}`]
                          ),
                          drupal_field_name:
                            values[`child_drupal_field_name_${i}_${j}_${k}`],
                          children: [
                            {
                              type: 'text_link',
                              name: _.snakeCase(`img_source_${i}_${j}_${k}`),
                              label: ` ${
                                values[`nested_child_label_${i}_${j}_${k}`]
                              } image source`,
                              defaultValue:
                                values[`image_src_link_${i}_${j}_${k}`],
                            },
                            {
                              type: 'text_plain',
                              name: _.snakeCase(`img_alt_text_${i}_${j}_${k}`),
                              label: `${
                                values[`nested_child_label_${i}_${j}_${k}`]
                              } image alt text`,
                              defaultValue:
                                values[`image_alt_text_${i}_${j}_${k}`],
                            },
                            {
                              type: 'text_plain',
                              name: _.snakeCase(`img_styles_${i}_${j}_${k}`),
                              label: `${
                                values[`nested_child_label_${i}_${j}_${k}`]
                              } image styles`,
                              options: [...globalImageStyleOptions],
                              // defaultValue:
                              //   values[`image_style_${i}_${j}_${k}`],
                              defaultValue: (() => {
                                const modifiedValue = values[
                                  `image_style_${i}_${j}_${k}`
                                ]
                                  .replace(/(\.bootstrap-styles|\.|\s)/g, '')
                                  .trim();
                                return modifiedValue;
                              })(),
                            },
                          ],
                        };
                      } else if (
                        values[`nested_child_type_${i}_${j}_${k}`] === 'picture'
                      ) {
                        //////////////////////////

                        return {
                          type: values[`nested_child_type_${i}_${j}_${k}`],
                          label: values[`nested_child_label_${i}_${j}_${k}`],
                          drupal_field_name:
                            values[`child_drupal_field_name_${i}_${j}_${k}`],
                          name: _.snakeCase(
                            values[`nested_child_label_${i}_${j}_${k}`]
                          ),
                          children: [
                            {
                              type: 'image',
                              name: `fall_back_image`,
                              label: `fall back image`,
                              children: [
                                {
                                  type: 'text_link',
                                  name: `image_src_link_${i}_${j}_${k}`,
                                  label: 'Image src link',
                                  defaultValue:
                                    values[
                                      `fall_back_image_src_link_${i}_${j}_${k}`
                                    ],
                                },
                                {
                                  type: 'text_plain',
                                  name: `image_alt_text_${i}_${j}_${k}`,
                                  label: 'Image alt text',
                                  defaultValue:
                                    values[
                                      `fall_back_image_alt_text_${i}_${j}_${k}`
                                    ],
                                },
                                {
                                  type: 'text_link',
                                  name: `image_styles_${i}_${j}_${k}`,
                                  label: 'Image styles',
                                  options: [...globalImageStyleOptions],
                                  // defaultValue:
                                  // values[
                                  //   `fall_back_image_style_${i}_${j}_${k}`
                                  // ],
                                  defaultValue: (() => {
                                    const modifiedValue = values[
                                      `fall_back_image_style_${i}_${j}_${k}`
                                    ]
                                      .replace(
                                        /(\.bootstrap-styles|\.|\s)/g,
                                        ''
                                      )
                                      .trim();
                                    return modifiedValue;
                                  })(),
                                },
                              ],
                            },
                            {
                              type: 'sources',
                              name: `alternate_sources`,
                              label: `alternate source`,
                              children: [...pictureSourceCollection],
                            },
                          ],
                        };

                        ///////////////////////////
                      } else {
                        return {
                          type: values[`nested_child_type_${i}_${j}_${k}`],
                          label: values[`nested_child_label_${i}_${j}_${k}`],
                          drupal_field_name:
                            values[`child_drupal_field_name_${i}_${j}_${k}`],
                          name: _.snakeCase(
                            values[`nested_child_label_${i}_${j}_${k}`]
                          ),
                          defaultValue:
                            values[`nested_child_defaultValue_${i}_${j}_${k}`],
                          ...(values[`title_${i}_${j}_${k}`] && {
                            title: values[`title_${i}_${j}_${k}`],
                          }),
                        };
                      }
                    }),
                    // ],
                  };
                }

                if (values[`nested_child_type_${i}_${j}`] === 'image') {
                  return {
                    type: values[`nested_child_type_${i}_${j}`],
                    label: values[`nested_child_label_${i}_${j}`],
                    name: _.snakeCase(values[`nested_child_label_${i}_${j}`]),
                    drupal_field_name:
                      values[`child_drupal_field_name_${i}_${j}`],
                    // defaultValue: values[`nested_child_defaultValue_${i}_${j}`],
                    children: [
                      {
                        type: 'text_link',
                        name: _.snakeCase(`img_source_${i}_${j}`),
                        label: ` ${
                          values[`nested_child_label_${i}_${j}`]
                        } image source`,
                        defaultValue: values[`image_src_link_${i}_${j}`],
                      },
                      {
                        type: 'text_plain',
                        name: _.snakeCase(`img_alt_text_${i}_${j}`),
                        label: `${
                          values[`nested_child_label_${i}_${j}`]
                        } image alt text`,
                        defaultValue: values[`image_alt_text_${i}_${j}`],
                      },
                      {
                        type: 'text_plain',
                        name: _.snakeCase(`img_styles_${i}_${j}`),
                        label: `${
                          values[`nested_child_label_${i}_${j}`]
                        } image styles`,
                        options: [...globalImageStyleOptions],
                        // defaultValue: values[`image_style_${i}_${j}`],
                        defaultValue: (() => {
                          const modifiedValue = values[`image_style_${i}_${j}`]
                            .replace(/(\.bootstrap-styles|\.|\s)/g, '')
                            .trim();
                          return modifiedValue;
                        })(),
                      },
                    ],
                  };
                } else if (
                  values[`nested_child_type_${i}_${j}`] === 'picture'
                ) {
                  return {
                    type: values[`nested_child_type_${i}_${j}`],
                    label: values[`nested_child_label_${i}_${j}`],
                    name: _.snakeCase(values[`nested_child_label_${i}_${j}`]),
                    drupal_field_name:
                      values[`child_drupal_field_name_${i}_${j}`],
                    children: [
                      {
                        type: 'image',
                        name: `fall_back_image`,
                        label: `fall back image`,
                        children: [
                          {
                            type: 'text_link',
                            name: `image_src_link_${i}_${j}`,
                            label: 'Image src link',
                            defaultValue:
                              values[`fall_back_image_src_link_${i}_${j}`],
                          },
                          {
                            type: 'text_plain',
                            name: `image_alt_text_${i}_${j}`,
                            label: 'Image alt text',
                            defaultValue:
                              values[`fall_back_image_alt_text_${i}_${j}`],
                          },
                          {
                            type: 'text_link',
                            name: `image_styles_${i}_${j}`,
                            label: 'Image styles',
                            options: [...globalImageStyleOptions],
                            // defaultValue:
                            //   values[`fall_back_image_style_${i}_${j}`],
                            defaultValue: (() => {
                              const modifiedValue = values[
                                `fall_back_image_style_${i}_${j}`
                              ]
                                .replace(/(\.bootstrap-styles|\.|\s)/g, '')
                                .trim();
                              return modifiedValue;
                            })(),
                          },
                        ],
                      },
                      {
                        type: 'sources',
                        name: `alternate_sources`,
                        label: `${
                          values[`nested_child_label_${i}_${j}`]
                        } alternate source`,
                        children: [...pictureSourceCollection],
                      },
                    ],
                  };
                } else {
                  return {
                    type: values[`nested_child_type_${i}_${j}`],
                    label: values[`nested_child_label_${i}_${j}`],
                    drupal_field_name:
                      values[`child_drupal_field_name_${i}_${j}`],
                    name: _.snakeCase(values[`nested_child_label_${i}_${j}`]),
                    defaultValue: values[`nested_child_defaultValue_${i}_${j}`],
                    ...(values[`title_${i}_${j}`] && {
                      title: values[`title_${i}_${j}`],
                    }),
                  };
                }
              }),
              //////////////////////
              // }],
            });
          } else if (values[`child_type_${i}`] === 'image') {
            newChildrenArr.push({
              type: values[`child_type_${i}`],
              label: values[`child_label_${i}`],
              name: _.snakeCase(values[`child_label_${i}`]),
              drupal_field_name: values[`child_drupal_field_name_${i}`],
              //////
              children: [
                {
                  type: 'text_link',
                  name: _.snakeCase(`img_source_${i}`),
                  label: ` ${values[`child_label_${i}`]} image source`,
                  defaultValue: values[`image_src_link_${i}`],
                },
                {
                  type: 'text_plain',
                  name: _.snakeCase(`img_alt_text_${i}`),
                  label: `${values[`child_label_${i}`]} image alt text`,
                  defaultValue: values[`image_alt_text_${i}`],
                },
                {
                  type: 'text_plain',
                  name: _.snakeCase(`img_styles_${i}`),
                  label: `${values[`child_label_${i}`]} image styles`,
                  options: [...globalImageStyleOptions],
                  // defaultValue: values[`image_style_${i}`],
                  defaultValue: (() => {
                    const modifiedValue = values[`image_style_${i}`]
                      .replace(/(\.bootstrap-styles|\.|\s)/g, '')
                      .trim();
                    return modifiedValue;
                  })(),
                },
              ],
            });
          } else if (values[`child_type_${i}`] === 'picture') {
            /////////////////////////////////

            newChildrenArr.push({
              type: values[`child_type_${i}`],
              label: values[`child_label_${i}`],
              drupal_field_name: values[`child_drupal_field_name_${i}`],
              name: _.snakeCase(values[`child_label_${i}`]),
              children: [
                {
                  type: 'image',
                  name: `fall_back_image`,
                  label: `fall back image`,
                  children: [
                    {
                      type: 'text_link',
                      name: `image_src_link_${i}`,
                      label: 'Image src link',
                      defaultValue: values[`fall_back_image_src_link_${i}`],
                    },
                    {
                      type: 'text_plain',
                      name: `image_alt_text_${i}`,
                      label: 'Image alt text',
                      defaultValue: values[`fall_back_image_alt_text_${i}`],
                    },
                    {
                      type: 'text_link',
                      name: `image_styles_${i}`,
                      label: 'Image styles',
                      options: [...globalImageStyleOptions],
                      // defaultValue: values[`fall_back_image_style_${i}`],
                      defaultValue: (() => {
                        const modifiedValue = values[
                          `fall_back_image_style_${i}`
                        ]
                          .replace(/(\.bootstrap-styles|\.|\s)/g, '')
                          .trim();
                        return modifiedValue;
                      })(),
                    },
                  ],
                },
                {
                  type: 'sources',
                  name: `alternate_sources`,
                  label: `alternate source`,
                  children: [...pictureSourceCollection],
                },
              ],
            });
          } else {
            newChildrenArr.push({
              type: values[`child_type_${i}`],
              label: values[`child_label_${i}`],
              drupal_field_name: values[`child_drupal_field_name_${i}`],
              name: _.snakeCase(values[`child_label_${i}`]),
              defaultValue: values[`child_defaultValue_${i}`],
              // children: [],
              ...(values[`title_${i}`] && { title: values[`title_${i}`] }),
            });
          }
        } else {
          // console.log("timer",values[`title_${i}`])
          newChildrenArr.push({
            type: values[`child_type_${i}`],
            label: values[`child_label_${i}`],
            drupal_field_name: values[`child_drupal_field_name_${i}`],
            name: _.snakeCase(values[`child_label_${i}`]),
            defaultValue: values[`child_defaultValue_${i}`],
            // children: [],
          });
        }
      });
    } else if (values.type === 'picture') {
      newChildrenArr = [
        {
          type: 'image',
          name: _.snakeCase(`fall_back_image`),
          label: `fall back image`,
          children: [
            {
              type: 'text_link',
              name: 'image_src_link',
              label: 'Image src link',
              defaultValue: values.fall_back_image_src_link,
            },
            {
              type: 'text_plain',
              name: 'image_alt_text',
              label: 'Image alt text',
              defaultValue: values.fall_back_image_alt_text,
            },
            {
              type: 'text_link',
              name: 'image_styles',
              label: 'Image styles',
              options: [...globalImageStyleOptions],
              // defaultValue: values.fall_back_image_style,
              defaultValue: (() => {
                const modifiedValue = values.fall_back_image_style
                  .replace(/(\.bootstrap-styles|\.|\s)/g, '')
                  .trim();
                return modifiedValue;
              })(),
            },
          ],
        },
        {
          type: 'sources',
          name: _.snakeCase(`alternate_sources`),
          label: `alternate sources`,
          children: [...pictureSourceCollection],
        },
      ];
    } else if (values.type === 'image') {
      newChildrenArr = [
        {
          type: 'text_link',
          name: _.snakeCase(`img_source`),
          label: `image source`,
          defaultValue: values.image_src_link,
        },
        {
          type: 'text_plain',
          name: _.snakeCase(`img_alt_text`),
          label: `image alt text`,
          defaultValue: values.image_alt_text,
        },
        {
          type: 'text_plain',
          name: _.snakeCase(`img_styles`),
          label: `image styles`,
          options: [...globalImageStyleOptions],
          defaultValue: (() => {
            const modifiedValue = values.image_style
              .replace(/(\.bootstrap-styles|\.|\s)/g, '')
              .trim();
            return modifiedValue;
          })(),
        },
      ];
    }

    if (editTraitData) {
      Traits[traitIndex] = {
        key: editTraitData.key,
        type: values.type,
        name: `${_.snakeCase(values.label)}`,
        label: values.label,
        drupal_field: values.drupal_field_name,
        ...(values.cardinality && { cardinality: values.cardinality }),
        children: newChildrenArr,
        ...(values.defaultValue && { defaultValue: values.defaultValue }),
        ...(values.title && { title: values.title }),
        ...(values.options && { options: values.options.split(',') }),
      };
    } else {
      newAtoms[selectedAtomIndex].variations.forEach(ele => {
        if (ele.key === selectedVariation) {
          const newLabel = `${newAtoms[selectedAtomIndex].label} ${ele.label} ${values.label}`;
          const newName = `${_.snakeCase(
            newAtoms[selectedAtomIndex].label
          )}_${_.snakeCase(ele.label)}_${_.snakeCase(values.label)}`;
          if (ele.traits && ele.traits.length) {
            ele.traits.push({
              key: uuid(),
              type: values.type,
              name: newName,
              label: newLabel,
              drupal_field: values.drupal_field_name,
              ...(values.cardinality && { cardinality: values.cardinality }),
              ...(values.title && { title: values.title }),
              ...(newChildrenArr?.length > 0 && { children: newChildrenArr }),
              ...(values.defaultValue && { defaultValue: values.defaultValue }),
              ...(values.options && { options: values.options.split(',') }),
            });
            setTraitTable([...ele.traits]);
          } else {
            ele.traits = [
              {
                key: uuid(),
                type: values.type,
                name: newName,
                label: newLabel,
                drupal_field: values.drupal_field_name,
                ...(values.cardinality && { cardinality: values.cardinality }),
                ...(values.title && { title: values.title }),
                ...(newChildrenArr?.length > 0 && { children: newChildrenArr }),
                ...(values.defaultValue && {
                  defaultValue: values.defaultValue,
                }),
              },
            ];
            setTraitTable([...ele.traits]);
          }
        }
      });
    }

    setAtoms(newAtoms);
    setIsModalOpen(false);
    traitform.resetFields();
    setIsSelectedTraitType(undefined);
    setIsSelectedTraitParagraph(false);
    if (editTraitData) {
      setTraitTable([...Traits]);
    }
    setChildren([{ type: '', label: '', name: '', defaultValue: '' }]);
    setPictureSourceCollection([{ type: 'text_link', source: '', media: '' }]);
    setEditTraitData(null);
    showEditModal(false);
  };

  const handleCancel = () => {
    setEditTraitData(null);
    setIsModalOpen(false);
    setChildren([{ type: '', label: '', name: '', defaultValue: '' }]);
    setPictureSourceCollection([{ type: 'text_link', source: '', media: '' }]);
    edittraitform.resetFields();
    traitform.resetFields();
    showEditModal(false);
  };

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      // width: 100,
    },
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: 'Delete',
      dataIndex: '',
      key: '',
      render: (_, record) => {
        const hasChildren = record.children && record.children.length > 0;
        if ((hasChildren && !record.key) || record.key === undefined) {
          return null;
        }
        return (
          <a
            onClick={() => {
              Modal.confirm({
                title: 'Delete Alert.',
                content: 'Do you want to delete it?',
                okText: 'Yes',
                cancelText: 'No',
                onOk: () => {
                  const selectedAtomIndex = atoms.findIndex(
                    atom => atom.key === selectedAtom.key
                  );
                  const newAtoms = [...atoms];
                  newAtoms[selectedAtomIndex].variations.forEach(ele => {
                    if (ele.key === selectedVariation) {
                      if (ele.traits && ele.traits.length) {
                        const updatedTraits = traitTable.filter(
                          e => e.name != record.name
                        );
                        ele.traits = [...updatedTraits];
                        setTraitTable([...updatedTraits]);
                      }
                    }
                  });
                  setAtoms(newAtoms);
                },
              });
            }}
          >
            Delete
          </a>
        );
      },
    },
    {
      title: 'Edit',
      dataIndex: '',
      key: '',
      render: (_, record) => {
        const hasChildren = record.children && record.children.length > 0;
        if ((hasChildren && !record.key) || record.key === undefined) {
          return null;
        }
        return (
          <a
            onClick={() => {
              console.log('record', record);
              edittraitform.resetFields();
              showEditModal(true);
              setEditTraitData({ ...record });
              setChildren(record.children);
              if (record.type === 'picture') {
                setPictureSourceCollection(record.children?.[1]?.children);
              }
            }}
          >
            Edit
          </a>
        );
      },
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/configurations`
        );

        const fetchedOptions =
          response.data.global_configuration.globalImageStyles;
        const options =
          fetchedOptions?.map(opt => ({
            label: opt.name,
            value: `.${CONSTANTS.BOOTSTRAP_WRAPPER_CLASS} .${_.kebabCase(
              opt.name
            )}`,
          })) || [];
        const mediaOptions =
          response.data.global_configuration.breakpoints?.map(opt => {
            return {
              label: opt.name,
              value: `(min-width: ${opt.width}) and (max-width: ${opt.max_width})`,
            };
          }) || [];

        setGlobalImageStyleOptions([...options]);
        setGlobalMediaOptions([...mediaOptions]);
      } catch (error) {
        console.log('error', error);
      }
    };

    fetchData();
  }, [globalConfigs?.globalImageStyles, projectId]);

  useEffect(() => {
    if (selectedAtom?.variations?.length) {
      const variationTraits = selectedAtom.variations.filter(
        variation => variation.key === selectedVariation
      )[0];
      if (variationTraits.traits?.length) {
        setTraitTable([...variationTraits?.traits]);
      }
      setIsLoading(false);
    }
  }, [selectedVariation]);

  const handleSaveVariation = (selectedAtom, selectedVariation) => {
    setKey(key + 1); // to rerender ckeditor...

    selectedVariation.html = editorRef.current.getData();

    const selectedAtomIndex = atoms.findIndex(
      atom => atom.key === selectedAtom.key
    );
    const newAtoms = [...atoms];
    const selectedVariationIndex = atoms[
      selectedAtomIndex
    ].variations.findIndex(
      variation => variation.key === selectedVariation.key
    );
    delete newAtoms[selectedAtomIndex].__v;
    delete newAtoms[selectedAtomIndex].variations[selectedVariationIndex]
      .components;
    axios
      .patch(
        `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/components/atoms/${newAtoms[selectedAtomIndex]._id}/variations/${newAtoms[selectedAtomIndex].variations[selectedVariationIndex].key}`,
        { ...newAtoms[selectedAtomIndex].variations[selectedVariationIndex] }
      )
      .then(data => {
        setDidTraitsUpdate(true);
        setIsLoading(false);
        message.success('Variation saved');
      })
      .catch(error => {
        setIsLoading(false);
        console.log(error);
      });
    setAtoms(newAtoms);
  };

  return (
    <>
      <EditForm
        form={edittraitform}
        globalImageStyleOptions={globalImageStyleOptions}
        globalMediaOptions={globalMediaOptions}
        setEditData={editTraitData}
        onFinish={onFinish}
        handleAddChildren={handleAddChildren}
        children={children}
        handleAddAnotherSource={handleAddAnotherSource}
        handleAddPictureCollection={handleAddPictureCollection}
        pictureSourceCollection={pictureSourceCollection}
        setPictureSourceCollection={setPictureSourceCollection}
        handleAddNested={handleAddNested}
        editModal={editModal}
        handleOk={handleOk}
        setEditTraitData={setEditTraitData}
        setIsModalOpen={setIsModalOpen}
        setChildren={setChildren}
        showEditModal={showEditModal}
        handleRemoveNested={handleRemoveNested}
      />

      <Modal
        title={'Create New Trait'}
        okText={createTraitOptions === 'choose' ? 'Copy' : 'Create'}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={() => {
          handleCancel();
        }}
        width={650}
      >
        <Radio.Group
          onChange={e => setCreateTraitOptions(e.target.value)}
          value={createTraitOptions}
        >
          <Radio value="create">Create New Trait</Radio>
          <Radio value="choose">Choose From GlobalTraits Library</Radio>
        </Radio.Group>

        {createTraitOptions === 'create' && (
          <div style={{ marginTop: '30px' }}>
            <TraitForm
              form={traitform}
              globalImageStyleOptions={globalImageStyleOptions}
              globalMediaOptions={globalMediaOptions}
              handleAddChildren={handleAddChildren}
              onFinish={onFinish}
              children={children}
              handleAddNested={handleAddNested}
              handleRemoveNested={handleRemoveNested}
              handleAddAnotherSource={handleAddAnotherSource}
              handleAddPictureCollection={handleAddPictureCollection}
              pictureSourceCollection={pictureSourceCollection}
              setPictureSourceCollection={setPictureSourceCollection}
            />
          </div>
        )}

        {createTraitOptions === 'choose' && <div>
        <Select placeholder='Choose From The Given Traits....' style={{ width: 300, marginTop: '40px', marginLeft: '100px' }} onChange={(value) => {
          setChoosenTraitValue(value);
          }}>
            {globalTraits.map((option) => (
              <Option value={option.name}>{option.name}</Option>
            ))}
          </Select>
          </div>}
      </Modal>

      <Row>
        <Col md={collapsed ? 1 : 4} style={{ transition: '1s' }}>
          <Button
            type="primary"
            onClick={toggleCollapsed}
            style={{
              marginBottom: 16,
              //   marginLeft: 16,
            }}
          >
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </Button>
          <Menu
            mode="inline"
            onSelect={handleMenuItemSelect}
            inlineCollapsed={collapsed}
          >
            {atoms.map(atom =>
              atom.label !== 'Spacing New' && atom.label !== 'Grids' ? (
                <SubMenu key={`menu-${atom.key}`} title={atom.label}>
                  {atom.variations.map(subMenuItem => (
                    <MenuItem
                      key={subMenuItem.key}
                      onClick={() => setSelectedAtom(atom)}
                    >
                      {subMenuItem.label}
                    </MenuItem>
                  ))}
                </SubMenu>
              ) : null
            )}
          </Menu>
        </Col>
        <Col md={collapsed ? 23 : 20} style={{ transition: '1s' }}>
          {selectedVariation ? (
            atoms.map(atom => {
              return atom.variations.map(variation =>
                selectedVariation === variation.key ? (
                  <Spin spinning={isLoading}>
                    <CKEditor
                      key={key}
                      entity={variation}
                      editorRef={editorRef}
                      bootstrap_css={bootstrap_css}
                      saveRef={saveRef}
                      component={variation}
                    />

                    <Row style={{ marginTop: '2em', float: 'right' }}>
                      <Col style={{ marginLeft: '1em' }}>
                        <Button
                          type="primary"
                          onClick={() => {
                            handleAddTraits(atom, variation);
                            traitform.resetFields();
                          }}
                        >
                          Create Traits
                        </Button>
                      </Col>
                      <Col style={{ marginLeft: '1em' }}>
                        <Button
                          type="primary"
                          onClick={() => {
                            window.open(
                              window.location.href +
                                `/preview?key=${variation.key}`
                            );
                          }}
                        >
                          Preview
                        </Button>
                      </Col>
                      <Col style={{ marginLeft: '1em' }}>
                        <Button type="primary" onClick={handleReset}>
                          Reset
                        </Button>
                      </Col>
                      <Col style={{ marginLeft: '1em' }}>
                        <Button
                          ref={saveRef}
                          type="primary"
                          onClick={() => {
                            handleSaveVariation(atom, variation);
                            setIsLoading(true);
                          }}
                        >
                          Save
                        </Button>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                      <LastModified
                        entityType="atoms"
                        entityId={atom._id}
                        forceUpdate={didTraitsUpdate}
                        projectRole={projectRole}
                      />
                    </Row>
                    <Row>
                      {variation.traits?.length ? (
                        <Col justify="start" span={21}>
                          <h3>Available Traits</h3>
                          <Table
                            dataSource={traitTable}
                            columns={columns}
                            pagination={false}
                            size="small"
                          />
                        </Col>
                      ) : null}
                    </Row>
                  </Spin>
                ) : null
              );
            })
          ) : (
            <>
              <Row justify="center" align="middle" style={{ height: '100%' }}>
                <Col>
                  <Text type="warning">
                    Please select a component to proceed
                  </Text>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = state => {
  return {
    designComponents: state.DesignComponents,
    atoms: state.DesignComponents.atoms,
    molecules: state.DesignComponents.molecules,
    organisms: state.DesignComponents.organisms,
    globalConfigs:
      state.GlobalConfigurations.finalConfigs?.global_configuration,
    token: state.Token.token,
    configuration: state.GlobalConfigurations,
    projectRole: state.ProjectRole.projectRole,
    globalTraits: state.GlobalTraits.globalTraits
  };
};

const mapDispatchToProps = dispatch => ({
  setAtoms: atoms => {
    dispatch(setAtoms(atoms));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AtomsTraits);
