/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import CSSPropertyWithUnit from '../../../../common/CSSPropertyWithUnit';

const { Title } = Typography;
const { Option } = Select;

const ImageStyleForm = ({ globalImageStyles, setGlobalImageStyles }) => {
  const [imageStyleForm] = Form.useForm();
  const [editRecord, setEditRecord] = useState(null);
  //------------------------ columns for image styles ---------------//

  const parseValueAndUnit = value => {
    const numericValue = parseInt(value, 10);
    const unit = value.replace(numericValue, '');
    return [numericValue, unit];
  };

  useEffect(() => {
    if (editRecord) {
      const initialValues = {
        width: {
          value: parseValueAndUnit(editRecord.width)[0],
          unit: parseValueAndUnit(editRecord.width)[1],
        },
        height: {
          value: parseValueAndUnit(editRecord.height)[0],
          unit: parseValueAndUnit(editRecord.height)[1],
        },
        border_radius: {
          value: parseValueAndUnit(editRecord.border_radius)[0],
          unit: parseValueAndUnit(editRecord.border_radius)[1],
        },
      };
      imageStyleForm.setFieldsValue({
        name: editRecord.name,
        effect: editRecord.effect,
        ...initialValues,
      });
    }
  }, [editRecord]);

  const imageStylesColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Height',
      dataIndex: 'height',
      key: 'height',
    },
    {
      title: 'width',
      dataIndex: 'width',
      key: 'width',
    },
    {
      title: 'Border Radius',
      dataIndex: 'border_radius',
      key: 'border_radius',
    },
    {
      title: 'Effect',
      dataIndex: 'effect',
      key: 'effect',
    },
    {
      title: 'Delete',
      dataIndex: '',
      key: '',
      render: (_, record) => {
        return (
          <a
            onClick={() => {
              Modal.confirm({
                title: 'Delete Alert.',
                content: 'Do you want to delete it?',
                okText: 'Yes',
                cancelText: 'No',
                onOk: () => {
                  let updatedValues = globalImageStyles.filter(
                    ele => ele.id !== record.id
                  );
                  setGlobalImageStyles(updatedValues);
                },
              });
            }}
          >
            Delete
          </a>
        );
      },
    },
    {
      title: 'Edit',
      dataIndex: '',
      key: '',
      render: (_, record) => {
        return (
          <a
            onClick={() => {
              setEditRecord(record);
            }}
          >
            Edit
          </a>
        );
      },
    },
  ];
  //-----------------------------------------------------------------//

  const onFinish = values => {
    console.log({ values });
    values.name = values.name.trim();
    const object = Object.keys(values);
    object.map(item => {
      if (typeof values[item] == 'object') {
        values[item] = '' + ( values[item].value ? values[item].value : '' ) + ( values[item].unit ? values[item].unit : '' );
      }
    });
    if (editRecord) {
      const newStyles = globalImageStyles.map(style => {
        if (style.id === editRecord.id) {
          style = {
            id: style.id,
            ...values,
          };
        }
        return style;
      });
      setGlobalImageStyles(newStyles);
      setEditRecord(null);
      imageStyleForm.resetFields();
    } else {
      const valueToPush = { id: uuid(), ...values };
      setGlobalImageStyles([...globalImageStyles, valueToPush]);
      imageStyleForm.resetFields();
    }
  };
  return (
    <>
      <h3>Image Styles:</h3>
      <Row gutter={20}>
        <Col span={6}>
          <Title level={5} style={{ textAlign: 'center' }}>
            {editRecord ? 'Edit Image Style' : 'Add Image Style'}
          </Title>
          <Form
            name="imageStyleForm"
            form={imageStyleForm}
            onFinish={onFinish}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            labelAlign="left"
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                { required: true, message: 'Please input Name of Image style' },
                {
                  pattern: /^[A-Za-z0-9_-]+$/,
                  message: "Invalid Input Special Character's not allowed",
                },
                { whitespace: true },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>

            <CSSPropertyWithUnit fieldName="height" placeholder="Height" />

            <CSSPropertyWithUnit fieldName="width" placeholder="Width" />

            <CSSPropertyWithUnit
              fieldName="border_radius"
              placeholder="Border Radius"
              required={false}
            />

            <Form.Item
              label="Effect"
              name="effect"
              rules={[
                { required: true, message: 'Please choose One of The Effect' },
              ]}
            >
              <Select placeholder="Effect" style={{ width: 285 }}>
                <Option value="crop">Crop</Option>
                <Option value="resize">Resize</Option>
                <Option value="scale">Scale</Option>
                <Option value="scale and crop">Scale & Crop</Option>
              </Select>
            </Form.Item>

            <Button type="primary" onClick={() => imageStyleForm.submit()}>
              {editRecord ? 'Edit Image Style' : 'Add Image Style'}
            </Button>
          </Form>
        </Col>
        <Col span={18}>
          {imageStylesColumns && imageStylesColumns.length > 0 && (
            <Table
              columns={imageStylesColumns}
              dataSource={globalImageStyles}
              pagination={false}
              bordered
              size="small"
              scroll={{
                y: 240,
              }}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default ImageStyleForm;
