const getHtml = () => {
  return `
    <button type="button" class="btn btn-link">Link</button>
    `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const LinkButton = { getHtml, getCss, getJs };

export default LinkButton;
