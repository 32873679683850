import {
  PlusOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LockFilled,
} from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Row,
  Select,
  Table,
  Tooltip,
  Typography,
  Spin,
} from 'antd';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import uuid from 'react-uuid';
import DependencyTable from '../../../common/DependencyTable/index';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  setAtoms,
  setMolecules,
  setOrganisms,
} from '../../../../redux/design-components/actions';
import Editor from '../../../common/editor';
import EditorConfig from './editor-config';
import axios from '../../../../utils/axios';
import InputCode from '../../../common/InputCode';
import LastModified from '../../../common/LastModified.js';
import WarningModal from '../../../common/modals/WarningModal';
import { replaceVariables } from '../../../../utils/helpers/replaceTraits';
import CONSTANTS from '../../../../utils/constants';
import { Switch, InputNumber } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';

const { Item: MenuItem, SubMenu } = Menu;
const { Text } = Typography;

const updatedBlocks = (entities, type) => {
  const configs = { ...EditorConfig() };

  entities.forEach(entity => {
    entity.variations.forEach(variation => {
      variation._id = entity._id;
      variation.type = type;
      variation.category = entity.label;
      variation.content = `<div>${variation.html}</div> <style>${variation.css}</style>`;
      const existingVariation = configs.blockManager.blocks.find(
        block => block.key === variation.key
      );
      if (!existingVariation) {
        configs.blockManager.blocks.push(variation);
      }
    });
  });
  return configs;
};

const OrganismsEditor = ({
  atoms,
  molecules,
  organisms,
  setOrganisms,
  globalConfigs,
  projectRole,
}) => {
  const editorRef = useRef(null);

  const { projectId, step } = useParams();

  const didOrganismsUpdate = useRef(false);

  const [nameOfOrganismToRename, setNameofOrganismToRename] =
    useState(undefined);
  const [selectedOrganism, setSelectedOrganism] = useState(null);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addNewOragnismForm] = Form.useForm();
  const [showJSBlock, setShowJSBlock] = useState(false);
  const [currentCompJs, setCurrentCompJs] = useState('');
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [usingComponent, setUsingComponent] = useState();
  const [variationData, setVariationData] = useState();
  const [updatedComponent, setUpdatedComponent] = useState({
    layouts: [],
    pages: [],
  });

  const [usedInfo, setUsedInfo] = useState(false);
  const [usagesDetail, setUsagesDetail] = useState({});
  const [childrenInfoModalOpen, setChildrenInfoModalOpen] = useState(false);
  const [usedChildren, setUsedChildren] = useState();
  const [isPreviewMode, setIsPreviewMode] = useState(true);

  // const [unlockComponent, setUnlockComponent] = useState(false);

  const [globalMediaOptions, setGlobalMediaOptions] = useState([]);

  const [globalImageStyleOptions, setGlobalImageStyleOptions] = useState([]);
  const [pictureSourceCollection, setPictureSourceCollection] = useState([
    [
      { type: 'text_link', name: 'srcset', label: '', defaultValue: '' },
      {
        type: 'text_plain',
        name: 'media',
        label: 'media',
        defaultValue: '',
        options: globalMediaOptions,
      },
    ],
  ]);
  const [selectedDependencyType, setSelectedDependencyType] =
    useState(undefined);
  const [enteredCdnLink, setEnteredCdnLink] = useState(undefined);
  const [draggedcomphtml, setdraggedcomphtml] = useState([]);

  const [externalLibraryModal, setExternalLibraryModal] = useState(false);

  const [collapsed, setCollapsed] = useState(false);
  const isUsedChildrenLoaded = useRef(false);
  const [isLoading, setIsLoading] = useState(true);

  const [externalLibrary] = Form.useForm();

  const [checked, setChecked] = useState(false);
  const [draggedModal, setDraggedModal] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [insertData, setInsertData] = useState(null);
  const [draggedForm] = Form.useForm();
  const [droppedComp, setDroppedComp] = useState(null);

  // useEffect(() => {
  //   console.log(table);
  // }, [table]);

  const columns = [
    {
      title: 'Trait Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Parent Name',
      dataIndex: 'parentname',
      key: 'parentname',
    },
    {
      title: 'Trait Type',
      dataIndex: 'type',
      key: 'traittype',
    },
    {
      title: 'Default value',
      dataIndex: 'defaultValue',
      key: 'defaultvalue',
      render: (text, record) =>
        record.defaultValue ? (
          <Input
            defaultValue={text}
            onChange={e => {
              record.defaultValue = e.target.value; // Update the value in traitsToBeCopied array
            }}
          />
        ) : null,
    },
    {
      title: 'Options',
      dataIndex: 'options',
      key:'options',
      render: (text, record) => 
        record.options ? (
          <Input
            defaultValue={text}
            onChange={e => {
              record.options = e.target.value.split(','); // Update the value in traitsToBeCopied array
            }}
          /> 
      ) : null,
    },
  ];

  console.log(organisms);

  useEffect(() => {
    if(droppedComp || draggedModal)
    {
      importTheRequiredProperties(droppedComp);
    }
  },[checked , draggedModal])



  useEffect(() => {
    let newArray = [];

    // setdraggedcomphtml([]);

    if (selectedOrganism && draggedcomphtml.length === 0) {
      selectedOrganism?.traits?.forEach(trait => {
        if (trait.parent) {
          let html;

          if (trait.parent.type === 'atom') {
            atoms.forEach(atom => {
              if (atom._id === trait.parent.id) {
                atom.variations.forEach(variation => {
                  if (variation.key === trait.parent.key) {
                    html = variation.html;
                  }
                });
              }
            });
          }

          if (trait.parent.type === 'molecule') {
            molecules.forEach(molecule => {
              if (molecule._id === trait.parent.id) {
                molecule.variations.forEach(variation => {
                  if (variation.key === trait.parent.key) {
                    html = variation.html;
                  }
                });
              }
            });
          }

          newArray.push({
            key: trait.parent.key,
            prefix: trait.name
              .replace(trait.parentname, '')
              .trim()
              .slice(0, -1),
            html: html,
            type: trait.parent.type,
          });
        }
      });

      const uniqueArray = [
        ...new Set(newArray.map(obj => JSON.stringify(obj))),
      ].map(str => JSON.parse(str));

      setdraggedcomphtml(uniqueArray);
    }
  }, [organisms, selectedOrganism]);

  useEffect(() => {
    if (organisms && selectedOrganism && didOrganismsUpdate.current) {
      const organism = organisms.find(
        organismToFind => organismToFind.key === selectedOrganism.key
      );
      if (organism) {
        let editorComponents = organism.html ? organism.html : organism.html;
        if (organism.css) {
          editorComponents = `${editorComponents}<style>${organism.css}</style>`;
        }
        if (editorRef.current) {
          updateEditorComponents(editorRef.current, editorComponents);
        }
        didOrganismsUpdate.current = false;
        isUsedChildrenLoaded.current = false;
      }
    }
  }, [organisms]);

  useEffect(() => {
    if (selectedOrganism) {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/components/organisms/${selectedOrganism._id}/usages`
        )
        .then(res => {
          setUsagesDetail(res.data);
          setUsingComponent(res.data);
        })
        .catch(error => {
          console.log(error);
        });

      axios
        .get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/components/organisms/${selectedOrganism._id}/children`
        )
        .then(res => {
          setUsedChildren(res.data);
          if (res.status === 200) {
            isUsedChildrenLoaded.current = true;
            setIsLoading(false);
          }
        })
        .catch(error => {
          console.log(error);
          setIsLoading(false);
        });
    }
  }, [selectedOrganism, isWarningModalOpen, organisms, draggedcomphtml]);

  const handleInlineCss = e => {
    const newOrganisms = [...organisms];
    newOrganisms.forEach(organism => {
      if (organism.key === selectedOrganism.key) {
        organism.inLineCss = e.target.checked;
      }
    });
    setOrganisms(newOrganisms);
  };

  const handleSaveVariation = selectedOrganism => {
    if (editorRef.current)
      if (editorRef.current) {
        const html = editorRef.current.getHtml();
        const css = editorRef.current.getCss();
        let updatedOrganism = [...organisms];
        updatedOrganism.forEach(organism => {
          if (organism.key === selectedOrganism.key) {
            if (organism.html) {
              organism.html = html;
              organism.html = html;
            } else {
              organism.html = html;
            }
            organism.css = css;

            let modifiedHTML = html;

            if (draggedcomphtml?.length) {
              const atomCounts = {};
              const moleculeCounts = {};

              draggedcomphtml.forEach(comp => {
                let pattern = new RegExp(
                  `<!--START-${comp.type.toUpperCase()}-{{${
                    comp.key
                  }}}-->([\\s\\S]*?)<!--END-${comp.type.toUpperCase()}-{{${
                    comp.key
                  }}}-->`
                );

                let modifiedContent = comp.html.replace(
                  /{{([^}]+)}}|start_curly([^]+?)_end_curly|<!--(.*?)-->/g,
                  function (subMatch, p1, p2, p3) {
                    if (p1) {
                      return `{{ ${comp.prefix}_${p1.trim()} }}`;
                    } else if (p2) {
                      return `start_curly_${comp.prefix}${p2}_end_curly`;
                    } else if (p3) {
                      return `<!--${p3}-->`;
                    }
                  }
                );

                // update if loops are used -----
                modifiedContent = modifiedContent.replace(
                  /<!--(START-LOOP|END-LOOP).*?{{([^}]+)}}.*?-->/g,
                  function (subMatch, loopType, doubleBracesContent) {
                    console.log(loopType);
                    console.log(doubleBracesContent);
                    return `<!--${loopType}-{{${
                      comp.prefix
                    }_${doubleBracesContent.trim()}}}-->`;
                  }
                );

                if (comp.type === 'atom') {
                  let moleculePattern =
                    /<!--START-MOLECULE-(.*?)-->(.*?)<!--END-MOLECULE-\1-->/gs;
                  let matchedPatterns = [];

                  modifiedHTML = modifiedHTML.replace(
                    moleculePattern,
                    function (match, p1, p2) {
                      matchedPatterns.push(match); // Store the matched pattern
                      return 'temp'; // Replace with an empty string
                    }
                  );

                  const key = comp.key;

                  if (!atomCounts.hasOwnProperty(key)) {
                    atomCounts[key] = 0;
                  }

                  const count = atomCounts[key];
                  atomCounts[key] += 1;

                  let occurrences = 0;

                  modifiedHTML = modifiedHTML.replace(
                    new RegExp(pattern, 'g'),
                    function (match) {
                      return occurrences++ === count ? modifiedContent : match;
                    }
                  );

                  // Restore the matched patterns
                  matchedPatterns.forEach(function (pattern) {
                    modifiedHTML = modifiedHTML.replace('temp', pattern);
                  });
                } else {
                  const key = comp.key;

                  if (!moleculeCounts.hasOwnProperty(key)) {
                    moleculeCounts[key] = 0;
                  }

                  const count = moleculeCounts[key];
                  moleculeCounts[key] += 1;

                  let occurrences = 0;
                  modifiedHTML = modifiedHTML.replace(
                    new RegExp(pattern, 'g'),
                    function (match) {
                      return occurrences++ === count ? modifiedContent : match;
                    }
                  );
                }
              });

              organism.html = modifiedHTML;
            }
            delete organism.__v;

            axios
              .patch(
                `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}/organisms/${organism._id}`,
                { ...organism, components: updatedComponent }
              )
              .then(data => {
                message.success('Organism Saved successfully');
                axios
                  .get(
                    `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}`
                  )
                  .then(data => {
                    if (data) {
                      didOrganismsUpdate.current = true;
                      setAtoms([...data.data?.atoms]);
                      setMolecules([...data.data?.molecules]);
                      setOrganisms([...data.data?.organisms]);
                      setIsLoading(false);
                    }
                  })
                  .catch(err => {
                    console.log(err, 'error');
                    setIsLoading(false);
                  });
              })
              .catch(error => {
                console.error(error);
                message.error('Something went wrong ! Please try again.');
                setIsLoading(false);
              });
          }
        });
        setdraggedcomphtml([]);
        setOrganisms(updatedOrganism);
      }
  };

  const onFinish = values => {
    let { organismName } = values;
    const newOrganisms = [...organisms];
    const existingOrganismIndex = newOrganisms.findIndex(
      organism => organism.label === organismName
    );

    if (existingOrganismIndex !== -1) {
      message.error('Organism with Same Name Already Exist.');
    } else {
      const newOrganismKey = uuid();
      newOrganisms.push({
        label: organismName,
        key: newOrganismKey,
        html: '',
        css: '',
        html: '',
      });
      addNewOragnismForm.resetFields();
      axios
        .post(
          `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}/organisms`,
          {
            label: organismName,
            key: newOrganismKey,
            html: '',
            css: '',
            html: '',
          }
        )
        .then(result => {
          message.success('Organism Created successfully');
          axios(
            `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}`
          )
            .then(data => {
              if (data) {
                didOrganismsUpdate.current = true;
                setAtoms([...data.data?.atoms]);
                setMolecules([...data.data?.molecules]);
                setOrganisms([...data.data?.organisms]);
              }
            })
            .catch(err => console.log(err, 'error'));
        })
        .catch(error => console.log('error', error));
      setOrganisms(newOrganisms);
      setIsModalOpen(false);
    }
  };

  const handleAddNewOrganism = () => {
    setIsModalOpen(true);
  };

  const handleAddJS = () => {
    let updatedOrganism = [...organisms];
    updatedOrganism.forEach(organism => {
      if (organism.key === selectedOrganism.key) {
        organism.js = currentCompJs;
        setSelectedOrganism(organism);
      }
    });

    setOrganisms(updatedOrganism);
    setShowJSBlock(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    addNewOragnismForm.resetFields();
    // setPictureSourceCollection([{ type: 'text_link', source: '', media: '' }]);
  };

  const handleOk = () => {
    if (addNewOragnismForm) {
      addNewOragnismForm.submit();
    }
  };
  const handleDeleteOrganism = () => {
    let updatedOrganism = organisms.filter(
      organism => organism.key !== selectedOrganism.key
    );

    axios
      .delete(
        `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}/organisms/${selectedOrganism._id}`
      )
      .then(data => {
        message.success('Organism deleted successfully');
      })
      .catch(error => {
        console.error(error);
        message.error('Something went wrong ! Please try again.');
      });
    setOrganisms(updatedOrganism);
    setDeleteConfirmation(false);
    isUsedChildrenLoaded.current = false;
  };

  const addDependencies = values => {
    const newOrganisms = [...organisms];
    newOrganisms.forEach(organism => {
      if (organism.key === selectedOrganism.key) {
        if (organism.dependencies) {
          organism?.dependencies[values.select_library].push(values.cdn_link);
        } else {
          organism.dependencies = { js: [], css: [] };
          organism.dependencies[values.select_library].push(values.cdn_link);
        }
      }
    });
    setOrganisms(newOrganisms);
    setExternalLibraryModal(false);
    externalLibrary.resetFields();
  };

  const importTheRequiredProperties = (component) => {
    console.log({ checked });
    let newOrganisms = [...organisms];

    newOrganisms.forEach(organism => {
      if (organism.key === selectedOrganism.key) {
        if (component?.dependencies) {
          const JSdependenciesToBeCopied = [...component.dependencies?.js];
          const CSSdependenciesToBeCopied = [...component.dependencies?.css];

          if (organism.dependencies) {
            organism.dependencies.js = Array.from(
              new Set(
                organism.dependencies?.js.concat(JSdependenciesToBeCopied)
              )
            );
            organism.dependencies.css = Array.from(
              new Set(
                organism.dependencies?.css.concat(CSSdependenciesToBeCopied)
              )
            );
          } else {
            organism.dependencies = {
              js: [...JSdependenciesToBeCopied],
              css: [...CSSdependenciesToBeCopied],
            };
          }
        }

        /******************************************************************* */

        let traitsToBeCopied = component.traits ? [...component.traits] : [];
        let currentHTML = editorRef.current.getHtml();
        let count;

        const pattern = new RegExp(
          component.type === 'atom'
            ? `<!--START-ATOM-{{${component.key}}}-->`
            : `<!--START-MOLECULE-{{${component.key}}}-->`,
          'g'
        );

        // to not allow atom count inside molecule if it is an atom
        if (component.type === 'atom') {
          var moleculePattern =
            /<!--START-MOLECULE-(.*?)-->(.*?)<!--END-MOLECULE-\1-->/gs;
          var matchedPatterns = [];

          currentHTML = currentHTML.replace(
            moleculePattern,
            function (match, p1, p2) {
              matchedPatterns.push(match); // Store the matched pattern
              return 'temp'; // Replace with an empty string
            }
          );
          count = (currentHTML.match(pattern) || []).length;
        } else {
          count = (editorRef.current.getHtml().match(pattern) || []).length;
        }

        traitsToBeCopied = traitsToBeCopied.map((trait, index) => {
          let modifiedChildren = trait?.children;

          trait = {
            ...trait,
            key: uuid(),
            name: !checked
              ? _.snakeCase(`${selectedOrganism.label}_${count}_${trait.name}`)
              : trait.name,
            label: !checked
              ? `${selectedOrganism.label} ${trait.label} ${count}`
              : trait.name,
            children: modifiedChildren,
            // drupal_field: '', // added to not include drupal_field because it is to be unique everywhere and setted manually.
            parent: {
              type: component.type,
              id: component._id,
              name: trait.name,
              traitKey: trait.key,
              key: component.key,
              sequenceNumber: count,
              draggedComponent: _.snakeCase(
                `${component.category}_${component.label}`
              ),
            },
          };
          return trait;
        });

        ///////////////////////////////////////////////////////////////////

        // const columns = [
        //   {
        //     title: 'Trait Name',
        //     dataIndex: 'name',
        //     key: 'name',
        //   },
        //   {
        //     title: 'Parent Name',
        //     dataIndex: 'parentname',
        //     key: 'parentname',
        //   },
        //   {
        //     title: 'Trait Type',
        //     dataIndex: 'type',
        //     key: 'traittype',
        //   },
        //   {
        //     title: 'Default value',
        //     dataIndex: 'defaultValue',
        //     key: 'defaultvalue',
        //     render: (text, record) =>
        //       record.defaultValue ? (
        //         <Input
        //           defaultValue={text}
        //           onChange={e => {
        //             record.defaultValue = e.target.value; // Update the value in traitsToBeCopied array
        //           }}
        //         />
        //       ) : null,
        //   },
        // ];

        const data = traitsToBeCopied.map(trait => {
          return {
            ...trait,
            parentname: trait.parent.name,
          };
        });

        setTableData(data);
        // setDraggedModal(true);
        setInsertData({
          key: component.key,
          html: component.html,
          prefix: _.snakeCase(`${selectedOrganism.label}_${count}`),
          traits: component.traits,
          type: component.type,
        });

        // traitsToBeCopied.length > 0 &&
        //   Modal.info({
        //     title: 'Copy Traits',
        //     onOk: () => {
        //       setdraggedcomphtml(prevState => [
        //         ...prevState,
        // {
        //   key: component.key,
        //   html: component.html,
        //   prefix: _.snakeCase(`${selectedOrganism.label}_${count}`),
        //   type: component.type,
        // },
        //       ]);

        //   if (organism.traits) {
        //     data.forEach(incomingTrait => {
        //       if (
        //         !organism.traits.some(
        //           oldTrait => oldTrait.name === incomingTrait.name
        //         )
        //       ) {
        //         organism.traits = [...organism.traits, incomingTrait];
        //       }
        //     });
        //   } else {
        //     organism.traits = [...data];
        //   }
        // },
        //     // onCancel: handleCancel,
        // okText: 'Save',
        // width: 1350,
        //     content: (
        //       <div>
        //         <Switch
        //           checked={table}
        //           onChange={() => {
        //             setTable(!table);
        //           }}
        //         />
        //         {table && (
        //           <Table
        //             columns={columns}
        //             dataSource={data}
        //             pagination={{ pageSize: 5 }}
        //           />
        //         )}
        //       </div>
        //     ),
        //   });

        ///////////////////////////////////////////////////////////////////

        /*************************************************************************** */

        if (
          organism.baseComponents &&
          organism.baseComponents.includes(component.key)
        ) {
        } else {
          if (organism.baseComponents)
            organism.baseComponents.push(component.key);
          else organism.baseComponents = [component.key];
          organism.js = organism.js
            ? `${organism.js} ${component.js}`
            : component.js;
        }
      }
    });
    setOrganisms(newOrganisms);
  };

  const updateEditorComponents = (editorInstance, components) => {
    editorInstance.DomComponents.getWrapper().set('content', '');
    editorInstance.setComponents(components);
  };

  const selectedUpdatedComponent = collection => {
    setUpdatedComponent({ ...updatedComponent, ...collection });
  };

  const updateRef = () => {
    didOrganismsUpdate.current = true;
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleDragDrop = () => {
    if (!checked) {
      setdraggedcomphtml(prevState => [...prevState, insertData]);

      const newOrganisms = [...organisms];
      newOrganisms.forEach(organism => {
        if (organism.key === selectedOrganism.key) {
          if (organism.traits) {
            tableData.forEach(incomingTrait => {
              if (
                !organism.traits.some(
                  oldTrait => oldTrait.name === incomingTrait.name
                )
              ) {
                organism.traits = [...organism.traits, incomingTrait];
              }
            });
          } else {
            organism.traits = [...tableData];
          }
        }
      });
      setOrganisms(newOrganisms);
    } else {
      const newOrganisms = [...organisms];
      newOrganisms.forEach(organism => {
        if (organism.key === selectedOrganism.key) {
          const formData = draggedForm.getFieldsValue();
          console.log(formData);
          const newParagraphTrait = {
            key: uuid(),
            type: 'paragraph',
            dropAsPara: true,
            name: _.snakeCase(`${selectedOrganism.label}_${formData.label}`),
            label: `${selectedOrganism.label} ${formData.label}`,
            cardinality: formData.cardinality,
            children: tableData,
            drupal_field: formData.drupal_field,
          };
          organism.traits = organism.traits
            ? [...organism.traits, newParagraphTrait]
            : [newParagraphTrait];
        }
      });
      setOrganisms(newOrganisms);
    }
    setDraggedModal(false);
    setChecked(false);
    setTableData(null);
    setInsertData(null);
  };

  return (
    <>
      <Modal
        title="Copy Traits"
        open={draggedModal}
        onOk={handleDragDrop}
        onCancel={() => setDraggedModal(false)}
        okText="Save"
        width={1350}
      >
        <br />
        <Tooltip title="This will wrap all dragged components trait into a single Paragraph.">
          <InfoCircleTwoTone style={{ marginRight: '10px' }} />
        </Tooltip>
        Wrap in a Paragraph Trait
        <Switch
          style={{ marginLeft: '15px' }}
          checked={checked}
          onChange={() => {
            setChecked(!checked);
            // importTheRequiredProperties(droppedComp);
          }}
        />
        <br />
        <br />
        <br />
        {checked ? (
          <Form
            name="draggedForm"
            form={draggedForm}
            labelCol={{
              span: 8,
            }}
            style={{
              maxWidth: 600,
            }}
            autoComplete="off"
          >
            <Form.Item
              label="Label"
              name="label"
              rules={[
                {
                  required: true,
                  message: 'Please input Label!',
                },
                {
                  validator: (_, value) => {
                    if (!value.endsWith('s')) {
                      return Promise.reject('Label must end with "s"');
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Drupal field"
              name="drupal_field"
              rules={[
                {
                  required: true,
                  message: 'Please input drupal_field!',
                },
                {
                  validator: (_, value) => {
                    if (!value.endsWith('s')) {
                      return Promise.reject('drupal_field must end with "s"');
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Cardinality"
              name="cardinality"
              rules={[
                { required: true, message: 'Please enter a cardinality' },
                { type: 'integer', message: 'Cardinality must be an integer' },
              ]}
            >
              <InputNumber min={1} />
            </Form.Item>
          </Form>
        ) : (
          <Table
            columns={columns}
            dataSource={tableData}
            pagination={{ pageSize: 5 }}
          />
        )}
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        open={deleteConfirmation}
        onOk={handleDeleteOrganism}
        onCancel={() => setDeleteConfirmation(false)}
      >
        <p>Are you sure ? You want to delete this organism !</p>
      </Modal>

      {/* Add JavaScript block */}
      <Modal
        closable={false}
        open={showJSBlock}
        onOk={handleAddJS}
        onCancel={() => setShowJSBlock(false)}
        title="Add JavaScript"
        width={1200}
      >
        <InputCode
          language="javascript"
          defaultValue={currentCompJs}
          setValue={setCurrentCompJs}
        />
      </Modal>

      {/* warning modal */}
      <Modal
        centered
        key={selectedOrganism?.key}
        open={isWarningModalOpen}
        okText={usedInfo ? '' : 'Ok'}
        onOk={() => {
          handleSaveVariation(variationData);
          setIsWarningModalOpen(false);
          setIsLoading(true);
        }}
        onCancel={() => {
          setIsWarningModalOpen(false);
          setUsedInfo(false);
        }}
        width={1000}
        title={
          usedInfo ? 'Component Usages' : 'Auto-Update Higher Level Components'
        }
      >
        <WarningModal
          usingComponent={usingComponent}
          selectedUpdatedComponent={selectedUpdatedComponent}
          usedInfo={usedInfo}
        />
      </Modal>
      <Modal
        open={childrenInfoModalOpen}
        onCancel={() => {
          setChildrenInfoModalOpen(false);
        }}
        onOk={() => {
          setChildrenInfoModalOpen(false);
        }}
        title="Child Components."
      >
        <WarningModal
          usingComponent={usedChildren}
          selectedUpdatedComponent={selectedUpdatedComponent}
          usedInfo="childrenDetails"
        />
      </Modal>

      {/* Add new Organism */}
      <Modal
        title={'Create New Organism'}
        okText="Create"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ width: '50vw' }}
      >
        <Form
          name="addNewOragnismForm"
          form={addNewOragnismForm}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
        >
          <Form.Item
            label="Organism Name"
            name="organismName"
            rules={[{ required: true, message: 'Please input Organism name' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      {/* Rename Modal */}
      <Modal
        open={isRenameModalOpen}
        closable={false}
        onOk={() => {
          setIsRenameModalOpen(false);
          let updatedOrganisms = [...organisms];
          updatedOrganisms.forEach(organism => {
            if (organism.key === selectedOrganism.key) {
              organism.label = nameOfOrganismToRename;

              axios
                .patch(
                  `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}/organisms/${organism._id}`,
                  organism
                )
                .then(data => {
                  message.success('Organism Renamed successfully');
                  axios
                    .get(
                      `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/${step}`
                    )
                    .then(data => {
                      if (data) {
                        didOrganismsUpdate.current = true;
                        setAtoms([...data.data?.atoms]);
                        setMolecules([...data.data?.molecules]);
                        setOrganisms([...data.data?.organisms]);
                      }
                    })
                    .catch(err => console.log(err, 'error'));
                })
                .catch(error => {
                  console.error(error);
                  message.error('Something went wrong ! Please try again.');
                });
            }
          });
          setOrganisms([...updatedOrganisms]);
        }}
        onCancel={() => setIsRenameModalOpen(false)}
      >
        <Input
          value={nameOfOrganismToRename}
          onChange={e => setNameofOrganismToRename(e.target.value)}
        />
      </Modal>

      <Modal
        title="Add External JS or CSS Library"
        closable={false}
        open={externalLibraryModal}
        onOk={() => {
          if (externalLibrary) {
            externalLibrary.submit();
          }
        }}
        onCancel={() => setExternalLibraryModal(false)}
      >
        <Form
          name="externalLibrary"
          form={externalLibrary}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={addDependencies}
        >
          <Form.Item
            label="Select Library"
            name="select_library"
            rules={[
              { required: true, message: 'Please select type of Library ' },
            ]}
          >
            <Select
              style={{ width: 120 }}
              options={[
                {
                  value: 'css',
                  label: 'CSS',
                },
                {
                  value: 'js',
                  label: 'JS',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Enter CDN Link"
            name="cdn_link"
            rules={[
              { required: true, message: 'Please input CDN Link' },
              {
                type: 'url',
                message: 'Please enter a valid URL for the CDN link',
              },
            ]}
          >
            <Input style={{ width: 200 }} />
          </Form.Item>
        </Form>
      </Modal>

      {/* End of Modals */}

      <Row>
        <Col md={collapsed ? 1 : 4} style={{ transition: '1s' }}>
          <Button
            type="primary"
            onClick={toggleCollapsed}
            style={{
              marginBottom: 16,
            }}
          >
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </Button>
          <Menu
            mode="inline"
            // onSelect={handleMenuItemSelect}
            inlineCollapsed={collapsed}
          >
            {organisms &&
              organisms?.map(organism => (
                <MenuItem
                  key={organism?.key}
                  onClick={() => {
                    setSelectedOrganism(organism);
                    setdraggedcomphtml([]);
                    if (isUsedChildrenLoaded.current) {
                      isUsedChildrenLoaded.current = false;
                    }
                  }}
                >
                  {organism?.label}
                </MenuItem>
              ))}
            <MenuItem
              key="add_molecule_icon"
              onClick={() => {
                handleAddNewOrganism();
              }}
            >
              <PlusOutlined />
              <Text>Add New Organism</Text>
            </MenuItem>
          </Menu>
        </Col>
        <Col md={collapsed ? 23 : 20} style={{ transition: '1s' }}>
          {selectedOrganism && isUsedChildrenLoaded.current === true ? (
            organisms?.map(organism => {
              // isUsedChildrenLoaded.current=false;
              return selectedOrganism.key === organism.key ? (
                <Spin spinning={isLoading}>
                  <Editor
                    id={`organisms-editor-${organism.key}`}
                    childCss={usedChildren?.childCss}
                    // ankitFun={ankit}
                    onInit={editor => {
                      let editorComponents = organism.html
                        ? organism.html
                        : organism.html;
                      if (organism.css) {
                        editorComponents = `${editorComponents}<style>${organism.css}</style>`;
                      }
                      if (organism.html) {
                        const previewButton = editor.Panels.getButton(
                          'options',
                          'preview'
                        );
                        previewButton.set('active', true, {
                          fromListen: true,
                        });
                        editor.on('stop:preview', () => {
                          editor.setComponents(
                            `${organism.html}<style>${organism.css}</style>`
                          );
                          setIsPreviewMode(false);
                          document.getElementsByClassName(
                            'gjs-pn-panel gjs-pn-devices-c gjs-one-bg gjs-two-color'
                          )[0].style.display = 'block';
                          document.getElementsByClassName(
                            'gjs-pn-panel gjs-pn-views-container gjs-one-bg gjs-two-color'
                          )[0].style.display = 'block';
                        });
                        editor.on('run:preview', async () => {
                          const replacedHtml = await replaceVariables({
                            component: organism,
                            traits: organism.traits,
                            html: organism.html,
                          });
                          editor.setComponents(
                            `${replacedHtml}<style>${organism.css}</style>`
                          );
                          setIsPreviewMode(true);
                          document.getElementsByClassName(
                            'gjs-pn-panel gjs-pn-devices-c gjs-one-bg gjs-two-color'
                          )[0].style.display = 'none';
                          document.getElementsByClassName(
                            'gjs-pn-panel gjs-pn-views-container gjs-one-bg gjs-two-color'
                          )[0].style.display = 'none';
                        });
                      } else {
                        const previewButton = editor.Panels.getButton(
                          'options',
                          'preview'
                        );
                        previewButton.set('disable', true, {
                          fromListen: true,
                        });
                        setIsPreviewMode(false);
                      }

                      updateEditorComponents(editor, editorComponents);
                      editor.on(`block:drag:stop`, (component, block) => {
                        // importTheRequiredProperties(block.attributes);
                        setDroppedComp(block.attributes);
                        setDraggedModal(true);

                      });
                      return (editorRef.current = editor);
                    }}
                    {...updatedBlocks(atoms, 'atom')}
                    {...updatedBlocks(molecules, 'molecule')}
                  ></Editor>
                  <Row
                    style={{
                      marginTop: '10px',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <LastModified
                      entityType="organisms"
                      entityId={organism._id}
                      forceUpdate={didOrganismsUpdate.current}
                      updateRef={updateRef}
                      projectRole={projectRole}
                    />
                    <Button
                      type="link"
                      onClick={() => {
                        if (
                          usagesDetail.layouts?.length > 0 ||
                          usagesDetail.pages?.length > 0
                        ) {
                          setUsedInfo(true);
                          setIsWarningModalOpen(true);
                        }
                      }}
                    >
                      Used in {usagesDetail?.layouts?.length} layouts{', '}
                      {usagesDetail?.pages?.length} pages{' '}
                    </Button>
                  </Row>
                  <Row justify={'end'}>
                    <Button
                      type="link"
                      onClick={() => {
                        if (
                          usedChildren?.atoms?.length ||
                          usedChildren?.molecules?.length
                        ) {
                          setChildrenInfoModalOpen(true);
                        }
                      }}
                      style={{ float: 'right' }}
                    >
                      Uses {usedChildren?.atoms?.length || 0} atoms,
                      {usedChildren?.molecules?.length || 0} molecule.
                    </Button>
                  </Row>
                  <Row justify="end" style={{ marginTop: '1em' }}>
                    <Col style={{ margin: '1em' }}>
                      <Button
                        type="primary"
                        onClick={() => {
                          setShowJSBlock(true);
                          setCurrentCompJs(selectedOrganism.js);
                        }}
                      >
                        Add JavaScript
                      </Button>
                    </Col>

                    <Col style={{ margin: '1em' }}>
                      <Button
                        type="primary"
                        disabled={isPreviewMode}
                        onClick={async () => {
                          try {
                            let isUsages = false;
                            let res = await axios.get(
                              `${process.env.REACT_APP_SERVER_BASE_URL}/projects/${projectId}/components/organisms/${selectedOrganism._id}/usages`
                            );
                            const componnetsKeys = Object.keys(res.data);
                            componnetsKeys?.map(component => {
                              if (res.data[component].length) {
                                isUsages = true;
                              }
                            });

                            if (isUsages) {
                              setIsWarningModalOpen(true);
                              setUsingComponent(res.data);
                              setVariationData(organism);
                            } else {
                              handleSaveVariation(organism);
                              setIsLoading(true);
                            }
                          } catch (err) {
                            console.log(err);
                          }
                        }}
                      >
                        Save
                      </Button>
                    </Col>

                    <Col style={{ margin: '1em' }}>
                      {usagesDetail.layouts?.length > 0 ||
                      usagesDetail.pages?.length > 0 ||
                      projectRole === CONSTANTS.USER_ROLES.DEVELOPER ? (
                        <Tooltip
                          title={
                            projectRole === CONSTANTS.USER_ROLES.DEVELOPER
                              ? 'Request team lead for updating these changes'
                              : 'This component cannot be deleted since it has been used in higher level components.'
                          }
                        >
                          <Button type="primary" disabled={true}>
                            Delete
                          </Button>
                        </Tooltip>
                      ) : (
                        <Button
                          type="primary"
                          onClick={() => {
                            setDeleteConfirmation(true);
                          }}
                        >
                          Delete
                        </Button>
                      )}
                    </Col>

                    <Col style={{ margin: '1em' }}>
                      <Button
                        type="primary"
                        onClick={() => {
                          setIsRenameModalOpen(true);
                          setNameofOrganismToRename(selectedOrganism.label);
                        }}
                      >
                        Rename
                      </Button>
                    </Col>

                    <Col style={{ margin: '1em' }}>
                      <Button
                        type="primary"
                        onClick={() => {
                          setExternalLibraryModal(true);
                        }}
                      >
                        Add External Library{' '}
                      </Button>
                    </Col>

                    <Col style={{ margin: '1em' }}>
                      <Button
                        type="primary"
                        onClick={() => {
                          window.open(
                            window.location.href +
                              `/preview?key=${selectedOrganism.key}&type=organisms&id=${selectedOrganism._id}`
                          );
                        }}
                      >
                        Preview
                      </Button>
                    </Col>

                    <Col style={{ margin: '1em' }}>
                      <Checkbox
                        checked={
                          selectedOrganism.inLineCss
                            ? selectedOrganism.inLineCss
                            : false
                        }
                        onChange={handleInlineCss}
                      >
                        Inline CSS
                      </Checkbox>
                    </Col>
                  </Row>
                  {(organism.dependencies?.js?.length ||
                    organism.dependencies?.css?.length) && (
                    <Row style={{ marginTop: '30px' }}>
                      <Col justify="start" span={12}>
                        <DependencyTable
                          setEntity={setOrganisms}
                          Entity={organisms}
                          selectedEntity={organism}
                        />
                      </Col>
                    </Row>
                  )}
                </Spin>
              ) : null;
            })
          ) : (
            <>
              <Row justify="center" align="middle" style={{ height: '100%' }}>
                <Col>
                  <Text type="warning">
                    Please select a component to proceed
                  </Text>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = state => {
  return {
    atoms: state.DesignComponents.atoms,
    molecules: state.DesignComponents.molecules,
    organisms: state.DesignComponents.organisms,
    globalConfigs:
      state.GlobalConfigurations.finalConfigs?.global_configuration,
    projectRole: state.ProjectRole.projectRole,
  };
};

const mapDispatchToProps = dispatch => ({
  setAtoms: atoms => {
    dispatch(setAtoms(atoms));
  },
  setMolecules: molecules => {
    dispatch(setMolecules(molecules));
  },
  setOrganisms: organisms => {
    dispatch(setOrganisms(organisms));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganismsEditor);
