const getHtml = () => {
  return `
  <button type="button" class="btn-close" aria-label="Close"></button>
   `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const Active = { getHtml, getCss, getJs };

export default Active;
