const getHtml = () => {
  return `
    <div class="progress">
    <div class="progress-bar w-75" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
  </div>
                  `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const Basic = { getHtml, getCss, getJs };

export default Basic;
