import ButtonLink from './buttonLink';
import Default from './default';
import Primary from './primary';
import Secondary from './secondary';
import WarningLink from './warningLink';

const Link = {
  Default,
  Primary,
  Secondary,
  ButtonLink,
  WarningLink
};

export default Link;
