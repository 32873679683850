import { Modal, Form, Input } from 'antd';
import axios from '../../utils/axios';

const ChangePassword = props => {
  const [changePasswordForm] = Form.useForm();

  return (
    <>
      <Modal
        open={props.open}
        onCancel={() => {
          props.setIsChangePassword(false);
          changePasswordForm.resetFields();
        }}
        title="Change Password"
        onOk={()=>{
            changePasswordForm.submit();
        }}
        width={600}
      >
        <Form
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 16,
          }}
          form={changePasswordForm}
          name="Change password Form"
          onFinish={value => {
            props.setIsChangePassword(false);
            changePasswordForm.resetFields();
            axios
              .patch(
                `${process.env.REACT_APP_SERVER_BASE_URL}/users/${props.id}`,
                {password:value.password}
              )
              .then(res => {
                props.setListingData([]);
                props.isUpdatedHandler();
                props.infoMessage('success','Password Updated')

              })
              .catch(error => {
                console.log('error');
                props.infoMessage('error','Password not updated')
              });
          }}
        >
          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: 'Password is required!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirm_password"
            label="Confirm Password"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      'The two passwords that you entered do not match!'
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ChangePassword;
