import { Alert, Card, Col, Divider, Input, Row, Typography } from 'antd';
import { useEffect, useState } from 'react';
import './editor.css';

const CustomBlockManager = ({ blocks, dragStop, dragStart, editor }) => {
  const [searchInput, setSearchInput] = useState();
  const [filterBlocks, setFilterBlocks] = useState([...blocks]);
  const { Text } = Typography;
  useEffect(() => {
    if (searchInput) {
      const data = blocks?.filter(block =>
        `${block.attributes?.category} - ${block.attributes?.label}`
          ?.toLowerCase()
          .includes(searchInput?.toLowerCase())
      );
      setFilterBlocks([...data]);
    } else {
      setFilterBlocks([...blocks]);
    }
  }, [searchInput]);
  let category;
  let renderExtraLabel = true;

  return (
    <>
      <div className="blocks">
        <Row>
          {filterBlocks?.some(
            block => block.attributes.type !== 'Bootstrap Components'
          ) && (
            <Alert
              message="Only the components with traits (defined and inserted) are listed here."
              type="info"
              style={{ margin: '8px', fontSize: '11px' }}
            />
          )}
          <Col md={23} style={{ margin: '5px' }}>
            <Input
              placeholder="Search blocks"
              onChange={e => {
                setSearchInput(e.target.value);
              }}
            />
          </Col>
          {searchInput && filterBlocks.length === 0 ? (
            <Text type="warning" style={{ margin: 'auto' }}>
              No Block found !!
            </Text>
          ) : null}
          {filterBlocks?.map(block => {
            console.log({block});
            if (
              block.attributes.type === 'Bootstrap Components' ||
              (block.attributes.html &&
                block.attributes.html !== '' &&
                block.attributes.category === 'Grids' ||  block.attributes.category === 'Spacing' || block.attributes.traits?.length) ||
              block.attributes.type === 'layout'
            ) {
              if (category !== block.attributes.type) {
                category = block.attributes.type;
                renderExtraLabel = true;
              } else {
                renderExtraLabel = false;
              }
              return (
                <>
                  {renderExtraLabel && (
                    <Col md={24}>
                      <p style={{ color: '#d278c9', margin: 'auto' }}>
                        {category ? category === 'layout' ? 'DRUPAL COMPONENTS' :  category.toUpperCase() : 'Extra'}
                      </p>
                    </Col>
                  )}
                  <Col md={24}>
                    <div
                      className="block"
                      draggable={true}
                      onDragStart={() => {
                        dragStart(block);
                      }}
                      onDragEnd={() => dragStop()}
                      // onClick={() => {
                      //   // editor.DomComponents.addComponent(block.attributes);
                      //   if(editor.getSelected()){
                      //         editor.getSelected().append(block.get('content'))
                      //         // editor.trigger('block:drag:stop', [editor.getSelected(),block]);

                      //     console.log([editor.getSelected(),block])
                      //   }else{
                      //        editor.getWrapper().append(block.get('content'));
                      //       //  editor.trigger('block:drag:stop', [editor.getWrapper(),block]);

                      //       console.log([editor.getWrapper(),block])

                      //   }
                      //   dragStart(block);
                      //   dragStop();
                      //   // editor.trigger('block:drag:stop', { block: blockElement });
                      //   console.log(editor.getSelected());
                      // }}
                    >
                      <p>
                        <span style={{ fontWeight: 'bolder' }}>
                          {block.attributes.category}
                        </span>{' '}
                        - {block.attributes.label}
                      </p>
                    </div>
                  </Col>
                </>
              );
            }

            return null;
          })}
        </Row>
      </div>
    </>
  );
};

export default CustomBlockManager;
