const getHtml = () => {
    return `
      <div class="input-group-text">
        <input type="radio" id="inputRadio" aria-describedby="radio input">
      </div>
    `;
  };
  
  const getCss = () => {
    return ``;
  };
  
  const getJs = () => {
    return ``;
  };
  
  const RadioInput = { getHtml, getCss, getJs };
  
  export default RadioInput;
  