import React from 'react';

import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './store';

import Layout from './components/layout';
import routes from '../src/utils/constants/routes';
const App = () => {
  const routeComponents = routes.map(
    ({ route, component, layout, authenticated }, key) => (
      <Route
        path={route}
        element={
          layout ? (
            <Layout isLogout={authenticated}>{component}</Layout>
          ) : (
            <>{component}</>
          )
        }
      />
    )
  );

  return (
    <Router>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Routes>{routeComponents}</Routes>
        </PersistGate>
      </Provider>
    </Router>
  );
};

export default App;
