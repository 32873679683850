const getHtml = () => {
    return `
      <div class="container" data-gjs-type="default" >
        <div class="row">
          <div class="col" data-gjs-type="default" style="min-height : 50px;"></div>
        </div>
      </div>
    `;
  };
  
  const getCss = () => {
    return `
    .col{
      background-image: url('https://cdn.ayroui.com/1.0/images/card/card-1.jpg')
  }
    `;
  };
  
  const getJs = () => {
    return ``;
  };
  
  const OneColumn = { getHtml, getCss, getJs };
  
  export default OneColumn;
  