const getHtml = () => {
  return `
        <h6>Example heading <span class="badge bg-secondary">New</span></h6>
        `;
};

const getCss = () => {
  return ``;
};

const getJs = () => {
  return ``;
};

const H6Badge = { getHtml, getCss, getJs };

export default H6Badge;
