import AtomsLibrary from '../../../../utils/library/atoms';
const blockList = [];
const EditorConfig = () => ({
  height: 900,
  storageManager: false,
  blockManager: {
    appendOnClick: false,
    custom: true,
    blocks: blockList,
  },
});
export default EditorConfig;
